import React, { useState, useEffect } from 'react'
import CBHeader from '../core/components/Header'
// import Footer from '../core/components/Footer'
import { Layout } from 'antd'

const { Header, Content, Sider, Footer } = Layout

import { Authenticator } from 'aws-amplify-react'
import { useGlobalState } from '../../store'
import { navigate } from '@reach/router'
import { loadSyncs, loadAllData } from '../../utils/fetchData'
import TrialBlockPage from '../core/pages/TrialBlockPage'
import MediaQuery from 'react-responsive'

const federated = {
  google_client_id:
    '479956927646-jkvb3l683vnejv0ne9dmbjk8hlacvoca.apps.googleusercontent.com',
}

const DashboardRoute = ({ location, children }) => {
  const [authState, setAuthState] = useState(null)
  const [trialBlock, setTrialBlock] = useState(false)
  const [, setAccounts] = useGlobalState('accounts')
  const [, setLoadingCoreData] = useGlobalState('loadingCoreData')
  const [user, setUser] = useGlobalState('user')
  const [, setSyncs] = useGlobalState('syncs')
  const [, setSchedules] = useGlobalState('schedules')
  const [, setIntegrations] = useGlobalState('integrations')
  const [license, setLicense] = useGlobalState('license')

  const [, setErrors] = useGlobalState('errors')
  const [collapsed, setCollapsed] = useState(false)
  let refreshInterval = null

  const onStateChange = (authState, data) => {
    setAuthState(authState)
    if (authState === 'signIn') {
      navigate('/login')
    }
    setUser(data)
  }

  useEffect(() => {
    setLoadingCoreData(true)
    async function fetchData() {
      
      if (!user || !user.username) {
        return
      }
      const { accounts, syncs, schedules, license, error, integrations } =
        await loadAllData(user)

      console.log("LOADING DATA")
      
      if (error) {
        
        setErrors("there was an error")
        console.error(error)
        return
      }
      setAccounts(accounts)
      setSyncs(syncs)
      setSchedules(schedules)
      setLicense(license)
      setIntegrations(integrations)
      setLoadingCoreData(false)

      if (syncs.some((s) => s.status === 'syncing')) {
        refreshInterval = setInterval(async () => {
          const syncs = await loadSyncs()
          setSyncs(syncs)

          if (syncs.some((s) => s.status !== 'syncing')) {
            clearInterval(refreshInterval)
          }
        }, 3000)
      }

      if (!license.active && (license.trial || license.wasDeactivated)) {
        setTrialBlock(true)
      }
    }
    fetchData()
  }, [authState, user && user.username, license && license.active])

  if (trialBlock) {
    return (
      <div style={{ maxWidth: 1000, margin: '0 auto' }}>
        <TrialBlockPage />
      </div>
    )
  }

  return (
    <>
      <MediaQuery minDeviceWidth={1224}>
        <Layout className="cb-full-frame">
          <Sider
            width={300}
            className="cb-content-frame"
            style={{ background: 'white' }}
            // style={{
            //   borderStyle:"solid",
            //     borderWidth: '1px',
            //     borderRadius: '2px',
            //     borderColor: '#E6EAF0'
            // }}
            onCollapse={(collapsed) => {
              // setCollapsed(collapsed)
            }}
            
          >
            <CBHeader
              collapsed={collapsed}
              authstate={authState}
              location={location}
            ></CBHeader>
          </Sider>
          <Content
          //  style={{minHeight:2080

          // }}
          >
            <Authenticator
              hideDefault={true}
              federated={federated}
              onStateChange={onStateChange}
            >
              <>{children}</>
            </Authenticator>
          </Content>
        </Layout>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={1224}>
        <div style={{ maxWidth: 1000, margin: '0 auto' }}>
          <CBHeader authstate={authState} location={location} mobile={true} />
          <Authenticator
            hideDefault={true}
            federated={federated}
            onStateChange={onStateChange}
          >
            <>{children}</>
          </Authenticator>
        </div>
      </MediaQuery>
    </>
  )
}

export default DashboardRoute
