import React, { useState, useEffect } from 'react'
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Card, Col, Row, Menu, Dropdown, Avatar,Checkbox } from 'antd'


import { API } from 'aws-amplify'
import moment from 'moment'

import momentTz from 'moment-timezone'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBColorPicker,
  CBModal,
  CBSelect,
  CBList,
} from '../../core/components/cbd'
import {
  CalendarOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  InfoCircleTwoTone,
  UserOutlined,
} from '@ant-design/icons'

import Carat from '../../../images/arrow-right-chevron.png'
import Add from '../../../images/svg/carat.svg'

import Mail from '../../../images/svg/mail.svg'
import Scheduling from '../../../images/svg/logo-scheduling.svg'
import Envelope from '../../../images/svg/envelope.svg'
import More from '../../../images/svg/menu-more-horizontal.svg'
import Trash from '../../../images/svg/trash-delete.svg'

import Edit from '../../../images/svg/edit.svg'
const googleColors = [
  
  '#EF972C',
  '#16E6B7',
  '#6F5D70',
  '#A2E5F4',
  '#C8C9CA',
  '#3A677D',
  '#717234',
  '#FF484F',
  '#F1F104',
  '#a4bdfc',
]

const CopyElement = ({text="hi"}) => {
  const [copied,setCopied] = useState(false)
  return <a className="text-sm" onClick={
    e=>{
      // alert(link.toString())
      navigator.clipboard.writeText(text.toString())
      setCopied(true)
    }
  }>{copied ? 'copied' : 'copy'}</a>
}
const GroupItem = ({ item, preview = false,timezone }) => {
  const [force, setForce] = useState(null)
  const [excluded, setExcluded] = useState([])

  const [booking, setBooking] = useState(false)
  function forceBook(params) {
    setForce(params)
  }

  useEffect(() => {
    //console.log(excluded)
  }, [excluded])

  async function forceDelete(id) {
    try {
      const group = await API.post(
        'group-manage',
        '/group-manage?forceDelete=true',
        {
          body: {
            groupId: id,
            start: 'a',
            end: 'b',
            timezone: 'nah',
          },
        }
      )
      window.location.reload()
    } catch (e) {
      console.log('error')
    }
  }

  async function book() {
    // console.log({
    //   groupId: force.group.group.id,
    //   start: force.start,
    //   end: force.end,
    //   timezone: 'nah',
    // })
    setBooking(true)
    try {
      const group = await API.post(
        'group-manage',
        '/group-manage?forceBook=true',
        {
          body: {
            groupId: force.group.group.id,
            start: force.start,
            end: force.end,
            timezone: 'nah',
            excluded,
          },
        }
      )
      //console.log('DONE')
      window.location.reload()
    } catch (e) {
      console.log(e)
      alert(e.response.data.message)
      setBooking(false)
      
    }
  }
  const returnFlattenObject = (arr) => {
    const flatObject = {};
    for(let i=0; i<arr.length; i++){
       for(const property in arr[i]){
          flatObject[`${property}`] = arr[i][property];
       }
    };
    return flatObject;
 }
  var e = item
  //filter pending
  // var force = {}
  var entryMap = {}
  var votes = {}
  var colorMap = {}
  var colorInc = 0
  const flatUsers = returnFlattenObject(e.group.attendees)
  // console.log("FLAT")
  // console.log(flatUsers)
  e.group.attendees.forEach((e) => {
    var id = Object.keys(e)[0]
    colorMap[id] = googleColors[colorInc]
    colorInc++
  })

  e.entries.forEach((entry) => {
    // console.log(entry)
    // console.log(entry.userId)
    entryMap[entry.userId.split('-')[1]] = true

    entry.choices.forEach((choice) => {
      var timeSignature = moment(choice.start).format('LLLL')
      var thing = votes[timeSignature]
      if (thing) {
        thing['votes'] = thing['votes'] + 1
        thing['people'] = [entry.userId.split('-')[1], ...thing['people']]
      } else {
        // e.group.choices.push(choice)
        thing = {
          votes: 1,
          people: [entry.userId.split('-')[1]],
        }
      }
      votes[timeSignature] = thing //votes[choice.start] != null ? votes[choice.start]+1 : 1
    })
  })

  var pending = e.group.attendees.filter((e) => {
    var key = Object.keys(e)[0]
    return entryMap[key] == null
  })
  // console.log(e)
  var votes = e.group.choices.map((choice) => {
    var timeSignature = moment(choice.start).format('LLLL')
      
    if (votes[timeSignature] != null) {
      choice['votes'] = votes[timeSignature]
    } else {
      choice['votes'] = { votes: 0, people: [] }
    }
    return choice
  })
  const handleClick = (ev) => {
    if (ev.key === '0') {
      //showForceDeleteConfirm()
      forceDelete(e.group.id)
    } else if (ev.key === '1') {
      // showDeleteConfirm()
    } else if (ev.key === '2') {
      //showEditConfirm()
    } else if (ev.key === '3') {
      //navigate(`/dashboard/syncs/${item.id.replace('#', '___')}/edit`)
    }
  }
  const menu = (
    <Menu onClick={handleClick} style={{ width: 125 }}>
      <Menu.Item key="0">
        {/* <Icon type="stop" /> */}
        {e.group.status != 'complete' ? 'Cancel' : 'Delete'}
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <div className="rounded-sm border-2 border-neutral_1 mt-2 mb-6 flex flex-col shadow-md">
        <div className="flex flex-col pl-4 pr-4 pb-4">
          <CBHeader
            label={<div>{e.group.subject}</div>}
            icon={<Mail></Mail>}
            menu={
              <div className="flex-grow">
                {!preview && (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <CBButton type="ternary" icon={<More />} size="large" />
                  </Dropdown>
                )}
              </div>
            }
          ></CBHeader>
          <div
            style={{
              marginTop: 16,
              fontFamily: 'Sarabun',
              fontSize: '16px',
              color: '#49515C',
            }}
          >
            {e.group.result && <div>{e.group.result}</div>}

            {!e.group.result && (
              <>
                {moment(e.group.rangeStart).format('LL')} -{' '}
                {moment(e.group.rangeEnd).format('LL')}
              </>
            )}
          </div>

          <div
            style={{
              marginTop: 16,
              marginBottom: 16,
              fontFamily: 'Sarabun',
              fontSize: '16px',
              color: '#8994A3',
            }}
          >
            {e.group.body != '' && (
              <>
                {' '}
                {'"'}
                {e.group.body}
                {'"'}
              </>
            )}
           
          </div>
          {!preview && e.group.status == 'active' && <div className="text-lg font-sarabun flex flex-row gap-x-4 items-center">
          <a target="_blank" href={`${window.location.origin}/book/${e.group.parentId.replace("/__me__","")}/${e.group.id}/g`}>Group Link</a>
          <CopyElement text={`${window.location.origin}/book/${e.group.parentId.replace("/__me__","")}/${e.group.id}/g`.toString()}></CopyElement>
                    </div>
                    }
          {/* {e.group.status == 'failed' &&
          <div className="bg-neutral_1 font-sarabun text-neutral_4 p-3">Your invite failed to reach a quorum, you may select a meeting time below to book a time anyway.</div>} */}
          {preview && (
            <div className="font-sarabun text-neutral_4">
              <div>Invitees:</div>
              <CBList label="Invitees" borderLight={true}>
                {e.group.attendees.map((e) => (
                  <CBListItem borderLight={true} label={e.email}>{e.email}</CBListItem>
                ))}
              </CBList>
              
            </div>
          )}
          {(e.group.status == 'active' || e.group.status == 'failed') && (
            <>
              <Row>
                <Col span={24} style={{ marginTop: 16 }}>
                  {pending.length > 0 && <>
                  <div className="title_label">Waiting on response</div>
                  <div style={{ marginTop: 8 }}>
                    <CBList borderLight={true}>
                      {pending.map((member) => {
                       
                        var key = Object.keys(member)[0]
                        var values = Object.values(member)[0]

                        return (
                          <CBListItem borderLight={true} type="list"
                          blockClick={!force}
                          onClick={e=>{
                           
                            if(force){
                              if(excluded.indexOf(member.email) != -1){
                                setExcluded(
                                  excluded.filter(
                                    (e) => e != member.email
                                  )
                                )
                              } else{
                                setExcluded([...excluded, member.email])
                              }
                            }
                          }}
                          icon={<>
                          {force && (
                                <Checkbox
                                  style={{ marginLeft: 8 }}
                                  value={excluded.indexOf(member.email) == -1}
                                  onChange={(e) => {
                                    
                                    if (e.target.checked) {
                                      setExcluded(
                                        excluded.filter(
                                          (e) => e != values.email
                                        )
                                      )
                                    } else {
                                      setExcluded([...excluded, values.email])
                                    }
                                  }}
                                >
                                  
                                </Checkbox>
                              )}
                          </>}>
                            <div className="flex flex-row gap-x-4 items-center">
                              {!preview && (
                                <div clasName="flex-1"><Avatar style={{ background: colorMap[key] }}>
                                  {values.email.slice(0, 2).toUpperCase()}
                                </Avatar></div>
                              )}
                              {values.email}{' '}
                              {!preview && process.env.GATSBY_DEBUG_UI == 'true' && (
                                <>
                                <a target="_blank"
                                  href={`${process.env.GATSBY_BOOKING_LINK_URL}/book/${e.group.parentId}/${e.group.id}/${key}`}
                                >
                                 Personalized Link
                                </a>
                                <CopyElement text={`${process.env.GATSBY_BOOKING_LINK_URL}/book/${e.group.parentId}/${e.group.id}/${key}`}></CopyElement>
                                </>
                              )}
                              
                            </div>
                          </CBListItem>
                        )
                      })}
                    </CBList>
                  </div></>}
                  {e.entries.length > 0 && (
                    <div>
                      <div className="flex font-sarabun text-neutral_4 mb-2">Responded{force &&<div className="flex-grow text-right pr-2">Invite</div>}</div>
                      <CBList borderLight={true}>
                        {e.entries.map((entry) => {
                          var entryId = entry.userId.split('-')[1]
                          var user = e.group.attendees.filter(
                            (e) => e[entryId] != null
                          )[0][entryId]

                          return (
                            <CBListItem borderLight={true}
                            blockClick={!force}
                            onClick={e=>{
                            
                              if(force){
                               
                                if(excluded.indexOf(user.email) != -1){
                                  
                                  setExcluded(
                                    excluded.filter(
                                      (e) => e != user.email
                                    )
                                  )
                                } else{
                                  
                                  setExcluded([...excluded, user.email])
                                }
                              }
                            }}
                            icon={<>
                            {force && (
                                  <Checkbox
                                    style={{ marginLeft: 8 }}
                                    checked={excluded.indexOf(user.email) == -1}
                                    onChange={(e) => {
                                      
                                      if (e.target.checked) {
                                        setExcluded(
                                          excluded.filter(
                                            (e) => e != user.email
                                          )
                                        )
                                      } else {
                                        setExcluded([...excluded, user.email])
                                      }
                                    }}
                                  >
                                    
                                  </Checkbox>
                                )}
                            </>}
                            >
                              <div className="flex flex-row gap-x-4 items-center">
                                {!preview && (
                                  <Avatar
                                    style={{ background: colorMap[entryId] }}
                                  >
                                    {user.email.slice(0, 2).toUpperCase()}
                                  </Avatar>
                                )}
                                {user.email}{' '} 
                                {entry.choices.length > 0 ? (
                                  <> </>
                                ) : entry.calendars.length > 0 ? (
                                  <>(Auto schedule)</>
                                ) : (
                                  <>(Declined)</>
                                )}
                                
                                
                              </div>
                            </CBListItem>
                          )
                        })}
                      </CBList>
                    </div>
                  )}
                </Col>
                {/* <Row style={{marginTop:16}}>
            <div style={{color:'#49515C', fontFamily: 'Sarabun', marginBottom:'8px'}}>Votes</div>
           <Row>
          {votes.map(choice => 
           <LongButton type="list" icon={ <span style={{float:'right'}}>{choice["votes"]["votes"]}</span>}  onClick={ev=> forceBook({
              "group": e,
                "start":choice.start,
                "end":choice.end
              })}>{momentTz(choice.start).format('h:mm a')} - {momentTz(choice.end).format('h:mm a (MM/DD)')}  </LongButton>  
             )}
              </Row>
          </Row> */}
                
              </Row>
            </>
          )}

          
          {/* {e.group.status != 'complete' && (
                <>
                  <Col span={24} style={{ marginTop: 16, marginRight: 8 }}>
                    <CBButton
                      type="secondary"
                      onClick={(ev) => {
                        forceDelete(e.group.id)
                      }}
                    >
                      Cancel
                    </CBButton>
                  </Col>
                </>
              )} */}

          {e.group.status == 'complete' && (
            <>
              <div style={{ marginTop: 16 }}>
                Attendees
                {e.group.attendees.map((e) => {
                  var user = Object.values(e)[0]
                  var entryId = Object.keys(e)[0]
                  return (
                    <CBListItem borderLight={true} type="list" blockClick={true}>
                      {user.email}
                    </CBListItem>
                  )
                })}
              </div>

              <div></div>
            </>
          )}
        </div>
        {preview && (
          <div className="p-2 flex flex-col font-sarabun text-neutral_4 bg-neutral_1">
            <div className="mb-2 ml-2">Event Times ({`${timezone}`})</div>
             <CBList label="Event Times">
              {e.group.choices.map((choice) => (
                <CBListItem>
                  {momentTz(choice.start).format('h:mm a')} -{' '}
                  {momentTz(choice.end).format('h:mm a z (MMM DD)')}
                </CBListItem>
              ))}
            </CBList>
          </div>
        )}
        {(e.group.status == 'active' || e.group.status == 'failed')  && (
          <div className="bg-neutral_1 p-4 flex flex-col justify-center">
            
              <>
                <div
                  style={{
                    color: '#49515C',
                    fontFamily: 'Sarabun',
                    marginBottom: '8px',
                  }}
                >
                  Votes ({`${timezone}`})
                </div>
                {e.group.status == 'failed' && <div className="p-2 m-2 bg-red-100 text-red-600 ">(!) None of the proposed times worked for all invitees. {votes.filter((choice) => momentTz(choice.start).isAfter(momentTz())).length > 0 ? <>To book anyways, select a time below.</> : <></>}</div>}
           
                <>
               
                  <CBList>
                    {votes.map((choice) => {
                      
                       const inPast = momentTz(choice.start).isBefore(momentTz())
                      return (
                      <CBListItem
                        type="list"
                        icon={
                          <div className="flex flex-col md:flex-row lg:flex-row gap-x-2 items-center">
                            {choice['votes']['votes'] > 0 && (
                              <div>
                                <Avatar.Group maxCount={4}>
                                {choice['votes']['people'].map((p) => (
                                  <Avatar style={{ background: colorMap[p] }}>
                                    {
                                      flatUsers[p].email.slice(0,2).toUpperCase()
                                      // e.group.attendees.filter((u) => {
                                      //   console.log(u)
                                      //   console.log(p)
                                      //   return u[p] != null
                                      // })[0][p].email.slice(0,2)
                                    }
                                  </Avatar>
                                ))}
                                </Avatar.Group>
                                
                              </div>
                            )}
                            <span
                              className="bg-white rounded-full pl-4 pr-4 pt-1 pb-1"
                              style={{ float: 'right' }}
                            >
                              {choice['votes']['votes']}
                            </span>
                            
                            { !inPast ? <img src={Carat}></img> : <div className="m-1 text-red-600">expired</div>}
                          </div>
                        }
                       
                        blockClick={ inPast}
                        
                        onClick={(ev) => {
                          
                          if(!inPast) {
                          forceBook({
                            group: e,
                            start: choice.start,
                            end: choice.end,
                          })
                        } 
                        }}
                      > 
                        {momentTz(choice.start).format('h:mm a')} -{' '}
                        {momentTz(choice.end).format('h:mm a (MMM DD)')}{' '}
                      </CBListItem>
                    )})}
                  </CBList>
                  <div>
            {force && (
              <>
              <div className="font-sarabun mb-2">Selected meeting</div>
              <CBListItem icon={<Trash></Trash>} onClick={q=>{
                setForce(null)
              }}>{momentTz(force.start).format(
                    'h:mm a'
                  )} - {momentTz(force.end).format('h:mm a (MMM DD)')}</CBListItem>
              <div className="mt-4">
                <SubmitButton
                  disabled={excluded.length == e.group.attendees.length}
                  loading={booking}
                  onClick={(e) => book()}
                >
                  {' '}
                  Send Invite Anyway 
                </SubmitButton>
                
              </div>
              </>
            )}
          </div>
                </>
              </>
            
          </div>
        )}
      </div>
    </>
  )
}

export default GroupItem
