import React, { useCallback } from 'react'
import { CBListItem, CBList } from '../../../../../core/components/cbd'
import Trash from '../../../../../../images/delete.png'
import map from 'lodash/map'
import filter from 'lodash/filter'
import momentTz from 'moment-timezone'
import { useGroupScheduleContext } from '../../GroupScheduleContext'

const SelectedItem = ({ item, onSelectItem }) => {
  const tz = momentTz.tz.guess()
  var eventStart = momentTz(item.start).tz(tz)
  var eventEnd = momentTz(item.end).tz(tz)

  const onClick = useCallback(() => {
    onSelectItem(item)
  }, [item, onSelectItem])

  return (
    <CBListItem
      borderLight={true}
      icon={<img src={Trash} style={{ float: 'right' }} />}
      style={{ marginBottom: 16 }}
      key={item.start + item.end}
      onClick={onClick}
    >
      {`${eventStart.format('h:mm a')} - ${eventEnd.format('h:mm a (MM/DD)')}`}
    </CBListItem>
  )
}

export const SelectedButtons = ({ selectedItems, isSingleSelect }) => {
  const { dispatch } = useGroupScheduleContext()

  const onSelectItem = useCallback(
    (item) => {
      let newSelectedItems = []
      if (isSingleSelect) {
        newSelectedItems = [item]
      } else if (selectedItems.includes(item)) {
        // deselect
        newSelectedItems = filter(selectedItems, (sItem) => sItem !== item)
      } else {
        newSelectedeItems = Array.from(new Set([...selectedItems, item]))
      }

      dispatch({ type: 'setSelectedItems', selectedItems: newSelectedItems })
    },
    [isSingleSelect, selectedItems]
  )

  return (
    <>
      {!!selectedItems.length && (
        <div className="mb-8">
          <div style={{ marginBottom: 16 }}>Selected Times</div>
          <CBList borderLight={true}>
            {map(selectedItems, (item) => (
              <SelectedItem item={item} onSelectItem={onSelectItem} />
            ))}
          </CBList>
        </div>
      )}
    </>
  )
}
