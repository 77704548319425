import React, { useCallback, useMemo } from 'react'
import get from 'lodash/get'
import { useNavigate } from '@reach/router'

import Close from '../../../../../images/svg/x-close.svg'
import {
  CBHeader,
  CBInput,
  CBTextArea,
  SubmitButton,
} from '../../../../core/components/cbd'
import { useGlobalState } from '../../../../../store'
import { useGroupScheduleContext } from '../GroupScheduleContext'

export const NameDescription = () => {
  const { state, dispatch, onSetCurrentPage } = useGroupScheduleContext()
  const navigate = useNavigate()
  const [accounts] = useGlobalState('accounts')
  const [license] = useGlobalState('license')

  const showAddCalendarButton = useMemo(
    () => Boolean(get(license, 'userId') && !accounts.length),
    [(accounts, license)]
  )

  const { eventSubject, eventDescription } = useMemo(() => {
    return {
      eventSubject: get(state, 'eventSubject'),
      eventDescription: get(state, 'eventDescription'),
    }
  }, [state])

  const onCancel = useCallback(() => {
    navigate('/dashboard/group', { replace: true })
  }, [])

  const onCreateNewCalendarAccount = useCallback(() => {
    navigate('/dashboard/accounts/new', { replace: true })
  }, [])

  const onSetEventSubject = useCallback(
    (newSubject) => {
      dispatch({ type: 'setEventSubject', eventSubject: newSubject })
    },
    [dispatch]
  )

  const onSetEventDescription = useCallback(
    (newDesciption) => {
      dispatch({ type: 'setEventDescription', eventDescription: newDesciption })
    },
    [dispatch]
  )

  const onSubmit = useCallback(() => {
    onSetCurrentPage(1)
  }, [onSetCurrentPage])

  return (
    <div className="cb-content-pane mobile_padding min-h-screen">
      <CBHeader
        step={1}
        label="Name"
        buttonLabel="Cancel booking"
        buttonIcon={<Close />}
        buttonAction={onCancel}
      />

      {!!showAddCalendarButton && (
        <center className="mt-8 mb-8 text-lg">
          <a onClick={onCreateNewCalendarAccount}>
            Add calendar account and create a scheduler!
          </a>
        </center>
      )}

      <div className="font-sarabun text-neutral_4 mt-8 mb-8">
        Set an event name for the subject line of the invitation email.
      </div>

      <CBInput
        label="Event subject"
        value={eventSubject}
        onChange={onSetEventSubject}
      />
      <div className="mt-8"></div>

      <CBTextArea
        label="Event Description (optional)"
        value={eventDescription}
        onChange={onSetEventDescription}
      />

      <div className="mt-8">
        <SubmitButton
          badge={2}
          disabled={!eventSubject}
          type="primary"
          onClick={onSubmit}
        >
          Continue to Availability
        </SubmitButton>
      </div>
    </div>
  )
}
