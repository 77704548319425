import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Table, Button, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons'

import OrgTable from './OrgTable'
import FullOrgEditModal from './Modals/FullOrgEditModal'

const OrgScreen = () => {
	var placeholderOrg = {
		stripeId: "",
		subId: "",
		orgInterval: "monthly",
		allowedDomains: [],
		new: true
	}
	const [organizationData, setOrganizationData] = useState({})
	const [modalFormLoading, setModalFormLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [lastEvaluatedKey, setLastEvaluatedKey] = useState(false)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const [selectedOrganizationData, setSelectedOrganizationData] = useState(placeholderOrg)

	async function loadOrganizationData() {
		setLoading(true)
		try {
			const orgResponse = await API.get('scan', '/scan', {
				queryStringParameters: {
					action: 'getOrganizationList'
				},
			})
			setOrganizationData(orgResponse.results)
		} finally {
			setLoading(false)
		}
	}

	async function upsertOrgData() {
		try {
			if (selectedOrganizationData.orgId) {
				const apiData = await API.post('scan', '/scan', { body: { ...selectedOrganizationData, action: "updateOrganization" } });
				// console.log(apiData)
				setSelectedOrganizationData(placeholderOrg)
				loadOrganizationData();
			} else {
				alert("bad")
			}
		} catch (ex) {
			console.log(ex)
		}
	}

	useEffect(() => {

		loadOrganizationData();
	}, []);

	if (loading) {
		return <FontAwesomeIcon icon={faSpinner} spin />
	}
	if (error) {
		return <div>{error}</div>;
	}


	return (<div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
		<Row>
			<Col span={6} offset={18}>
				<Button type="primary" onClick={() => {
					//TODO launch modal
					// console.log('Add organization button clicked.')
					setSelectedOrganizationData(placeholderOrg)
					setModalVisible(true);
				}}>Add Organization</Button>
			</Col>
		</Row>
		<Row>
			<Col span={24} offset={0}>
				<OrgTable
					organizationData={organizationData}
					editOrganizationHandler={(organization) => {
						// console.log('Edit organization button clicked.', organization)
						setSelectedOrganizationData(organization)
						setModalVisible(true);

					}}
				/>
			</Col>
		</Row>
		{selectedOrganizationData && modalVisible && <FullOrgEditModal
			title={'Add/Edit Org'}
			visible={modalVisible}
			handleOk={() => {
				
				setModalVisible(false);
				upsertOrgData()
			}}
			confirmLoading={modalFormLoading}
			handleCancel={() => {
				setSelectedOrganizationData(placeholderOrg)
				setModalVisible(false);
			}}
			organizationData={selectedOrganizationData}
			setOrgData={e => {
				console.log("SET ORG DATA")
				console.log(e)
				setSelectedOrganizationData(e)
			}}
		/>}
	</div>)
}

export default OrgScreen