import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import JSONTree from 'react-json-tree'
import { Empty, Input, Card, Col, Row, Button } from 'antd'
import get from 'lodash/get'
import SyncItem from "./SyncItem";
import LicenseInfo from './LicenseInfo'
import QueryString from 'query-string'


const BoomerScanAccountList = ({ initialAccounts, theme, userEmail, deleteCallback, pageLimit = 100 }) => {
    const [accounts, setAccounts] = useState(initialAccounts);
    const [moreAccounts, setMoreAccounts] = useState(initialAccounts.length === pageLimit)

    useEffect(() => {
        setMoreAccounts(accounts.length % pageLimit === 0);
    }, [accounts]);

    async function loadNewAccountPage() {
        //get last key. 'calendarAccountId' for accounts. 'id' for bridge/sync
        const lastKey = get(accounts.at(-1), 'calendarAccountId')
        const parameters = {
            email: userEmail,
            limit: pageLimit,
            exclusiveStartIdString: lastKey,
            action: 'getAccountPage'
        }
        try {
            var responseAccountInfo = await API.get('scan', '/scan', {
                queryStringParameters: parameters,
            })
            const newAccountsPage = get(responseAccountInfo, 'results.accounts') || [];
            if (newAccountsPage.length) {
                setAccounts([...accounts, ...newAccountsPage]);
            } else {
                setMoreAccounts(false);
            }
        } catch (ex) {
            alert('Error: getting new accounts page')
            // console.log(ex.message);
        } finally {

        }
    }

    return (
        <div >
            Accounts:
            <div className="grid lg:grid-cols-4 grid-cols-2">
            {accounts && accounts.map((account) => (
                <Card style={{ marginBottom: 10 }}>{account.email} - {account.calendarAccountType}
                    {account.errorState != "" && <div className="text-red-700">Error State: {account.errorState}</div>}
                    <JSONTree
                        data={[account]}
                        hideRoot={true}
                        theme={theme}
                    />
                    <Button onClick={e=>{
                      deleteCallback(account.calendarAccountId)
                        
                    }}>Delete</Button>
                </Card>
            ))}
            </div>

            {moreAccounts && <Button onClick={() => {
                alert("load more accounts");
                loadNewAccountPage();
            }}>Load More Accounts</Button>}
        </div>
   );
}

export default BoomerScanAccountList;