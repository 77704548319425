import 'react-universal-hooks'
import React from 'react'
import { Router } from '@reach/router'
import Amplify from 'aws-amplify'

import { setupUserSession } from '../utils/auth'
import { GlobalStateProvider } from '../store'
import apiConfig from '../utils/apiConfig'

import Layout from '../components/Layout'
// Routing Wrappers
import DashboardRoute from '../components/routers/DashboardRoute'
import ItemDetailRoute from '../components/routers/ItemDetailRoute'
// Core
import SettingsPage from '../components/core/pages/SettingsPage'
import HomePage from '../components/core/pages/HomePage'
// Accounts
import AccountListPage from '../components/accounts/pages/AccountListPage'
import AccountCreatePage from '../components/accounts/pages/AccountCreatePage'
import AccountDetailPage from '../components/accounts/pages/AccountDetailPage'
import AccountEditPage from '../components/accounts/pages/AccountEditPage'
// Syncs
import SyncListPage from '../components/syncs/pages/SyncListPage'
import SyncCreatePage from '../components/syncs/pages/SyncCreatePage'
import SyncCreatePageBatch from '../components/syncs/pages/SyncCreatePageBatch'
import SyncDetailPage from '../components/syncs/pages/SyncDetailPage'
import SyncCreateCompletePage from '../components/syncs/pages/SyncCreateCompletePage'
// Group Scheduling
import GroupScheduleListPage from '../components/schedules/group/GroupScheduleListPage'
import ActivateAccountPage from '../components/schedules/group/ActivateAccountPage'

import { GroupScheduleCreatePage } from '../components/schedules/group/GroupScheduleCreatePage'
// Scheduling
import ScheduleListPage from '../components/schedules/pages/ScheduleListPage'
import ScheduleCreatePage from '../components/schedules/pages/ScheduleCreatePage'
import ScheduleCreateCompletePage from '../components/schedules/pages/ScheduleCreateCompletePage'
import ScheduleDetailPage from '../components/schedules/pages/ScheduleDetailPage'
// Extra
import ReferralPage from '../components/core/pages/ReferralPage'
import AdminScanPage from '../components/admin/pages/AdminScanPage'

// Organization
import OrganizationPage from '../components/organization/pages/OrganizationPage'
import CreateOrganizationPage from '../components/organization/pages/CreateOrganizationPage'

//Integrations
import ZoomAuthPage from '../components/integrations/pages/ZoomAuthPage'
import ZoomActivatePage from '../components/integrations/pages/ZoomActivatePage'

Amplify.configure(apiConfig)

export default class DashboardPage extends React.Component {
  constructor() {
    setupUserSession()
    super()
  }

  render = () => (
    <GlobalStateProvider>
      <Layout location={this.props.location}>
        <Router>
          <DashboardRoute path="/dashboard">
            {/* Core */}
            <HomePage path="/" />
            <SettingsPage path="settings" />

            {/* Accounts */}
            <AccountListPage path="accounts" />
            <AccountCreatePage path="accounts/new" />
            <ItemDetailRoute path="accounts/:id">
              <AccountDetailPage path="/" />
              <AccountEditPage path="edit" />
            </ItemDetailRoute>

            {/* Syncs */}
            <SyncListPage path="syncs" />
            <SyncCreatePage path="syncs/new" />
            <SyncCreateCompletePage path="syncs/complete" />

            {/* Syncs Batch */}
            {/* <SyncListPage path="syncs_batch" />
            <SyncCreatePageBatch path="syncs_batch/new" />
            <SyncCreateCompletePage path="syncs_batch/complete" /> */}

            <ItemDetailRoute path="syncs/:id">
              <SyncCreatePage path="/" />
              <SyncCreatePage path="edit" />
            </ItemDetailRoute>

            {/* Scheduling */}
            <ScheduleListPage path="schedules" />
            <ScheduleCreatePage path="schedules/new" />
            <ScheduleCreateCompletePage path="schedules/complete" />
            {/* Group Scheduling */}
            <GroupScheduleListPage path="group/" />
            <GroupScheduleCreatePage path="group/new" />
            <ActivateAccountPage path="group/contacts" />
            <ItemDetailRoute path="schedules/:id">
              <ScheduleDetailPage path="/" />
              <ScheduleCreatePage path="edit" />
            </ItemDetailRoute>

            {/* Admin */}
            <AdminScanPage path="admin/scan"></AdminScanPage>

            {/* Extra */}
            <ReferralPage path="referrals" />

            {/* Organization */}
            <OrganizationPage path="organization" />
            <CreateOrganizationPage path="organization/new" />

            {/* Integrations */}
            <ZoomAuthPage path="zoom" />
            <ZoomActivatePage path="zoom/deauth" />
          </DashboardRoute>
        </Router>
      </Layout>
    </GlobalStateProvider>
  )
}
