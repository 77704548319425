import React, { useMemo, useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import JSONTree from 'react-json-tree'
import { Button, Card, Select, Col, Row } from 'antd'
import ScanSyncItem from './ScanSyncItem'
import BoomerCalendar from './BoomerCalendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

function SyncItem({ bridge, theme, accounts, pushSubscriptionStatus }) {
	const [infoExpanded, setInfoExpanded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [ver, setVer] = useState({});
	const [currentMonth, setCurrentMonth] = useState(0)
	const findSrcAccount = accounts.filter(acc => acc.calendarAccountId == bridge.sourceCalendarAccountId)
	const findDestAccount = accounts.filter(acc => acc.calendarAccountId == bridge.destCalendarAccountId)
	
	const sourceAccount = findSrcAccount.length > 0 ? findSrcAccount[0] : {displayName: "Src Account Missing", email: "Something is wrong"}
	const destAccount = findDestAccount.length > 0 ? findDestAccount[0] : {displayName: "Dest Account Missing", email: "Something is wrong"}

	useEffect(() => {

		if (infoExpanded) {
			loadDetails()
		}
	}, [currentMonth])

	async function bumpSync() {
		setLoading(true);
		try {
			const responseDetails = await await API.post('scan', '/scan', {
				body: {
					bridgeId: bridge.id,
					action: "bumpSync"
				}
			});
			// console.log(responseDetails);
		} finally {
			setLoading(false);
		}
	}

	async function desync() {
		setLoading(true);
		try {
			const responseDetails = await await API.post('scan', '/scan', {
				body: {
					bridgeId: bridge.id,
					action: "desyncBridge"
				}
			});
			// console.log(responseDetails);
		} finally {
			setLoading(false);
		}
	}

	async function loadDetails() {
		try {
			const responseDetails = await await API.get('scan', '/scan', {
				queryStringParameters: {
					bridgeId: bridge.id,
					month: currentMonth,
					action: "getSyncDetails"
				}
			});
			setVer(responseDetails.results)
			setInfoExpanded(true)
		} catch (ex) {
			//console.log(ex)
			alert('Sync Too Dang Big')
		} finally {
			setLoading(false);
		}
	}

	async function toggleExpand() {
		setLoading(true);
		if (infoExpanded) {
			setVer({});
			setInfoExpanded(false);
			setLoading(false);
		} else {
			loadDetails()
		}
	}


	if (infoExpanded === false) {
		return (
			<Card>
				<ScanSyncItem item={bridge}
					sourceAccountName={`${sourceAccount.displayName}-
		${sourceAccount.email}`}
					destAccountName={`${destAccount.displayName}-
		${destAccount.email}`}

				></ScanSyncItem>
				<Row>
					<Col style={{ paddingBottom: '1rem' }}>
						<Button type="primary" onClick={() => {
							toggleExpand();
						}}>Expand</Button>
					</Col>
					<Col>
						<Button type="secondary" onClick={() => {
							bumpSync();
						}}>Bump the sync</Button>
					</Col>
					<Col>
						<Button type="secondary" onClick={() => {
							desync();
						}}>Desync</Button>
					</Col>
				</Row>
				<div style={{ paddingBottom: '1rem' }}>
					{loading && <FontAwesomeIcon icon={faSpinner} spin />}
				</div>

				{typeof pushSubscriptionStatus !== 'undefined' && <h5>Push Subscription Status: {pushSubscriptionStatus ? 'Active' : 'Not Active'}</h5>}
				{<h5>Sync Status: {bridge.status}</h5>}
				{<h5>Sync Active: {bridge.active ? 'Active' : "De-Activated"}</h5>}
				{<h5>Sync Created : {moment(bridge.modified).format('LLLL')}</h5>}
				{<h5>Last sync: {moment(Math.abs(bridge.lastSyncTime)).format('LLLL')}</h5>}
				{<h5>Resynced: {bridge.resynced == 0 ? "NOT YET" :moment(Math.abs(bridge.resynced)).format('LLLL')}</h5>}
				{/* {<h5>Push Active: {sourceSub != null ? 'True' : 'False'}</h5>} */}

				{bridge.errorState && bridge.errorState != '' && (
					<h5>Error: {bridge.errorState}</h5>
				)}
		Raw Bridge:
				<JSONTree
					data={{ items: bridge }}
					hideRoot={true}
					theme={theme}
				/>
			</Card>);
	}
	const now = moment()
	return (<Card>
		<ScanSyncItem item={ver.bridge} sourceAccountName={`${ver.srcAccount.displayName}-
		${ver.srcAccount.email}`}
			destAccountName={`${ver.destAccount.displayName}-
		${ver.destAccount.email}`}></ScanSyncItem>
		<Row>
			<Col style={{ paddingBottom: '1rem' }}>
				<Button type="primary" onClick={() => {
					toggleExpand();
				}}>Collapse</Button>
			</Col>
			<Col style={{ paddingBottom: '1rem' }}>
				<Button type="secondary" onClick={() => {
					bumpSync();
				}}>Bump the sync</Button>
			</Col>
			<Col>
						<Button type="secondary" onClick={() => {
							desync();
						}}>Desync</Button>
					</Col>
		</Row>

		<div>
			{loading && <FontAwesomeIcon icon={faSpinner} spin />}
		</div>

		{<h5>Sync Status: {ver.bridge.status}</h5>}
		{<h5>Sync Active: {ver.bridge.active ? 'Active' : "De-Activated"}</h5>}
		{<h5>Sync Created : {moment(bridge.modified).format('LLLL')}</h5>}
		{<h5>Last sync: {moment(bridge.lastSyncTime).format('LLLL')}</h5>}
		{<h5>Push Active: {ver.sourceSub != null ? 'True' : 'False'}</h5>}

		{ver.bridge && ver.bridge.errorState && ver.bridge.errorState != '' && (
			<h5>Error: {ver.bridge.errorState}</h5>
		)}
		Raw Bridge:
		<JSONTree
			data={{ items: ver.bridge }}
			hideRoot={true}
			theme={theme}
		/>

		<Row>
			<Col span={4}>
				Month:
		<Select
					defaultValue={0}
					style={{ width: '100%' }}
					onChange={e => {
						setCurrentMonth(e)
					}}>
					<Select.Option value={-2}>{now.clone().add(-2, 'month').format('MMMM')}</Select.Option>

					<Select.Option value={-1}>{now.clone().add(-1, 'month').format('MMMM')}</Select.Option>

					<Select.Option value={0}>{now.format('MMMM')}</Select.Option>
					<Select.Option value={1}>{now.clone().add(1, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={2}>{now.clone().add(2, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={3}>{now.clone().add(3, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={4}>{now.clone().add(4, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={5}>{now.clone().add(5, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={6}>{now.clone().add(6, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={7}>{now.clone().add(7, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={8}>{now.clone().add(8, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={9}>{now.clone().add(9, 'month').format('MMMM')}</Select.Option>
					<Select.Option value={10}>{now.clone().add(10, 'month').format('MMMM YYYY')}</Select.Option>
					<Select.Option value={11}>{now.clone().add(11, 'month').format('MMMM YYYY')}</Select.Option>
					<Select.Option value={12}>{now.clone().add(12, 'month').format('MMMM YYYY')}</Select.Option>

				</Select>
			</Col>
		</Row>
		<BoomerCalendar month={currentMonth} sourceItems={ver.sourceItems} destItems={ver.destItems} bridge={ver.bridge}></BoomerCalendar>
		Source Items (current month)
		<JSONTree
			data={{ items: ver.sourceItems }}
			hideRoot={true}
			theme={theme}
		/>
Dest Items (current month)
		<JSONTree
			data={{ items: ver.destItems }}
			hideRoot={true}
			theme={theme}
		/>
Mappings
		<JSONTree
			data={{ items: ver.mappings }}
			hideRoot={true}
			theme={theme}
		/>
Source Account: {ver.srcAccount.displayName}-
		{ver.srcAccount.email}
		<JSONTree
			data={{ item: ver.srcAccount }}
			hideRoot={true}
			theme={theme}
		/>
Source Calendars
		<JSONTree
			data={{ items: ver.srcCals }}
			hideRoot={true}
			theme={theme}
		/>
Dest Account: {ver.destAccount.displayName}-
		{ver.destAccount.email}
		<JSONTree
			data={{ item: ver.destAccount }}
			hideRoot={true}
			theme={theme}
		/>
Dest Calendars
		<JSONTree
			data={{ items: ver.destCals }}
			hideRoot={true}
			theme={theme}
		/>
	</Card>);
}

export default SyncItem;
