import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import JSONTree from 'react-json-tree'
import { Empty, Input, Card, Col, Row, Button } from 'antd'
import get from 'lodash/get'
import SyncItem from "./SyncItem";
import LicenseInfo from './LicenseInfo'
import QueryString from 'query-string'


const BoomerScanBridgeList = ({ initialBridges, userEmail, theme, pageLimit = 100, accounts }) => {
    const [bridges, setBridges] = useState(initialBridges);
    const [moreBridges, setMoreBridges] = useState(initialBridges.length === pageLimit)
    const [filterBridges, setFilterBridges] = useState(initialBridges);
    
    useEffect(() => {
        setMoreBridges(bridges.length % pageLimit === 0);
        
    }, [bridges]);

    async function loadNewBridgePage() {
        //get last key. 'calendarAccountId' for accounts. 'id' for bridge/sync
        const lastKey = get(bridges.at(-1), ['bridge', 'id']) || null;

        // console.log(bridges)
        debugger;
        const parameters = {
            email: userEmail,
            limit: pageLimit,
            exclusiveStartIdString: lastKey,
            action: 'getBridgePage'
        }
        try {
            var responseBridgeInfo = await API.get('scan', '/scan', {
                queryStringParameters: parameters,
            })
            const newBridgePage = get(responseBridgeInfo, 'results.bridges') || [];
            if (newBridgePage.length) {
                setFilterBridges([...bridges, ...newBridgePage]);
                setBridges([...bridges, ...newBridgePage]);
            } else {
                setMoreBridges(false);
            }
        } catch (ex) {
            alert('Error: getitng new accounts page')
            // console.log(ex.message);
        } finally {

        }
    }

    return (<div>Syncs {bridges && bridges && <>{bridges.length} </>}:
    
    <div className="mt-4 mb-4"><div>Search:</div><Input placeholder="search syncs " allowClear onChange={e=>{
        var val = e.target.value
       
        if(val == '') {
            setFilterBridges(bridges)
        }else {
           setFilterBridges(bridges.filter(b => {
               //console.log(b)
              return b.bridge.sourceCalendarAccountId.toLowerCase().includes(val) || b.bridge.destCalendarAccountId.toLowerCase().includes(val) ||
               b.bridge.sourceCalendarId.toLowerCase().includes(val) || b.bridge.destCalendarId.toLowerCase().includes(val)
            }))
        } 
    }}></Input></div>
    {filterBridges && filterBridges.map(({ bridge, pushSubscriptionStatus }) => (
        <SyncItem 
            bridge={bridge} 
            pushSubscriptionStatus={pushSubscriptionStatus} 
            theme={theme}
            accounts={accounts} 
            />
    ))}
    {moreBridges && <Button onClick={() => {
        loadNewBridgePage();
    }}>Load More Bridges</Button>}
</div>);
}

export default BoomerScanBridgeList;