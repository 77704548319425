import React, { useEffect, useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Radio, message, Row, Col, Input, Collapse } from 'antd'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import badge from '../../../images/stripe-badge-transparent.png'
import Paragraph from 'antd/lib/typography/Paragraph'
import moment from 'moment'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBColorPicker,
  CBModal,
  CBSelect,
  CBList,
  CBUrlField,
  CurrentPlan,
} from '../../core/components/cbd'
const { Panel } = Collapse
const v2_plans = {
  basic: {
    monthly: {
      value: 5,
      planId: process.env.GATSBY_STRIPE_MONTHLY_BASIC_PLAN,
      fullPrice: 6,
      book: 5,
    },

    yearly: {
      value: 4,
      planId: process.env.GATSBY_STRIPE_YEARLY_BASIC_PLAN,
      fullPrice: 5,
      book: 5,
    },
  },
  premium: {
    monthly: {
      syncs: 16,
      value: 10,
      planId: process.env.GATSBY_STRIPE_MONTHLY_PREMIUM_PLAN,
      fullPrice: 12,
      book: 5,
    },

    yearly: {
      syncs: 16,
      value: 8,
      planId: process.env.GATSBY_STRIPE_YEARLY_PREMIUM_PLAN,
      fullPrice: 10,
      book: 5,
    },
  },
  pro: {
    monthly: {
      syncs: 36,
      value: 20,
      planId: process.env.GATSBY_STRIPE_MONTHLY_PRO_PLAN,
      fullPrice: 24,
      book: 10,
    },

    yearly: {
      syncs: 36,
      planId: process.env.GATSBY_STRIPE_YEARLY_PRO_PLAN,
      fullPrice: 20,
      book: 10,
    },
  },
}

const v3_plans = {
  basic: {
    monthly: {
      syncs: 2,
      value: 5,
      planId: process.env.GATSBY_STRIPE_MONTHLY_BASIC_PLAN,
      fullPrice: 6,
      book: 10,
    },

    yearly: {
      syncs: 2,
      value: 4,
      planId: process.env.GATSBY_STRIPE_YEARLY_BASIC_PLAN,
      fullPrice: 5,
      book: 10,
    },
  },
  premium: {
    monthly: {
      syncs: 16,
      value: 10,
      planId: process.env.GATSBY_STRIPE_MONTHLY_PREMIUM_PLAN,
      fullPrice: 12,
      book: 10,
    },

    yearly: {
      syncs: 16,
      value: 8,
      planId: process.env.GATSBY_STRIPE_YEARLY_PREMIUM_PLAN,
      fullPrice: 10,
      book: 10,
    },
  },
  pro: {
    monthly: {
      syncs: 36,
      value: 40,
      planId: process.env.GATSBY_STRIPE_MONTHLY_PRO_V3_PLAN,
      fullPrice: 48,
      book: 10,
    },

    yearly: {
      syncs: 36,
      value: 32,
      planId: process.env.GATSBY_STRIPE_YEARLY_PRO_v3_PLAN,
      fullPrice: 40,
      book: 10,
    },
  },
  
}

const BillingForm = ({
  isLoading,
  successMessage = 'Saved Billing Settings',
  reloadAfterSuccess = true,
  showPlan = false,
  showCard = false,
  showCurrentPlan = false,
  syncs = 0,
  schedules = 0,
  onCancel,
  onRestore,
}) => {
  const [user] = useGlobalState('user')
  const [license, setLicense] = useGlobalState('license')
  const [isProcessing, setIsProcessing] = useState(false)

  const [showReferral, setShowReferral] = useState(false)
  const [isCardComplete, setIsCardComplete] = useState(false)
  const [attemptedSubmission, setAttemptedSubmission] = useState(false)
  const [isYearlyPlan, setIsYearlyPlan] = useState(
    license.planId.includes('yearly')
  )
  const [actionShowPlan, setActionShowPlan] = useState(license.trial)

  const [actionShowCard, setActionShowCard] = useState(license.trial)

  const [actionShowButton, setActionShowButton] = useState(
    license.trial || (!license.active && license.wasDeactivated)
  )

  const [referralCode, setReferralCode] = useState('')

  const [plan, setPlan] = useState(getPlanState())

  const [cardError, setCardError] = useState(null)
  function trailing_zeros(number) {
    number = number.toFixed(2);
    return number
  }
  const [plans, setPlans] = useState(
    license.v3limit != null && license.v3limit ? v3_plans : v2_plans
  )

  useEffect(() => {
    setPlans(license.v3limit != null && license.v3limit ? v3_plans : v2_plans)
  }, [license])

  function getPlanState() {
    var state = ''
    if (license.trial) {
      state = 'premium'
    } else if (
      license.planId == 'calendarbridge-test' ||
      license.planId == 'calendarbridge-pro'
    ) {
      state = 'premium'
    } else {
      state = license.planId.split('-')[0]
    }
    return state
  }
  const stripe = useStripe()
  const elements = useElements()

  function validateForm() {
    return !attemptedSubmission || !actionShowCard || isCardComplete
  }

  async function handleSubmitClick(event) {
    //event.preventDefault()

    setIsProcessing(true)
    setAttemptedSubmission(true)
    setCardError(null)
    if (!stripe || !elements) {
      message.error(
        'Sorry! We had issues submitting billing modifications. Please try again or contact support.'
      )
      setIsProcessing(false)
      return
    }

    let token = null
    // Only fetch token under trial period
    if (actionShowCard || showCard || license.trial) {
      const cardElement = elements.getElement(CardElement)

      const resp = await stripe.createToken(cardElement)
      // console.log(resp)
      if (resp.error) {
        
        message.error(resp.error.message)
        setIsProcessing(false)
        return
      }

      token = resp.token
    }
    var planId = ""
    if(plan == "admin") {
      if(isYearlyPlan) {
        planId = process.env.GATSBY_STRIPE_YEARLY_ADMIN_PLAN
      } else {
        planId = process.env.GATSBY_STRIPE_MONTHLY_ADMIN_PLAN
      }
    } else {
      planId = plans[plan][isYearlyPlan ? 'yearly' : 'monthly']['planId']
    }
    try {
      const resp = await API.post('billing', '/billing', {
        body: {
          token:
            actionShowCard || license.trial || showCard ? token.id : undefined, // dont send a token if we are upgrading/downgrading
          email: user.username,
          referralCode: actionShowCard || showCard ? referralCode : undefined,
          plan: planId,
        },
        response: true,
      })
      const wasTrial = license.trial
      setLicense(resp.data.license)

      if (wasTrial) {
        //log a pay only if license is currently trial (don't log upgrades/downgrades)
        window.dataLayer = window.dataLayer || []

        //leaving in old 'pay' conversion until time to train on the "new-<type>' conversions
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'pay',
          label: resp.data.license.userId,
          email: resp.data.license.email,
        })

        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'new_' + resp.data.license.planId,
          label: resp.data.license.userId,
          email: resp.data.license.email,
        })
      }

      setIsProcessing(false)
      message.success(successMessage)
      if (reloadAfterSuccess) {
        window.location.reload()
      }
    } catch (ex) {
      console.log(ex)
      setIsProcessing(false)
      if (ex.response) {
        setCardError(ex.response.data.message)
      }
    }
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  const currentPlanLabel = getPlanState()
  const currentPlanIntervalYearly = license.planId.includes('yearly')
  return (
    <>
      {showCurrentPlan && (
        <div className="mb-4">
          <CBHeader label={'Current Plan'}></CBHeader>
          {currentPlanLabel != 'admin' && <CurrentPlan
            primary={
              <div className="font-sarabun lg:text-xl text-neutral_4 mb-3">
                <b>
                  {capitalizeFirstLetter(currentPlanLabel)} -{' '}
                  {currentPlanIntervalYearly ? 'Yearly' : 'Monthly'}{' '}
                  {license.trial && (
                    <div className="font-sarabun lg:text-lg text-burnt">
                      TRIAL - expires{' '}
                      {moment(license.trialStarted).add(7, 'day').fromNow()}
                    </div>
                  )}
                </b>
              </div>
            }
            secondary={
              <div className="font-sarabun  flex flex-col gap-y-4">
                {license.state.includes('cancel_at_end') && (
                  <div className="text-xl text-burnt">CANCELLED</div>
                )}

                <div className="text-lg">
                  <b>
                    Currently using {syncs}/
                    {
                      plans[currentPlanLabel][
                        currentPlanIntervalYearly ? 'yearly' : 'monthly'
                      ].syncs
                    }{' '}
                    Sync Connections
                  </b>
                </div>

                <div className="text-lg">
                  <b>
                    Currently using {schedules}/
                    {
                      plans[currentPlanLabel][
                        currentPlanIntervalYearly ? 'yearly' : 'monthly'
                      ].book
                    }{' '}
                    booking links
                  </b>
                </div>
                {license.planId != 'calendarbridge-test' &&
                  license.planId != 'calendarbridge-pro' &&
                  isYearlyPlan &&
                  !license.trial && (
                    <div className="text-neutral_4 text-lg">
                      <b>
                        Charged $
                        {plans[currentPlanLabel][
                          currentPlanIntervalYearly ? 'yearly' : 'monthly'
                        ].value * 12}{' '}
                        per year
                      </b>
                    </div>
                  )}
                {license.planId != 'calendarbridge-test' &&
                  license.planId != 'calendarbridge-pro' &&
                  !license.trial &&
                  !currentPlanIntervalYearly && (
                    <div className="text-neutral_4 text-lg">
                      <b>
                        $
                        {
                          plans[currentPlanLabel][
                            currentPlanIntervalYearly ? 'yearly' : 'monthly'
                          ].value
                        }{' '}
                        per month
                      </b>
                    </div>
                  )}
                {(license.planId == 'calendarbridge-test' ||
                  license.planId == 'calendarbridge-pro') &&
                  !license.trial &&
                  !currentPlanIntervalYearly && (
                    <div className="text-neutral_4 text-lg">
                      <b>$4 per month</b>
                    </div>
                  )}
              </div>
            }
          ></CurrentPlan>}
          {license.trial && license.active && (
            <div className="flex flex-row gap-4 font-sarabun mt-2 justify-end">
              <CBButton onClick={(e) => onCancel()} type="ternary">
                Cancel Trial
              </CBButton>
            </div>
          )}
          {!license.trial && !license.state.includes('cancel_at_end') && (
            <div>
            <div className="relative">
            <div className="flex  flex-col lg:flex-row mt-4 gap-4 lg:absolute lg:-top-8 lg:right-0">
              <div>
                <CBButton
                  type="primary"
                  disabled={license.stripeUserId == 'site'}
                  onClick={(e) => {
                    setActionShowButton(true)
                    setActionShowCard(true)
                    setActionShowPlan(false)
                  }}
                >
                  Update Payment Information
                </CBButton>
              </div>

              <div>
                <CBButton
                  disabled={license.orgAdmin || license.stripeUserId == 'site'}
                  onClick={(e) => {
                    setActionShowButton(true)
                    setActionShowCard(false)
                    setActionShowPlan(true)
                  }}
                  type="ternary"
                >
                  Change Plan
                </CBButton>
              </div>
              <div>
                <CBButton
                  disabled={license.orgAdmin || license.stripeUserId == 'site'}
                  onClick={(e) => onCancel()}
                  type="ternary"
                >
                  Cancel Subscription
                </CBButton>
              </div>
              
              </div>
              
            </div>
            
            
            </div>
          )}
          {!license.trial && license.state.includes('cancel_at_end') && (
            <div className="flex mt-4 gap-x-4">
              <div>
                <CBButton onClick={(e) => onRestore()} type="ternary">
                  Restore Subscription
                </CBButton>
              </div>
            </div>
          )}
          {license.orgAdmin && (
                <div className="flex flex-col mt-12 text-right">
                  <div>
                    Org Admin: Change or cancel plan through{' '}
                    <a href="/dashboard/organization">Organization Manage Billing Page</a>
                  </div>
                </div>
              )}
        </div>
      )}
      {actionShowPlan && (
        <>
          {license.trial && (
            <CBHeader label="Upgrade to a paid plan"></CBHeader>
          )}{' '}
          {!license.trial && <CBHeader label="Select a paid plan"></CBHeader>}
        </>
      )}
      {(actionShowPlan || showPlan) && (
        <div className="flex flex-col mb-4">
          <CBRadioGroup
            title="Billing Period"
            selected={isYearlyPlan ? 1 : 0}
            labels={['Monthly', 'Yearly']}
            values={['monthly', 'yearly']}
            onChange={(e) => {
              setIsYearlyPlan(e == 1)
            }}
          ></CBRadioGroup>
          <CBRadioGroupBig
            id="asdas"
            title="Plans"
            collapsible={false}
            selected={plan == 'premium' ? 1 : plan == 'pro' ? 2 : 0}
            values={Object.keys(plans).map((e) => e)}
            labels={Object.keys(plans).map((e) => (
              <div className="font-sarabun text-2xl">{capitalizeFirstLetter(e)}</div>
            ))}
            subLabels={Object.values(plans).map((e) => {
              return (
                <div className="font-sarabun  text-neutral_half flex flex-col gap-2 text-lg">
                  <div className="text-neutral_4 text-lg">
                    <b>
                      ${e[isYearlyPlan ? 'yearly' : 'monthly'].value} per month
                    </b>
                  </div>
                  <div className="text-burnt text-lg">
                    <b>
                      {e[isYearlyPlan ? 'yearly' : 'monthly'].syncs} Sync
                      Connections
                    </b>
                  </div>
                  <div>Unlimited connected calendar accounts</div>
                  <div>
                    {e[isYearlyPlan ? 'yearly' : 'monthly'].book} booking links
                  </div>
                  {isYearlyPlan && (
                    <div className="text-neutral_4 text-md">
                      <b>
                        Charged $
                        {e[isYearlyPlan ? 'yearly' : 'monthly'].value * 12} per
                        year, you save $
                        {e[isYearlyPlan ? 'yearly' : 'monthly'].fullPrice -
                          e[isYearlyPlan ? 'yearly' : 'monthly'].value}{' '}
                        per month{' '}
                      </b>
                    </div>
                  )}
                </div>
              )
            })}
            onChange={(e) => {
              // console.log(e)
              switch (e) {
                case 0:
                  setPlan('basic')
                  break
                case 1:
                  setPlan('premium')
                  break
                case 2:
                  setPlan('pro')
                  break
              }
            }}
          ></CBRadioGroupBig>
        </div>
      )}
      <form className="BillingForm" onSubmit={(e) => handleSubmitClick(e)}>
        {/* {showPlan && (
          <div style={{ marginBottom: 16 }}>
            <Paragraph>Select a plan that's right for you.</Paragraph>
            <Radio.Group
              defaultValue={plan == 'premium' ? 1 : plan == 'pro' ? 2 : 0}
              size="large"
              onChange={(e) => {
                switch (e.target.value) {
                  case 0:
                    setPlan('basic')
                    break
                  case 1:
                    setPlan('premium')
                    break
                  case 2:
                    setPlan('pro')
                    break
                }
              }}
            >
              <Radio.Button
                value={0}
                style={{ paddingTop: 10, height: 120, lineHeight: 1.5 }}
              >
                Basic
                <span style={{ fontSize: 12 }}>
                  <br />- 2 Always-On Sync Connections*
                  <br />- Unlimited Connected Calendars
                  <br />- 5 Scheduling Links
                </span>
              </Radio.Button>
              <Radio.Button
                value={1}
                style={{ paddingTop: 10, height: 120, lineHeight: 1.5 }}
              >
                Premium
                <span style={{ fontSize: 12 }}>
                  <br />- 16 Always-On Sync Connections*
                  <br />- Unlimited Connected Calendars
                  <br />- 5 Scheduling Links
                </span>
              </Radio.Button>
              <Radio.Button
                value={2}
                style={{ paddingTop: 10, height: 120, lineHeight: 1.5 }}
              >
                Pro
                <span style={{ fontSize: 12 }}>
                  <br />- 36 Always-On Sync Connections*
                  <br />- Unlimited Connected Calendars
                  <br />- 10 Scheduling Links
                </span>
              </Radio.Button>
            </Radio.Group>
            <Paragraph>
              *Connections are one-way. Connecting two calendars in both
              directions requires 2 connections.
            </Paragraph>
            <br />
            <Radio.Group
              defaultValue={isYearlyPlan}
              onChange={(e) => setIsYearlyPlan(e.target.value)}
              style={{ marginRight: 10 }}
            >
              <Radio.Button value={true}>Billed Yearly</Radio.Button>
              <Radio.Button value={false}>Billed Monthly</Radio.Button>
            </Radio.Group>
            <br />
            <br />
            <label style={{ fontWeight: 'bold' }}>
              {plan == 'premium'
                ? 'Premium '
                : plan == 'pro'
                ? 'Pro '
                : 'Basic '}{' '}
              Plan:{' $'}
              <span>
                {plans[plan][isYearlyPlan ? 'yearly' : 'monthly']['value']}
              </span>
              {'/user/month ($'}
              <span>
                {plans[plan][isYearlyPlan ? 'yearly' : 'monthly']['value'] * 12}
              </span>
              {'/user/year)'}
            </label>
          </div>
        )} */}

        {(actionShowCard || showCard) && (
          <div style={{ maxWidth: 400 }}>
            <CBHeader label="Payment Information"></CBHeader>

            <CardElement
              onChange={(e) => {
                if (cardError) {
                  setCardError(null)
                }
                setIsCardComplete(e.complete)
              }}
              options={{
                style: {
                  base: {
                    fontSize: '18px',
                    fontFamily: '"Open Sans", sans-serif',
                  },
                },
              }}
            />
          </div>
        )}
        {validateForm() && cardError && (
          <div>
            <span className="font-sarabun ml-4 mt-4" style={{ color: 'red' }}>
              {cardError}
            </span>
          </div>
        )}
        {!validateForm() && (
          <p className="font-sarabun ml-4 mt-4" style={{ color: 'red' }}>
            Enter: (1) card number, (2) expiration, (3) cvc, and (4) zipcode
          </p>
        )}

        {(license.trial ||
          ((actionShowPlan || showPlan || actionShowCard || showCard) &&
            license.planId !=
              `${plan}-${isYearlyPlan ? 'yearly' : 'monthly'}` &&
            license.planId != 'calendarbridge-test' &&
            license.planId != 'calendarbridge-pro')) && (
          <div className="font-sarabun mt-8 ml-2">
            Amount to be charged:{' '}
            {license.partner ?<div>
              $
              <b>{trailing_zeros(plans[plan][isYearlyPlan ? 'yearly' : 'monthly']['value'] *
                (isYearlyPlan ? 12 : 1) * 0.6)}{' '}
              {isYearlyPlan ? 'per year' : 'per month'}</b> for first year, <b>
              $
              {plans[plan][isYearlyPlan ? 'yearly' : 'monthly']['value'] *
                (isYearlyPlan ? 12 : 1)}{' '}
              {isYearlyPlan ? 'per year' : 'per month'}
            </b> after first year
            </div> : <b>
              $
              {plans[plan][isYearlyPlan ? 'yearly' : 'monthly']['value'] *
                (isYearlyPlan ? 12 : 1)}{' '}
              {isYearlyPlan ? 'per year' : 'per month'}
            </b>}
          </div>
        )}
        <div className="flex flex-col mt-4">
        {license.trial && <a className="ml-2" onClick={e=> setShowReferral(!showReferral)}>{!showReferral ? 'Have A Referral Code?' : 'Hide Referral Form'}</a>}
        {license.trial && !license.partner && showReferral &&  (
          <div style={{ marginTop: 4 }}>
            <Col span={10}>
              <div className="">
                
                <div
                  className="font-sarabun"
                  header="Referral Code"
                  style={{ border: 0 }}
                >
                  <CBInput
                    hint="Referral Code"
                    value={referralCode}
                    maxLength={10}
                    onChange={(e) => {
                      setReferralCode(e)
                    }}
                  />
                  <small className="font-sarabun">
                    By submitting a referral code, you accept the{' '}
                    <a
                      href="https://help.calendarbridge.com/referral-program-terms-and-conditions/"
                      target="_blank"
                    >
                      referral program terms and conditions
                    </a>
                  </small>
                </div>
              
              </div>
            </Col>
          </div>
        )}
        </div>
        
        <div className="h-4"></div>
        {actionShowButton && (
          <SubmitButton
            size="large"
            type="primary"
            onClick={(e) => handleSubmitClick()}
            loading={isProcessing || isLoading}
            disabled={!validateForm() || isProcessing}
            style={{ marginTop: 20 }}
          >
            {actionShowPlan || showPlan
              ? 'Subscribe to Plan'
              : 'Update Credit Card'}
          </SubmitButton>
        )}
        {license.partner && <div><b>40% off for first year</b> coupon applied</div>}
        

        {actionShowCard && (
          <div style={{ marginTop: 50 }}>
            <center>
              <img src={badge} />
            </center>
          </div>
        )}
      </form>
      
    </>
  )
}

export default BillingForm
