export function syncTypeText(
  includeSubject,
  includeDescription,
  includeLocation,
  includeReminder,
  allPrivate,
  conference,
  attendee,
  tag
) {
  var syncType = 'Date'
  if (
    includeSubject &&
    includeDescription &&
    includeLocation &&
    includeReminder &&
    allPrivate &&
    conference &&
    attendee
  ) {
    syncType = 'All, Reminders, Private'
  } else {
    if (includeSubject) {
      syncType += ', Subject'
    }
    if (includeDescription) {
      syncType += ', Description'
    }
    if (includeLocation) {
      syncType += ', Location'
    }
    if (includeReminder) {
      syncType += ', Reminders'
    }
    if (allPrivate) {
      syncType += ', Private'
    }
    if (conference) {
      syncType += ', Conference'
    }
    if (attendee) {
      syncType += ', Attendees'
    }
    if (tag) {
      syncType = 'Tagged ' + syncType
    }
  }

  return syncType
}

export function syncFilterText(type, free, busy, tentative, oof, elsewhere) {
  //console.log(type)
  if (type == 'google') {
    if (free && busy) {
      return 'None'
    } else {
      return "Free"
    }
  } else {
    if (free && busy && tentative && oof && elsewhere) {
      return 'None'
    }
  }
  var str = ''
  if (!free) {
    str += 'Free '
  }

  if (!tentative) {
    str += 'Tentative'
  }

  return str
}
