import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Row,
  Switch,
  Popconfirm,
  Button,
  Dropdown,
  Menu,
  Modal,
  Progress,
  Spin,
} from 'antd';
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import {
  colorPositive,
  colorNeutral,
  colorNeutralDark,
  colorNegative,
} from '../../../utils/colors'
import { faOutlook, accountIconMap } from '../../../utils/customIcons'
import { syncTypeText } from '../../../utils/words'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import ColorPicker from '../../syncs/components/ColorPicker'
// const syncStatusMap = {
//   synced: colorNeutral,
//   syncing: colorPositive,
//   synced_error: colorNegative,
//   deleting: colorNegative,
// }

const syncStatusMap = {
  synced: 'normal',
  syncing: 'normal',
  sync_conflict: 'exception',
  sync_conflict_onecal: 'exception',
  synced_error: 'exception',
  deleting: 'exception',
}

const SyncItem = ({ item, sourceAccountName,destAccountName,preview = false }) => {
  const [license] = useGlobalState('license')

  function resolveError() {
    var str = item.errorState
    if(str.includes("Auth token refresh failed for")) {
      //re-authenticate account with link
      var accountId = str.substring(str.indexOf("for ")+4)
      return (<div><a href={`/dashboard/accounts/${accountId}`}
        >Account Issue please Re-Authenticate {accountId}</a></div>)
    } else if(item.status == "sync_conflict"){
      return (<div>Jerk Detected: reclaim or clockwise expand to find out</div>
        )
    } else if(item.status == "sync_conflict_onecal"){
      return (<div>Albanian malware detected</div>
        )
    }else if(str != ""){
      return (<div>Error: {str}</div>
        )
    } else {
      return <div>deleting...</div>
    }
  }

  function showDeleteConfirm() {
    Modal.confirm({
      title: 'Are you sure you want to delete this sync?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        await API.del('bridge', '/bridge', {
          body: {
            sourceCalendarId: item.sourceCalendarId,
            destCalendarId: item.destCalendarId,
          },
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'deleteSync',
          label: license.userId,
          email: license.email
        })

        window.location.reload()
      },
    })
  }
  function showForceDeleteConfirm() {
    Modal.confirm({
      title: 'Are you sure you want to force delete this sync? Copies will not be deleted!',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        await API.del('bridge', '/bridge?force=true', {
          body: {
            sourceCalendarId: item.sourceCalendarId,
            destCalendarId: item.destCalendarId,
          },
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'deleteSync',
          label: license.userId,
          email: license.email
        })

        window.location.reload()
      },
    })
  }
  const handleClick = (e) => {
    if (e.key === '0') {
      // showDisableConfirm()
      showForceDeleteConfirm()
    } else if (e.key === '1') {
      showDeleteConfirm()
    }
  }

  const menu = (
    <Menu onClick={handleClick} style={{ width: 125 }}>
      {/* <Menu.Item key="0">
        <Icon type="stop" />
        Disable
      </Menu.Item> */}
      {item.status == 'delete_error' && <Menu.Item key="0" style={{ color: colorNegative }}>
        <DeleteOutlined />
        Force Delete
      </Menu.Item>}
      <Menu.Item key="1" style={{ color: colorNegative }}>
        <DeleteOutlined />
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <Card
      style={{ marginBottom: 20, position: 'relative', wordWrap: 'break-word' }}
    >
      {!preview && (
        <div
          style={{
            float: 'right',
            position: 'absolute',
            right: 5,
            top: 5,
            zIndex: 100,
          }}
        >
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="dashed" icon={<MoreOutlined />} size="large" />
          </Dropdown>
        </div>
      )}
      <Row style={{ display: 'flex', alignItems: 'center' }}>  
        <Col span={8} >
          <Title level={4}>{item.sourceCalendarName}</Title>
          <Text style={{ color: colorNeutralDark }}>
          <div>{sourceAccountName}</div>
            {item.sourceCalendarAccountId}
            <FontAwesomeIcon
              icon={accountIconMap[item.sourceCalendarType].icon}
              style={{
                marginLeft: 10,
                marginRight: 10,
                display: 'inline-block',
              }}
            />
            </Text>
            {!preview && item.sourceColor != "same" && item.sourceColor != "default" && (<ColorPicker type={item.sourceCalendarType} color={item.sourceColor} preview={true}></ColorPicker>)}
        </Col>
        
        <Col span={8} >
          {item.twoWay  && <FontAwesomeIcon
            icon={faLongArrowAltLeft}
            size="3x"
            color={colorPositive}
          />}{' '}
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            size="3x"
            color={colorPositive}
          />
          {item.twoWay && <div><Text style={{ fontSize: 12, color: colorNeutralDark }}>TWO WAY ROOT SYNC</Text></div>}
          {item.lastSyncTime < 0 && <div><Text style={{ fontSize: 12, color: colorNeutralDark }}>TWO WAY LEG</Text></div>}
          <br />
          <Text style={{ fontSize: 12, color: colorNeutralDark }}>
            {syncTypeText(
              !preview && item.securityPolicy.includes('subject'),
              !preview && item.securityPolicy.includes('description'),
              !preview && item.securityPolicy.includes('location'),
              !preview && item.securityPolicy.includes('reminder'),
              !preview && item.securityPolicy.includes('private'),
              !preview && item.securityPolicy.includes('meeting'),
              !preview && item.securityPolicy.includes('contact')
            )}
             {!preview && (<div><Text style={{ fontSize: 12, color: colorNeutralDark }}>
          Tag: {item.tag != "default" ? item.tag : '(copy)'}</Text></div>)}
            {!preview && item.status === 'synced' && 'Active and Up-to-Date'}
          </Text>
        </Col>
         
        <Col span={8} style={{ textAlign: 'center' }}>
          <Title level={4}>{item.destCalendarName} 
    </Title>
         <Text style={{ color: colorNeutralDark }}>
         <div>{destAccountName}</div>
            {item.destCalendarAccountId}
           
            <FontAwesomeIcon
              icon={accountIconMap[item.destCalendarType].icon}
              style={{
                marginLeft: 10,
                marginRight: 10,
                display: 'inline-block',
              }}
            />
            {!preview && item.destinationColor != "default" && item.destinationColor != "same" && (<ColorPicker type={item.destCalendarType} color={item.destinationColor} preview={true}></ColorPicker>)}
     
            </Text>
            
           </Col>
        </Row>

        
      {!preview && item.status !== 'synced' && (
        <><div
          style={{
            float: 'right',
            position: 'absolute',
            right: 10,
            bottom: 10,
            zIndex: 100,
          }}
        >
          <Progress
            type="circle"
            percent={Math.round((item.syncProgress / 12) * 100)}
            width={30}
            status={syncStatusMap[item.status]}
          />
          
        </div>
        {syncStatusMap[item.status] == "exception" && <div style={{
            float: 'right',
            position: 'absolute',
            marginRight:30,
            right: 20,
            bottom: 0,
            zIndex: 100,
          }}>{resolveError()}</div>}
          </>
      )}
      
    </Card>
  );
}

export default SyncItem
