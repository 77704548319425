import React, { useState, useEffect } from 'react'

import { Modal, Switch } from 'antd'

const EditOrgAdminModal = ({
	currentOrg,
	visible,
	handleOk,
	confirmLoading,
	handleCancel,
	setNewAdminStatus
}) => {

	const [checked, setChecked] = useState(currentOrg);

	useEffect(() => {
		setChecked(currentOrg);
	}, [visible]);

	return (
		<Modal
			title="Edit Organization Admin Role"
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<Switch defaultChecked={currentOrg} checked={checked} onChange={(pendingChecked) => {
				setChecked(pendingChecked);
				setNewAdminStatus(pendingChecked);
			}} />
		</Modal>
	)
}

export default EditOrgAdminModal
