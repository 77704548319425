import React, { useState, useEffect } from 'react'
import Title from 'antd/lib/typography/Title'
import { API } from 'aws-amplify'
import {
  CalendarOutlined,
  CheckCircleTwoTone,
  DeleteOutlined,
  ExclamationCircleTwoTone,
  InfoCircleTwoTone,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Card, Row, Col, Avatar, Checkbox, Spin,Dropdown, Menu} from 'antd'

import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBList,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
} from '../../core/components/cbd'
import Info from '../../../images/svg/info.svg'
import { Loading3QuartersOutlined } from '@ant-design/icons';
import More from '../../../images/svg/menu-more-horizontal.svg'
import momentTz from 'moment-timezone'
import moment from 'moment'
import { navigate } from '@reach/router'
import LongButton from '../../core/components/LongButton'
import CBCard from '../../core/components/CBCard'
import ActionButton from '../../core/components/ActionButton'
import Add from '../../../images/svg/plus.svg'

import Mail from '../../../images/svg/mail.svg'
import Scheduling from '../../../images/svg/envelope.svg'
import GroupItem from '../components/GroupItem'
import { useGlobalState } from '../../../store'
import SEO from '../../../components/SEO'

const googleColors = [
  '#7ae7bf',
  '#dbadff',
  '#ff887c',
  '#fbd75b',
  '#ffb878',
  '#46d6db',
  '#e1e1e1',
  '#5484ed',
  '#51b749',
  '#a4bdfc',
]


const GroupScheduleListPage = () => {
  const [groups, setGroups] = useState(null)
  const [tz, setTz] = useState(momentTz.tz.guess())
	const [schedules] = useGlobalState('schedules');

  useEffect(() => {
    loadGroups()
  }, [])

  async function loadGroups() {
    const groups = await API.get('group-manage', '/group-manage')
    console.log(groups.groups)
    setGroups(groups.groups)
  }

  function renderVotes(entry) {
    return entry.map((vote) => {
      return <div>aaa</div>
    })
  }

  function renderFinishedGroups() {
    let now = moment()
    let complete = groups
    .filter((item) => item.group.status == "complete")

     let upcoming = complete.filter((item) => moment(item.group.result.split(" - ")[0]).isAfter(now) )

     let completed = complete.filter((item) => moment(item.group.result.split(" - ")[0]).isBefore(now) )

     return (
      <>
        {status == 'complete' ? <div></div> : <div></div>}
        {upcoming.length > 0 && <div className="font-sarabun text-neutral_4 ">Upcoming</div>}
        {upcoming
          
          .map((e) => {
           
            return <GroupItem item={e} timezone={tz}></GroupItem>
          })}
        {completed.length > 0 && <div className="font-sarabun text-neutral_4">Completed</div>}
        {completed
          
          .map((e) => {
           
            return <GroupItem item={e} timezone={tz}></GroupItem>
          })}
      </>
    )
  }
  function renderGroups(status) {
    return (
      <>
        {status == 'complete' ? <div></div> : <div></div>}
        {groups
          .filter((item) => item.group.status == status)
          .map((e) => {
           
            return <GroupItem item={e} timezone={tz}></GroupItem>
          })}
      </>
    )
  }

  return (
    <div className="cb-content-frame ">
      <SEO title="Group Bookings Dashboard" />
    <div className="cb-content-pane mobile_padding min-h-screen"  >
        <CBHeader label="Booking" icon={<Scheduling></Scheduling>}
         buttonLabel={'Help'}
                 buttonIcon={<Info></Info>}
                 buttonAction={(e) => {
                   window.open(
                     'https://help.calendarbridge.com/doc/group-scheduling-beta/',
                     '_blank'
                   )
                 }}
                 ></CBHeader>
        <div className="font-sarabun text-neutral_4 mt-4 mb-8">
          Set up and send out invitations with time options that attendees can
          vote on, optimized for everyone’s availability.
        </div>

          <CBButton
						disabled={!schedules.length}
            long={true}
            style={{ marginBottom: 40 }}
            onClick={(e) => {
              navigate('/dashboard/group/new/')
            }}
            type="primary"
            icon={<Add style={{ float: 'right' }}></Add>}
          >
            New invitation
          </CBButton>
					{groups != null && groups.length == 0 && !schedules.length && (
						<div className='flex flex-col justify-center mt-4'>
							<Button onClick={(e) => {
								navigate('/dashboard/schedules')
							}}>Create a scheduler first!</Button>
						</div>)
					}
          <div className="h-4"></div>
          {/* <Button
     icon={<CalendarOutlined />}
     type="primary"
     style={{marginTop:16, marginBottom:16, width:'100%'}}
     onClick={e=>{
       navigate("/dashboard/group/new/")
     }}
     >
       New Invitation
     </Button> */}
        
        {!groups && (
          <div className="v-full">
            <center className="mt-8">
            <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{  fontSize: '100px' }} />
            }
          />
            </center>
          </div>
        )}

        {groups && (
          <>
            

            {renderGroups('failed')}
            {renderGroups('active')}
            {renderFinishedGroups()}
          </>
        )}
      </div>
    </div>
  )
}

export default GroupScheduleListPage
