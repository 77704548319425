import React, { useState, useEffect } from 'react'
import { useGlobalState } from '../../../store'
import BillingForm from '../components/BillingForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'
import { Loading3QuartersOutlined, WarningOutlined } from '@ant-design/icons'
import {
  Card,
  Row,
  Col,
  message,
  Button,
  Modal,
  Descriptions,
  Menu,
  Layout,
  Spin,
  Divider,
} from 'antd'

import Info from '../../../images/svg/info.svg'
import Paragraph from 'antd/lib/typography/Paragraph'
import { API } from 'aws-amplify'
import {
  CBButton,
  CBHeader,
} from '../../core/components/cbd'
import { navigate } from '@reach/router'
import SEO from '../../../components/SEO'
const { Header, Content, Footer, Sider } = Layout

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

const SettingsPage = () => {
  const [user] = useGlobalState('user')
  const [license, setLicense] = useGlobalState('license')
  const [syncs] = useGlobalState('syncs')
  const [schedules] = useGlobalState('schedules')
  const [accounts] = useGlobalState('accounts')
  const [loadingCoreData] = useGlobalState('loadingCoreData')

  const [isCancelling, setIsCancelling] = useState(false)
  const [billingSection, toggleBillingSection] = useState('status')
  const [showChangeEmail,setShowChangeEmail] = useState(false)
  const [loadRedirect, setLoadRedirect] = useState(false)
  useEffect(() => {
    if (license != null) {
      if (license.trial) {
        toggleBillingSection('trial')
      } else {
        toggleBillingSection('status')
      }
    } else {
      toggleBillingSection('status')
    }
  }, [license])

  if (loadingCoreData) {
    return (
      <div className="cb-content-frame h-full">
      <div  className="cb-content-pane mobile_padding min-h-screen">
        <CBHeader
          icon={<Info></Info>}
          label="Settings &amp; Billing"
        ></CBHeader>
        <div className="flex justify-center items-center">
        <Spin
          indicator={
            <Loading3QuartersOutlined spin style={{ fontSize: '100px' }} />
          }
        />
        <div className="h-96"></div>

        <div className="h-96"></div>

        <div className="h-96"></div>
        </div>
        </div>
      </div>
    )
  }

  function showCancelConfirm() {
    if(license.trial) {
      Modal.confirm({
        title:
          'Are you sure you want to cancel your trial? All syncs and schedules will be disabled.',
        okText: 'Cancel Trial',
        okType: 'danger',
        cancelText: 'Nevermind',
        icon: <WarningOutlined />,
        async onOk() {
          handleCancel()
        },
      })
    } else {
    Modal.confirm({
      title:
        'Are you sure you want to cancel your subscription? All syncs and schedules will be disabled at end of current billing cycle. If you do not receive a confirmation email please try again.',
      okText: 'Cancel Subscription',
      okType: 'danger',
      cancelText: 'Nevermind',
      icon: <WarningOutlined />,
      async onOk() {
        handleCancel()
      },
    })
  }
  }

  function showUnCancelConfirm() {
    Modal.confirm({
      title:
        'Are you sure you want to restore your subscription? You will be charged at the start of the next scheduled billing cycle. ',
      okText: 'Restore Subscription',
      okType: 'danger',
      cancelText: 'Nevermind',
      icon: <WarningOutlined />,
      async onOk() {
        handleUnCancel()
      },
    })
  }


  async function getPortalUrl() {
    setLoadRedirect(true)
    try{
    const resp = await API.get('billing', '/billing?session=true', {
    })
    setLoadRedirect(false)
    navigate(resp.message)
  } catch(ex) {
    setLoadRedirect(false)
    alert("You do not have access this to portal")
  }
  }

  async function handleUnCancel() {
    setIsCancelling(true)
    const resp = await API.post('billing', '/billing', {
      body: {
        cancel: true,
        uncancel:true
      },
    })

    setLicense(resp.license)
    setIsCancelling(false)
    message.success('Your subscription has been restored.')
  }

  async function handleCancel() {
    setIsCancelling(true)
    const resp = await API.post('billing', '/billing', {
      body: {
        cancel: true,
      },
    })

    setLicense(resp.license)
    setIsCancelling(false)
    if(resp.license.trial) {
      message.success(
        'Your Trial Has Been Cancelled'
      )
      window.location.href = "/dashboard"
      
    } else {
      message.success(
        'Your subscription will be cancelled on ' +
          moment(resp.license.cancelEnd * 1000).format('LL')
      )
    }
    
  }


  async function changeEmail(email) {
    setIsCancelling(true)
    try{
    const resp = await API.post('account', '/account', {
      body: {
        code:'rename',
        type: email,
      },
    })
    
    // setLicense(resp.license)
    setIsCancelling(false)
    
      // message.success(
      //   'Your email has been changed'
      // )
      location.reload()
    
    }catch(ex) {
      //console.log(ex)
      message.error(
        'CalendarBridge email already exists'
      )
    } finally{
      setIsCancelling(false)
    }   
    }
    
  

  const isPremium = license.planId.includes('premium') || license.trial
  const isPro = license.planId.includes('pro')
  var syncLegLimit = isPremium ? 16 : isPro ? 36 : 2
  const isLegacy =
    !license.trial &&
    (license.planId == 'calendarbridge-test' ||
      license.planId == 'calendarbridge-pro')
  if (isLegacy) {
    syncLegLimit = 14
  }
  const twoWayCount = syncs.filter((sync) => sync.twoWay === true).length
  const syncLegCount = syncs.length + twoWayCount // two way sync counts as two legs

  const pages = {
    status: (
      <>
        <h3>Billing Status</h3>

        {license.trial && (
          <>
            <br />
            <strong>Trial:</strong> Active (Expires{' '}
            {moment(license.trialStarted).add(7, 'day').fromNow()})
          </>
        )}

        {license.orgId === 'single' && !license.trial && (
          <>
            <strong>Plan:</strong>{' '}
            {license.planId.includes('calendarbridge')
              ? 'Introductory Premium'
              : license.planId.includes('basic')
              ? 'Basic'
              : license.planId.includes('premium')
              ? 'Premium'
              : 'Pro'}{' '}
            {license.trial ? 'Trial' : ''} (Billed{' '}
            {license.planId.includes('yearly') ? 'Yearly' : 'Monthly'})
          </>
        )}
        {!license.trial && license.active && (
          <Paragraph style={{ marginTop: 20 }}>
            Your credit card is on file.
          </Paragraph>
        )}
      </>
    ),
    plan: (
      <>
        <h3>Select or Change Plan</h3>

        <Elements stripe={stripePromise}>
          <BillingForm isLoading={false}  />
        </Elements>
      </>
    ),
    card: (
      <>
        <h3>Change Credit Card Info</h3>
        <Elements stripe={stripePromise}>
          <BillingForm isLoading={false} showCard />
        </Elements>
      </>
    ),
    trial: (
      <>
        <h3>Upgrade to Paid Plan</h3>
        <Elements stripe={stripePromise}>
          <BillingForm
            isLoading={false}
            showCard
            showPlan
            reloadAfterSuccess={true}
            successMessage="Successfully upgraded to paid plan!"
          />
        </Elements>
      </>
    ),
    cancel: (
      <>
        {!license.state.includes('cancel_at_end') && (
          <>
            <h3>Cancel Subscription</h3>
            <Paragraph>
              Are you sure you want to cancel your subscription? All syncs and
              schedules will be disabled at end of current billing cycle. If you do not receive a confirmation email please try again.
            </Paragraph>
            <Button
              onClick={showCancelConfirm}
              type="danger"
              size="large"
              loading={isCancelling}
              style={{ fontWeight: 'bold' }}
            >
              Cancel Subscription
            </Button>
          </>
        )}
        {license.state.includes('cancel_at_end') && (
          <>
            <h3>Restore Subscription</h3>
            <Paragraph>
              Are you sure you want to restore your subscription? You will be
              charged at the start of the next scheduled billing cycle.
            </Paragraph>
            <Button
              onClick={showUnCancelConfirm}
              type="danger"
              size="large"
              loading={isCancelling}
              style={{ fontWeight: 'bold' }}
            >
              Restore Subscription
            </Button>
          </>
        )}
      </>
    ),
  }

  if (!user) return <div></div>

  return (
    <div className="cb-content-frame">
      <SEO title="Account Settings" />
      <div className="cb-content-pane mobile_padding">
        <CBHeader
          icon={<Info></Info>}
          label="Settings &amp; Billing"
        ></CBHeader>
        <div>
          <div>
            <CBHeader label="Account Details"></CBHeader>
            <div className="flex flex-col lg:ml-4 gap-y-2 lg:text-lg">
              <div className="flex flex-row gap-x-4 items-center font-sarabun ">
                <div className="text-lg w-32">Email:</div> 
                <div className="p-2 border">{license.email}</div>{' '}
                {license.orgId == 'single' && 
                <CBButton type="ternary" onClick={e=>{
                  if(showChangeEmail) {
                    setShowChangeEmail(false)
                  } else {
                  setShowChangeEmail(true)
                }
                }}>{showChangeEmail ? 'Cancel' : 'Change'}</CBButton>}
              </div>
              {showChangeEmail && <div className="pt-4 pl-4 pb-4 bg-neutral">
                Connected Accounts:
                {accounts.length == 0 && <div>No Connected Accounts</div>}
                {accounts.filter(account => account.email != license.email).map(account => <div className="flex flex-row gap-x-4 items-center m-4">{account.email} <CBButton loading={isCancelling} type="ternary" onClick={e=>{
                  Modal.confirm({
                    title:
                      'Are you sure you want to change your login email to: ' + account.email,
                    okText: 'Change Login Email',
                    okType: 'danger',
                    cancelText: 'Nevermind',
                    icon: <WarningOutlined />,
                    async onOk() {
                      changeEmail(account.email)
                    },
                  })
                }}>Use this email</CBButton></div>)}
                </div>}
              <div className="flex flex-row gap-x-4 items-center font-sarabun ">
                <div className="text-lg w-32">Organization:</div>
                <div className="p-2 border">
                  {license.orgId === 'single' ? 'Individual' : license.orgId}
                </div>{' '}
              </div>
              <div className="flex flex-row gap-x-4 items-center font-sarabun ">
                <div className="text-lg w-32">Account Status:</div>
                <div className="p-2 border">
                  {license.active ? 'Active' : 'Inactive'}{' '}
                  {license.state.includes('cancel_at_end') && (
                    <>
                      {' '}
                      - (Cancelled, ends on{' '}
                      {moment(license.cancelEnd * 1000).format('LL')})
                    </>
                  )}
                </div>{' '}
              </div>
              {!license.trial && license.code != 'site' && (
                <div className="flex flex-row gap-x-4 items-center font-sarabun ">
                  <div className="text-lg w-32">Referral Code:</div>
                  <div className="p-2 bg-border">
                    <>
                      {license.referralCode}{' '}
                      <a href="referrals">View Referral Program</a>
                    </>
                  </div>{' '}
                </div>
              )}
              
            {!license.trial && (license.stripeUserId != 'site') && license.stripeUserId != 'trial' && (
                <div className="flex flex-col gap-4 font-sarabun mt-2 w-64">
                  <CBButton onClick={e=> getPortalUrl()} type="ternary" loading={loadRedirect}>Update Billing Address</CBButton>
                  <CBButton onClick={e=> getPortalUrl()} type="ternary" loading={loadRedirect}>View Invoices</CBButton>
               
                </div>
              )}
            </div>
          </div>
        </div>
       
        <Elements stripe={stripePromise}>
        <BillingForm showCurrentPlan syncs={syncs.length} schedules={schedules.length}
        onCancel={e=>{
          showCancelConfirm()
        }}
        onRestore={e=>{
          showUnCancelConfirm()
        }}
        ></BillingForm>
        </Elements>
        
      </div>
    </div>
  )
}

export default SettingsPage
