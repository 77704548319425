import React, { useMemo } from 'react'
import MediaQuery from 'react-responsive'
import get from 'lodash/get'
import { Layout } from 'antd'
const { Sider, Content } = Layout

import { CBHeader } from '../../../core/components/CBHeader'

import NewBooking from '../../../../images/svg/new-booking.svg'

import {
  useGroupScheduleContext,
  withGroupScheduleContextProvider,
} from './GroupScheduleContext'

import { Steps } from './Steps'
import { GroupSchedulePages } from './GroupSchedulePages'

import SEO from '../../../../components/SEO'

const GroupScheduleCreatePage = () => {
  const { state } = useGroupScheduleContext()
  const currentPage = useMemo(() => get(state, 'currentPage'), [state])

  const Component = GroupSchedulePages[currentPage] || null

  return (
    <Layout>
      <SEO title="Create a Group Invitation" />
      <Content className="cb-content-frame" style={{ background: 'white' }}>
        <div className="cb-content-pane mobile_padding min-h-screen">
          <Component />
        </div>
      </Content>
      <MediaQuery minDeviceWidth={1224}>
        <Sider
          width={400}
          style={{ background: 'white' }}
          className="cb-content-frame"
        >
          <div className="ml-12">
            <CBHeader label="Create Booking" icon={<NewBooking />}></CBHeader>
          </div>

          <Steps />
        </Sider>
      </MediaQuery>
    </Layout>
  )
}

export default withGroupScheduleContextProvider(GroupScheduleCreatePage)
