import React, { useEffect } from 'react'
import Title from 'antd/lib/typography/Title'
import { Button, Row, Col } from 'antd'
import { Link } from 'gatsby'
import { loadSyncs } from '../../../utils/fetchData'
import { useGlobalState } from '../../../store'
import SEO from '../../../components/SEO'

const SyncCreateCompletePage = () => {
  const [, setLoadingCoreData] = useGlobalState('loadingCoreData')
  const [, setSyncs] = useGlobalState('syncs')
  useEffect(() => {
    setLoadingCoreData(true)
    async function fetchData() {
      const syncs = await loadSyncs()
      setSyncs(syncs)
      setLoadingCoreData(false)
    }
    fetchData()
  }, [])

  return (
    <>
      <SEO title="Sync Setup Success" />
      <Title style={{ textAlign: 'center', marginTop: 40 }}>
        Congrats! Your calendars are syncing now.
      </Title>
      <Title
        level={3}
        style={{ textAlign: 'center', marginTop: 0, marginBottom: 40 }}
      >
        It may take a couple of minutes to be fully synced.
      </Title>
      <Row gutter={15}>
        <Col span={12}>
          <Link to="/dashboard/syncs/new">
            <Button block size="large" type="primary">
              Add Another
            </Button>
          </Link>
        </Col>
        <Col span={12}>
          <Link to="/dashboard/syncs">
            <Button block size="large">
              All Done!
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  )
}

export default SyncCreateCompletePage
