import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import {
  Card,
  Col,
  Row,
  Switch,
  Popconfirm,
  Button,
  Dropdown,
  Menu,
  Modal,
  Progress,
  Spin,
} from 'antd'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import {
  colorBlack,
  colorPositive,
  colorNeutral,
  colorNeutralDark,
  colorNegative,
} from '../../../utils/colors'
import { faOutlook, accountIconMap } from '../../../utils/customIcons'
import { syncTypeText } from '../../../utils/words'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import ColorPicker from '../../syncs/components/ColorPicker'
// const syncStatusMap = {
//   synced: colorNeutral,
//   syncing: colorPositive,
//   synced_error: colorNegative,
//   deleting: colorNegative,
// }

const syncStatusMap = {
  synced: 'normal',
  syncing: 'normal',
  synced_error: 'exception',
  deleting: 'exception',
}

const SyncItem = ({ item, preview = false }) => {
  const [license] = useGlobalState('license')

  function resolveError() {
    var str = item.errorState
    if (str.includes('Auth token refresh failed for')) {
      //re-authenticate account with link
      var accountId = str.substring(str.indexOf('for ') + 4)
      return (
        <div>User Account Error: User does not exist anymore</div>
      )
    } else if (str.includes('timeout')) {
      return <div></div>
    } else if (str.includes("User")) {
      return <div>User Account Error: User does not exist anymore</div>
    } else if (
      str.includes('rateLimitExceeded') ||
      str.includes('usageLimits')
    ) {
      return <div>Calendar Service API Rate Limit Reached</div>
    } else if(item.status == "sync_conflict") {
      return (<div>Conflicting sync software detected. Due to privacy, security, and quality of service concerns these events have not been synced.</div>
        )
    } else if (str != '') {
      return (
        <div>
          Synced Account issue please contact{' '}
          <a href="mailto:support@calendarbridge.com">
            support@calendarbridge.com
          </a>
        </div>
      )
    } else {
      return <div>deleting...</div>
    }
  }

  function showDeleteConfirm() {
    Modal.confirm({
      title: 'Are you sure you want to delete this sync?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        await API.del('bridge', '/bridge', {
          body: {
            sourceCalendarId: item.sourceCalendarId,
            destCalendarId: item.destCalendarId,
          },
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'deleteSync',
          label: license.userId,
          email: license.email
        })

        window.location.reload()
      },
    })
  }

  function showForceDeleteConfirm() {
    Modal.confirm({
      title: 'Are you sure you want to force delete this sync? Copies will not be deleted!',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        await API.del('bridge', '/bridge?force=true', {
          body: {
            sourceCalendarId: item.sourceCalendarId,
            destCalendarId: item.destCalendarId,
          },
        })

        // window.dataLayer = window.dataLayer || []
        // window.dataLayer.push({
        //   event: 'dashboardEvent',
        //   category: 'userActivity',
        //   action: 'deleteSync',
        //   label: license.userId,
        //   email: license.email
        // })

        window.location.reload()
      },
    })
  }

  function showResyncConfirm() {
    Modal.confirm({
      title:
        'Resyncing requires all copies to be recreated. This process can take time based on how many events you have synced. Continue?',
      okText: 'Start Resync',
      okType: 'danger',
      cancelText: 'Cancel',
      async onOk() {
        try {
          await API.del('bridge', '/bridge?resync=true', {
            body: {
              sourceCalendarId: item.sourceCalendarId,
              destCalendarId: item.destCalendarId,
            },
          })
          window.location.reload()
        } catch (ex) {
          alert(
            'You may only resync once every 8 hours. Next resync at: ' +
              new Date(item.resynced + 1000 * 60 * 60 * 8).toLocaleString() 
          )
        }
      },
    })
  }

  const handleClick = (e) => {
    if (e === '0') {
      showForceDeleteConfirm()
    } else if (e === '1') {
      showDeleteConfirm()
    } else if (e === '4') {
      showResyncConfirm()
    }
  }

  function renderMenu(){
    return <div  className="flex flex-row gap-2">
    {/* <Menu.Item key="0">
      <Icon type="stop" />
      Disable
    </Menu.Item> */}
    {item.status == 'delete_error' && <div key="0" style={{ color: colorBlack }} className="border rounded-full p-2 hover:bg-neutral cursor-pointer bg-white">
    <div onClick={e=>handleClick("0")} className="flex gap-x-2 items-center">
    <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
      Force Delete
      </div>
    </div>}
    <div key="1" onClick={e=>handleClick("1")} style={{ color: colorBlack }} className="border rounded-full p-2 hover:bg-neutral cursor-pointer  bg-white">
    <div className="flex gap-x-2 items-center">
    <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
      Delete
      </div>
    </div>
    {(!item.twoWay && item.status != 'syncing' && item.status != 'deleting' && (item.status == 'synced' || item.status == 'synced_error')) && (
      <div key="4" onClick={e=>handleClick("4")} className="border rounded-full p-2 hover:bg-neutral cursor-pointer  bg-white">
        <div className="flex gap-x-2 items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          Resync
        </div>
      </div>
    )}
  </div>
  }

  const menu = (
    <Menu onClick={handleClick} style={{ width: 125 }}>
      {/* <Menu.Item key="0">
        <Icon type="stop" />
        Disable
      </Menu.Item> */}
      {item.status == 'delete_error' && <Menu.Item key="0" style={{ color: colorNegative }}>
      <div className="flex gap-x-4 items-center">
      <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
        Force Delete
        </div>
      </Menu.Item>}
      <Menu.Item key="1" style={{ color: colorNegative }}>
      <div className="flex gap-x-4 items-center">
      <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
        Delete
        </div>
      </Menu.Item>
      {(!item.twoWay && item.status != 'syncing' && item.status != 'deleting' && (item.status == 'synced' || item.status == 'synced_error')) && (
        <Menu.Item key="4">
          <div className="flex gap-x-4 items-center">
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
            Resync
          </div>
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <div className="border p-4 mb-8"
      style={{  wordWrap: 'break-word' }}
    >
      {/* {!preview && (
        <div
          style={{
            float: 'right',
            position: 'absolute',
            right: 5,
            top: 5,
            zIndex: 100,
          }}
        >
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="dashed" icon={<MoreOutlined />} size="large" />
          </Dropdown>
        </div>
      )} */}
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Title style={{ color: colorBlack }} level={4}>{item.sourceCalendarName}</Title>
          <Text style={{ color: colorBlack }}>
            {item.sourceCalendarAccountId}
            <FontAwesomeIcon
              icon={accountIconMap[item.sourceCalendarType].icon}
              style={{
                marginLeft: 10,
                marginRight: 10,
                display: 'inline-block',
              }}
            />
          </Text>
          {!preview &&
            item.sourceColor != 'default' &&
            item.sourceColor != 'same' && (
              <ColorPicker
                type={item.sourceCalendarType}
                color={item.sourceColor}
                preview={true}
              ></ColorPicker>
            )}
        </Col>

        <Col span={8} style={{ textAlign: 'center' }}>
          {item.twoWay && <FontAwesomeIcon
            icon={faLongArrowAltLeft}
            size="3x"
            color={item.twoWay ? colorBlack : colorBlack}
          />}{' '}
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            size="3x"
            color={colorBlack}
          />
          <br />
          <Text style={{ fontSize: 12, color: colorBlack }}>
            {syncTypeText(
              !preview && item.securityPolicy.includes('subject'),
              !preview && item.securityPolicy.includes('description'),
              !preview && item.securityPolicy.includes('location'),
              !preview && item.securityPolicy.includes('reminder'),
              !preview && item.securityPolicy.includes('private'),
              !preview && item.securityPolicy.includes('meeting'),
              !preview && item.securityPolicy.includes('contact')
            )}
            {!preview && (
              <div>
                <Text style={{ fontSize: 12, color: colorBlack }}>
                  Tag: {item.tag != 'default' ? item.tag : '(copy)'}
                </Text>
                {!preview && item.matchColors && <div style={{ color: colorBlack }}>Match Source Colors</div>}
              </div>
            )}
            {!preview && item.status === 'synced' && 'Active and Up-to-Date'}
          </Text>
        </Col>

        <Col span={8} style={{ textAlign: 'center' }}>
          <Title style={{ color: colorBlack }} level={4}>{item.destCalendarName}</Title>
          <Text style={{ color: colorBlack }}>
            {item.destCalendarAccountId}
            <FontAwesomeIcon
              icon={accountIconMap[item.destCalendarType].icon}
              style={{
                marginLeft: 10,
                marginRight: 10,
                display: 'inline-block',
              }}
            />
            {!preview &&
              item.destinationColor != 'same' &&
              item.destinationColor != 'default' && (
                <ColorPicker
                  type={item.destCalendarType}
                  color={item.destinationColor}
                  preview={true}
                ></ColorPicker>
              )}
          </Text>
        </Col>
      </Row>

      
      {!preview && item.status !== 'synced' && !item.errorState.includes('timeout') &&(
        <>
          <div
            style={{
              float: 'right',
              position: 'absolute',
              right: 10,
              bottom: 10,
              zIndex: 100,
            }}
          >
            <Progress
              type="circle"
              percent={Math.round((item.syncProgress / 12) * 100)}
              width={30}
              status={item.errorState.includes('timeout') ? 'normal' : syncStatusMap[item.status] }
            />
          </div>
          {syncStatusMap[item.status] == 'exception' && (
            <div
              style={{
                float: 'right',
                position: 'absolute',
                marginRight: 30,
                right: 20,
                bottom: 0,
                zIndex: 100,
              }}
            >
              {resolveError()}
            </div>
          )}
        </>
      )}
      <div className="relative">
        <div className="absolute right-0">{renderMenu()}</div>
      
      </div>
    </div>
  )
}

export default SyncItem
