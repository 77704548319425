import React, { useState } from 'react'
import { Table, Button } from 'antd'


function OrgTable({
	organizationData,
	editOrganizationHandler
}) {
	const [filterValues, SetFilterValues] = useState({});

	const columns = [
		{
			title: 'Edit Org',
			dataIndex: 'edit',
			key: 'edit',
			render: (text, record) => (
				<Button
					type={'primary'}
					onClick={() => {
						editOrganizationHandler(record);
					}}
				>
					Edit
				</Button>
			),
		},
		{
			title: 'orgId',
			dataIndex: 'orgId',
			key: 'orgId',
			sorter: (a, b) => String(a.orgId) > String(b.orgId),
			sortDirections: ['ascend', 'descend'],
			onFilter: (value, record) => record.orgId.toString().toLowerCase().includes(value.toLowerCase())
		},
		{
			title: 'Billing Style',
			dataIndex: 'converted',
			key: 'converted',
			render: (text, record) => (
				<div	
				>
				{text == true ? 'New' : 'Old'}
				</div>
			)
		},
		{
			title: 'stripeId',
			dataIndex: 'stripeId',
			key: 'stripeId',
			sorter: (a, b) => String(a.stripeId) > String(b.stripeId),
			sortDirections: ['ascend', 'descend'],
			onFilter: (value, record) => record.stripeId.toString().toLowerCase().includes(value.toLowerCase())
		},
		{
			title: 'subId',
			dataIndex: 'subId',
			key: 'subId',
			sorter: (a, b) => String(a.subId) > String(b.subId),
			sortDirections: ['ascend', 'descend'],
			onFilter: (value, record) => record.subId.toString().toLowerCase().includes(value.toLowerCase())
		},
		{
			title: 'orgInterval',
			dataIndex: 'orgInterval',
			key: 'orgInterval',
			sorter: (a, b) => String(a.orgInterval) > String(b.orgInterval),
			sortDirections: ['ascend', 'descend'],
			onFilter: (value, record) => record.orgInterval.toString().toLowerCase().includes(value.toLowerCase())
		},
		{
			title: 'allowedDomains',
			dataIndex: 'allowedDomains',
			key: 'allowedDomains',
			render: (text, record) => {
				if (text === null) {
					return <span>{'null'}</span>;
				}
				return <ul>{text.map((domain) => (<li>{`${domain.name} - ${domain.type}`}</li>))}</ul>;
			},
			onFilter: (value, record) => {
				//need to go through every entry.
				if (Array.isArray(record.allowedDomains)) {
					for (let i = 0; i < record.allowedDomains.length; i++) {
						const domain = record.allowedDomains[i];
						if (domain.name.toString().toLowerCase().includes(value.toLowerCase())) {
							return true;
						}
					}
				}
				return false;
			}
		}
	];


	return (<><h4>Organizations: {organizationData.length}</h4>

		<Table
			scroll={{ x: 'max-content' }}
			dataSource={organizationData}
			columns={columns}
			pagination={false}
			bordered={true}
			onChange={(pagination, filters, sorter, extra) => {
				console.log('onChange table params', pagination, filters, sorter, extra);
			}}
		/>
	</>)
}

export default OrgTable