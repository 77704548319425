import React, { useEffect, useState, useRef,useCallback } from 'react'
import Title from 'antd/lib/typography/Title'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import OrgSyncItem from '../components/OrgSyncItem'

import ScheduleItem from '../components/OrgScheduleItem'
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons'

import JSONTree from 'react-json-tree'
import Connections from '../../../images/svg/connections.svg'
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Select,
  Table,
  Divider,
  Radio,
  Checkbox,
  Spin,
  Menu,
  Tag,
  TimePicker,
  Modal,
  InputNumber,
  Space
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Text from 'antd/lib/typography/Text'
import { accountIconMap } from '../../../utils/customIcons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  colorPositive,
  colorBlack,
  colorNeutral,
  colorNeutralDark,
  colorNegative,
} from '../../../utils/colors'
import ColorPicker from '../../syncs/components/ColorPicker'

import momentTz from 'moment-timezone'
import moment from 'moment'
import { debounce } from 'lodash'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBTextArea,
  CBModal,
  CBList,
  CBCalendar,
  CBIncrementer,
} from '../../core/components/cbd'

import {
  v2_plans,
  v3_plans,
  sync_plans_old_monthly,
  sync_plans_old_yearly,
  sync_plans_new_monthly,
  sync_plans_new_yearly,
} from '../components/OrgPrices'

const theme = {
  scheme: 'google',
  author: 'seth wright (http://sethawright.com)',
  base00: '#1d1f21',
  base01: '#282a2e',
  base02: '#373b41',
  base03: '#969896',
  base04: '#b4b7b4',
  base05: '#c5c8c6',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#CC342B',
  base09: '#F96A38',
  base0A: '#FBA922',
  base0B: '#198844',
  base0C: '#3971ED',
  base0D: '#3971ED',
  base0E: '#A36AC7',
  base0F: '#3971ED',
}

function getUserSeatLabel(type, org) {
  return `${type}-${
    org.organization.orgInterval.includes('monthly') ? 'monthly' : 'yearly'
  }`
}
function getUserSeats(type, org) {
  
  return org.billing.cbUsers[getUserSeatLabel(type, org)] === undefined
    ? 0
    : org.billing.cbUsers[getUserSeatLabel(type, org)]
}

function getSyncLabel(org) {
  return `one-way-sync-${org.organization.orgInterval}`
}

function getAvailableUserSeats(type, org) {
  return org.billing.availableMap[getUserSeatLabel(type, org)]
    ? org.billing.availableMap[getUserSeatLabel(type, org)]
    : 0
}

function getUsedSyncs(org) {
  return org.billing.cbBridges[getSyncLabel(org)] === undefined
    ? 0
    : org.billing.cbBridges[getSyncLabel(org)]
}

function getAvailableSyncs(org) {
  return org.billing.availableMap[
    `one-way-sync-${org.organization.orgInterval}`
  ] === undefined
    ? 0
    : org.billing.availableMap[`one-way-sync-${org.organization.orgInterval}`]
}

function isYearly(org) {
  return org.organization.orgInterval.includes('yearly')
}
function check2022(org) {
  return org.organization.orgInterval.includes('2022')
}
function checkPro(org) {
  return !(
    org.billing.stripeBreakdown['pro-monthly'] != undefined ||
    org.billing.stripeBreakdown['pro-yearly'] != undefined
  )
}

const BillingPage = ({ org }) => {
  
  const isYearlyPlan = isYearly(org)
  const is2022 = check2022(org)

  const isProV3 = checkPro(org)
  const usedSyncs = getUsedSyncs(org)
  
  const usedAdmin = getUserSeats('admin', org)
  const availAdmin = getAvailableUserSeats('admin', org)

  const usedBasics = getUserSeats('basic', org)
  const availBasic =
    getAvailableUserSeats('basic', org) -
    getAvailableUserSeats('group-admin', org)
    
  const availPremium = getAvailableUserSeats('premium', org)
  const availPro = getAvailableUserSeats('pro', org)
  const availProV3 = getAvailableUserSeats('pro-v3', org)
  const availSync = getAvailableSyncs(org)

  const [admin, setAdmin] = useState(
    getAvailableUserSeats('admin', org) 
  )

  const [basic, setBasic] = useState(
    getAvailableUserSeats('basic', org) -
      getAvailableUserSeats('group-admin', org)
  )

  const [groupAdmin, setGroupAdmin] = useState(
    getAvailableUserSeats('group-admin', org)
  )

  const usedPremium = getUserSeats('premium', org)

  const [premium, setPremium] = useState(getAvailableUserSeats('premium', org))

  const usedPro = getUserSeats('pro', org)

  const [pro, setPro] = useState(getAvailableUserSeats('pro', org))

  const usedProV3 = getUserSeats('pro-v3', org)

  const [proV3, setProV3] = useState(getAvailableUserSeats('pro-v3', org))

  const [syncs, setSyncs] = useState(getAvailableSyncs(org))

  const [loading, setLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)

  const [showCancelModal, setShowCancelModal] = useState(false)
  async function adjustSeats(org) {
    var body = { seats: {} }

    body.seats[getSyncLabel(org)] = usedSyncs + syncs
    body.seats[getUserSeatLabel('admin', org)] = usedAdmin + admin
    body.seats[getUserSeatLabel('basic', org)] = usedBasics + basic
    body.seats[getUserSeatLabel('premium', org)] = usedPremium + premium
    body.seats[getUserSeatLabel('pro', org)] = usedPro + pro
    body.seats[getUserSeatLabel('pro-v3', org)] = usedProV3 + proV3

    try {
      setLoading(true)
      var org = await API.post(
        'organization',
        '/organization/?edit_seats=true&id=' + org.organization.orgId,
        {
          body,
        }
      )
      // console.log(org)
      location.reload()
    } catch (e) {
      alert(e.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  
  async function cancelOrg(org) {
    

    var body = { seats: {} }
    try {
      setLoading(true)
      var org = await API.post(
        'organization',
        '/organization/?cancel_org=true&id=' + org.organization.orgId,
        {
          body,
        }
      )
      // console.log(org)
      location.href = window.location.origin + '/dashboard/'
    } catch (e) {
      console.log(e)
      alert(e.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  function renderExisting(org) {
    return (
      <>
        <div className="text-lg">Occupied Seats</div>
        {groupAdmin > 0 && (
          <div className="text-lg">Group Admin: {groupAdmin}</div>
        )}
         <div className="text-lg">Admin Only: {usedAdmin}</div>
        <div className="text-lg">
          Basic: {groupAdmin > 0 ? usedBasics - groupAdmin : usedBasics}
        </div>
        <div className="text-lg">Premium: {usedPremium}</div>
        {!isProV3 ? (
          <div className="text-lg">Pro: {usedPro}</div>
        ) : (
          <div className="text-lg">Pro: {usedProV3}</div>
        )}
        <div className="text-lg">
          Syncs:{' '}
          {org.billing.cbBridges[
            `one-way-sync-${org.organization.orgInterval}`
          ] === undefined
            ? 0
            : org.billing.cbBridges[
                `one-way-sync-${org.organization.orgInterval}`
              ]}
        </div>
      </>
    )
  }

  function getPlusMinus(number) {
    if (number == 0) {
      return 'No Change'
    }
    if (number < 0) {
      return `${number}`
    } else {
      return `+${number}`
    }
  }

  function renderSummary(org) {
    return (
      <div className="p-4 text-lg">
        {' '}
        <div>Summary</div>
        <div>{isYearlyPlan ? 'Yearly' : 'Monthly'}</div>
        <table>
          <tr>
            <td>Plan</td>
            <td>Current</td>
            <td>New</td>
          </tr>
          
          <tr>
            <td>Basic</td>
            <td>{usedBasics + availBasic}</td>
            <td>
              <b>
                {usedBasics + basic} ({getPlusMinus(basic - availBasic)})
              </b>
            </td>
          </tr>
          <tr>
            <td>Premium</td>
            <td>{usedPremium + availPremium}</td>
            <td>
              <b>
                {usedPremium + premium} ({getPlusMinus(premium - availPremium)})
              </b>
            </td>
          </tr>

          <tr>
            <td>Pro</td>
            <td>{!isProV3 ? usedPro + availPro : usedProV3 + availProV3}</td>
            <td>
              <b>
                {!isProV3 ? usedPro + pro : usedProV3 + proV3} (
                {!isProV3
                  ? getPlusMinus(pro) - availPro
                  : getPlusMinus(proV3 - availProV3)}
                )
              </b>
            </td>
          </tr>
          <tr>
            <td>Admin Only</td>
            <td>{usedAdmin + availAdmin}</td>
            <td>
              <b>
                {usedAdmin + admin} ({getPlusMinus(admin - availAdmin)})
              </b>
            </td>
          </tr>
          <tr>
            <td>Syncs</td>
            <td>{usedSyncs + availSync}</td>
            <td>
              <b>
                {usedSyncs + syncs} ({getPlusMinus(syncs - availSync)})
              </b>
            </td>
          </tr>
        </table>
      </div>
    )
  }

  return (
    <div className="m-2 p-4">
      {/* <div>Customer Id: {org.organization.stripeId}</div>
      <div>Sub Id: {org.organization.subId}</div> */}

      <div className="p-4 m-2 bg-neutral lg:w-2/3">{renderExisting(org)}</div>
      <div className="lg:w-2/3">
        Adjust Available Seats:
        
        <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
          <div className="text-lg">Basic Users</div>
          <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
            <CBIncrementer
              min={0}
              onChange={(e) => {
                setBasic(e)
              }}
              defaultValue={basic}
            />
            <div className="text-lg font-bold">
              x ${v3_plans['basic'][isYearlyPlan ? 'yearly' : 'monthly'].value}{' '}
              {isYearlyPlan ? 'yearly' : 'monthly'}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
          <div className="text-lg"> Premium Users</div>
          <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
            <CBIncrementer
              min={0}
              onChange={(e) => {
                setPremium(e)
              }}
              defaultValue={premium}
            />
            <div className="text-lg font-bold">
              x $
              {v3_plans['premium'][isYearlyPlan ? 'yearly' : 'monthly'].value}{' '}
              {isYearlyPlan ? 'yearly' : 'monthly'}
            </div>
          </div>
        </div>
        {!isProV3 ? (
          <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
            <div className="text-lg"> Pro Users</div>
            <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
              <CBIncrementer
                min={0}
                onChange={(e) => {
                  setPro(e)
                }}
                defaultValue={pro}
              />
              <div className="text-lg font-bold">
                x ${v2_plans['pro'][isYearlyPlan ? 'yearly' : 'monthly'].value}{' '}
                {isYearlyPlan ? 'yearly' : 'monthly'}
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
            <div className="text-lg"> Pro Users</div>
            <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
              <CBIncrementer
                min={0}
                onChange={(e) => {
                  setProV3(e)
                }}
                defaultValue={proV3}
              />
              <div className="text-lg font-bold">
                x ${v3_plans['pro'][isYearlyPlan ? 'yearly' : 'monthly'].value}{' '}
                {isYearlyPlan ? 'yearly' : 'monthly'}
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
          <div className="text-lg">Admin Only Users</div>
          <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
            <CBIncrementer
              min={0}
              max={5}
              onChange={(e) => {
                setAdmin(e)
              }}
              defaultValue={admin}
            />
            <div className="text-lg font-bold">
              x ${v3_plans['admin'][isYearlyPlan ? 'yearly' : 'monthly'].value}{' '}
              {isYearlyPlan ? 'yearly' : 'monthly'}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
          <div className="text-lg">Admin Syncs</div>
          <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
            <CBIncrementer
              min={0}
              onChange={(e) => {
                setSyncs(e)
              }}
              defaultValue={syncs}
            />
            <div className="text-lg font-bold">
              x $
              {is2022 ? (
                <>
                  {isYearlyPlan
                    ? `${sync_plans_new_yearly} yearly`
                    : `${sync_plans_new_monthly} monthly`}
                </>
              ) : (
                <>
                  {isYearlyPlan
                    ? `${sync_plans_old_yearly} yearly`
                    : `${sync_plans_old_monthly} monthly`}
                </>
              )}
            </div>
          </div>
        </div>
        <SubmitButton loading={loading} onClick={(e) => setShowModal(true)}>
          Add/Remove Seats
        </SubmitButton>

       
      </div>
      <div className="mt-8 p-4 gap-2">
        {/* org.syncs.length != 0 || org.users.filter(user => user.orgAdmin).length != org.users.length */}
        <CBButton disabled={org.syncs.length != 0 || org.users.filter(user => user.orgAdmin).length != org.users.length} onClick={e=> setShowCancelModal(true)}>Cancel Organization</CBButton>
        {org && org.syncs.length != 0 && <div>All Syncs must be deleted</div>}
        {org && (org.users.filter(user => user.orgAdmin).length != org.users.length) && <div>All users except for organization admins must be removed</div>}
        {/* {org && (org.syncs.length == 0 && org.users.filter(user => user.orgAdmin).length == org.users.length) && <div>Can delete org</div>} */}
      </div>
      <CBModal
        disabled={loading}
        positiveLabel="Confirm"
        visible={showCancelModal}
        onPositiveClick={(q) => {
          cancelOrg(org)
        }}
        onCancelClick={(v) => setShowCancelModal(false)}
      >
        <div>Confirm cancelling organization is irreversible and immediate, all accounts will be disabled.</div>
      </CBModal>
      <CBModal
        disabled={loading}
        positiveLabel="Confirm"
        visible={showModal}
        onPositiveClick={(q) => {
          adjustSeats(org)
        }}
        onCancelClick={(v) => setShowModal(false)}
      >
        {renderSummary(org)}
      </CBModal>
      {/* <div className="flex flex-row gap-x-4">
        <div className="text-lg">Available Pro:</div>
        <div>
          <CBIncrementer
            min={0}
            onChange={(e) => {
              setPro(e)
            }}
            defaultValue={pro}
          />
        </div>
      </div> */}

      {/* <div className="flex flex-row gap-x-4">
        <div className="text-lg">Available Pro:</div>
        <div>
          <CBIncrementer
            min={0}
            onChange={(e) => {
              setProV3(e)
            }}
            defaultValue={proV3}
          />
        </div>
      </div> */}

      {/* <div className="flex flex-row gap-x-4">
        <div className="text-lg">Available Syncs:</div>
        <div>
          <CBIncrementer
            min={0}
            onChange={(e) => {
              setSyncs(e)
            }}
            defaultValue={syncs}
          />
        </div>
      </div> */}
    </div>
  )
}

const OrgItem = (props) => {
  const [license] = useGlobalState('license')
  const [twoWay, setTwoWay] = useState(false)
  const [hasFilters, setHasFilters] = useState(false)
  const [filterFree, setFilterFree] = useState(false)
  const [filterColor, setFilterColor] = useState("none")
  const [selectedOption, setSelectedOption] = useState(0)
  const [selectedUserOption, setSelectedUserOption] = useState(1)
  const [checkUserOptions, setCheckUserOptions] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ])
  const [sourceCalendarTimezone, setSourceCalendarTimezone] = useState(
    momentTz.tz.guess()
  )
  const [sourceColor, setSourceColor] = useState('default')
  const [sourceCalendarType, setSourceCalendarType] = useState(null)
  const [label, setLabel] = useState(null)
  const [destCalendarType, setDestCalendarType] = useState(null)
  const [showSync, setShowSync] = useState(false)
  const [showUserManagement, setShowUserManagement] = useState(true)

  const [showDomainManagement, setShowDomainManagement] = useState(false)
  const [showBilling, setShowBilling] = useState(false)
  const [syncError, setSyncError] = useState(null)
  const [sourceLoad, setSourceLoad] = useState(false)
  const [destLoad, setDestLoad] = useState(false)
  const [disableLoad, setDisableLoad] = useState(false)
  const [quickSyncLoading, setQuickSyncLoading] = useState(false)
  const [quickAddLoading, setQuickAddLoading] = useState(false)

  const [domainError, setDomainError] = useState('')

  const [destColor, setDestColor] = useState('default')
  const [destCalendarTimezone, setDestCalendarTimezone] = useState(
    momentTz.tz.guess()
  )
  const [preloads, setPreloads] = useState([])
  const [planId, setPlanId] = useState(null)
  const [checkOptions, setCheckOptions] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ])
  const [sourceEmail, setSourceEmail] = useState('')

  const [destEmail, setDestEmail] = useState('')
  //error states

  const [sourceError, setSourceError] = useState(null)
  const [destError, setDestError] = useState(null)
  const [userError, setUserError] = useState(null)

  const sourceFormRef = useRef(null)
  const destFormRef = useRef(null)
  const [sourceValid, setSourceValid] = useState(false)
  const [destValid, setDestValid] = useState(false)

  const [userAddValid, setUserAddValid] = useState(false)
  const [editUser, setEditUser] = useState(false)

  function getTzOptions() {
    return moment.tz.names().map((tz) => {
      return (
        <Select.Option key={tz}>
          {tz} ({moment.tz(tz).format('Z z')})
        </Select.Option>
      )
    })
  }

  function printTzOptions() {
    return moment.tz.names().map((tz) => {
      return <div>{`windowsTimeZoneMapping.put("${tz}", "${tz}")`}</div>
    })
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Plan',
      dataIndex: 'planId',
      key: 'planId',
      render: (planId) => {
        return <Tag color={'blue'}>{planId.split('-')[0].toUpperCase()}</Tag>
      },
    },
    {
      title: 'Policy',
      dataIndex: 'policy',
      key: 'policy',
      render: (tags) => (
        <span>
          {tags.split('|').map((tag) => {
            let color = 'volcano'
            if (tag === 'all' || tag === 'time') {
              color = 'green'
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            )
          })}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'orgAdmin',
      key: 'orgAdmin',
      render: (tags) => (
        <span>
          {tags ? "Admin" : "Standard"}
        </span>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'userId',
      key: 'userId',
      render: (tags) => {
        if (tags.includes('us-east')) {
          return <div>Active</div>
        } else {
          return (
            <div>Needs to log-in</div>
          )
        }
      },
    },
    {
      title: 'Options',
      dataIndex: 'userId',
      key: 'userId',
      render: (tags) => {
        if (tags.includes('us-east')) {
          return (
            <div>
              <Button
                style={{ marginRight: 8 }}
                loading={disableLoad}
                onClick={(e) => editUserEntry(tags)}
              >
                Edit
              </Button>
              <Button
                loading={disableLoad}
                onClick={(e) => deleteUserAccount(tags)}
              >
                Delete
              </Button>
            </div>
          )
        } else {
          return (
            <div>
              <Button
                style={{ marginRight: 8 }}
                loading={disableLoad}
                onClick={(e) => editUserEntry(tags)}
              >
                Edit
              </Button>
              <Button
                style={{ marginRight: 16 }}
                loading={disableLoad}
                onClick={(e) => resend(tags)}
              >
                Re-send invite
              </Button>
              <Button
                loading={disableLoad}
                onClick={(e) => deleteUserAccount(tags)}
              >
                Delete
              </Button>
            </div>
          )
        }
      },
    },
  ]

  const [sourceDomain, setSourceDomain] = useState(0)
  const [destDomain, setDestDomain] = useState(0)
  const [sourceCalendars, setSourceCalendars] = useState([
    { name: 'Default', id: 'Default' },
  ])
  const [selectedSourceCalendar, setSelectedSourceCalendar] = useState(0)
  const [selectedDestCalendar, setSelectedDestCalendar] = useState(0)

  const [sourceName, setSourceName] = useState(null)
  const [destName, setDestName] = useState(null)

  const [destCalendars, setDestCalendars] = useState([
    { name: 'Default', id: 'Default' },
  ])

  const [matchColors, setMatchColors] = useState(false)
  const [preloadName, setPreloadName] = useState(null)
  const [preloadDomain, setPreloadDomain] = useState(0)

  const [addedDomain, setAddedDomain] = useState(null)
  const [showAddDomain, setShowAddDomain] = useState(true)
  const [testEmail, setTestEmail] = useState(null)
  const [accept, setAccept] = useState(false)
  const [tennantType, setTennantType] = useState('ms')
  const [admin, setAdmin] = useState(false)

  function handleChange(value, position) {
    if (position == 'source') {
      setSourceName('')
      setSourceDomain(value)
      setSourceCalendarType(org.organization.allowedDomains[value].type)

      setSourceCalendars[{ id: 'id', name: 'Default' }]
      setSelectedSourceCalendar('Default')
      setSourceEmail('')
      // if (sourceFormRef.current) {
      //   setSourceEmail('')
      //   sourceFormRef.current.setValue('')
      //   sourceFormRef.current.focus()
      // }
    } else if (position == 'dest') {
      setDestName('')
      setDestEmail('')
      setDestDomain(value)
      setDestCalendarType(org.organization.allowedDomains[value].type)
      if (destFormRef.current) {
        destFormRef.current.setValue('')
      }

      setDestCalendars[{ id: 'id', name: 'Default' }]
      setSelectedDestCalendar('Default')
    } else {
      setPreloadDomain(value)
    }
  }

  function editUserEntry(id) {
    //console.log('EDIT ' + id)
    //console.log(org.users)
    setEditUser(true)
    setUserAddValid(true)
    var user = org.users.filter((e) => e.userId == id)
    //console.log(user)
    if (user.length > 0) {
      var us = user[0]
      //console.log(us)
      setEmail(us.email)
      setPlanId(us.planId)
      setAdmin(us.orgAdmin)
      var policy = us.policy
      if (policy == 'all') {
        setSelectedUserOption(1)
        setCheckUserOptions(
          getOptions([true, true, true, true, true, true, true])
        )
      } else if (policy == 'time') {
        setSelectedUserOption(0)
      } else {
        setSelectedUserOption(2)

        setCheckUserOptions(
          getOptions([
            policy.includes('subject'),
            policy.includes('description'),
            policy.includes('contact'),
            policy.includes('location'),
            policy.includes('meeting'),
            policy.includes('reminder'),
            policy.includes('private'),
          ])
        )
      }
    }
  }

  const selectAfter = (position) => {
    if (
      !org ||
      !org.organization.allowedDomains ||
      org.organization.allowedDomains.size == 0
    )
      return <Select></Select>

    return (
      <Select
        hint="select domain"
        onChange={(e) => handleChange(e, position)}
        defaultValue={position == 'source' ? sourceDomain : destDomain}
        style={{ width: 300 }}
      >
        {org.organization.allowedDomains.map((acc, index) => {
          return (
            <Option value={index}>
              @{acc.domain}{' '}
              <FontAwesomeIcon
                style={{ marginTop: 8, marginLeft: 8 }}
                icon={accountIconMap[acc.type].icon}
                size="1x"
                pull="right"
                color={accountIconMap[acc.type].color}
              />
            </Option>
          )
        })}
      </Select>
    )
  }
  const [org, setOrg] = useState(null)

  const [filterBridges, setFilterBridges] = useState([])
  const [filterUsers, setFilterUsers] = useState([])
  const [email, setEmail] = useState(null)

  async function getCalendars(email, sourceForm) {
    
    setSourceError(null)
    setDestError(null)
    if (email != '') {
      var source = ''
      var type = ''
      if (sourceForm == 'source') {
        source = org.organization.allowedDomains[sourceDomain].domain
        type = org.organization.allowedDomains[sourceDomain].type
        setSourceLoad(true)
      } else {
        setDestLoad(true)
        source = org.organization.allowedDomains[destDomain].domain
        type = org.organization.allowedDomains[destDomain].type
      }
      try {
        var calendars = await API.get(
          'organization',
          '/organization/?calendars=true&id=' +
            encodeURIComponent(email + '@' + source) +
            '&type=' +
            type
        )
        setDestError(null)
        setSourceError(null)
        setMatchColors(false)
        if (sourceForm == 'source') {
          setSourceValid(true)
          setSourceLoad(false)
          setSourceCalendars(calendars.items)
          setSelectedSourceCalendar(0)
          if (
            calendars.items[0].summary != null &&
            calendars.items[0].summary != ''
          ) {
            setSourceCalendarTimezone(calendars.items[0].timeZone)
          } else {
            setSourceCalendarTimezone(momentTz.tz.guess())
          }
        } else {
          calendars.items = calendars.items.filter(
            (item) =>
              item.canEdit || (item.accessRole && item.accessRole != 'reader')
          )
          setDestValid(true)
          setDestLoad(false)
          setDestCalendars(calendars.items)
          setSelectedDestCalendar(0)
          if (
            calendars.items[0].summary != null &&
            calendars.items[0].summary != ''
          ) {
            setDestCalendarTimezone(calendars.items[0].timeZone)
          } else {
            setDestCalendarTimezone(momentTz.tz.guess())
          }
        }
      } catch (ex) {
        //console.log('ERROR')
        //console.log(ex)
        if (sourceForm == 'source') {
          setSourceLoad(false)
          setSourceError('Error loading user, is this a valid user?')
        } else {
          setDestLoad(false)
          setDestError('Error loading user, is this a valid user?')
        }
      }
    }
  }

  async function resend(email) {
    setDisableLoad(true)
    await API.get(
      'organization',
      '/organization/?resend=true&id=' + encodeURIComponent(email)
    )
    setDisableLoad(false)
  }

  async function quickSync() {
    setQuickSyncLoading(true)
    setSourceError(null)
    setSyncError(null)
    var policy = ''
    if (selectedOption == 1) {
      policy = 'subject|description|location|contact|meeting|reminder|private'
    } else if (selectedOption == 2) {
      //console.log(checkOptions)
      policy = 'time'
      if (checkOptions[0]) {
        policy += '|subject'
      }
      if (checkOptions[1]) {
        policy += '|description'
      }
      if (checkOptions[2]) {
        policy += '|contact'
      }
      if (checkOptions[3]) {
        policy += '|location'
      }
      if (checkOptions[4]) {
        policy += '|meeting'
      }
      if (checkOptions[5]) {
        policy += '|reminder'
      }
      if (checkOptions[6]) {
        policy += '|private'
      }
    }
    if (policy == '') {
      policy = 'time'
    }
    var sourceCalendarId = null
    var sourceCalendarName = null
    var destCalendarId = null
    var destCalendarName = null
    if (sourceCalendars[selectedSourceCalendar].id != 'default') {
      var cal = sourceCalendars[selectedSourceCalendar]
      sourceCalendarId = cal.id
      sourceCalendarName = cal.name ? cal.name : cal.summary
    }
    if (destCalendars[selectedDestCalendar].id != 'default') {
      var cal = destCalendars[selectedDestCalendar]
      destCalendarId = cal.id
      destCalendarName = cal.name ? cal.name : cal.summary
    }
    //console.log(policy)
    //console.log(twoWay)
    var syncLevel = ['free', 'tentative', 'busy', 'oof', 'workingElsewhere'].join("|")
    if(filterFree) {
      syncLevel = ['tentative', 'busy', 'oof', 'workingElsewhere'].join("|")
    }

    try {
      var orgP = await API.post(
        'organization',
        '/organization/?id=' + props.org,
        {
          body: {
            sourceEmail: `${sourceName}@${org.organization.allowedDomains[sourceDomain].domain}`,
            sourceType: `${org.organization.allowedDomains[sourceDomain].type}`,
            sourceCalendarId: sourceCalendarId,
            destCalendarId: destCalendarId,
            sourceCalendarName: sourceCalendarName,
            destCalendarName: destCalendarName,
            destEmail: `${destName}@${org.organization.allowedDomains[destDomain].domain}`,
            destType: `${org.organization.allowedDomains[destDomain].type}`,
            policy: policy,
            sourceColor,
            destColor,
            sourceCalendarTimezone,
            destCalendarTimezone,
            tag: label ? label : 'default',
            twoWay,
            matchColors,
            hasFilters: hasFilters,
            filterColor: filterColor,
            syncLevel: syncLevel
          },
        }
      )
      setSourceEmail('')
      setDestEmail('')
      // setSourceName(null)
      // location.reload()
      // if (sourceFormRef.current) {
      //   sourceFormRef.current.setValue('')
      //   sourceFormRef.current.focus()
      // }
      // if (destFormRef.current) {
      //   destFormRef.current.setValue('')
      // }
      setMatchColors(false)
      setSourceName(null)
      setDestName(null)
      setSourceValid(false)
      setDestValid(false)
      setQuickSyncLoading(false)

      setDestCalendars[{ id: 'id', name: 'Default' }]
      setSourceCalendars[{ id: 'id', name: 'Default' }]
      setSelectedSourceCalendar('Default')
      setSelectedDestCalendar('Default')
      setOrg({
        organization: orgP.organization,
        syncs: orgP.syncs,
        accounts: orgP.accounts,
        users: orgP.users,
        schedules: orgP.schedules,
        billing: orgP.orgBreakDown,
      })
    } catch (e) {
      // alert(e.response.data.message)
      console.log(e)
      if(e.response) {
      if (e.response.data.message.includes('exists')) {
        if (twoWay) {
          setSyncError(
            'Error Creating Sync, sync or part of sync already exists'
          )
        } else {
          setSyncError('Error Creating Sync, sync already exists')
        }
      } else {
        setSyncError('Error creating sync, contact support')
      }

      setQuickSyncLoading(false)
    } else {
      console.log("BAD")
      console.log(e)
      setSyncError('Error creating sync, contact support')
      setQuickSyncLoading(false)
    }
  }
  }

  function clearUser() {
    setEditUser(false)
    setEmail(null)
    setPreloads([])
    setPreloadName(null)
    setPreloadDomain(null)
    setQuickAddLoading(false)
    setAdmin(false)
  }

  async function addCustomDomain() {
    setDomainError('')
    setQuickSyncLoading(true)

    var body = {
      testEmail: testEmail.toLowerCase() + '@' + addedDomain.toLowerCase(),
      domain: addedDomain,
      type: tennantType,
    }
    //console.log(body)

    try {
      var org = await API.post(
        'organization',
        '/organization/?addDomain=true&id=' + props.org,
        {
          body,
        }
      )

      //console.log(org)
      location.reload()
    } catch (e) {
      // alert(e.response.data.message)
      setDomainError(e.response.data.message)
    } finally {
      setQuickSyncLoading(false)
    }
  }

  async function removeDomain(domain, type) {
    setDomainError('')
    setQuickSyncLoading(true)

    var body = {
      testEmail: domain,
      domain: domain,
      type: type,
    }
    //console.log(body)
    location.reload()
    try {
      var org = await API.post(
        'organization',
        '/organization/?removeDomain=true&id=' + props.org,
        {
          body,
        }
      )

      //console.log(org)
      // location.reload()
    } catch (e) {
      alert(e.response.data.message)
      setDomainError(e.response.data.message)
    } finally {
      setQuickSyncLoading(false)
    }
  }

  async function addAccount(email) {
    setUserError(null)
    setQuickAddLoading(true)
    var policy = 'time'
    if (selectedUserOption == 1) {
      policy = 'all'
    } else if (selectedUserOption == 2) {
      // console.log(checkUserOptions)
      policy = 'time'
      if (checkUserOptions[0]) {
        policy += '|subject'
      }
      if (checkUserOptions[1]) {
        policy += '|description'
      }
      if (checkUserOptions[2]) {
        policy += '|contact'
      }
      if (checkUserOptions[3]) {
        policy += '|location'
      }
      if (checkUserOptions[4]) {
        policy += '|meeting'
      }
      if (checkUserOptions[5]) {
        policy += '|reminder'
      }
      if (checkUserOptions[6]) {
        policy += '|private'
      }
    }
    if (policy == 'time') {
      policy = 'time'
    }
    var body = {
      email: email,
      orgId: props.org,
      planId: planId,
      preloads: preloads,
      policy,
      admin: admin,
    }

    try {
      var org = await API.post(
        'organization',
        '/organization/?addUser=true&id=' + props.org,
        {
          body,
        }
      )

      clearUser()
      setOrg({
        organization: org.organization,
        syncs: org.syncs,
        accounts: org.accounts,
        users: org.users,
        schedules: org.schedules,
        billing: org.orgBreakDown,
      })
    } catch (e) {
      alert(e.response.data.message)
      setUserError(e.response.data.message)
      setQuickAddLoading(false)
    }
  }

  async function deleteUserAccount(email) {
    var displayEmail = email
    if (email.includes('us-east')) {
      var list = org.users.filter((e) => e.userId == email)
      if (list.length > 0) {
        displayEmail = list[0].email
      }
    }
    Modal.confirm({
      title: `Are you sure you want to delete ${displayEmail}?`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        setDisableLoad(true)
        try {
          var org = await API.del(
            'organization',
            '/organization/?addUser=true&id=' + props.org,
            {
              body: {
                email: email,
                orgId: props.org,
              },
            }
          )

          setOrg({
            organization: org.organization,
            syncs: org.syncs,
            accounts: org.accounts,
            users: org.users,
            schedules: org.schedules,
            billing: org.orgBreakDown,
          })
        } catch (ex) {
          alert(ex.response.data.message)
        }
        setDisableLoad(false)
        //
      },
    })
  }

  useEffect(() => {
    async function getOrg(org) {
      //console.log('Load org ' + org)
      var org = await API.get('organization', '/organization/?id=' + org)
      //console.log(org)

      setOrg({
        organization: org.organization,
        syncs: org.syncs,
        accounts: org.accounts,
        users: org.users,
        schedules: org.schedules,
        billing: org.orgBreakDown,
      })
      if (
        org.organization.allowedDomains &&
        org.organization.allowedDomains.length > 0
      ) {
        setSourceCalendarType(
          org.organization.allowedDomains[sourceDomain].type
        )
        setDestCalendarType(org.organization.allowedDomains[destDomain].type)
      }
    }

    getOrg(props.org)
  }, [])

  useEffect(() => {
    if (org) {
      setFilterBridges(org.syncs)
      setFilterUsers(org.users)
    }
  }, [org])

  function onChange(e) {
    //console.log('radio checked', e.target.value)
    setSelectedOption(e.target.value)
  }

  function getPlans() {
    function getDefaultPlan() {
      if (showBasic) {
        return 'basic-'
      } else if (showPremium) {
        return 'premium-'
      } else if (showProV3) {
        return 'pro-v3-'
      } else if (showAdmin) {
        return 'admin-'
      }else {
        return 'basic-'
      }
    }

    const showBasic =
      !org.organization.converted || getAvailableUserSeats('basic', org) > 0

    const showPremium =
      !org.organization.converted || getAvailableUserSeats('premium', org) > 0

    const showAdmin =
      !org.organization.converted || getAvailableUserSeats('admin', org) > 0
    const showPro =
      !org.organization.converted || getAvailableUserSeats('pro', org) > 0

    const showProV3 =
      !org.organization.converted || getAvailableUserSeats('pro-v3', org) > 0
    //console.log(org)
    if (planId == null) {
      setPlanId(
        getDefaultPlan() +
          (org.organization.orgInterval.includes('monthly')
            ? 'monthly'
            : 'yearly')
      )
    }
    if (org.organization.orgInterval.includes('monthly')) {
      return (
        <Select
          style={{ marginTop: 8 }}
          width={800}
          value={planId}
          defaultValue={
            showBasic
              ? 'basic-monthly'
              : showPremium
              ? 'premium-monthly'
              : showProV3
              ? 'pro-v3-monthly'
              : showAdmin
              ? 'admin-monthly'
              : ''
          }
          onChange={(e) => {
            if(e.includes('admin')) {
              setAdmin(true)
            } else {
              setAdmin(false)
            }
            setPlanId(e)
          }}
        >
          
          {showBasic && (
            <Select.Option value="basic-monthly">
              Basic Monthly (2 Syncs)
            </Select.Option>
          )}
          {showPremium && (
            <Select.Option value="premium-monthly">
              Premium Monthly (16 Syncs)
            </Select.Option>
          )}
          {showPro && (
            <Select.Option value="pro-monthly">
              Pro Monthly(32 Syncs)
            </Select.Option>
          )}
          {showProV3 && (
            <Select.Option value="pro-v3-monthly">
              Pro(v3) Monthly(32 Syncs)
            </Select.Option>
          )}
          {showAdmin && (
            <Select.Option value="admin-monthly">
              Admin Monthly (0 Syncs)
            </Select.Option>
          )}
        </Select>
      )
    } else {
      return (
        <Select
          style={{ marginTop: 8 }}
          width={800}
          value={planId}
          defaultValue={
            showBasic
              ? 'basic-yearly'
              : showPremium
              ? 'premium-yearly'
              : showProV3
              ? 'pro-v3-yearly'
              :  showAdmin
              ? 'admin-yearly'
              : ''
          }
          onChange={(e) => setPlanId(e)}
        >
          {showBasic && (
            <Select.Option value="basic-yearly">
              Basic Yearly (2 Syncs)
            </Select.Option>
          )}
          {showPremium && (
            <Select.Option value="premium-yearly">
              Premium Yearly (16 Syncs)
            </Select.Option>
          )}
          {showPro && (
            <Select.Option value="pro-yearly">
              Pro Yearly(32 Syncs)
            </Select.Option>
          )}
          {showProV3 && (
            <Select.Option value="pro-v3-yearly">
              Pro(v3) Yearly(32 Syncs)
            </Select.Option>
          )}
          {showAdmin && (
            <Select.Option value="admin-yearly">
              Admin Yearly (0 Syncs)
            </Select.Option>
          )}
        </Select>
      )
    }
  }

  function onChangeUser(e) {
    //console.log('radio checked', e.target.value)
    setSelectedUserOption(e.target.value)
  }

  function addPreload() {
    if (preloadName != null && preloadName != '') {
      var domain = org.organization.allowedDomains[preloadDomain]
      //console.log(preloadDomain)
      setPreloads([
        {
          id: `${preloadName}@${domain.domain}`,
          type: domain.type,
          domain: domain.domain,
        },
        ...preloads,
      ])
      setPreloadName('')
    }
  }

  function getSourceCalendars() {
    return sourceCalendars.map((e, i) => {
      return (
        <Select.Option value={i} key={i}>
          <Text>
            {e.name ? e.name : e.summary}
            {e.summary &&
              !e.id.endsWith('calendar.google.com') &&
              e.summary != e.id && <> - ({e.id})</>}
          </Text>
        </Select.Option>
      )
    })
  }
  function getDestCalendars() {
    return destCalendars.map((e, i) => {
      return (
        <Select.Option value={i} key={i}>
          <Text>
            {e.name ? e.name : e.summary}
            {e.summary &&
              !e.id.endsWith('calendar.google.com') &&
              e.summary != e.id && <> - ({e.id})</>}
          </Text>
        </Select.Option>
      )
    })
  }
  // getCalendars(e,"source")useCallback(debounce((name) => checkName(name),2000),[])
  const loadCalendar = useCallback(debounce((e,loc) => handleSourceChange(e,loc),2000),[org,sourceDomain,destDomain])
  function handleSourceChange(e, loc) {
   
    if (loc == 'source') {
      setSourceName(e)
      getCalendars(e, 'source')
    } else {
      setDestName(e)
      getCalendars(e, 'dest')
    }
  }

  const validation = (email) => {
    const result =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return result.test(String(email).toLowerCase())
  }

  const handleMenuClick = (e) => {
    if (e.key == 'syncs') {
      setShowBilling(false)
      setShowSync(true)
      setShowUserManagement(false)
      setShowDomainManagement(false)
    } else if (e.key == 'users') {
      setShowBilling(false)
      setShowSync(false)

      setShowDomainManagement(false)
      setShowUserManagement(true)
    } else if (e.key == 'billing') {
      setShowBilling(true)
      setShowSync(false)
      setShowUserManagement(false)

      setShowDomainManagement(false)
    } else if (e.key == 'domains') {
      setShowBilling(false)
      setShowSync(false)
      setShowUserManagement(false)
      setShowDomainManagement(true)
    } else {
    }
  }

  function showSyncUI(org) {
    return getAvailableSyncs(org) > 0 || !org.organization.converted
  }

  function showUserUI(org) {
    return (
      getAvailableUserSeats('basic', org) > 0 ||
      getAvailableUserSeats('premium', org) > 0 ||
      getAvailableUserSeats('pro', org) > 0 ||
      getAvailableUserSeats('admin', org) > 0 ||
      getAvailableUserSeats('pro-v3', org) > 0 ||
      !org.organization.converted
    )
  }

  function showConvertPanel() {
    if (!license.email) {
      return false
    }
    if (license.email == undefined) {
      return false
    }
    return (
      license.email.includes('@calendarbridge.com') &&
      !org.organization.converted
    )
  }

  async function convertOrg() {
    var org = await API.post(
      'organization',
      '/organization/?convertOrg=true&id=' + props.org,
      {
        body: {
          orgId: props.org,
        },
      }
    )
    location.reload()
  }

  function showConvert() {
    return showConvertPanel() ? (
      <>
        <div className="p-4 border-2 border-blac rounded-sm text-red-600">
          Conversion view: only you (a cb employee with admin rights) can see
          this
          <Button
            onClick={(e) => {
              convertOrg()
            }}
          >
            Convert to new billing style
          </Button>
        </div>
      </>
    ) : (
      <></>
    )
  }
  function getColorOptions() {
    if (sourceCalendarType == 'ms') {
      return [
        <Select.Option value="none"> None </Select.Option>,
        <Select.Option value="Green category">
          <Space>
            <div
              className="w-4 h-4 rounded-full"
              style={{ background: '#00ff00' }}
            />
            Green category
          </Space>
        </Select.Option>,

        <Select.Option value="Yellow category">
          <Space>
            <div className="w-4 h-4 rounded-full bg-yellow-200" /> Yellow
            category
          </Space>
        </Select.Option>,
        <Select.Option value="Red category">
          <Space>
            <div className="w-4 h-4 rounded-full bg-red-500" /> Red category
          </Space>
        </Select.Option>,
        <Select.Option value="Orange category">
          <Space>
            <div
              className="w-4 h-4 rounded-full"
              style={{ background: 'orange' }}
            />{' '}
            Orange category
          </Space>
        </Select.Option>,
        <Select.Option value="Blue category">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: 'blue' }}
            />{' '}
            Blue category
          </Space>
        </Select.Option>,
        <Select.Option value="Purple category">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: 'purple' }}
            />{' '}
            Purple category
          </Space>
        </Select.Option>,
      ]
    } else {
      return [
        <Select.Option value="none"> None </Select.Option>,
        <Select.Option value="11">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: '#D50000' }}
            />
            Tomato
          </Space>
        </Select.Option>,
        <Select.Option value="10">
          <Space>
            <div
              className="w-4 h-4 rounded-full"
              style={{ background: '#0B8043' }}
            />
            Basil
          </Space>
        </Select.Option>,
        <Select.Option value="9">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: '#3F51B5' }}
            />
            Blueberry
          </Space>
        </Select.Option>,
      ]
    }
  }
  return (
    <div style={{ marginTop: 24 }}>
      {!org && (
        <center>
          <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{ fontSize: '100px' }} />
            }
          />
        </center>
      )}

      {org && org.organization.stripeId == 'pending' && (
        <div>
          {' '}
          Please complete organization check out{' '}
          <a href="organization/new">here</a>
        </div>
      )}
      {org && org.organization.stripeId != 'pending' && (
        <div>
          {/* <h3>Authorized Domains</h3> */}
          {/* <a href="https://help.calendarbridge.com/doc/adding-authorized-domains/">Add authorized domains (optional)</a> Password: K4ydZqUg */}
          <div>
            <Menu
              onClick={(e) => handleMenuClick(e)}
              defaultSelectedKeys={['users']}
              mode="horizontal"
            >
              <Menu.Item key="users">Manage Users</Menu.Item>
              {org &&
                ((org.organization.allowedDomains &&
                  org.organization.allowedDomains.length > 0) ||
                  org.organization.converted) && (
                  <Menu.Item key="syncs">Manage Syncs</Menu.Item>
                )}
              <Menu.Item key="domains">Manage Domains</Menu.Item>
              {(org.organization.converted || showConvertPanel()) && (
                <Menu.Item key="billing">Manage Billing</Menu.Item>
              )}
            </Menu>
          </div>
          {showBilling && org && <BillingPage org={org}></BillingPage>}

          {!showBilling &&
            org &&
            ((org.organization.allowedDomains &&
              org.organization.allowedDomains.length > 0) ||
              org.organization.converted) && (
              <>
                <Row>
                  {org.organization.allowedDomains &&
                    org.organization.allowedDomains.map((sync) => (
                      <Col span={8}>
                        <Card style={{ margin: 16 }}>
                          <b>{sync.domain}</b>{' '}
                          <FontAwesomeIcon
                            icon={accountIconMap[sync.type].icon}
                            size="2x"
                            pull="right"
                            color={accountIconMap[sync.type].color}
                          />
                          {process.env.GATSBY_DEBUG_UI == 'true' && (
                            <Button
                              onClick={(e) => {
                                removeDomain(sync.domain, sync.type)
                              }}
                            >
                              Delete (debug only)
                            </Button>
                          )}
                        </Card>
                      </Col>
                    ))}
                </Row>

                {showSync && (
                  <>
                    {(org.organization.converted || showConvertPanel()) && (
                      <>
                        {showConvert()}
                        <div className="m-4 lg:w-1/2 text-lg p-4 bg-neutral">
                          Available Sync Seats: {getAvailableSyncs(org)}
                        </div>
                        {!showSyncUI(org) && (
                          <div
                            className="p-4 m-4 text-lg cursor-pointer"
                            onClick={(e) => {
                              setShowBilling(true)
                              setShowSync(false)
                              setShowUserManagement(false)
                              setShowDomainManagement(false)
                            }}
                          >
                            Purchase sync seats to add
                          </div>
                        )}
                      </>
                    )}
                    {showSyncUI(org) &&
                    org.organization.allowedDomains &&
                    org.organization.allowedDomains.length > 0 ? (
                      <Card style={{ marginBottom: 16 }}>
                        <h4>Add Sync</h4>
                        <div>
                          <Row style={{ marginBottom: 16 }}>
                            <Col span={16}>
                              <h4>Source</h4>
                              <Input
                                // ref={sourceFormRef}
                                allowClear={true}
                                value={sourceEmail}
                                placeholder={'address'}
                                onChange={(e) => {
                                  
                                  var entry = e.target.value.trim()
                                  
                                  if (entry.includes('@')) {
                                    entry = entry.substring(
                                      0,
                                      entry.indexOf('@')
                                    )
                                  }
                                  setSourceEmail(entry)
                                  if (entry == '') {
                                    setSelectedSourceCalendar('Default')
                                    setSourceValid(false)
                                  } else {
                                    
                                    loadCalendar(entry, 'source')
                                  }
                                  
                                  
                                }}
                                addonAfter={selectAfter('source')}
                              ></Input>
                              {sourceError && (
                                <div>
                                  <span style={{ color: 'red' }}>
                                    {sourceError}
                                  </span>
                                </div>
                              )}

                              <Row>
                                <Col span={24}>
                                  <h4>Source Calendar</h4>
                                  {sourceCalendars && (
                                    <Select
                                      size="large"
                                      placeholder="Select Calendar"
                                      style={{ width: '100%' }}
                                      value={selectedSourceCalendar}
                                      onChange={(v) => {
                                        setSelectedSourceCalendar(v)
                                        var cal = sourceCalendars[v]
                                        if (cal.summary) {
                                          setSourceCalendarTimezone(
                                            cal.timeZone
                                          )
                                        } else {
                                        }
                                      }}
                                      defaultValue={selectedSourceCalendar}
                                    >
                                      {sourceCalendars && getSourceCalendars()}
                                    </Select>
                                  )}
                                </Col>
                                {sourceLoad && (
                                  <Spin
                                    style={{ marginLeft: 8, marginTop: 28 }}
                                    indicator={
                                      <Loading3QuartersOutlined
                                        spin
                                        style={{ fontSize: '25px' }}
                                      />
                                    }
                                  />
                                )}
                              </Row>
                              <Row>
                                {sourceCalendarType &&
                                  (sourceCalendarType == 'ms' ||
                                    sourceCalendarType == 'ews') && (
                                    <Col span={24} style={{ marginTop: 8 }}>
                                      <h4>Source Calendar Timezone</h4>
                                      <Select
                                        showSearch
                                        size="small"
                                        style={{
                                          width: '100%',
                                          margin: '0px 0px 20px 0px',
                                        }}
                                        placeholder="Select a time zone"
                                        filterOption={(input, option) => {
                                          return (
                                            option.key
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          )
                                        }}
                                        value={sourceCalendarTimezone}
                                        onChange={(e) =>
                                          setSourceCalendarTimezone(e)
                                        } //this.setState({sourceCalendarTimezone: e})
                                      >
                                        {getTzOptions()}
                                      </Select>
                                    </Col>
                                  )}

                                {sourceCalendarType &&
                                  sourceCalendarType == 'google' && (
                                    <Col span={24} style={{ marginTop: 8 }}>
                                      <h4>Source Calendar Timezone</h4>{' '}
                                      {sourceCalendarTimezone}
                                    </Col>
                                  )}
                              </Row>
                              {twoWay && (
                                <Row style={{ marginTop: 8 }}>
                                  <h4>Source Copy Color</h4>
                                  <Col span={24}>
                                    <ColorPicker
                                      type={sourceCalendarType}
                                      radius={4}
                                      onColorChangeCallback={(color) => {
                                        setSourceColor(color)
                                      }}
                                    ></ColorPicker>
                                  </Col>
                                </Row>
                              )}
                              <center style={{ marginTop: 8 }}>
                                <h5>Direction</h5>
                                {/* <FontAwesomeIcon
                                onClick={(e) => setTwoWay(!twoWay)}
                                icon={faLongArrowAltLeft}
                                size="3x"
                                color={twoWay ? colorPositive : colorNeutral}
                              />{' '} */}
                                <FontAwesomeIcon
                                  icon={faLongArrowAltRight}
                                  size="3x"
                                  color={colorBlack}
                                />
                              </center>
                              <Row style={{ marginBottom: 16 }}>
                                <Col span={24}>
                                  <h4>Destination</h4>
                                  <Input
                                    // ref={destFormRef}
                                    allowClear={true}
                                    value={destEmail}
                                    placeholder={'address'}
                                    onChange={(e) => {
                                      var entry = e.target.value.trim()
                                      
                                      if (entry.includes('@')) {
                                        entry = entry.substring(
                                          0,
                                          entry.indexOf('@')
                                        )
                                      }
                                      setDestEmail(entry)
                                      loadCalendar(entry, 'dest')
                                      if (e.target.value == '') {
                                        setSelectedDestCalendar('Default')
                                        setDestValid(false)
                                      }
                                    }}
                                    addonAfter={selectAfter('dest')}
                                  ></Input>
                                  {destError && (
                                    <div>
                                      <span style={{ color: 'red' }}>
                                        {destError}
                                      </span>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <h4>Destination Calendar</h4>{' '}
                                  <Select
                                    size="large"
                                    placeholder="Select Calendar"
                                    style={{ width: '100%' }}
                                    value={selectedDestCalendar}
                                    onChange={(v) => setSelectedDestCalendar(v)}
                                    defaultValue={selectedDestCalendar}
                                  >
                                    {destCalendars && getDestCalendars()}
                                  </Select>
                                </Col>
                                {destLoad && (
                                  <Spin
                                    style={{ marginLeft: 8, marginTop: 28 }}
                                    indicator={
                                      <Loading3QuartersOutlined
                                        spin
                                        style={{ fontSize: '25px' }}
                                      />
                                    }
                                  />
                                )}
                              </Row>
                              <Row style={{ marginTop: 8 }}>
                                {twoWay && (
                                  <>
                                    {destCalendarType &&
                                      destCalendarType == 'google' && (
                                        <Row>
                                          <Col
                                            span={24}
                                            style={{ marginTop: 8 }}
                                          >
                                            <h4>
                                              Destination Calendar Timezone
                                            </h4>{' '}
                                            {destCalendarTimezone}
                                          </Col>
                                        </Row>
                                      )}

                                    {destCalendarType &&
                                      (destCalendarType == 'ms' ||
                                        destCalendarType == 'ews') && (
                                        <Row>
                                          <Col
                                            span={24}
                                            style={{ marginTop: 8 }}
                                          >
                                            <h4>
                                              Destination Calendar Timezone
                                            </h4>
                                            <Select
                                              showSearch
                                              size="small"
                                              style={{
                                                width: '100%',
                                                margin: '0px 0px 0px 0px',
                                              }}
                                              placeholder="Select a time zone"
                                              filterOption={(input, option) => {
                                                return (
                                                  option.key
                                                    .toLowerCase()
                                                    .indexOf(
                                                      input.toLowerCase()
                                                    ) >= 0
                                                )
                                              }}
                                              value={destCalendarTimezone}
                                              //  value={this.state.sourceCalendarTimezone}
                                              onChange={(e) =>
                                                setDestCalendarTimezone(e)
                                              } //this.setState({destCalendarTimezone: e})
                                            >
                                              {getTzOptions()}
                                            </Select>
                                          </Col>
                                        </Row>
                                      )}
                                  </>
                                )}
                                <Row style={{ marginTop: 8 }}>
                                  <h4>Destination Copy Color</h4>
                                  <Col span={24}>
                                    <ColorPicker
                                      type={destCalendarType}
                                      radius={4}
                                      onColorChangeCallback={(color) => {
                                        setDestColor(color)
                                      }}
                                    ></ColorPicker>
                                    {destCalendarType == sourceCalendarType && (
                                      <div className="mt-2 ml-1">
                                        <Checkbox
                                          checked={matchColors}
                                          onChange={(e) => {
                                            setMatchColors(e.target.checked)
                                          }}
                                        >
                                          Match Source Colors
                                        </Checkbox>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </Row>
                            </Col>
                          </Row>
                          <div style={{ marginTop: 16 }}>
                            <h4>Fields</h4>
                            <Radio.Group
                              style={{ marginLeft: 8 }}
                              onChange={onChange}
                              defaultValue={1}
                              value={selectedOption}
                            >
                              <Radio value={0}>Free/Busy Only</Radio>
                              <Radio value={1}>All Fields</Radio>
                              <Radio value={2}>Custom</Radio>
                            </Radio.Group>
                            {selectedOption == 0 && (
                              <div className="flex flex-col ml-2">
                                <Row
                                  style={{ marginBottom: 16, marginTop: 16 }}
                                >
                                  <Checkbox checked={false} disabled={true}>
                                    Subject
                                  </Checkbox>
                                  <Checkbox checked={false} disabled={true}>
                                    Description
                                  </Checkbox>
                                  <Checkbox checked={false} disabled={true}>
                                    Attendees
                                  </Checkbox>
                                  <Checkbox checked={false} disabled={true}>
                                    Location
                                  </Checkbox>
                                  <Checkbox checked={false} disabled={true}>
                                    Conference
                                  </Checkbox>
                                  <Checkbox checked={false} disabled={true}>
                                    Reminders
                                  </Checkbox>
                                  <Checkbox checked={false} disabled={true}>
                                    All Private
                                  </Checkbox>
                                </Row>
                              </div>
                            )}
                            {(selectedOption == 2 || selectedOption == 1) &&
                              checkOptions && (
                                <Row
                                  style={{ marginBottom: 16, marginTop: 16 }}
                                >
                                  <Checkbox
                                    checked={checkOptions[0]}
                                    onChange={(e) => {
                                      checkOptions[0] = e.target.checked
                                      setCheckOptions(getOptions(checkOptions))
                                    }}
                                    disabled={selectedOption == 1}
                                  >
                                    Subject
                                  </Checkbox>
                                  <Checkbox
                                    checked={checkOptions[1]}
                                    onChange={(e) => {
                                      checkOptions[1] = e.target.checked
                                      setCheckOptions(getOptions(checkOptions))
                                    }}
                                    disabled={selectedOption == 1}
                                  >
                                    Description
                                  </Checkbox>
                                  <Checkbox
                                    checked={checkOptions[2]}
                                    onChange={(e) => {
                                      checkOptions[2] = e.target.checked
                                      setCheckOptions(getOptions(checkOptions))
                                    }}
                                    disabled={selectedOption == 1}
                                  >
                                    Attendees
                                  </Checkbox>
                                  <Checkbox
                                    checked={checkOptions[3]}
                                    onChange={(e) => {
                                      checkOptions[3] = e.target.checked
                                      setCheckOptions(getOptions(checkOptions))
                                    }}
                                    disabled={selectedOption == 1}
                                  >
                                    Location
                                  </Checkbox>
                                  <Checkbox
                                    checked={checkOptions[4]}
                                    onChange={(e) => {
                                      checkOptions[4] = e.target.checked
                                      setCheckOptions(getOptions(checkOptions))
                                    }}
                                    disabled={selectedOption == 1}
                                  >
                                    Conference
                                  </Checkbox>
                                  <Checkbox
                                    checked={checkOptions[5]}
                                    onChange={(e) => {
                                      checkOptions[5] = e.target.checked
                                      setCheckOptions(getOptions(checkOptions))
                                    }}
                                    disabled={selectedOption == 1}
                                  >
                                    Reminders
                                  </Checkbox>
                                  <Checkbox
                                    checked={checkOptions[6]}
                                    onChange={(e) => {
                                      checkOptions[6] = e.target.checked
                                      setCheckOptions(getOptions(checkOptions))
                                    }}
                                    disabled={selectedOption == 1}
                                  >
                                    All Private
                                  </Checkbox>
                                </Row>
                              )}
                          </div>
                          <Row>
                            <Col span={8}>
                              <h4>Tag</h4>
                              <Input
                                disabled={label == ' '}
                                value={label}
                                placeholder="(copy)"
                                onChange={(e) => setLabel(e.target.value)}
                              />

                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setLabel(' ')
                                  } else {
                                    setLabel('(copy)')
                                  }
                                }}
                              >
                                No Tag (
                                <a href="https://help.calendarbridge.com/doc/getting-started/setting-up-calendar-syncing/">
                                  not recommended
                                </a>
                                )
                              </Checkbox>
                            </Col>
                          </Row>
                          <Row>
                          <Col span={8} className="mt-4">
                          <h4>Filtering</h4>
                          <div> <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFilterFree(true)
                                    setHasFilters(true)
                                  } else {
                                   setFilterFree(false)
                                   if(filterColor == "none") {
                                    setHasFilters(false)
                                   }
                                  }
                                }}
                              >
                                Filter Free
                              </Checkbox></div>
                          <div className="mt-4">Filter By Color <Select
                  size="small"
                  style={{
                    width: '200px',
                    margin: '10px 0px 0px 0px',
                  }}
                  placeholder="Select a color to filter"
                  value={filterColor}
                  onChange={(e) => {
                    console.log(e)
                    if(e == "None") {
                      if(!filterFree)
                        setHasFilters(false)
                    } else {
                      setHasFilters(true)
                    }

                    setFilterColor(e)
                    //this.changeSyncValue('filterColor', e)
                  }}
                >
                  {getColorOptions()}
                </Select></div>
                          
                          </Col>
                          </Row>
                          <Button
                            loading={quickSyncLoading}
                            disabled={!sourceValid || !destValid}
                            type="primary"
                            style={{ marginTop: 16 }}
                            onClick={(e) => quickSync('test')}
                          >
                            Create Sync
                          </Button>
                          {syncError && (
                            <div>
                              <span style={{ color: 'red' }}>{syncError}</span>
                            </div>
                          )}
                        </div>
                      </Card>
                    ) : !org.organization.allowedDomains ? (
                      <div> </div>
                    ) : (
                      <div></div>
                    )}
                    <Card style={{ marginTop: 24, marginBottom: 16 }}>
                      <h5>Syncs</h5>
                      {org && org.syncs && (
                        <h5>
                          Sync connections total:{' '}
                          {org.syncs.size == 0
                            ? 0
                            : org.syncs.filter((e) => e.twoWay).length * 2 +
                              org.syncs.filter((e) => !e.twoWay).length}
                        </h5>
                      )}
                      <div className="mt-4 mb-4">
                        <h5>Search:</h5>
                        <Input
                          placeholder="search syncs "
                          allowClear
                          onChange={(e) => {
                            var val = e.target.value

                            if (val == '') {
                              setFilterBridges(org.syncs)
                            } else {
                              setFilterBridges(
                                org.syncs.filter((b) => {
                                  //console.log(b)
                                  return (
                                    b.sourceCalendarAccountId
                                      .toLowerCase()
                                      .includes(val) ||
                                    b.destCalendarAccountId
                                      .toLowerCase()
                                      .includes(val) ||
                                    b.sourceCalendarId
                                      .toLowerCase()
                                      .includes(val) ||
                                    b.destCalendarId.toLowerCase().includes(val)
                                  )
                                })
                              )
                            }
                          }}
                        ></Input>
                      </div>
                      <div style={{ marginTop: 8 }}>
                        {filterBridges.map((sync) => (
                          <OrgSyncItem item={sync}></OrgSyncItem>
                        ))}
                      </div>
                    </Card>
                  </>
                )}
              </>
            )}

          {showUserManagement && (
            <>
              {(org.organization.converted || showConvertPanel()) && (
                <>
                  {showConvert()}

                  <div className="text-lg m-4">Available Seat Summary</div>
                  <div className="flex flex-col flex-wrap m-4 text-lg p-4 bg-neutral lg:w-1/2">
                    <div>
                      Basic:{' '}
                      {getAvailableUserSeats('basic', org) -
                        getAvailableUserSeats('group-admin', org)}
                    </div>
                    <div>Premium: {getAvailableUserSeats('premium', org)}</div>
                    {!checkPro(org) ? (
                      <div>Pro*: {getAvailableUserSeats('pro', org)}</div>
                    ) : (
                      <div>Pro: {getAvailableUserSeats('pro-v3', org)}</div>
                    )}
                    <div>
                      Admin Only:{' '}
                      {getAvailableUserSeats('admin', org) }
                    </div>
                  </div>
                </>
              )}
              {showUserUI(org) || editUser ? (
                <Card>
                  <Row>
                    <Col span={12} style={{ marginBottom: 16 }}>
                      <h4>{editUser ? 'Edit User' : 'Add User'}</h4>
                      <Row>
                        <Input
                          disabled={editUser}
                          placeholder="User Email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value.trim().toLowerCase())
                            if (
                              validation(e.target.value.trim().toLowerCase())
                            ) {
                              setUserAddValid(true)
                            } else {
                              setUserAddValid(false)
                            }
                            // setEmail(e.target.value)
                          }}
                        />
                        {!showUserUI(org) && editUser ? (
                          <div>
                            <span style={{ color: 'red' }}>
                              You may need to purchase more seats to change
                              plans
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                        {userError && (
                          <div>
                            <span style={{ color: 'red' }}>{userError}</span>
                          </div>
                        )}
                        <h4 style={{ marginTop: 12, marginRight: 8 }}>
                          Plan:{' '}
                        </h4>
                        {org && org.organization && getPlans()}
                      </Row>

                      <Row>
                        <Col>
                          {org.organization.allowedDomains != null &&
                            org.organization.allowedDomains.length > 0 && (
                              <>
                                <h4 style={{ marginTop: 8, marginBottom: 8 }}>
                                  Include Managed Calendar Accounts For User
                                </h4>
                                <Input
                                  placeholder={'address'}
                                  value={preloadName}
                                  onChange={(e) =>{
                                    var entry = e.target.value.trim()
                                    if(entry.includes('@')) {
                                      entry = entry.substring(0,entry.indexOf('@'))
                                    }
                                    setPreloadName(entry)
                                  }
                                  }
                                  addonAfter={selectAfter('preload')}
                                ></Input>

                                <Button
                                  style={{ marginTop: 8 }}
                                  disabled={
                                    preloadName == '' || preloadName == null
                                  }
                                  onClick={(e) => addPreload()}
                                >
                                  Add Calendar Account
                                </Button>
                                {preloads &&
                                  preloads.length > 0 &&
                                  preloads.map((acc) => (
                                    <Card style={{ margin: 16 }}>
                                      <Button
                                        style={{ marginRight: 8 }}
                                        onClick={(e) =>
                                          setPreloads(
                                            preloads.filter(
                                              (item) => acc.id != item.id
                                            )
                                          )
                                        }
                                      >
                                        x
                                      </Button>
                                      <b>{acc.id}</b>{' '}
                                      <FontAwesomeIcon
                                        icon={accountIconMap[acc.type].icon}
                                        size="2x"
                                        pull="right"
                                        color={accountIconMap[acc.type].color}
                                      />
                                    </Card>
                                  ))}
                              </>
                            )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Checkbox onClick={(e) => setAdmin(!admin)} checked={admin}>
                    Admin Access
                  </Checkbox>
                  <div style={{ marginTop: 16 }}>
                    <div>
                      Access Control Policy:{' '}
                      <Radio.Group
                        style={{ marginLeft: 8 }}
                        onChange={onChangeUser}
                        defaultValue={0}
                        value={selectedUserOption}
                      >
                        <Radio value={0}>Free/Busy Only</Radio>
                        <Radio value={1}>All Fields</Radio>
                        <Radio value={2}>Custom</Radio>
                      </Radio.Group>
                    </div>
                    {selectedUserOption == 0 && (
                      <div style={{ marginBottom: 16, marginTop: 16 }}>
                        <Checkbox checked={false} disabled={true}>
                          Subject
                        </Checkbox>
                        <Checkbox checked={false} disabled={true}>
                          Description
                        </Checkbox>
                        <Checkbox checked={false} disabled={true}>
                          Attendees
                        </Checkbox>
                        <Checkbox checked={false} disabled={true}>
                          Location
                        </Checkbox>
                        <Checkbox checked={false} disabled={true}>
                          Conference
                        </Checkbox>
                        <Checkbox checked={false} disabled={true}>
                          Reminders
                        </Checkbox>
                        <Checkbox checked={false} disabled={true}>
                          All Private
                        </Checkbox>
                      </div>
                    )}

                    {(selectedUserOption == 2 || selectedUserOption == 1) &&
                      checkUserOptions && (
                        <Row style={{ marginBottom: 16, marginTop: 16 }}>
                          <Checkbox
                            checked={checkUserOptions[0]}
                            onChange={(e) => {
                              checkUserOptions[0] = e.target.checked
                              setCheckUserOptions(getOptions(checkUserOptions))
                            }}
                            disabled={selectedUserOption == 1}
                          >
                            Subject
                          </Checkbox>

                          <Checkbox
                            checked={checkUserOptions[1]}
                            onChange={(e) => {
                              checkUserOptions[1] = e.target.checked
                              setCheckUserOptions(getOptions(checkUserOptions))
                            }}
                            disabled={selectedUserOption == 1}
                          >
                            Description
                          </Checkbox>
                          <Checkbox
                            checked={checkUserOptions[2]}
                            onChange={(e) => {
                              checkUserOptions[2] = e.target.checked
                              setCheckUserOptions(getOptions(checkUserOptions))
                            }}
                            disabled={selectedUserOption == 1}
                          >
                            Attendees
                          </Checkbox>
                          <Checkbox
                            checked={checkUserOptions[3]}
                            onChange={(e) => {
                              checkUserOptions[3] = e.target.checked
                              setCheckUserOptions(getOptions(checkUserOptions))
                            }}
                            disabled={selectedUserOption == 1}
                          >
                            Location
                          </Checkbox>
                          <Checkbox
                            checked={checkUserOptions[4]}
                            onChange={(e) => {
                              checkUserOptions[4] = e.target.checked
                              setCheckUserOptions(getOptions(checkUserOptions))
                            }}
                            disabled={selectedUserOption == 1}
                          >
                            Conference
                          </Checkbox>
                          <Checkbox
                            checked={checkUserOptions[5]}
                            onChange={(e) => {
                              checkUserOptions[5] = e.target.checked
                              setCheckUserOptions(getOptions(checkUserOptions))
                            }}
                            disabled={selectedUserOption == 1}
                          >
                            Reminders
                          </Checkbox>
                          <Checkbox
                            checked={checkUserOptions[6]}
                            onChange={(e) => {
                              checkUserOptions[6] = e.target.checked
                              setCheckUserOptions(getOptions(checkUserOptions))
                            }}
                            disabled={selectedUserOption == 1}
                          >
                            All Private
                          </Checkbox>
                        </Row>
                      )}
                  </div>
                  <Button
                    style={{ margin: 8 }}
                    type="primary"
                    loading={quickAddLoading}
                    disabled={!userAddValid}
                    onClick={(e) => addAccount(email)}
                  >
                    {editUser ? 'Edit User' : 'Add User'}
                  </Button>
                  {editUser && (
                    <Button onClick={(e) => clearUser()}>Cancel Edit</Button>
                  )}
                </Card>
              ) : (
                <div
                  className="p-4 m-4 text-lg cursor-pointer"
                  onClick={(e) => {
                    setShowBilling(true)
                    setShowSync(false)
                    setShowUserManagement(false)
                    setShowDomainManagement(false)
                  }}
                >
                  Purchase more seats to add Users
                </div>
              )}

              <div style={{ marginTop: 16 }}>
                <h5>User Accounts</h5>
                <h5>User Count: {org != null ? org.users.length : 0}</h5>
                <div className="mt-4 mb-4">
                  <h5>Search:</h5>
                  <Input
                    placeholder="search users"
                    allowClear
                    onChange={(e) => {
                      var val = e.target.value

                      if (val == '') {
                        setFilterUsers(org.users)
                      } else {
                        setFilterUsers(
                          org.users.filter((b) => {
                            // console.log(b)
                            return b.email.toLowerCase().includes(val)
                          })
                        )
                      }
                    }}
                  ></Input>
                </div>
                <Table
                  dataSource={filterUsers}
                  columns={columns}
                  footer={() => <></>}
                />
              </div>
            </>
          )}
          {showDomainManagement && org && (
            <>
              <div className="flex flex-col space-y-4 m-4 p-4 bg-white lg:w-1/2">
                <CBHeader
                  icon={<Connections></Connections>}
                  label="Manage Domains"
                  buttonLabel="Help"
                  buttonIcon={<></>}
                  buttonAction={(e) => {
                    window.open(
                      'https://help.calendarbridge.com/doc/group-account-management/managing-authorized-domains/',
                      '_blank'
                    )
                  }}
                >
                  Manage Domains
                </CBHeader>
              </div>
              <div className="flex flex-col space-y-4 m-4 p-4 bg-neutral lg:w-1/2">
                <div className="mt-2">
                  Domain:
                  <Input
                    label={'Domain Name:'}
                    placeholder="example.com"
                    value={addedDomain}
                    onChange={(e) => {
                      var test = e.target.value.toLowerCase()
                      setAddedDomain(test)
                      setAccept(false)
                    }}
                  ></Input>
                </div>
                <div className="mt-2">
                  Tennant Type:
                  <Radio.Group
                    style={{ marginLeft: 8 }}
                    onChange={(e) => {
                      setTennantType(e.target.value)
                    }}
                    defaultValue={'ms'}
                    value={tennantType}
                  >
                    <Radio value={'ms'}>Microsoft 365</Radio>
                    <Radio value={'google'}>Google</Radio>
                  </Radio.Group>
                </div>
                <div className="m-2 p-4 bg-neutral">
                  {tennantType == 'ms' ? (
                    <div>
                      <a
                        href="https://help.calendarbridge.com/group-admin-docs/authorizing-access-for-managed-syncs/"
                        target="_blank"
                      >
                        Please follow these instructions for adding a Microsoft
                        365 Tennant
                      </a>
                    </div>
                  ) : (
                    <div>
                      <a
                        href="https://help.calendarbridge.com/group-admin-docs/authorizing-access-for-managed-syncs/"
                        target="_blank"
                      >
                        Please follow these instructions for adding an Google
                        Workspace Tennant
                      </a>
                    </div>
                  )}
                </div>
                <Checkbox
                  disabled={addedDomain == '' || addedDomain == null}
                  checked={accept}
                  onChange={(e) => {
                    setAccept(e.target.checked)
                  }}
                >
                  I have followed the above instructions
                </Checkbox>
                {accept && (
                  <div className="mt-4">
                    Provide an email account to verify setup:
                    <Input
                      value={testEmail}
                      label="Test email"
                      allowClear={true}
                      placeholder={'address'}
                      onChange={(e) => {
                        var entry = e.target.value.trim()
                        if (entry.includes('@')) {
                          entry = entry.substring(0, entry.indexOf('@'))
                        }

                        setTestEmail(entry)
                      }}
                      addonAfter={<div>@{addedDomain}</div>}
                    ></Input>
                  </div>
                )}
                {domainError != '' && (
                  <div className="p-2 text-red-700">{domainError}</div>
                )}
                <Button
                  loading={quickSyncLoading}
                  disabled={!accept || testEmail == null}
                  onClick={(e) => {
                    addCustomDomain()
                  }}
                >
                  Add Domain
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const renderItem = (item) => {
  return (
    <Card style={{ width: '600px', margin: '8px' }}>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={14}>
          <Text style={{ fontSize: '1.2em', wordBreak: 'break-all' }}>
            <b>{item.email}</b>
          </Text>
        </Col>
        <Col
          span={10}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {/* <Text>{item.calendars.length || 0} Calendars</Text> */}
          <FontAwesomeIcon
            icon={accountIconMap[item.calendarAccountType].icon}
            size="2x"
            color={accountIconMap[item.calendarAccountType].color}
            style={{ marginLeft: 10, marginRight: 10 }}
          />
        </Col>
      </Row>
    </Card>
  )
}
const OrganizationPage = (props) => {
  const [license] = useGlobalState('license')
  const [currentOrg, setCurrentOrg] = useState(null)

  useEffect(() => {
    //console.log(license)
    if (license.orgId) setCurrentOrg(license.orgId.split(',')[0])
  }, [license])

  const handleOrgMenuClick = (e) => {
    //console.log('current ' + currentOrg)
    //console.log(e)
    setCurrentOrg(e.key)
  }
  const getOrg = () => {
    //console.log(currentOrg)
    if (currentOrg == null) return <div>loading org</div>
    return <OrgItem key={currentOrg} org={currentOrg}></OrgItem>
  }
  return (
    <div className="cb-content-frame" style={{ background: 'white' }}>
      <div className="m-8" style={{ minHeight: 800 }}>
        <CBHeader
          icon={<Connections></Connections>}
          label="Manage Organization"
        >
          Manage Org
        </CBHeader>

        {!license && (
          <Spin
            indicator={
              <Loading3QuartersOutlined
                spin
                style={{ padding: 100, fontSize: '100px' }}
              />
            }
          />
        )}
        {license && !license.orgAdmin && <div></div>}
        {license && license.orgAdmin && (
          <>
            <Menu
              onClick={(e) => handleOrgMenuClick(e)}
              defaultSelectedKeys={[license.orgId.split(',')[0]]}
              mode="horizontal"
            >
              {license.orgId &&
                license.orgId
                  .split(',')
                  .map((e) => <Menu.Item key={e}>{e}</Menu.Item>)}
            </Menu>
            {getOrg()}
          </>
        )}
      </div>
    </div>
  )
}

export default OrganizationPage
function getOptions(checkOptions) {
  return [...checkOptions]
}
