import React, { useState, useEffect } from 'react'

import { Modal, Switch } from 'antd'
import { inputLabel } from 'aws-amplify'

const EditActiveModal = ({
	currentActive,
	visible,
	handleOk,
	confirmLoading,
	handleCancel,
	setNewActiveState
}) => {

	const [checked, setChecked] = useState(currentActive);

	useEffect(() => {
		setChecked(currentActive);
	}, [visible]);

	return (
		<Modal
			title="Edit Active"
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<Switch defaultChecked={currentActive} checked={checked} onChange={(pendingChecked) => {
				setChecked(pendingChecked);
				setNewActiveState(pendingChecked);
			}} />
		</Modal>
	)
}

export default EditActiveModal
