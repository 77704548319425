import React, {
  useReducer,
  createContext,
  useContext,
  useCallback,
  useMemo,
	useEffect,
	useState
} from 'react'
import { API } from 'aws-amplify'
import { reducer, DEFAULT_STATE } from './reducer'
import get from 'lodash/get'
import map from 'lodash/map'
import moment from 'moment'
import momentTz from 'moment-timezone'

const GroupScheduleContext = createContext(null)

export const GroupScheduleContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)
	const [isFetchingData, setIsFetchingData] = useState(false)

  const parentId = useMemo(() => get(state, 'parentId'), [state])
  const guests = useMemo(() => get(state, 'guests'), [state])
  const emails = useMemo(() => {
    return map(guests, (guest) => ({
      name: guest,
      email: guest,
      searchable: false,
    }))
  }, [guests])
  const firstDate = useMemo(() => get(state, 'range[0]'), [state])
  const secondDate = useMemo(() => get(state, 'range[1]'), [state])
  const increment = useMemo(() => get(state, 'increment'), [state])

  const onSetCurrentPage = useCallback((newCurrentPage) => {
    dispatch({ type: 'setCurrentPage', currentPage: newCurrentPage })
  }, [])

  const onFetchData = useCallback(async () => {
		setIsFetchingData(true);
    
    try {
      const response = await API.post(
        'group-manage',
        '/group-manage?queryUser=true',
        {
          body: {
            parentId,
            emails,
            rangeStart: moment(firstDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            rangeEnd: moment(secondDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            timezone: momentTz.tz.guess(),
            increment,
          },
        }
      )

      const { openTimeResult, buddies } = response
      const firstFreeTime = get(openTimeResult, 'firstFreeTime')

      if (firstFreeTime) {
        dispatch({
          type: 'setFirstFree',
          firstFree: momentTz.tz(
            get(firstFreeTime, 'start'),
            get(firstFreeTime, 'startTz')
          ),
        })
      }

      dispatch({
        type: 'setFreeTimes',
        freeTimes: get(openTimeResult, 'slots'),
      })

      dispatch({
        type: 'setExcludedDays',
        excludedDays: get(openTimeResult, 'excludedDays'),
      })

      dispatch({
        type: 'setFriends',
        friends: buddies,
      })

      dispatch({
        type: 'setSingleSelect',
        singleSelect: buddies.length === guests.length,
      })

      dispatch({
        type: 'setSelectedItems',
        selectedItems: []
      })
    } catch (err) {
      console.log(':WHAT', err)
    }

		setIsFetchingData(false)
  }, [dispatch, guests, parentId, emails, increment, firstDate, secondDate])

  useEffect(() => {
    if (guests.length && firstDate && secondDate) {
      onFetchData()
    }
  }, [guests, firstDate, secondDate, increment])

  const value = {
    state,
    dispatch,
    onSetCurrentPage,
    onFetchData,

		isFetchingData
  }

  return (
    <GroupScheduleContext.Provider value={value}>
      {children}
    </GroupScheduleContext.Provider>
  )
}

export const useGroupScheduleContext = () => useContext(GroupScheduleContext)

export const withGroupScheduleContextProvider = (Component) => (props) => {
  return (
    <GroupScheduleContextProvider>
      <Component {...props} />
    </GroupScheduleContextProvider>
  )
}
