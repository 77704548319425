import React from 'react'

import { Modal, Input } from 'antd'

const EditStripeModal = ({
	stripeId,
	subId,
	visible,
	handleOk,
	confirmLoading,
	handleCancel,
	newStripeId,
	setNewStripeId,
	newSubId,
	setNewSubId
}) => {

	const handleInputChange = (e) => {
		setNewStripeId(e.target.value)
	}
	const handleSubChange = (e) => {
		setNewSubId(e.target.value)
	}
	return (
		<Modal
			title="Edit Stripe"
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<p>Change the Stripe Info for a User</p>
			<Input.Group compact={false}>
				<Input
					style={{ paddingBottom: '1em' }}
					addonBefore={'Current Customer Id'}
					value={stripeId}
					readOnly={true}
				/>
				<Input
					style={{ paddingBottom: '1em' }}
					addonBefore={'Current Sub Id'}
					value={subId}
					readOnly={true}
				/>
				<Input
					style={{ paddingBottom: '1em' }}
					value={newStripeId}
					addonBefore={'New Stripe Id'}
					placeholder={'New Stripe Id'}
					onChange={handleInputChange}
				/>
				<Input
					style={{ paddingBottom: '1em' }}
					value={newSubId}
					addonBefore={'New Sub Id'}
					placeholder={'New Sub Id'}
					onChange={handleSubChange}
				/>
			</Input.Group>
		</Modal>
	)
}

export default EditStripeModal
