import React from 'react'

import { Modal, Select } from 'antd'
const { Option } = Select;

const planOptions = [
	'calendarbridge-pro',
	'basic-monthly',
	'basic-yearly',
	'premium-monthly',
	'premium-yearly',
	'pro-monthly',
	'pro-yearly',
	'admin-monthly',
	'admin-yearly'
];

const EditPlanModal = ({
	currentPlan,
	visible,
	handleOk,
	confirmLoading,
	handleCancel,
	setNewPlan
}) => {
	function handleChange(value) {
		
		setNewPlan(value.value);
	}
	return (
		<Modal
			title="Edit Plan (tries to update stripe)"
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<Select
				labelInValue
				
				defaultValue={{ key: currentPlan, label: currentPlan }}
				style={{ width: 350 }} onChange={handleChange}>
				{planOptions.map((plan, index) => (
					<Option value={plan} key={index}>{plan}</Option>
				))}
			</Select>
		</Modal>
	)
}

export default EditPlanModal
