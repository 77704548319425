import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import JSONTree from 'react-json-tree'
import { Empty, Input, Card, Col, Row, Button, Modal } from 'antd'
import get from 'lodash/get'
import SyncItem from './SyncItem'
import LicenseInfo from './LicenseInfo'
import QueryString from 'query-string'
import {
	DeleteOutlined,
  } from '@ant-design/icons'
import BoomerScanAccountList from './BoomerScanAccountList'
import BoomerScanBridgeList from './BoomerScanBridgeList'

const theme = {
  scheme: 'google',
  author: 'seth wright (http://sethawright.com)',
  base00: '#1d1f21',
  base01: '#282a2e',
  base02: '#373b41',
  base03: '#969896',
  base04: '#b4b7b4',
  base05: '#c5c8c6',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#CC342B',
  base09: '#F96A38',
  base0A: '#FBA922',
  base0B: '#198844',
  base0C: '#3971ED',
  base0D: '#3971ED',
  base0E: '#A36AC7',
  base0F: '#3971ED',
}

const PAGE_LIMIT = 100

const BoomerScan = () => {
  const [boomerData, setBoomerData] = useState({})
  const [userEmail, setUserEmail] = useState('')

  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [bridges, setBridges] = useState([])
  const [accounts, setAccounts] = useState([])

  async function loadBoomerData() {
    if (userEmail.length) {
      setBoomerData({})
      setBridges([])
      setAccounts([])
      setLoading(true)
      try {
        var responseBoomerData = await API.get('scan', '/scan', {
          queryStringParameters: {
            email: userEmail,
            target: search,
            action: 'getBoomerData',
          },
        })
        setBoomerData(get(responseBoomerData, 'results'))
        setAccounts(get(responseBoomerData, ['results', 'accounts']) || [])
        setBridges(get(responseBoomerData, ['results', 'bridges']) || [])
      } catch (ex) {
        alert(ex.message)
      } finally {
        setLoading(false)
      }
    }
  }

  async function deleteCalendarAccount(accountId) {
    if (userEmail.length) {
      // setBoomerData({})
      // setBridges([])
      // setAccounts([])
      Modal.confirm({
        title: 'Are you sure you want to delete this account? Make sure there are no syncs!',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        icon: <DeleteOutlined />,
        async onOk() {
          

          

          setLoading(true)
          try {
            // console.log(accountId)
            var responseBoomerData = await API.post('scan', '/scan', {
              body: {
                email: accountId,
                action: 'deleteCalendarAccount',
              },
            })

            setForceRefresh(true)
            // setBoomerData(get(responseBoomerData, 'results'))
            // setAccounts(get(responseBoomerData, ['results', 'accounts']) || [])
            // setBridges(get(responseBoomerData, ['results', 'bridges']) || [])
          } catch (ex) {
            alert(ex.message)
          } finally {
            setLoading(false)
          }
        },
      })
    }
  }

  useEffect(() => {
    let params = QueryString.parse(location.search)
    if (params.userEmail) {
      // console.log(params.userEmail)
      setUserEmail(params.userEmail)

      setForceRefresh(true)
    }
  }, [])

  useEffect(() => {
    if (forceRefresh) {
      loadBoomerData()
      setForceRefresh(false)
    }
  }, [forceRefresh])

  async function updateEmailAndLoadNewData(email) {
    setUserEmail(email)
    setForceRefresh(true)
  }

  return (
    <>
    <div id="top"></div>
      <Input.Search
        placeholder="User Email"
        size="large"
        enterButton="Scan"
        value={userEmail}
        onChange={(e) => {
          setUserEmail(e.target.value.toLowerCase().trim())
        }}
        onSearch={loadBoomerData}
        loading={loading}
      />
      {Object.keys(boomerData).length > 0 ? (
        <div style={{ padding: 20 }}>
          {boomerData['license'] && (
            <LicenseInfo
              license={boomerData['license']}
              updateEmailAndLoadNewData={updateEmailAndLoadNewData}
            />
          )}
          {search && boomerData['targets'] && (
            <>
              <h3>Search Results</h3>
              <JSONTree
                data={{ items: boomerData['targets'] }}
                hideRoot={true}
                theme={theme}
              />
            </>
          )}
          <a href="#syncs">Jump to syncs</a>
          {accounts.length && (
            <BoomerScanAccountList
              userEmail={userEmail}
              theme={theme}
              initialAccounts={accounts}
              pageLimit={PAGE_LIMIT}
              deleteCallback={deleteCalendarAccount}
            />
          )}
          <a id="syncs"></a>
          <a href="#top">back to top</a>
          {bridges.length && (
            <BoomerScanBridgeList
              userEmail={userEmail}
              initialBridges={bridges}
              theme={theme}
              pageLimit={PAGE_LIMIT}
              accounts={accounts}
            />
          )}
          <div>
            Schedules:
            {boomerData['schedules'] && (
             <div>
              {boomerData['schedules'].map(item=>{
                return <div className="border p-3">
                  {item.label || item.displayName}
                  {' '} id:{' '} {item.id}
                  alias:{' '} {item.alias}
                  
                  <JSONTree
                data={{scheduler: item}}
                hideRoot={true}
                theme={theme}
              />
              {item.errorState != "" && <div>Error: {item.errorState}</div>}
                <div><a href={`/book/${item.id}?debug=true`} target="_blank">Open Debugger</a></div>
              </div>
              })}
             </div>)}
           
            </div>
         
        </div>
      ) : (
        <div style={{ padding: 20 }}>
          <Empty />
        </div>
      )}
    </>
  )
}

export default BoomerScan
