import React,{useState} from 'react'
import { Modal, Badge, Collapse,Button } from 'antd'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { Radio } from 'antd';

import JSONTree from 'react-json-tree'
const theme = {
	scheme: 'google',
	author: 'seth wright (http://sethawright.com)',
	base00: '#1d1f21',
	base01: '#282a2e',
	base02: '#373b41',
	base03: '#969896',
	base04: '#b4b7b4',
	base05: '#c5c8c6',
	base06: '#e0e0e0',
	base07: '#ffffff',
	base08: '#CC342B',
	base09: '#F96A38',
	base0A: '#FBA922',
	base0B: '#198844',
	base0C: '#3971ED',
	base0D: '#3971ED',
	base0E: '#A36AC7',
	base0F: '#3971ED',
  }


const { Panel } = Collapse;

const BoomerCalendar = (items) => {

	const [tz, setTz] = useState("local")
    const [showDestinationEvents,setShowDestinationEvents] = useState(false)
	const [showSourceCopyEvents,setShowSourceCopyEvents] = useState(false)
	
	const [thing, setThing] = useState(null) 
    const [showModal,setShowModal] = useState(false)
	function onTzChange(e) {
		// console.log(`radio checked:${e.target.value}`);
		setTz(e.target.value)
	  }

	// function getEventTimeEnd(event) {
	// 	if (event.event.sensitivity) {
	// 		// ms event
	// 		var eventTime = 
	// 		 momentTz.utc(event.event.end.dateTime, "YYYY-MM-DDTHH:mm:ss.SSSSSSS")
	// 		 if(event.event.allDay == true){
	// 			return eventTime
	// 		 } else {
	// 			if(tz == "local") {
	// 				return eventTime.local()
	// 			 } else {
	// 				return eventTime.tz(tz)
	// 			 }
	// 		 }
	// 	} else {
	// 		// goog event

	// 		return momentTz(event.event.end.dateTime || event.event.end.date)
	// 	}
	// }

	function getEventTime(event,pos="start") {
		if (event.event.sensitivity) {
			// ms event
			var time = event.event.start.dateTime
			if(pos != "start") {
				time = event.event.end.dateTime
			}
			var eventTime =  momentTz.utc(time, "YYYY-MM-DDTHH:mm:ss.SSSSSSS")
			if(event.event.allDay == true){
				return eventTime
				// if(tz == "local") {

				// 	return eventTime.local()
				//  } else {
				// 	return eventTime.tz(tz)
				//  }
			 } else {
				 if(tz == "local") {

					return eventTime.local()
				 } else {
					return eventTime.tz(tz)
				 }
			 }
		} else {
			// goog event
			var time = event.event.start.dateTime || event.event.start.date
			var endTime = event.event.end.dateTime || event.event.end.date
			var useTime = time
			if(pos != "start") {
				useTime = endTime
			}
			if(time.includes("00:00:00") && endTime.includes("00:00:00")){
					useTime = useTime.split("T")[0]
			}
			return momentTz(useTime)
		}
	}

	function compareEvents(a, b) {

		var aMoment = getEventTime(a,"start").unix()
		var bMoment = getEventTime(b,"start").unix()

		return aMoment - bMoment;
	}

	function checkMSExtended(ev) {
		// console.log(ev.subject)
		// console.log(ev.singleValueExtendedProperties)
		if(ev.singleValueExtendedProperties == null){
			
			return false
		} else {
			let filtered = ev.singleValueExtendedProperties.filter(e => {
				
				return e.id.includes("RecurProperty")
			})
			
			return filtered.length > 0
		}
	}

	function getListData(value) {
		var sourceItems = items.sourceItems
		var destItems = items.destItems
		
		let listData;
		var day = value.date()
		var month = value.month()
		// switch (value.date()) {

		var days = []
		if (sourceItems ) {

			var sourceDays = sourceItems.filter(e => {
				var d = getEventTime({ event: e },"start")
				var dayEvent = d.date()
				return dayEvent == day && month == d.month() && !((e.extendedProperties && e.extendedProperties.private && e.extendedProperties.private.originalId) || checkMSExtended(e))
			}).map((f) => {
				var title = f.summary || f.subject || "(no subject)"
				if(title.endsWith("  ")) {
					title += "no_tag"
				}
				return { color: "green", content: title , event: f }
			})
			days.push.apply(days, sourceDays)
			if (destItems && destItems.length > 0) {
				var destDays = destItems.filter(e => {
					var d = getEventTime({ event: e },"start")
					var dayEvent = d.date()
					return dayEvent == day && month == d.month() && ((e.extendedProperties && e.extendedProperties.private && e.extendedProperties.private.originalId) || checkMSExtended(e))
				}).map((f) => {
					var color = 'red'
					var title = f.summary || f.subject || "(no subject)"
						if(title.endsWith("  ")) {
							title += "no_tag"
						}
						return { color: color, content: title , event: f }
				})
				days.push.apply(days, destDays)
				if(showDestinationEvents) {
					
					var nonCopyDestination = destItems.filter(e => {
						var d = getEventTime({ event: e },"start")
						var dayEvent = d.date()
						return dayEvent == day && month == d.month() && (!((e.extendedProperties && e.extendedProperties.private && e.extendedProperties.private.originalId) || checkMSExtended(e)))
					}).map((f) => {
						var color = 'orange'
						var title = f.summary || f.subject || "(no subject)"
						if(title.endsWith("  ")) {
							title += "no_tag"
						}
						return { color: color, content: title , event: f }
					})
					// console.log(nonCopyDestination)
					days.push.apply(days, nonCopyDestination)
				}

				if(showSourceCopyEvents) {
					// console.log("gloppy")
					var copySource = sourceItems.filter(e => {
						var d = getEventTime({ event: e },"start")
						var dayEvent = d.date()
						return dayEvent == day && month == d.month() && ((e.extendedProperties && e.extendedProperties.private && e.extendedProperties.private.originalId) || checkMSExtended(e))
					}).map((f) => {
						var color = 'blue'
						var title = f.summary || f.subject || "(no subject)"
						if(title.endsWith("  ")) {
							title += "no_tag"
						}
						return { color: color, content: title , event: f }
					})
					
					days.push.apply(days, copySource)
					// console.log("Copy source")
					// console.log(copySource)
				}
				// days  = sourceDays.map(function(e, i) {
				// 	return [e, destDays[i]];
				//   });
			}
		}

		var cool = days.sort(compareEvents)
		// console.log(cool)
		return cool;
	}

	function checkEvent(item) {
		if(item.event.summary && 
			!item.event.start.date && 
			item.event.start.dateTime.includes("00:00:00")
			&& 
			!item.event.end.date && 
			item.event.end.dateTime.includes("00:00:00")) {
				return "ALL DAY"
			} else {
				return ""
			}
	}
	function timeString(item, pos="start"){
		var append = checkEvent(item)
		//check for wierd google days
		
		if(item.event.start.date || (item.event.allDay == true)){
			
				return getEventTime(item,pos).format("MM/DD") + " ALL DAY"
			
		} else {
			if(append == "ALL DAY") {
				if(tz == "local") {
					return getEventTime(item,pos).local().format("MM/DD") + " ALL DAY"
				} else {
					return getEventTime(item,pos).tz(tz).format("MM/DD") + " ALL DAY"
				}
			} else {
				if(tz == "local") {
					return getEventTime(item,pos).local().format("hh:mm A Z")
				} else {
					return getEventTime(item,pos).tz(tz).format("hh:mm A Z")
				}
			}
			
		
			
				
		}
	}

	function hasDates(value) {
		return getListData(value).length > 0
	}

	function shouldFilter(event, color, inviteStatus, visibility) {
		var filterMe = false
		var viz = visibility
		if(viz == "transparent") {
			viz = "free"
		}
		if(viz == "opaque") {
			viz = "busy"
		}
		// console.log("SHOULD FILTTER!")
		
		if(items.bridge.hasFilters) {
			if(items.bridge.excludeInvites && inviteStatus != "n/a" && inviteStatus != "accepted") {
				
				filterMe =  true
			} else {
				
			}
			if(items.bridge.filterColor != "none" && color.toLowerCase().includes(items.bridge.filterColor.toLowerCase())) {
				
				filterMe = true
			}
			//status
			if(items.bridge.syncLevel != "all" && !items.bridge.syncLevel.includes(viz)) {
				
				filterMe = true
			}
		} else {
			
		}
		return filterMe ? 'true' : 'false'
	}

	function getInviteStatus(event) {
		if(event.attendees) {
			var thing = event.attendees.filter(attendee => attendee.self == true)
			if(thing.length > 0) {
				return thing[0].responseStatus
			} else {
				return "n/a"
			}
		} else {
			return "n/a"
		}
	}

	function dateCellRender(value, expanded = false) {
		const listData = getListData(value);
		return (
			<>
				{listData.map(item => {
					var color = item.event.categories ? item.event.categories.join('|').toLowerCase() : (item.event.colorId ? item.event.colorId : 'none')
					var visiblity = item.event.showAs ? item.event.showAs : item.event.transparency
					var inviteStatus = getInviteStatus(item.event)
					return <div onClick={e => {
						setShowModal(true)
						setThing(item)
					}}>

						<Badge color={item.color} text={item.content} />
						{expanded && (<><div>&nbsp;&nbsp;&nbsp;&nbsp;Start: {timeString(item,"start")}</div>
							<div>&nbsp;&nbsp;&nbsp;&nbsp;End: {timeString(item,"end")}</div>{(item.event.seriesMasterId || item.event.recurringEventId) && <>&nbsp;&nbsp;&nbsp;&nbsp;(recur)</>}</>)}
							{items.bridge.hasFilters && item.color != "red" &&  <><>&nbsp;&nbsp;&nbsp;&nbsp;Status: <b>{visiblity}</b></>
							<>&nbsp;&nbsp;&nbsp;&nbsp;Color: <b>{color}</b></>
							<>&nbsp;&nbsp;&nbsp;&nbsp;Invite Status: <b>{inviteStatus}</b></>
							<div>&nbsp;&nbsp;&nbsp;&nbsp;Should be Filtered: <b>{shouldFilter(item.event,color,inviteStatus,visiblity)}</b></div></>}
					</div>
				})}
			</>
		);
	}

	function getMonthData(value) {
		if (value.month() === 8) {
			return 1394;
		}
	}


	const numbers = function* () {
		let i = 1
		while (true) {
			yield i++
		}
	}

	function renderDayList() {
		var begin = moment().add(items.month,"month").date(1)
		// console.log(begin)
		var arr = []
		for (var i = 0; i < 60; i++) {
			arr.push(begin.clone().add(i, "day"))
		}
		return arr.filter(e => hasDates(e)).map(e => <>
			<div><ul><b>{e.format("MMMM DD")}</b></ul></div>
			{dateCellRender(e, true)}
			<p></p>
		</>)
	}

	function monthCellRender(value) {
		const num = getMonthData(value);
		return num ? (
			<div className="notes-month">
				<section>{num}</section>
				<span>Backlog number</span>
			</div>
		) : null;
	}

	return <>
	{items.bridge.hasFilters && <div className="mb-4">
	<h2>FILTERS</h2>
	<div>Allow Events: {items.bridge.syncLevel}</div>
	<div>Filter Color: {items.bridge.filterColor}</div>
	<div>Filter Unnacepted Invites: {items.bridge.excludeInvites ? 'true' : 'false'}</div>
	</div>}
		<h2>Events</h2>
		<div><Badge color={"green"} text={"Source Events"} /></div>
		<div><Badge color={"red"} text={"Destination Copies"} /></div>

		<div><Badge color={"blue"} text={"Copies in Source"} /></div>
		<div><Badge color={"orange"} text={"Destination non-copies"} /></div>
		<div>TimeZone</div>
		<Radio.Group onChange={onTzChange} defaultValue="local">
      <Radio.Button value="local">local: {moment.tz.guess()}</Radio.Button>
      <Radio.Button value={items.bridge.sourceCalendarTimezone}>Source Tz: {items.bridge.sourceCalendarTimezone}</Radio.Button>
	  <Radio.Button value={items.bridge.destCalendarTimezone}>Dest Tz: {items.bridge.destCalendarTimezone}</Radio.Button>
     
    </Radio.Group>
	<div>
	<Button onClick={e=>setShowDestinationEvents(!showDestinationEvents)}>{showDestinationEvents ? 'Hide' : 'Show'} Destination Events</Button>
	<Button onClick={e=>setShowSourceCopyEvents(!showSourceCopyEvents)}>{showSourceCopyEvents ? 'Hide' : 'Show'} Source Copies</Button>
	
	</div>
		<p></p>
		{(showDestinationEvents || !showDestinationEvents) && renderDayList()}
		<div className="lg:mt-8">
		<Modal
			title={thing != null ? (thing.event.summary || thing.event.subject) : ''}
			visible={showModal}
			onOk={e=> {

				setShowModal(false)

			}}
			onCancel={e => {
				setShowModal(false)
			}}
		>
			<Button onClick={e=>{
				navigator.clipboard.writeText(JSON.stringify(thing, null, 2))
			}}>COPY TO CLIPBOARD</Button>
          
				<pre className="bg-gray-300">{JSON.stringify(thing, null, 2) }</pre>
        </Modal>
		</div>
	</>
}


export default BoomerCalendar;