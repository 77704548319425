import React, {useState} from 'react'

import { Modal, Input, Button, Card } from 'antd'
import { Radio } from 'antd';
import { Select } from 'antd';
const { Option } = Select;

function FullOrgEditModal({
	title,
	visible,
	handleOk,
	confirmLoading,
	handleCancel,
	// domain,
	// interval,
	// stripeId,
	// subId,
	// allowedDomains,
	// changeDomain,
	// changeStripeId
	organizationData,
	setOrgData
}) {
	//console.log(organizationData)
	
	const [domainType, setDomainType] = useState("google")
	const [allowedDomainName, setAllowedDomainName] = useState("")
	
	function handleChange(value){
			//console.log(`selected ${value}`);
			setDomainType(value)
	}

	function addDomain() {
		if(!organizationData.allowedDomains) {
			organizationData.allowedDomains = []
		}
		organizationData.allowedDomains.push(
			{
			name: allowedDomainName,
			domain: allowedDomainName,
			type: domainType
			})
		
		setAllowedDomainName('')
		setOrgData({...organizationData, allowedDomains: organizationData.allowedDomains
		})
	}


	return (
		<Modal
			title={title}
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<Input
            style={{ paddingBottom: '1em' }}
            addonBefore={'Org Id'}
            defaultValue={organizationData.orgId}
			onChange={e=>{
				setOrgData({...organizationData, orgId: e.target.value, orgDomain: e.target.value})
			}}
			disabled={!organizationData.new}
			
          />
			
			{/* <Input
            style={{ paddingBottom: '1em' }}
            addonBefore={'Domain'}
            defaultValue={organizationData.orgDomain}
			onChange={e=>{
				setOrgData({...organizationData, orgDomain: e.target.value})
			}}
          /> */}
		  <Input
            style={{ paddingBottom: '1em' }}
            addonBefore={'Stripe Id'}
            defaultValue={organizationData.stripeId}
			onChange={e=>{
				setOrgData({...organizationData, stripeId: e.target.value})
			}}
          />
		  <Input
            style={{ paddingBottom: '1em' }}
            addonBefore={'Subscription Id'}
            defaultValue={organizationData.subId}
			onChange={e=>{
				setOrgData({...organizationData, subId: e.target.value})
			}}
          />
		<div >Billing Interval: 
		  <Radio.Group style={{marginLeft:5, marginTop:5, marginBottom:5}} onChange={e=>{
				setOrgData({...organizationData, orgInterval: e.target.value})
			}} defaultValue={organizationData.orgInterval || "monthly"}>

      <Radio.Button value="monthly">Monthly</Radio.Button>
      <Radio.Button value="yearly">Yearly</Radio.Button>
	  <Radio.Button value="2022-monthly">2022 Monthly</Radio.Button>
	  <Radio.Button value="2022-yearly">2022 Yearly</Radio.Button>

	  </Radio.Group></div>
	  <Card title="Add Authed Domain">
			
		  <Input
            style={{ paddingBottom: '1em' }}
            value={allowedDomainName}
			onChange={e=> setAllowedDomainName(e.target.value.toLowerCase())}
          />
		   <Select defaultValue="google" style={{ width: 120 }} onChange={handleChange}>
      <Option value="google">Google</Option>
      <Option value="ms">MS</Option>
      <Option value="ews" >
        EWS
      </Option>
    </Select>
	<Button onClick={e => addDomain()}>Add Domain</Button>
	<div style={{marginTop:5}}>Authed Domains:</div>
	{organizationData.allowedDomains && organizationData.allowedDomains.map((e,i)=> 
		{
			
		return	(<div style={{marginTop:5}} key={i}>{e.domain}-{e.type} <Button onClick={event=>
		{
			
			var domains = organizationData.allowedDomains.filter(toFilter=> !(e.domain == toFilter.domain && e.type == toFilter.type))
			setOrgData({...organizationData,allowedDomains:domains})
	}	
		}>x</Button></div>)
		})}
	</Card>
		</Modal>);
}


export default FullOrgEditModal;