import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  Card,
  Col,
  Row,
  Skeleton,
  Empty,
  Dropdown,
  Button,
  Menu,
  Modal,
  Tooltip,
  Alert,
  message,
  Spin,
} from 'antd'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import { accountIconMap } from '../../../utils/customIcons'
import { useGlobalState } from '../../../store'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBList,
} from '../../core/components/cbd'
import Trash from '../../../images/svg/trash-delete.svg'
import ReAuth from '../../../images/svg/sync.svg'
import GoogleLogo from '../../../images/svg/google-logo.svg'

import {
  Loading3QuartersOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  MoreOutlined,
} from '@ant-design/icons'

import { API } from 'aws-amplify'

import Add from '../../../images/svg/plus.svg'

import More from '../../../images/svg/menu-more-horizontal.svg'

const AccountItem = ({ item, setShowError }) => {
  const [showCalendars, setShowCalendars] = useState(false)
  
  function showDeleteConfirm() {
    Modal.confirm({
      title: 'Are you sure you want to delete this account?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        deleteAccount()

        // window.location.reload()
      },
    })
  }

  const deleteAccount = () => {
    API.del('account', '/account?id=' + item.calendarAccountId, {
      body: {},
    })
      .then(async () => {
        // const accounts = await loadAccounts()
        // setAccounts(accounts)
        // alert('ok!')
        // window.location.reload()
        window.location.href = window.location.origin + '/dashboard/accounts/'
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'deleteAccount',
          label: item.ownerId,
          email: item.email,
        })
      })
      .catch((error) => {
        if (error.response) {
          
          // message.error(error.response.data.message)
          setShowError(error.response.data.message)
        } else {
        }
        // alert(error.response.data.message)
      })
  }

  const redirectToService = async (redirectUri) => {
    window.location.href = redirectUri
  }

  const reauth = () => {
    API.del('account', '/account?unhook=true&id=' + item.calendarAccountId, {
      body: {},
    })
      .then(async () => {
        //const accounts = await loadAccounts()
        // console.log(item)
        if (item.calendarAccountType == 'ms') {
          if (item.msType == 'gcc') {
            redirectToService(
              `https://login.microsoftonline.us/common/oauth2/v2.0/authorize?prompt=login&client_id=${
                process.env.GATSBY_OAUTH_O365_CLIENT_GCC
              }&response_type=code&scope=offline_access+Calendars.ReadWrite+User.Read&state=gcc&redirect_uri=${
                window.location.origin + process.env.GATSBY_OAUTH_O365_URL
              }`
            )
          } else {
            redirectToService(
              `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?prompt=login&client_id=${
                process.env.GATSBY_OAUTH_O365_CLIENT
              }&response_type=code&scope=offline_access+Calendars.ReadWrite+User.Read&state=normal&redirect_uri=${
                window.location.origin + process.env.GATSBY_OAUTH_O365_URL
              }`
            )
          }
        } else if(item.calendarAccountType == 'caldav'){
          redirectToService("/dashboard/accounts/new?caldav=" + encodeURIComponent(item.email))

        } else {
          redirectToService(
            `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
              process.env.GATSBY_OAUTH_GOOGLE_CLIENT
            }&login_hint=${item.email}&redirect_uri=${
              window.location.origin + process.env.GATSBY_OAUTH_GOOGLE_URL
            }&response_type=code&prompt=login&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&access_type=offline`
          )
        }
      })
      .catch((error) => {
        // alert(error.response.data.message)
        message.error(error.response.data.message)
      })
  }
  const handleClick = (e) => {
    if (e.key === '0') {
      // showForceDeleteConfirm()
      reauth()
    } else if (e.key === '1') {
      // showDeleteConfirm()
      showDeleteConfirm()
    } else if (e.key === '2') {
      // showEditConfirm()
    } else if (e.key === '3') {
      // navigate(`/dashboard/syncs/${item.id.replace('#', '___')}/edit`)
    }
  }

  const menu = (
    <Menu onClick={handleClick} style={{ width: 125 }}>
      <Menu.Item key="0">
        {/* <Icon type="stop" /> */}
        <div className="flex gap-x-4 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>{' '}
          Re-Auth
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        {/* <Icon type="stop" /> */}
        <div className="flex gap-x-4 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
          Delete
        </div>
      </Menu.Item>

      {/* {item.status == 'delete_error' && (
        <Menu.Item key="0" style={{ color: colorNegative }}>
          <DeleteOutlined />
          Force Delete
        </Menu.Item>
      )}
      {item.status != 'delete_error' && (
        <Menu.Item key="1" style={{ color: colorNegative }}>
          <DeleteOutlined />
          Delete
        </Menu.Item>
      )}
      {item.status == 'synced_renew' && (
        <Menu.Item key="3" style={{ color: colorPositive }}>
          <FormOutlined />
          Finish Edit
        </Menu.Item>
      )}
      {item.status == 'synced' && (
        <Menu.Item key="2" style={{ color: colorPositive }}>
          <EditOutlined />
          Edit
        </Menu.Item>
      )} */}
    </Menu>
  )

  return (
    <>
      <div className="rounded-sm border-2 border-neutral_1 mt-2 mb-6 flex flex-col shadow-sm">
        <div className="flex flex-col pl-4 pr-4 pb-4">
          <div>
            <CBHeader
              label={<div className="text-sm lg:text-lg">{item.email}</div>}
              icon={
                item.calendarAccountType == 'google' ? (
                  <GoogleLogo></GoogleLogo>
                ) : (
                  <FontAwesomeIcon
                    icon={accountIconMap[item.calendarAccountType].icon}
                    size="2x"
                    color={accountIconMap[item.calendarAccountType].color}
                  />
                )
              }
              menu={
                <div className="flex-grow">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <CBButton type="ternary" icon={<More />} size="large" />
                  </Dropdown>
                </div>
              }
            ></CBHeader>
          </div>
          {item.errorState != '' && item.errorState != 'Personal' && (
            <Alert
              message="Authentication Issue: Please Re-Authenticate Account in pulldown menu"
              type="error"
              
              showIcon
            ></Alert>
          )}
          {item.errorState != '' && item.errorState == 'Personal' && (
            <a
              href="https://help.calendarbridge.com/doc/sync-troubleshooting/microsoft-plan-and-account-types/"
              target="_blank"
            >
              <Alert
                message="Warning: Microsoft 365 Personal Accounts are not supported by CalendarBridge, please make sure you have added correct account, tap here for more information!"
                type="error"
                showIcon
                
              ></Alert>
            </a>
          )}
        </div>
        <div className="bg-neutral_1 p-4 flex flex-col justify-center">
          <CBListItem
            onClick={(e) => setShowCalendars(!showCalendars)}
            border={false}
            icon={
              <div className="rounded-full flex flex-row gap-x-2 items-center justify-center pt-2 pb-2 pl-4 pr-4 bg-white">
                {item.calendars.length}{' '}
                {showCalendars ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                )}
              </div>
            }
          >
            Calendars
          </CBListItem>
          {showCalendars && (
            <CBList>
              {item.calendars.map((e) => {
                return <CBListItem key={e.id}>{e.name || e.summary}</CBListItem>
              })}
            </CBList>
          )}
        </div>
      </div>
    </>
  )
}
const AccountSelectList = () => {
  const [accounts] = useGlobalState('accounts')
  const [loadingCoreData] = useGlobalState('loadingCoreData')
  const [showError,setShowError] = useState("")
  if (loadingCoreData) {
    return (
      <div className="min-h-screen">
        <center className="mt-8">
          <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{ fontSize: '100px' }} />
            }
          />
        </center>
      </div>
    )
  }

  const renderItem = (item) => {
    return <AccountItem key={item.calendarAccountId} item={item} setShowError={setShowError}></AccountItem>
  }

  const renderCreate = () => (
    <div className="mt-2 mb-8">
      <CBButton
        onClick={(e) => navigate('/dashboard/accounts/new')}
        long={true}
        icon={<Add></Add>}
      >
        Add Account
      </CBButton>
    </div>
  )

  const list = accounts
    .sort((a, b) => {
      var nameB = b.email
      var nameA = a.email
      if (nameA < nameB)
        //sort string ascending
        return -1
      if (nameA > nameB) return 1
      return 0
    })
    .sort((a, b) => (b.errorState == '' ? 0 : 1) - (a.errorState == '' ? 0 : 1))
    .map(renderItem)

  return (
    <>
    {showError != "" && <div className="p-4 border bg-red-500 text-white">{showError}</div>}
      {renderCreate()}
      <div style={{ maxWidth: 800, minHeight: 1200, margin: '0 auto' }}>
        {list}
        {list.length == 0 && (
          <div className="p-4 padding bg-neutral_0 border text-center"
          
          >No Accounts</div>
          
        )}
      </div>
    </>
  )
}

export default AccountSelectList
