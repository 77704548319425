import React, { useState, useEffect } from 'react'

import { Spin } from 'antd'
import { useGlobalState } from '../../../store'

import Title from 'antd/lib/typography/Title'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { navigate } from '@reach/router'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBColorPicker,
  CBModal,
  CBSelect,
  CBList,
  CBUrlField,
  CBCalendar,
} from '../../core/components/cbd'

import NewSchedule from '../../../images/svg/new-scheduler.svg'

import Connections from '../../../images/svg/connections.svg'
import NewSync from '../../../images/svg/new-sync.svg'
import NewBooking from '../../../images/svg/new-booking.svg'
import LongButton from '../../core/components/LongButton'
import CBCard from '../../core/components/CBCard'
import ActionButton from '../../core/components/ActionButton'
import AnimatedButton from '../../core/components/AnimatedButton'
import Add from '../../../images/svg/plus.svg'
import Chip from '../../../images/svg/right_chip.svg'

import Edit from '../../../images/svg/pencil.svg'
import Check from '../../../images/svg/check.svg'
import AddDark from '../../../images/svg/plus_dark.svg'
import Info from '../../../images/svg/info.svg'
import Trash from '../../../images/svg/trash-delete.svg'
import More from '../../../images/svg/dotdotdot.svg'
import Calendar from '../../../images/svg/calendar-event-date.svg'
import ScheduleIcon from '../../../images/svg/logo-scheduling.svg'
import AddAccount from '../../../images/svg/add_account.svg'
import AddSchedule from '../../../images/svg/add_schedule.svg'
import AddSync from '../../../images/svg/add_sync.svg'
import AddBook from '../../../images/svg/add_book.svg'
import Carat from '../../../images/arrow-right-chevron.png'
import HomeIcon from '../../../images/svg/home.svg'
import SyncIcon from '../../../images/svg/logo-syncing.svg'
import GiveGet from '../../../images/svg/give_cb_get_cb.svg'
import BookingIcon from '../../../images/svg/logo-booking.svg'
import Logo from '../../../images/svg/calendar_bridge_logo.svg'
import momentTz from 'moment-timezone'
import SEO from '../../../components/SEO'

const MultiDropDownTest = ({ type = 'stuff', values, single = true }) => {
  const [value, setValue] = useState('')
  const [items, setItems] = useState([])
  return (
    <>
      <label for={type}>
        <CBInput
          id={`bong-${value}-${Math.random()}`}
          key={`test-${value == '' ? Math.random() : Math.random()}-ass`}
          list={type}
          name={type}
          type="text"
          value={value}
          onChange={(e) => {
            if (e) {
              if (values.indexOf(e) >= 0 && items.indexOf(e) == -1) {
                //console.log('ADD')
                if (!single) {
                  setValue('')
                } else {
                  setValue(e)
                }
                if (!single) {
                  setItems([...items, e])
                } else {
                  setItems([e])
                }
              } else if (items.indexOf(e) >= 0) {
                if (!single) {
                  setValue('')
                } else {
                  setValue(e)
                }
              } else {
                //console.log('WHAT')
              }
            }
          }}
        />
      </label>

      {!single && items.length > 0 && (
        <>
          {items.map((e) => (
            <CBListItem
              onClick={(q) => {
                var remove = items.filter((v) => v != e)
                setItems(remove)
              }}
              icon={<Trash></Trash>}
            >
              {e}
            </CBListItem>
          ))}
        </>
      )}
      <datalist id={type}>
        {values.map((v, i) => {
          return <option data-value={v} value={v}></option>
        })}
      </datalist>
    </>
  )
}
const CalendarTest = ({
  excludedDays = [],
  passDate,
  singleSelect = true,
  onSelect = (e) => console.log(e),
}) => {
  const [date, setDate] = useState(passDate || momentTz())
  const [dayOfMonth, setDayOfMonth] = useState(date.date())
  const [firstSelect, setFirstSelect] = useState(null)
  const [secondSelect, setSecondSelect] = useState(null)

  function generateDays() {
    const firstOfMonth = date.clone().date(1)
    const prevMonth = firstOfMonth.clone().month(firstOfMonth.month() - 1)
    const lastDayInMonth = date.clone().date(firstOfMonth.daysInMonth() - 1)
    const firstDayOfWeek = firstOfMonth.day()
    const lastDayOfWeek = lastDayInMonth.day()

    var daysInPrevMonth = prevMonth.daysInMonth()

    var dayArr = []
    var prevArr = []
    var postArr = []
    for (var j = 0; j < firstDayOfWeek; j++) {
      prevArr.unshift(daysInPrevMonth)
      daysInPrevMonth--
    }
    for (var i = 1; i <= date.daysInMonth(); i++) {
      dayArr.push(i)
    }
    var dayDiff = 7 - lastDayOfWeek - 1
    if (lastDayOfWeek == 6) {
      dayDiff = 7
    }
    if (lastDayOfWeek == 5) {
      dayDiff = 9
    }
    for (var k = 1; k < dayDiff; k++) {
      postArr.push(k)
    }
    return { pre: prevArr, days: dayArr, post: postArr }
  }
  const { pre, days, post } = generateDays(date)
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-center gap-x-4 items-center">
        <CBButton
          type="qt"
          onClick={(e) => {
            setDate(date.clone().month(date.month() - 1))
          }}
        >
          {'<'}
        </CBButton>
        <span> {date.format('LL')}</span>
        <CBButton
          onClick={(e) => {
            setDate(date.clone().month(date.month() + 1))
          }}
          type="qt"
        >
          {'>'}
        </CBButton>
      </div>
      {/* <div>
        {firstSelect != null ? `Start: ${firstSelect.format('LL')}` : ''}{' '}
        {secondSelect != null ? ` End: ${secondSelect.format('LL')}` : ''}
      </div> */}
      <div className="grid grid-cols-7 gap-1 content-center w-1/2">
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((e) => {
          return <div className="text-neutral_4 p-1 text-center">{e}</div>
        })}
      </div>
      <div className="grid grid-cols-7 gap-1 w-1/2">
        {pre.map((e) => {
          return (
            <div
              onClick={(q) => {
                setDayOfMonth(e)
                setDate(
                  date
                    .clone()
                    .month(date.month() - 1)
                    .date(e)
                )
              }}
              className={`text-grey text-center p-1 m-1`}
            >
              {e}
            </div>
          )
        })}
        {days.map((e) => {
          const cellDate = date.clone().date(e)
          return (
            <div
              onClick={(q) => {
                setDayOfMonth(e)
                setDate(cellDate)
                onSelect(cellDate)
                if (!singleSelect) {
                  if (secondSelect != null) {
                    setFirstSelect(cellDate)
                    setSecondSelect(null)
                  } else if (
                    firstSelect != null &&
                    (cellDate.isSame(firstSelect) ||
                      cellDate.isAfter(firstSelect))
                  ) {
                    setSecondSelect(cellDate)
                  } else if (firstSelect == null) {
                    setFirstS
                  }
                  elect(cellDate)
                }
              }}
              className={`
              ${
                singleSelect && cellDate.isSame(date)
                  ? 'bg-neutral_4 text-white hover:bg-neutral_4'
                  : ''
              }

              ${
                cellDate.isSame(firstSelect) || cellDate.isSame(secondSelect)
                  ? 'bg-neutral_4 text-white'
                  : ''
              } ${
                cellDate.isAfter(firstSelect) && cellDate.isBefore(secondSelect)
                  ? 'bg-neutral_2'
                  : ''
              } bg-neutral_1 hover:bg-neutral_2 text-neutral_4 text-center p-1 m-1 ${
                dayOfMonth == e ? 'bg-neutral_2 text-white' : ''
              } 
              ${
                excludedDays.includes(date.format('YYYY-MM-DD')) && 'text-gray'
              }`}
            >
              {e}
            </div>
          )
        })}{' '}
        {post.map((e) => {
          return (
            <div
              onClick={(q) => {
                setDayOfMonth(e)
                setDate(
                  date
                    .clone()
                    .month(date.month() + 1)
                    .date(e)
                )
              }}
              className={`text-grey text-center p-1 m-1`}
            >
              {e}
            </div>
          )
        })}
      </div>
    </div>
  )
}
const DropDownTest = () => {
  const [open, setOpen] = useState(false)
  const [selectedText, setSelectedText] = useState('')

  return (
    <div className="flex flex-col w-32">
      <div
        id="toggle"
        className="flex flex-col bg-neutral_1 text-neutral_4 p-3 shadow-inner"
        onClick={(e) => {
          setOpen(!open)
        }}
      >
        {' '}
        {selectedText != '' ? (
          selectedText
        ) : (
          <div className="text-neutral_2">Select an Item</div>
        )}
      </div>

      {open ? (
        <div id="items" className="absolute bg-neutral_1 overflow-auto">
          <div
            onClick={(e) => {
              setSelectedText('Item aaa')
              setOpen(false)
            }}
            className="hover:bg-neutral_2 p-3 cursor-pointer block flex-grow relative w-32"
          >
            Item aaa
          </div>
          <div
            onClick={(e) => {
              setSelectedText('Item 2')
              setOpen(false)
            }}
            className="hover:bg-neutral_2 p-3 cursor-pointer block flex-grow w-32"
          >
            Item qqqq
          </div>
          <div
            onClick={(e) => {
              setSelectedText('Item 3')
              setOpen(false)
            }}
            className="hover:bg-neutral_2 p-3 cursor-pointer block w-32"
          >
            Item 3
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const HomePage = () => {
  const [schedules] = useGlobalState('schedules')
  const [accounts] = useGlobalState('accounts')
  const [syncs] = useGlobalState('syncs')
  const [license] = useGlobalState('license')

  const [bgError] = useGlobalState('errors')
  const [hasErrors, setHasErrors] = useState(false)
  const [hasSyncErrors, setHasSyncErrors] = useState(false)
  const [hasAccountErrors, setHasAccountErrors] = useState(false)
  const [vally, setVally] = useState('testing')
  const [shrink, setShrink] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    var hasAccountErrors =
      accounts.filter((account) => account.errorState != '').length > 0
    var hasSyncErrors =
      syncs.filter(
        (sync) => sync.status == 'synced_error' && !sync.errorState.includes('null') 
      ).length > 0
    setHasAccountErrors(hasAccountErrors)
    setHasSyncErrors(hasSyncErrors)
    setHasErrors(hasAccountErrors || hasSyncErrors)
  }, [accounts, syncs])

  function onSelect(selectedList, selectedItem) {}

  function onRemove(selectedList, removedItem) {}

  return (
    <div className="cb-content-frame">
      <SEO title="Dashboard Home" />
      <div className="cb-content-pane mobile_padding min-h-screen">
        <div className="mb-6">
          <CBHeader
            label="Home"
            icon={<HomeIcon></HomeIcon>}
            buttonLabel={'Help'}
            buttonIcon={<Info></Info>}
            buttonAction={(e) => {
              window.open('https://help.calendarbridge.com/', '_blank')
            }}
          ></CBHeader>
        </div>
        {/* <CBCalendar excludedDays={["2021-09-15"]}></CBCalendar> */}
        {/* <MultiDropDownTest values={momentTz.tz.names().map((tz) => tz)}></MultiDropDownTest> */}
        {hasErrors && (
          <div className="mb-8">
            <CBHeader label="Issues" icon={<Info></Info>}></CBHeader>
            {hasAccountErrors && (
              <>
                <div
                  className="main-grid-item hover:bg-neutral_1 mb-2 mt-2"
                  onClick={(e) => navigate('/dashboard/accounts/')}
                >
                  <div className="t3 flex flex-row gap-4 text-red-600">
                    <Connections></Connections>Account Issue
                  </div>
                </div>
              </>
            )}
            {hasSyncErrors && (
              <div
                className="main-grid-item hover:bg-neutral_1 mb-2"
                onClick={(e) => navigate('/dashboard/syncs/')}
              >
                <div className="t3 flex flex-row gap-4 text-red-600">
                  <SyncIcon></SyncIcon>Sync Issue
                </div>
              </div>
            )}
          </div>
        )}
        <div style={{ margin: '0 auto', paddingBottom: 16 }}>
          {license.orgAdmin ? (
            <div className="mb-2">
              <CBListItem
                borderLight={true}
                icon={<img src={Carat} style={{ float: 'right' }}></img>}
                onClick={(e) => navigate('/dashboard/organization')}
              >
                Manage Organization
              </CBListItem>
            </div>
          ) : (
            <></>
          )}
          {license.userId != null && accounts && accounts.length == 0 ? (
            <div>
              <div className="text-lg mb-2 text-center font-sarabun text-neutral_4">Start Here</div>
            <div
              className="main-grid-item hover:bg-neutral_1"
              onClick={(e) => navigate('/dashboard/accounts/new')}
            >
              
              <div className="t3 flex flex-row gap-4">
                <Connections></Connections> Add Calendars
              </div>
            </div>
            </div>
          ) : license.userId == null ? (
            <div style={{ minHeight: 400 }} className="main-grid-item">
              {bgError && bgError != '' ? (
                <div className="flex flex-col justify-center items-center gap-y-8 text-lg">
                  <div>There was an issue loading your account</div>
                  <CBButton
                    onClick={(e) => {
                      if (window) {
                        
                        navigate('/dashboard/')
                      }
                    }}
                    className="cusor-pointer"
                  >
                    Retry
                  </CBButton>
                  <div>or</div>
                  <CBButton
                    onClick={(e) => {
                      if (window) {
                        navigate('/dashboard/logout')
                      }
                    }}
                    className="cusor-pointer"
                  >
                    Log Out And Sign In Again
                  </CBButton>
                  {/* <div>
                    If the issue persists please{' '}
                    <a
                      target="_blank"
                      href="https://help.calendarbridge.com/request-support/"
                    >
                      contact support
                    </a>
                  </div> */}
                </div>
              ) : (
                <Spin
                  indicator={
                    <Loading3QuartersOutlined
                      spin
                      style={{ fontSize: '100px' }}
                    />
                  }
                />
              )}
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-2">
              <div
                className="main-grid-item hover:bg-neutral_1"
                onClick={(e) => navigate('/dashboard/accounts/new')}
              >
                <div className="t3 flex flex-row gap-4">
                  <Connections></Connections> Add Calendars
                </div>
              </div>

              <div
                onClick={(e) => navigate('/dashboard/syncs/new')}
                className="main-grid-item hover:bg-neutral_1"
              >
                <div className="t3 flex flex-row gap-4">
                  <NewSync></NewSync> Create Sync
                </div>
              </div>

              {true && (
                <div
                  className="main-grid-item hover:bg-neutral_1"
                  onClick={(e) => navigate('/dashboard/group/new')}
                >
                  <div className="t3 flex flex-row gap-4">
                    {' '}
                    <NewSchedule></NewSchedule>Create Booking
                  </div>
                </div>
              )}

              <div
                className="main-grid-item hover:bg-neutral_1"
                onClick={(e) => navigate('/dashboard/schedules/new')}
              >
                <div className="t3 flex flex-row gap-4 ">
                  {' '}
                  <NewBooking></NewBooking> Create Scheduler
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <CBButton  onClick={e=>{
       navigate("/dashboard/accounts/new/")
     }} long={true} type="primary" icon={<Add style={{float:'right'}}></Add>}>Connect Calendar</CBButton> */}
        <div className="h-4"></div>
        {/* <CBCard style={{ borderWidth: '0px',marginBottom:48}} title="Syncing" icon={<img style={{marginBottom:4, marginRight:4}} src={SyncIcon} ></img>} rightAction="New Sync" footerContent={<div>Sync your calendars</div>} >
      </CBCard>  
    
      <CBCard style={{ borderWidth: '0px',marginBottom:48}} title="Scheduling" icon={<img style={{marginBottom:4, marginRight:4}} src={ScheduleIcon} ></img>} rightAction="New Invitation" footerContent={<div>Schedule a meeting</div>} >
      </CBCard>  
      <CBCard style={{ borderWidth: '0px',marginBottom:48}} title="Booking" icon={<BookingIcon style={{marginRight: 8, verticalAlign: 'middle'}}/>} rightAction="New Scheduler" footerContent={<div>Create a scheduling link</div>} >
      </CBCard>   */}
        {license.userId != null &&
          !license.trial &&
          license.referralCode != '' && (
            <>
              {/* <div className="flex flex-col items-center gap-1 justify-center p-4 border-2 border-neutral_1 rounded-sm mb-4 text-center">
                <Title level={3} style={{ display: 'inline' }}>
                  Give CalendarBridge. Get CalendarBridge.
                </Title>
                <GiveGet className="md:pl-36 md:pr-36"></GiveGet>
                <div>
                  When someone signs up with your code, they get a free month
                  and you get a free month. <br /> Your code:
                </div>{' '}
                <Title level={5} copyable style={{ display: 'inline' }}>
                  {license.referralCode}
                </Title>{' '}
                <div>
                  <a href="dashboard/referrals">View Referral Program</a>
                </div>
              </div> */}
              <div className="flex flex-col items-center gap-1 justify-center p-4 border rounded-sm mb-4 text-center">
                <div className="font-sarabun text-lg text-neutral_4 mb-4">
                  Love CalendarBridge? 😍<br />Help spread the word and get up to $15 in credit<sup>1</sup>.
                </div>
                <div class="sharing-buttons grid grid-cols-1 md:grid-cols-3 ">
                  <a
                    class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-1 px-2 text-sm rounded text-brand_dark_blue border- border-brand_blue bg-white hover:bg-blue-700 hover:border-brand_blue"
                    target="_blank"
                    rel="noopener"
                    href={
                      'https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fhelp.calendarbridge.com%2Fshare%2F' +
                      license.referralCode
                    }
                    aria-label="Share on Linkedin"
                  >
                    <svg
                      aria-hidden="true"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="w-4 h-4"
                    >
                      <title>Linkedin</title>
                      <path d="M136 183v283H42V183h94zm6-88c1 27-20 49-53 49-32 0-52-22-52-49 0-28 21-49 53-49s52 21 52 49zm333 208v163h-94V314c0-38-13-64-47-64-26 0-42 18-49 35-2 6-3 14-3 23v158h-94V183h94v41c12-20 34-48 85-48 62 0 108 41 108 127z"></path>
                    </svg>
                    <span class="ml-1">Share on Linkedin</span>
                  </a>
                  
                  
                  <a
                    class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-1 px-2 text-sm rounded text-brand_dark_blue border- border-brand_blue bg-white hover:bg-blue-700 hover:border-brand_blue"
                    target="_blank"
                    rel="noopener"
                    href={
                      'https://twitter.com/intent/tweet?text=If%20you%20have%20calendars%20across%20multiple%20email%20addresses,%20you%20should%20check%20out%20CalendarBridge.com.%0AFinally%20an%20easy%20way%20to%20keep%20my%20availability%20accurate%20on%20all%20my%20calendars!%0A&url=https://help.calendarbridge.com/share/' +
                      license.referralCode
                    }
                    aria-label="Share on Twitter"
                  >
                    <svg
                      aria-hidden="true"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="w-4 h-4"
                    >
                      <title>Twitter</title>
                      <path d="m459 152 1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 0 0 156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0 1 36 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 0 1 181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z"></path>
                    </svg>
                    <span class="ml-1">Share on Twitter</span>
                  </a>

                  
                  <a
                    class="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-1 px-2 text-sm rounded text-brand_dark_blue border- border-brand_blue bg-white hover:bg-blue-700 hover:border-brand_blue"
                    target="_blank"
                    rel="noopener"
                    href={
                      'https://facebook.com/sharer/sharer.php?u=https://help.calendarbridge.com/share/' +
                      license.referralCode
                    }
                    aria-label="Share on Facebook"
                  >
                    <svg
                      aria-hidden="true"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="w-4 h-4"
                    >
                      <title>Facebook</title>
                      <path d="M379 22v75h-44c-36 0-42 17-42 41v54h84l-12 85h-72v217h-88V277h-72v-85h72v-62c0-72 45-112 109-112 31 0 58 3 65 4z"></path>
                    </svg>
                    <span class="ml-1">Share on Facebook</span>
                  </a>
                </div>
                <div className='grid-cols-1'>
                <div className="mb-2">
                  Include your referral code in your post to get and give free CalendarBridge<sup>2</sup>. <br /> Your code:
                </div>{' '}
                <Title level={5} copyable style={{ display: 'inline' }}>
                  {license.referralCode}
                </Title>{' '}
                <div>
                  <br /><sup>1</sup><a href="https://help.calendarbridge.com/get-5-for-sharing-calendarbridge/">Social Sharing Terms & Conditions</a>
                </div>
                <div><sup>2</sup><a href="dashboard/referrals">Referral Program Terms & Conditions</a></div>
                </div>
              </div>
            </>
          )}
        <div
          style={{
            color: '#49515C',
            fontFamily: 'Sarabun',
            marginBottom: '8px',
          }}
        >
          Settings
        </div>
        <CBList borderLight={true}>
          <CBListItem
            borderLight={true}
            style={{ marginBottom: 8 }}
            onClick={(e) => {
              navigate('/dashboard/accounts/')
            }}
            type="list"
            icon={<img src={Carat} style={{ float: 'right' }}></img>}
          >
            Manage Accounts
          </CBListItem>
          <CBListItem
            borderLight={true}
            style={{ marginBottom: 8 }}
            onClick={(e) => {
              navigate('/dashboard/settings/')
            }}
            type="list"
            icon={<img src={Carat} style={{ float: 'right' }}></img>}
          >
            Manage Billing
          </CBListItem>
          {license.orgId == 'single' ? (
            <CBListItem
              borderLight={true}
              icon={<img src={Carat} style={{ float: 'right' }}></img>}
              onClick={(e) => navigate('/dashboard/organization/new')}
            >
              Create Organization Group Account
            </CBListItem>
          ) : (
            <></>
          )}
          <CBListItem
            borderLight={true}
            style={{ marginBottom: 8 }}
            onClick={(e) => {
              navigate('/dashboard/logout/')
            }}
            type="list"
            icon={<img src={Carat} style={{ float: 'right' }}></img>}
          >
            Logout
          </CBListItem>
        </CBList>
      </div>
      {/* {license.orgAdmin && (<center><Button onClick={e => window.location="/dashboard/organization/"}>Manage your Organization</Button></center>)}
  {process.env.GATSBY_DEBUG_UI == "true" && <center><Button icon={<CalendarOutlined />} onClick={e => window.location="/dashboard/group/"}>Schedule A Meeting</Button></center>}  */}
      {/* <div className="grid lg:grid-cols-1 gap-4">
        <CBModal
          visible={showModal}
          onPositiveClick={(q) => setShowModal(false)}
          onCancelClick={(v) => setShowModal(false)}
        >
          
        </CBModal>
        <CBButton onClick={(e) => setShowModal(!showModal)}>
          Show Modal
        </CBButton>

        <CBColorPicker
          onColorChangeCallback={(color) => console.log(color)}
          type="google"
        ></CBColorPicker>
        <CBInput
          label="test label with error"
          error="Error: Bad Input"
        ></CBInput>
        <CBInput label="test label"></CBInput>
        <CBInput label="Disabled " disabled={true}></CBInput>
       
        <DropDownTest></DropDownTest>
        <CBSelect labels={['1', '2', '3']} values={[0, 1, 2]}></CBSelect>

        <input
          className="font-sarabun bg-neutral_1 text_neutral_4 shadow-inner p-2"
          type="time"
        ></input>
        <select
          className="appearance-none text-Sarabun bg-neutral_1 shadow-inner pl-1 pr-1 pt-2 pb-2"
          onChange={(e) => console.log(e.target.value)}
        >
          <option
            className="appearance-none text-Sarabun pl-1 pr-1 pt-3 pb-3"
            value={1}
          >
            Test 1
          </option>
          <option className="text-Sarabun pl-1 pr-1 pt-3 pb-3" value={2}>
            Test 2
          </option>
          <option className="text-Sarabun pl-1 pr-1 pt-3 pb-3" value={3}>
            Test 3
          </option>
        </select>
        <div className="font-manrope text-burnt text-lg">Colors</div>
        <div className="grid lg:grid-cols-3 gap-2 w-6/12">
          {[
            'burnt',
            'yellow_highlight',
            'white',
            'blue_highlight',
            'darkblue_highlight',
            'white',
            'neutral_1',
            'neutral_2',
            'neutral_3',
            'neutral_4',
          ].map((e) => (
            <div className={`w-12 h-12 bg-${e} rounded-sm`}></div>
          ))}
        </div>
        <div className="rounded-full bg-burnt text-white h-8 w-8 m-2 shadow-circle border-2 border-burnt flex items-center justify-center">
          1
        </div>
        <div className="font-manrope text-burnt text-lg">Text </div>
        <div className="font-manrope text-neutral_4 text-lg">
          T1 Title Text{' '}
        </div>
        <div className="font-sarabun text-neutral_4 text-lg">
          T2 Header Text{' '}
        </div>
        <div className="font-manrope text-neutral_4 text-md">
          T3 Subheader Text{' '}
        </div>
        <div className="font-sarabun text-neutral_4 text-md">T4 Body Text</div>
        <div className="font-manrope text-burnt text-lg">Buttons</div>
        <div>
          <button className="font-manrope text-md bg-blue_highlight text-neutral_0 pt-1 pb-1 pl-4 pr-4 hover:bg-darkblue_highlight hover:border-darkblue_highlight rounded-sm shadow-button border-2 border-blue_highlight">
            Button
          </button>
        </div>
        <div>
          <button className="font-manrope bg-blue_highlight text-neutral_0 pt-1 pb-1 pl-4 pr-3 hover:bg-darkblue_highlight hover:border-darkblue_highlight rounded-sm shadow-button border-2 border-blue_highlight flex space-x-3">
            <div>Button</div>
            <Add className="h-6 w-6"></Add>
          </button>
        </div>
        <div>
          <button className="font-manrope bg-darkblue_highlight text-neutral_0 pt-1 pb-1 pl-4 pr-3 hover:bg-darkblue_highlight hover:border-darkblue_highlight rounded-sm shadow-button border-2 border-darkblue_highlight flex space-x-3">
            <div>Button</div>
            <Add className="h-6 w-6"></Add>
          </button>
        </div>
        <div>
          <button className="font-manrope bg-white text-neutral_4 pt-1 pb-1 pl-4 pr-3 hover:bg-neutral_2 hover:border-neutral_2 rounded-sm border-2 border-neutral flex space-x-3">
            <div>Button</div>
            <div>
              <AddDark className="text-neutral_0 h-5 w-5 fill-current"></AddDark>
            </div>
          </button>
        </div>
        <div>
          <button className="font-manrope bg-white text-neutral_4 pt-1 pb-1 pl-4 pr-3 hover:bg-neutral_2 hover:border-neutral_2 rounded-sm border-2 border-white flex space-x-3">
            <div>Button</div>
          </button>
        </div>
        <div>
          <button className="font-manrope bg-blue_highlight text-neutral_0 pt-1 pb-1 pl-4 pr-3 hover:bg-darkblue_highlight hover:border-darkblue_highlight rounded-sm shadow-button border-2 border-blue_highlight flex space-x-3 w-full">
            <div>Button</div>
            <div className="flex-grow"></div>
            <div className="justify-self-end">
              <Add className="h-6 w-6"></Add>
            </div>
          </button>
        </div>

        <div className="flex mr-4">
          {' '}
          <div className="flex-grow"></div>
          <button className="justify-self-end font-manrope bg-blue_highlight text-neutral_0 pt-1 pb-1 pl-4 pr-3 hover:bg-darkblue_highlight hover:border-darkblue_highlight rounded-sm shadow-button border-2 border-blue_highlight flex space-x-3">
            <div>Submit</div>
          </button>
        </div>
        <CBInput
          onChange={(e) => console.log('CHANGEOE ' + e)}
          hint="test holder"
        ></CBInput>

        <CBInput
          label="Test label"
          onChange={(e) => {
            console.log('CHANGEDOE ' + e)
            setVally(e)
          }}
          hint="test holder"
          value={vally}
        ></CBInput>
        <div>
          <div className="font-sarabun text-neutral_4 text-md mb-2">Label</div>
          <input
            className="font-sarabun bg-neutral pt-1 pb-1 pl-4 pr-4 rounded-sm shadow-inner_form placeholder-neutral_2"
            placeholder="Input"
          ></input>
        </div>
        <div className="font-manrope text-burnt text-lg">Complex</div>
        <div className="grid lg:grid-cols-2 gap-3">
          <div className="font-sarabun text-neutral_4 border-2 border-neutral_1 rounded-sm hover:border-neutral_2  pt-1 pb-1 pl-4 pr-4 flex space-x-3 items-center cursor-pointer">
            <div>Location</div>
            <div className="flex-grow"></div>
            <div className="justify-self-end">
              <Chip></Chip>
            </div>
          </div>
          <CBListItem icon={<Chip></Chip>}>List Item Component</CBListItem>

          <div></div>
          <div className="font-sarabun text-neutral_4 border-2 border-neutral_1 rounded-sm hover:border-neutral_2  pt-1 pb-1 pl-4 pr-4 flex space-x-3 items-center cursor-pointer">
            <div>Text Row Content</div>
            <div className="flex-grow"></div>
            <div className="flex items-center space-x-5 justify-self-end">
              <div classname="font-sarabun">Chip</div>
              <Chip></Chip>
            </div>
          </div>
          <div></div>
          <div
            className="main-grid-item"
            onClick={(e) => navigate('/dashboard/accounts/new')}
          >
            <div>
              <div>
                <AddAccount className="animate-pulse"></AddAccount>
              </div>{' '}
              <div class="t3">Add Calendars</div>
            </div>
          </div>
          <div></div>
        </div>

        <div className="font-sarabun text-neutral_4 border-2 border-neutral_1 rounded-sm hover:border-neutral_2  p-4 flex space-x-3 cursor-pointer">
          <div className="m-1">
            <Calendar></Calendar>
          </div>
          <div className="grid grid-cols-1">
            <div className="font-sarabun text-neutral_4">Primary text</div>
            <div className="font-sarabun text-neutral_3">Secondary text</div>
          </div>
          <div className="flex-grow"></div>
          <div className="flex space-x-5 justify-self-end m-1">
            <div className="font-sarabun">Chip</div>
            <More></More>
          </div>
        </div>

        <CBRadioGroup
          labels={['hi', 'bye']}
          values={['poop', 'butt']}
        ></CBRadioGroup>
        <CBCheckItem
          label="Toggle Item 123"
          onChange={(e) => console.log('CHECK ' + e)}
        ></CBCheckItem>
        <CBRadioGroupBig
          title="HELLO WORLD"
          labels={['hi', 'bye']}
          subLabels={['ding', 'dong']}
          values={['poop', 'butt']}
        ></CBRadioGroupBig>

        <div className="font-sarabun text-neutral_4 border-2 border-neutral_1 rounded-sm hover:border-neutral_2  p-4 flex space-x-3 cursor-pointer">
          <div className="m-2">
            <Check></Check>
          </div>
          <div className="grid grid-cols-1">
            <div className="font-sarabun text-neutral_4">Primary text</div>
            <div className="font-sarabun text-neutral_3">Secondary text</div>
          </div>
          <div className="flex-grow"></div>
          <div className="justify-self-end m-1">
            <Edit></Edit>
          </div>
        </div>

        <div className="font-sarabun bg-neutral_1 text-neutral_4 border-2 border-neutral_1 rounded-sm hover:border-neutral_2  p-4 flex space-x-3 cursor-pointer">
          <div>
            <div className="rounded-full bg-burnt text-white h-8 w-8 shadow-circle border-2 border-burnt flex items-center justify-center">
              1
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="font-sarabun text-neutral_4">Primary text</div>
            <div className="font-sarabun text-neutral_3">Secondary text</div>
          </div>
        </div>

        <CBSteps onPageChanged={(e) => console.log('CHANGE ' + e)}>
          <CBStep index={1}></CBStep>
          <CBStep index={2}></CBStep>
          <CBStep index={3}></CBStep>
        </CBSteps>

        <div>Mobile Steps</div>
        <CBSteps
          mobile={true}
          onPageChanged={(e) => console.log('CHANGE ' + e)}
        >
          <CBStep index={1}></CBStep>
          <CBStep index={2}></CBStep>
          <CBStep index={3}></CBStep>
        </CBSteps>
        <div className="font-manrope text-burnt text-lg">Headers</div>

        <CBHeader
          icon={<Calendar></Calendar>}
          label="Meeting Title"
          buttonLabel="Edit"
          buttonIcon={
            <Edit className="text-neutral_0 h-5 w-5 fill-current"></Edit>
          }
          buttonAction={(e) => alert('hi!')}
        ></CBHeader>

        <CBHeader
          subheader={true}
          icon={<Calendar></Calendar>}
          label="Sub Header"
          buttonLabel="Edit"
          buttonIcon={
            <Edit className="text-neutral_0 h-5 w-5 fill-current"></Edit>
          }
          buttonAction={(e) => alert('hi!')}
          secondButtonLabel="Delete"
          secondButtonIcon={<Trash></Trash>}
          secondButtonAction={(e) => alert('delete')}
        ></CBHeader>

        <CBHeader
          subheader={true}
          step={1}
          label="Step header"
          buttonLabel="Edit"
          buttonIcon={
            <Edit className="text-neutral_0 h-5 w-5 fill-current"></Edit>
          }
          buttonAction={(e) => alert('hi!')}
          secondButtonLabel="Delete"
          secondButtonIcon={<Trash></Trash>}
          secondButtonAction={(e) => alert('delete')}
        ></CBHeader>

        <div className="font-manrope text-burnt text-lg">Component Buttons</div>

        <div>
          <CBButton onClick={(e) => alert('hi')} icon={<Add></Add>}>
            Primary
          </CBButton>
        </div>
        <div>
          <CBButton
            onClick={(e) => alert('hi')}
            icon={<Add></Add>}
            disabled={true}
          >
            Primary Disabled{' '}
          </CBButton>
        </div>

        <div>
          <CBButton
            type="secondary"
            onClick={(e) => alert('hi')}
            icon={<Add></Add>}
          >
            Secondary
          </CBButton>
        </div>
        <div>
          <CBButton
            type="secondary"
            onClick={(e) => alert('hi')}
            icon={<Add></Add>}
            disabled={true}
          >
            Secondary Disabled{' '}
          </CBButton>
        </div>

        <div>
          <CBButton
            type="ternary"
            onClick={(e) => alert('hi')}
            icon={<AddDark></AddDark>}
          >
            Ternary
          </CBButton>
        </div>
        <div>
          <CBButton
            type="ternary"
            onClick={(e) => alert('hi')}
            icon={<AddDark></AddDark>}
            disabled={true}
          >
            Disabled Ternary
          </CBButton>
        </div>

        <div>
          <CBButton type="qt" onClick={(e) => alert('hi')}>
            Quad
          </CBButton>
        </div>
        <div>
          <CBButton type="qt" onClick={(e) => alert('hi')} disabled={true}>
            Disabled Quad
          </CBButton>
        </div>
        <div>
          <CBButton long={true} onClick={(e) => alert('hi')} icon={<Add></Add>}>
            Long
          </CBButton>
        </div>

        <div>
          <SubmitButton onClick={(e) => alert('hi')}>Submit</SubmitButton>
        </div>
      </div> */}
    </div>
  )
}

const styles = {
  copyBlock: {
    display: 'inline-block',
    padding: 10,
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    backgroundColor: '#fff',
    marginBottom: 20,
  },
}

export default HomePage
