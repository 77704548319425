import React, { useEffect, useState } from 'react'
import Title from 'antd/lib/typography/Title'
import { DeleteOutlined, QuestionCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { List, Card, Row, Col, Skeleton, Button, Popconfirm, message } from 'antd';
import { Link, navigate } from 'gatsby'
import { accountIconMap } from '../../../utils/customIcons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Text from 'antd/lib/typography/Text'
import { useGlobalState } from '../../../store'
import { colorOutlook,colorPositive } from '../../../utils/colors'
import { API} from 'aws-amplify'
import { loadAccounts } from '../../../utils/fetchData'

const AccountDetailPage = ({ id }) => {
  const [activeAccount, setActiveAccount] = useState(null)
  const [accounts, setAccounts] = useGlobalState('accounts')
  const [license] = useGlobalState('license')

  useEffect(() => {
    const account = accounts.filter(a => a.calendarAccountId === id)
    setActiveAccount(account[0])
  }, [id, accounts])

  const deleteAccount = () => {
    API.del('account', '/account?id=' + activeAccount.calendarAccountId, {
      body: {},
    })
      .then(async () => {
        const accounts = await loadAccounts()
        setAccounts(accounts)
        navigate('/dashboard/accounts')

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'dashboardEvent', 'category': 'userActivity', 'action': 'deleteAccount', 'label': license.userId })
        
      })
      .catch(error => {
        message.error(error.response.data.message)
        
      })
  }

  const redirectToService = async (redirectUri) => {
    window.location.href = redirectUri
  }

  const reauth = () => {
    API.del('account', '/account?unhook=true&id=' + activeAccount.calendarAccountId, {
      body: {},
    })
      .then(async () => {
        //const accounts = await loadAccounts()
        // console.log(activeAccount)
        if(activeAccount.calendarAccountType == "ms") {
          if(activeAccount.msType == "gcc") {
            redirectToService(
              `https://login.microsoftonline.us/common/oauth2/v2.0/authorize?prompt=login&client_id=${
                process.env.GATSBY_OAUTH_O365_CLIENT_GCC
              }&response_type=code&state=gcc&scope=offline_access+Calendars.ReadWrite+User.Read&redirect_uri=${
                window.location.origin + process.env.GATSBY_OAUTH_O365_URL
              }`
            )
          } else {
          redirectToService(
            `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?prompt=login&client_id=${
              process.env.GATSBY_OAUTH_O365_CLIENT
            }&response_type=code&scope=offline_access+Calendars.ReadWrite+User.Read&redirect_uri=${
              window.location.origin + process.env.GATSBY_OAUTH_O365_URL
            }`
          )
        }
        } else {
          redirectToService(
            `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
              process.env.GATSBY_OAUTH_GOOGLE_CLIENT
            }&redirect_uri=${
              window.location.origin + process.env.GATSBY_OAUTH_GOOGLE_URL
            }&response_type=code&prompt=login&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&access_type=offline`
          )
        }
       
      })
      .catch(error => {
        message.error(error.response.data.message)
         })
  }

  if (!activeAccount) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </>
    )
  }

  // console.log(activeAccount)

  return <>
    <Text
      style={{
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {activeAccount.calendars.length} Calendars
      <FontAwesomeIcon
        icon={accountIconMap[activeAccount.calendarAccountType].icon}
        size="3x"
        pull="right"
        color={accountIconMap[activeAccount.calendarAccountType].color}
      />
    </Text>
    <Title>Account Calendars</Title>
    <Title level={3} type="secondary" style={{ marginTop: 0 }}>
      {activeAccount.email}
    </Title>
    <List
      grid={{ gutter: 16, column: 1 }}
      style={{ marginTop: 20 }}
      dataSource={activeAccount.calendars}
      renderItem={item => (
        <List.Item>
          <Card>
            <Row>
              <Col span={12}>
                <Title level={4}>{item.name || item.summary}</Title>
              </Col>
              {/* <Col span={8}>{item.count} Future Events</Col> */}
              <Col span={12} style={{ textAlign: 'right' }}>
                {/* <Link to="/dashboard/syncs/new">
                  Setup a sync
                </Link> */}
              </Col>
            </Row>
          </Card>
        </List.Item>
      )}
    />
    <div style={{ textAlign: 'center' }}>
      
       <Button type="link" style={{ color: colorPositive }} onClick={reauth} icon={<RedoOutlined />}>
          Re-Authenticate Account
        </Button>
        
          <div>
          <h4>Still having problems? Follow instructions for disconnecting your account type <a href="https://help.calendarbridge.com/doc/account-management/">here</a></h4> 
          </div>
       
      <Popconfirm
        title="Are you sure you want to delete this account?"
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        okText="Delete"
        onConfirm={deleteAccount}
      >
        <Button type="link" style={{ color: colorOutlook }} icon={<DeleteOutlined />}>
          Delete Account
        </Button>
      </Popconfirm>
    </div>
  </>;
}

export default AccountDetailPage
