import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { DeleteOutlined } from '@ant-design/icons'
import {
  Card,
  Col,
  Row,
  Skeleton,
  Empty,
  Button,
  Modal,
  Input,
  Alert,
  Spin,
} from 'antd'
import Title from 'antd/lib/typography/Title'
import ScheduleItem from './ScheduleItem'
import VanityUrl from './VanityUrl'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import zoomImage from '../../../images/zoom.png'
import { AmplifyMessageMapEntries } from 'aws-amplify-react'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
} from '../../core/components/cbd'

import Info from '../../../images/svg/info.svg'

import Close from '../../../images/svg/x-close.svg'
import Add from '../../../images/svg/plus.svg'
import Scheduling from '../../../images/svg/logo-booking.svg'
import { Loading3QuartersOutlined } from '@ant-design/icons'

const ScheduleSelectList = () => {
  const [schedules] = useGlobalState('schedules')
  const [loadingCoreData] = useGlobalState('loadingCoreData')
  const [integrations] = useGlobalState('integrations')
  const [license] = useGlobalState('license')
  const [showConfig, setShowConfig] = useState(false)
  function getPlanCount() {
    if (license != null && license.planId.includes('admin')) {
      return 0
    } else
    if (license != null && license.planId.includes('pro')) {
      return 10
    } else return 5
  }
  function checkLicense() {
    return license.v3limit && schedules.length >= getPlanCount()
  }
  useEffect(() => {
    if (license && license.vanityAlias == '') {
      setShowConfig(true)
    }
  }, [license])

  // if (loadingCoreData) {
  //   return (
  //     <div style={{ minHeight:800, maxWidth: 800, margin: '0 auto' }}>
  //       <Card style={{ margin: '20px 0' }}>
  //         <Skeleton active paragraph={{ rows: 2 }} title={{ width: 700 }} />
  //       </Card>
  //       <Card style={{ margin: '20px 0' }}>
  //         <Skeleton active paragraph={{ rows: 2 }} title={{ width: 700 }} />
  //       </Card>
  //       <Card style={{ margin: '20px 0' }}>
  //         <Skeleton active paragraph={{ rows: 2 }} title={{ width: 700 }} />
  //       </Card>
  //     </div>
  //   )
  // }

  async function deleteIntegration(integration) {
    Modal.confirm({
      title: `Are you sure you want to remove your Zoom Account ${integration.email} ?`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        try {
          await API.del('group-bookings', '/integrations', {
            body: {
              code: integration.accountId,
            },
          })

          window.location.reload()
        } catch (ex) {
          console.log(ex)
        }
      },
    })
  }

  const renderCreate = () => (
    <div className="mt-8 mb-8">
      <CBButton
        icon={<Add></Add>}
        onClick={(e) => navigate('/dashboard/schedules/new')}
        long={true}
        disabled={schedules && schedules.length >= 10}
      >
        Create Scheduler
      </CBButton>
    </div>
  )

  const list = schedules
    .sort((a, b) => b.label - a.label)
    .map((schedule) => (
      <ScheduleItem
        key={schedule.id}
        item={schedule}
        showClone={schedules.length < 10}
      />
    ))
  const integration = integrations.map((zoom) => (
    <CBListItem
      border={false}
      icon={
        <CBButton
          type="qt"
          style={{ marginTop: 0, float: 'right' }}
          onClick={(e) => {
            deleteIntegration(zoom)
          }}
          icon={<Close></Close>}
        ></CBButton>
      }
    >
      <div className="flex flex-row items-center" key={zoom.accountId}>
        <img
          style={{ marginTop: 0, marginRight: 8, borderRadius: 50 }}
          width={'30px'}
          height={'30px'}
          src={zoomImage}
        />{' '}
        {zoom.email}{' '}
      </div>
    </CBListItem>
  ))

  if(license != null && license.planId && license.planId.includes('admin')) {
    return <div className="cb-content-pane mobile_padding min-h-screen text-2xl">
      Admin users cannot create schedulers
      </div>
  }
  return (
    <div className="cb-content-pane mobile_padding min-h-screen">
      <CBHeader
        label="Schedulers"
        icon={<Scheduling></Scheduling>}
        buttonLabel={'Help'}
        buttonIcon={<Info></Info>}
        buttonAction={(e) => {
          window.open(
            'https://help.calendarbridge.com/doc/getting-started/creating-a-scheduling-link/',
            '_blank'
          )
        }}
      ></CBHeader>
      <div>
        {renderCreate()}
        <a
          className="flex flex-row items-center gap-x-2 mb-2"
          onClick={(e) => {
            setShowConfig(!showConfig)
          }}
        >
          {showConfig ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          )}{' '}
          {showConfig ? 'Hide' : 'Configure'} custom url and video conference integrations
        </a>
        {showConfig ? (
          <div className="rounded-sm border-2 border-neutral_1 mt-2 mb-6 flex flex-col shadow-md p-2">
            <VanityUrl></VanityUrl>

            <CBHeader label={'Video Conference Integrations'} subheader={true}></CBHeader>

            {integration}

            <center>
              <a
              className="mb-4"
                href={
                  'https://zoom.us/oauth/authorize?response_type=code&client_id=' +
                  process.env.GATSBY_OAUTH_ZOOM_CLIENT +
                  '&redirect_uri=' +
                  encodeURIComponent(
                    window.location.origin + '/dashboard/zoom/'
                  )
                }
              >
                Add Zoom Account
              </a>
            </center>
          </div>
        ) : (
          <></>
        )}
      </div>

      {loadingCoreData && (
        <div className="v-full">
          <center className="mt-8">
            <Spin
              indicator={
                <Loading3QuartersOutlined spin style={{ fontSize: '100px' }} />
              }
            />
          </center>
        </div>
      )}
      {list}
      {!loadingCoreData && list.length == 0 && (
        <div className="p-4 padding bg-neutral_0 border text-center"
          
        >No Meeting Schedulers</div>
        
      )}
    </div>
  )
}

export default ScheduleSelectList
