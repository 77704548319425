import React, { useState } from 'react'
import BoomerScan from '../components/BoomerScan'
import OrgScreen from '../components/OrgScreen'
import { useGlobalState } from '../../../store'
import { Menu } from 'antd'

const AdminScanPage = () => {
  const [license] = useGlobalState('license')
  const [currentPage, setCurrentPage] = useState('scan')
  return (
    <>
      <div className="bg-white" style={{ background: 'white' }}>
        <div className="mobile_padding min-h-screen">
          {license &&
            license.email &&
            license.email.includes('@calendarbridge.com') && (
              <>
                <Menu
                  onClick={(e) => {
                    //(e)
                    setCurrentPage(e.key)
                  }}
                  defaultSelectedKeys={['scan']}
                  mode="horizontal"
                >
                  <Menu.Item key="scan">User Admin</Menu.Item>
                  <Menu.Item key="orgs">Org Admin</Menu.Item>
                </Menu>
                {currentPage == 'scan' && <BoomerScan />}
                {currentPage == 'orgs' && <OrgScreen />}
              </>
            )}
        </div>
      </div>
    </>
  )
}
export default AdminScanPage
