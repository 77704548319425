import React, { useCallback, useMemo, useState } from 'react'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { Checkbox } from 'antd'
import { CBListItem } from '../../../../../core/components/cbd'
import { useGroupScheduleContext } from '../../GroupScheduleContext'
import Icon from '../../../../../../images/cb_icon_orange.png' 
import Trash from '../../../../../../images/delete.png'

export const GuestListItem = ({ guest, contactsForSearch }) => {
  const { state, dispatch } = useGroupScheduleContext()

  const friends = useMemo(() => get(state, 'friends'), [state])
  const guests = useMemo(() => get(state, 'guests'), [state])
  const guestsToSave = useMemo(() => get(state, 'guestsToSave'), [state])

  const onRemoveGuest = useCallback(() => {
    dispatch({ type: 'setGuests', guests: filter(guests, (g) => g != guest) })
  }, [guest, guests])

  const onToggleContactSearch = useCallback(() => {
		// if found, remove
		// if new, add
    const newState = guestsToSave.includes(guest)
      ? filter(guestsToSave, (g) => g !== guest)
      : Array.from(new Set([...guestsToSave, guest]))

    dispatch({
      type: 'setGuestsToSave',
      guestsToSave: newState,
    })
  }, [guest, guestsToSave])

  return (
    <CBListItem
      borderLight
      multiclick
      onClick={onRemoveGuest}
      icon={<img src={Trash} style={{ float: 'right' }} />}
    >
      <div className="flex flex-row justify-start items-center gap-4">
        <div>{guest}</div>
        {friends && friends.includes(guest) && (
          <div>
            <img src={Icon} width="25px" height="25px" />
          </div>
        )}
        {!contactsForSearch.includes(guest) && (
          <Checkbox
            onChange={onToggleContactSearch}
            checked={guestsToSave.includes(guest)}
          >
            Add to contact search
          </Checkbox>
        )}
      </div>
    </CBListItem>
  )
}
