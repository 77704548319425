import React, { useEffect, useState } from 'react'
import Title from 'antd/lib/typography/Title'
import { Row, Col, Card, Button, Result, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faOutlook } from '../../../utils/customIcons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { colorOutlook, colorGoogle, colorExchange } from '../../../utils/colors'
import QueryString from 'query-string'
import { API, Auth } from 'aws-amplify'
import { navigate } from 'gatsby'
import {Cookies} from 'js-cookie'
import { useGlobalState } from '../../../store'
import { loadAccounts } from '../../../utils/fetchData'



const ZoomActivatePage = () => {
  

  return <div style={{ textAlign: 'center' }}>
    Zoom Account Deactivated
</div>
  
}


export default ZoomActivatePage
