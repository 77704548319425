import React from 'react'
import SyncSelectList from '../components/SyncSelectList'
import Title from 'antd/lib/typography/Title'
import Scheduling from "../../../images/logo-syncing.png";
import SEO from '../../../components/SEO'

const SyncListPage = () => (
  <div className="cb-content-frame h-full">
    <SEO title="Syncs Dashboard" />
    <div  className="cb-content-pane mobile_padding min-h-screen" >
    
  
    <SyncSelectList />
    </div>
  </div>
)

export default SyncListPage
