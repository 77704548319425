import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import {
  Card,
  Col,
  Row,
  Menu,
  Dropdown,
  Button,
  Modal,
  Tooltip,
  Alert,
} from 'antd'

import Add from '../../../images/svg/plus_dark.svg'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import { colorNeutralDark, colorNegative } from '../../../utils/colors'
import Paragraph from 'antd/lib/typography/Paragraph'
import { API } from 'aws-amplify'
import moment from 'moment'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { useGlobalState } from '../../../store'
import ColorPicker from '../../syncs/components/ColorPicker'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBColorPicker,
  CBModal,
  CBSelect,
  CBList,
} from '../../core/components/cbd'
import Book from '../../../images/svg/book.svg'

import More from '../../../images/svg/menu-more-horizontal.svg'

const ScheduleItem = ({ item, preview = false, showClone = true }) => {
  const [license] = useGlobalState('license')
  const [expanded, setExpanded] = useState(preview)
  function showDeleteConfirm() {
    Modal.confirm({
      title: 'Are you sure you want to delete this schedule?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        await API.del('schedules', '/schedules', {
          body: {
            id: item.id,
          },
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'deleteScheduler',
          label: license.userId,
          email: license.email,
        })

        window.location.reload()
      },
    })
  }

  const duplicate = async () => {
    await API.post('schedules', '/schedules?duplicate=true', {
      body: {
        id: item.id,
      },
    })

    window.location.reload()
  }
  const handleClick = (e) => {
    if (e === '0') {
      navigate(`/dashboard/schedules/${item.id}/edit`)
    } else if (e === '1') {
      showDeleteConfirm()
    } else if (e === '2') {
      duplicate()
    }
  }

  const menu = (
    <Menu onClick={handleClick} style={{ width: 125 }}>
      
      <Menu.Item key="0">
        <div className="flex gap-x-4 items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
          Edit
        </div>
      </Menu.Item>
      {showClone && (
        <Menu.Item key="2">
          <div className="flex gap-x-4 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
              />
            </svg>
            Clone
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="1">
        <div className="flex gap-x-4 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
          Delete
        </div>
      </Menu.Item>
    </Menu>
  )

  function renderMenu(){
    return <div className="flex flex-row gap-2">
      
      <div key="0" onClick={e=> handleClick("0")}className="menu-thing">
        <div className="flex gap-x-2 items-center">
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
          Edit
        </div>
      </div>
      {showClone && (
        <div key="2" onClick={e=> handleClick("2")} className="menu-thing">
          <div className="flex gap-x-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
              />
            </svg>
            Clone
          </div>
        </div>
      )}
      <div key="1" onClick={e=> handleClick("1")} className="menu-thing">
        <div className="flex gap-x-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
          Delete
        </div>
      </div>
    </div>
  }

  {
    /* <Select.Option value="7">1 week</Select.Option>
            <Select.Option value="14">2 weeks</Select.Option>
            <Select.Option value="21">3 weeks</Select.Option>
            <Select.Option value="30">1 month</Select.Option>
            <Select.Option value="60">2 months</Select.Option>
            <Select.Option value="90">3 months</Select.Option>
            <Select.Option value="120">4 months</Select.Option>
            <Select.Option value="150">5 months</Select.Option>
            <Select.Option value="180">6 months</Select.Option> */
  }
  function convertRang(string) {
    var str = ''

    switch (string) {
      case '7':
        str = '1 week'
        break
      case '14':
        str = '2 weeks'
        break
      case '21':
        str = '3 weeks'
        break
      case '30':
        str = '1 month'
        break
      case '60':
        str = '2 months'
        break
      case '90':
        str = '3 months'
        break
      case '120':
        str = '4 months'
        break
      case '150':
        str = '5 months'
        break
      case '180':
        str = '6 months'
        break
      default:
        break
    }
    return str
  }

  function convertToMinHourThing(string) {
    var str = ''

    switch (string) {
      case 0:
        str = '0 min'
        break
      case 5:
        str = '5 min'
        break
      case 10:
        str = '10 min'
        break
      case 15:
        str = '15 min'
        break
      case 30:
        str = '30 min'
        break
      case 45:
        str = '45 min'
        break
      case 60:
        str = '1 hour'
        break
      case 90:
        str = '90 minutes'
        break
      case 120:
        str = '2 hours'
        break
      case 180:
        str = '3 hours'
        break
      case 240:
        str = '4 hours'
        break
      case 480:
        str = '8 hours'
        break
      case 720:
        str = '12 hours'
        break
      case 1440:
        str = '1 day'
        break
      case 2880:
        str = '2 days'
        break
      case 4320:
        str = '3 days'
        break
      case 10080:
        str = '1 week'
        break
      default:
        break
    }
    return str
  }

  return (
    <>
      <div className="rounded-sm border border-neutral_1 mt-2 mb-12 flex flex-col shadow-md">
        <div className="flex flex-col pl-2 pr-2 lg:pl-4 lg:pr-4 pb-4">
          <div>
            <CBHeader
              label={
                <div>
                  {item.label != '' && item.label != null
                    ? item.label
                    : item.displayName}{' '}
                  {item.greeting && `- ${item.greeting}`}
                </div>
              }
              icon={<Book></Book>}
              menu={
                <></>
              }
            ></CBHeader>
          </div>
          <div className="flex flex-row items-center">
            <div>
              <div className="text-neutral_4">
                {}
                <div className="font-sarabun text-neutral_4 text-lg">
                  {item.ownerName} - {item.ownerEmail}
                </div>
                {preview && item.alias != '' && (
                  <div className="font-sarabun text-neutral_4 mt-2 mb-2 text-lg bg-white border-2 border-neutral_1">
                    {' '}
                    {`${process.env.GATSBY_BOOKING_LINK_URL}/book/${license.vanityAlias}/` +
                      (item.alias != '__me__' ? item.alias : '')}
                  </div>
                )}
                {!preview && (
                  <div>
                    {license.vanityAlias == '' && (
                      <>
                        <Text
                          copyable
                        >{`${process.env.GATSBY_BOOKING_LINK_URL}/book/${item.id}`}</Text>
                        <Text style={{ color: colorNeutralDark }}>
                          <Tooltip title="Open link">
                            {' '}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={`${window.location.origin}/book/${item.id}`}
                            >
                              <FontAwesomeIcon
                                style={{ marginLeft: '5px' }}
                                icon={faExternalLinkAlt}
                              />
                            </a>
                          </Tooltip>
                        </Text>
                      </>
                    )}
                    {license.vanityAlias != '' &&
                      (!item.alias || item.alias == '') && (
                        <>
                          <Text
                            copyable
                          >{`${process.env.GATSBY_BOOKING_LINK_URL}/book/${item.id}`}</Text>
                          <Text style={{ color: colorNeutralDark }}>
                            <Tooltip title="Open link">
                              {' '}
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`${process.env.GATSBY_BOOKING_LINK_URL}/book/${item.id}`}
                              >
                                <FontAwesomeIcon
                                  style={{ marginLeft: '5px' }}
                                  icon={faExternalLinkAlt}
                                />
                              </a>
                            </Tooltip>
                          </Text>
                        </>
                      )}
                    {license.vanityAlias != '' &&
                      !(!item.alias || item.alias == '') && (
                        <div>
                          <Text copyable>
                            {`${process.env.GATSBY_BOOKING_LINK_URL}/book/${license.vanityAlias}/` +
                              (item.alias != '__me__' ? item.alias : '')}
                          </Text>
                          <Text style={{ color: colorNeutralDark }}>
                            <Tooltip title="Open link">
                              {' '}
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={
                                  `${process.env.GATSBY_BOOKING_LINK_URL}/book/${license.vanityAlias}/` +
                                  (item.alias != '__me__' ? item.alias : '')
                                }
                              >
                                <FontAwesomeIcon
                                  style={{ marginLeft: '5px' }}
                                  icon={faExternalLinkAlt}
                                />
                              </a>
                            </Tooltip>
                          </Text>
                        </div>
                      )}
                  </div>
                )}

                {!preview &&
                  license.vanityAlias != '' &&
                  (!item.alias || item.alias == '') && (
                    <a
                      onClick={(e) =>
                        navigate(`/dashboard/schedules/${item.id}/edit`)
                      }
                    >
                      <Alert
                        style={{ marginTop: 8 }}
                        showIcon
                        type="info"
                        message="Configure this scheduler with your Custom Url"
                      ></Alert>
                    </a>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-neutral_1 p-1 lg:p-4 flex flex-col justify-center">
          {item.destinationColor != 'default' && (
            <CBListItem
              border={false}
              icon={
                <ColorPicker
                  type={item.type}
                  color={item.destinationColor}
                  preview={true}
                ></ColorPicker>
              }
            >
              Color
            </CBListItem>
          )}
          <CBListItem
            border={false}
            icon={
              <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                {item.meetingIncrement
                  .map((e) => parseInt(e))
                  .sort(function (a, b) {
                    return a - b
                  })
                  .map((e) => e.toString())
                  .join(',')}
              </div>
            }
          >
            Meeting length
          </CBListItem>
          {item.startCrement != 0 && (
            <CBListItem
              border={false}
              icon={
                <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                  {`${item.startCrement} min`}
                </div>
              }
            >
              Start Increment
            </CBListItem>
          )}
          {item.defaultTime && item.defaultTime != -1  && (
            <CBListItem
              border={false}
              icon={
                <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                  {`${item.defaultTime} min`}
                </div>
              }
            >
              Default Time
            </CBListItem>
          )}
          {expanded && (
            <>
              <CBListItem
                border={false}
                icon={
                  <div className="text-center lg:text-left lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                    {convertRang(item.dayRange)}
                  </div>
                }
              >
                Booking Window
              </CBListItem>
              <CBListItem
                border={false}
                icon={
                  <div className="text-center lg:text-left lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                    {item.militaryTime ? '24h' : '12h (am/pm)'}
                  </div>
                }
              >
                Time format
              </CBListItem>
              <CBListItem
                border={false}
                icon={
                  <div className="text-center lg:text-left lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                    {convertToMinHourThing(item.leadTime)},{' '}
                    {convertToMinHourThing(item.bufferBefore)},{' '}
                    {convertToMinHourThing(item.bufferAfter)}{' '}
                  </div>
                }
              >
                Lead time, before, after
              </CBListItem>

              <CBListItem
                border={false}
                icon={
                  <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                    {item.questions.length}
                  </div>
                }
              >
                Questions
              </CBListItem>
              <CBListItem
                border={false}
                icon={
                  <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                    {item.integrations.length == 0 && !item.meeting && 'None'}
                    {item.integrations.length > 0 && 'Zoom'}{' '}
                    {item.meeting && item.integrations.length > 0 && ' or '}{' '}
                    {item.meeting && (
                      <>{item.type == 'google' ? 'Google Meet' : 'MS Teams'}</>
                    )}
                  </div>
                }
              >
                Online Meeting
              </CBListItem>

              {item.questions.length > 0 && (
                <CBList>
                  {item.questions.map((e) => {
                    return <CBListItem>{e}</CBListItem>
                  })}
                </CBList>
              )}

              <CBListItem
                border={false}
                icon={
                  <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                    {item.others.length}
                  </div>
                }
              >
                Additional calendars
              </CBListItem>
              {item.additionalAttendees.length > 0 && (
                <CBListItem
                  border={false}
                  icon={
                    <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                      {item.additionalAttendees.split(',').length}
                    </div>
                  }
                >
                  Additional attendees
                </CBListItem>
              )}
              {item.timeMatrix['Mon'] && (
                <CBList>
                  {item.dayOptions.split(',').map((d) => {
                    var dayName = d.charAt(0).toUpperCase() + d.slice(1)
                    var times = item.timeMatrix[dayName]
                    if (times) {
                      return (
                        <CBListItem
                          label={dayName}
                          icon={
                            <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                              {moment({
                                hour: times.startHour,
                                minute: times.startMin,

                        // tz: item.tz
                              }).format('h:mm a')}{' '}
                              -{' '}
                              {moment({
                                hour: times.endHour,
                                minute: times.endMin,

                        // tz: item.tz
                              }).format('h:mm a')}
                            </div>
                          }
                        >
                          {dayName}
                        </CBListItem>
                      )
                    } else {
                      return <></>
                    }
                  })}
                </CBList>
              )}
              {!item.timeMatrix['Mon'] && (
                <CBListItem
                  icon={
                    <div className="lg:bg-white rounded-full pl-4 pr-4 pt-1 pb-1">
                      {' '}
                      {moment({
                        hour: item.startHour,
                        minute: item.startMin,
                        // tz: item.tz
                      }).format('h:mm a')}{' '}
                      to{' '}
                      {moment({
                        hour: item.endHour,
                        minute: item.endMin,
                        // tz: item.tz
                      }).format('h:mm a')}
                    </div>
                  }
                >
                  {item.dayOptions.split(',').map((d) => (
                    <span key={d}>
                      {d.charAt(0).toUpperCase() + d.slice(1)}{' '}
                    </span>
                  ))}
                </CBListItem>
              )}
            </>
          )}
          {!preview && (
            <CBListItem
              onClick={(e) => {
                setExpanded(!expanded)
              }}
              border={false}
              icon={
                <div className="lg:bg-white flex flex-row items-center justify-center gap-x-2 rounded-full pl-4 pr-4 pt-1 pb-1">
                  {!expanded ? 'More' :  'Less'}{expanded ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mt-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mt-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                )} 
                </div>
              }
            ></CBListItem>
          )}

          {/* <CBListItem
            icon={
              <div className="flex flex-row items-center space-x-4">
                {!preview && item.sourceColor != 'default' && (
                  <ColorPicker
                    type={item.sourceCalendarType}
                    color={item.sourceColor}
                    preview={true}
                  ></ColorPicker>
                )}
                <div className="rounded-full p-2 bg-white text-sm">
                  {item.email}
                </div>
              </div>
            }
          >
            {getTypeIcon(item.sourceCalendarType)} {item.sourceCalendarName}
          </CBListItem> */}

          {/* <CBListItem
            icon={
              <div className="flex flex-row items-center space-x-4">
                {!preview && item.destColor != 'default' && (
                  <ColorPicker
                    type={item.destCalendarType}
                    color={item.destinationColor}
                    preview={true}
                  ></ColorPicker>
                )}
                <div className="rounded-full p-2 bg-white text-sm">
                  {destAccount.email}
                </div>
              </div>
            }
          >
            {getTypeIcon(item.destCalendarType)}
            {item.destCalendarName}
          </CBListItem> */}
          
        </div>
      </div>
      {!preview && <div className="relative"><div className="absolute bottom-8 right-0">{renderMenu()}</div></div>}
    </>
  )
}

export default ScheduleItem
