import React, { useState, useEffect } from 'react'
import Title from 'antd/lib/typography/Title'
import { useGlobalState } from '../../../store'
import { API, Auth } from 'aws-amplify'
import {
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Calendar,
  Spin,
  Steps,
  Radio,
  Card,
} from 'antd'

import GoogleLogo from '../../../images/svg/google-logo.svg'
import { accountIconMap } from '../../../utils/customIcons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBTextArea,
  CBModal,
  CBList,
  CBCalendar,
} from '../../core/components/cbd'
import Connections from '../../../images/svg/connections.svg'
import momentTz from 'moment-timezone'
const { RangePicker } = DatePicker
const { Step } = Steps
import TextArea from 'antd/lib/input/TextArea'

import Close from "../../../images/svg/x-close.svg";
import { navigate } from '@reach/router'

const ActivateAccountPage = () => {
  const [accounts] = useGlobalState('accounts')
  function redirectToService(redirectUri) {
    window.location.href = redirectUri
  }

  async function syncOrgAccount(id, type) {
    try {
      var auth = await API.post('group-bookings', '/group-add?auth=true', {
        body: {
          code: 'nah',
          type,
          scope: 'org',
          state: id,
          redirect: 'no',
        },
      })
      navigate('/dashboard/group/new')
    } catch (ex) {
      console.log(ex)
    }
  }
  async function removeAccount(id) {
    try {
      //console.log(id)
      var auth = await API.del('group-bookings', '/group-bookings?auth=true', {
        body: {
          calendarAccountId: id,
        },
      })
      //console.log(auth)
      navigate('/dashboard/group/new')
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div className="cb-content-frame ">
      <div className="cb-content-pane" style={{ minHeight: 800 }}>
        <CBHeader
          label={<div>Contact Search</div>}
          icon={<Connections></Connections>}
        buttonLabel="Go Back"
        buttonIcon={<Close></Close>}
        buttonAction={e=> navigate('/dashboard/group/new')}></CBHeader>

        {accounts.map((account) => (
          <div
            className="p-3 flex flex-row items-center bg-neutral_1 m-2 rounded-sm"
            
          >
            {account.calendarAccountType == 'google' ? <GoogleLogo className="ml-1 mr-3"></GoogleLogo> : 
            <FontAwesomeIcon
              icon={accountIconMap[account.calendarAccountType].icon}
              size="2x"
              color={accountIconMap[account.calendarAccountType].color}
              style={{ marginTop: 5, marginRight: 10 }}
            />}
            <div>
            <b>{account.email}</b>
            {account.contact && <div>Contact Search Enabled</div>}
            </div>
            <div className="flex-grow flex flex-col justify-end items-end">
              <div className="flex flex-col justify-end flex-initial">
              {account.orgCreated && account.contact && (
                <div className="mb-2">
                  <CBButton
                    
                    onClick={(e) => {
                      syncOrgAccount(account.email, account.calendarAccountType)
                    }}
                  >
                    {account.contactSynced
                      ? 'Refresh Org Account'
                      : 'Enable Org Account'}{' '}
                  </CBButton>
                </div>
              )}

              {account.calendarAccountType == 'ms' && !account.orgCreated && (
                <div className="mb-2">
                  <CBButton
                    style={{ margin: 8 }}
                    onClick={(e) => {
                      redirectToService(
                        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?prompt=login&client_id=${
                          process.env.GATSBY_OAUTH_O365_CLIENT
                        }&response_type=code&scope=offline_access+Calendars.ReadWrite+User.Read+People.Read&state=${
                          account.email
                        }&redirect_uri=${
                          window.location.origin +
                          process.env.GATSBY_OAUTH_O365_URL_BOOK
                        }`
                      )
                    }}
                  >
                    {account.contact ? 'Refresh' : 'Enable'}{' '}
                  </CBButton>
                </div>
              )}

              {account.calendarAccountType == 'google' && !account.orgCreated && (
                <div className="mb-2">
                  <CBButton
                   type="primary"
                   disabled={true}
                    style={{ margin: 8 }}
                    onClick={(e) => {
                      redirectToService(
                        `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
                          process.env.GATSBY_OAUTH_GOOGLE_CLIENT
                        }&redirect_uri=${
                          window.location.origin +
                          process.env.GATSBY_OAUTH_O365_URL_BOOK
                        }&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcontacts.readonly&access_type=offline&state=` +
                          account.email
                      )
                    }}
                  >
                    {account.contact ? 'Refresh' : 'Coming After Beta Period'}
                  </CBButton>
                </div>
              )}
              {account.contact && account.contactSynced && (
                <div className="mb-2">
                <CBButton
                  type="secondary"
                  onClick={(e) => removeAccount(account.calendarAccountId)}
                >
                  De-Sync
                </CBButton>
                </div>
              )}
              {/* <div>
                {account.contactSynced && <>Currently Synced (debug)</>}
                {!account.contactSynced && <>Currently Not Synced (debug)</>}
              </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ActivateAccountPage
