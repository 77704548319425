import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Title from 'antd/lib/typography/Title'
import ScheduleImageUpload from '../components/ScheduleImageUpload'
import {
  Layout,
  Button,
  Select,
  Radio,
  Steps,
  Checkbox,
  TimePicker,
  Card,
  Input,
  Row,
  Collapse,
  Alert,
} from 'antd'
import { validateEmail } from '../../../utils/auth'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import reduce from 'lodash/reduce'
import momentTz from 'moment-timezone'
import { navigate } from '@reach/router'
const { Header, Footer, Sider, Content } = Layout
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBSelect,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBColorPicker,
  CBModal,
  CBTextArea,
  CBUrlField,
} from '../../core/components/cbd'

import Up from '../../../images/svg/arrow-up-chevron.svg'
import Trash from '../../../images/svg/trash-delete.svg'
import Edit from '../../../images/svg/pencil.svg'
import NewSchedule from '../../../images/svg/new-scheduler.svg'
import Paragraph from 'antd/lib/typography/Paragraph'
import ScheduleItem from '../components/ScheduleItem'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import TextArea from 'antd/lib/input/TextArea'
import ColorPicker from '../../syncs/components/ColorPicker'
import zoomImage from '../../../images/zoom.png'
import MediaQuery from 'react-responsive'
import SEO from '../../../components/SEO'

const { Panel } = Collapse
const { Step } = Steps
const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const defaultStartTime = moment('9:00:00', 'HH:mm:ss')
const defaultEndTime = moment('17:00:00', 'HH:mm:ss')

class ScheduleCreatePage extends React.Component {
  state = {
    page: 0,
    showModal: false,
    lastPage: 2,
    allCalendars: [],
    readTooCalendars: [],
    existingScheduleId: null,
    filteredDestinationCalendars: [],
    minArray: ['60', '30', '15'],
    schedule: {
      source: undefined,
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      meetingIncrement: ['60'],
      bodyTemplate: '',
      timezone: momentTz.tz.guess(),
      dayRange: '30',
      greeting: '',
      label: '',
      others: [],
      questions: [],
      iconImage: '',
      avatarImage: '',
      militaryTime: false,
      bufferBefore: 0,
      bufferAfter: 0,
      leadTime: 0,
      meeting: false,
      destinationColor: 'default',
      integrations: [],
      startTimeSun: defaultStartTime,
      endTimeSun: defaultEndTime,
      startTimeMon: defaultStartTime,
      endTimeMon: defaultEndTime,
      startTimeTue: defaultStartTime,
      endTimeTue: defaultEndTime,
      startTimeWed: defaultStartTime,
      endTimeWed: defaultEndTime,
      startTimeThu: defaultStartTime,
      endTimeThu: defaultEndTime,
      startTimeFri: defaultStartTime,
      endTimeFri: defaultEndTime,
      startTimeSat: defaultStartTime,
      endTimeSat: defaultEndTime,
      alias: '',
      customSubject: '{invitee_name} and {my_name} - {subject}',
      additionalAttendees: [],
      startCrement: 0,
    },
    isLoading: false,

    questionInputValue: '',
  }

  getButtonLabelByStep() {
    var label = ''
    switch (this.state.page) {
      case 0:
        // code block
        label = 'Continue to Availability'
        break
      case 1:
        // code block
        label = 'Continue to Event Details'
        break
      case 2:
        label = 'Review And Create'
        break
      default:

      // code block
    }
    return label
  }

  componentDidUpdate(prevProps, prevState) {
    // listen in on the source calendar changes
    if (prevState.schedule.source !== this.state.schedule.source) {
      const { allCalendars, readTooCalendars } = this.state

      // find the calendar via source index
      const foundCalendar = allCalendars[this.state.schedule.source] ?? false

      // filter out the source calendar
      const filteredDestinationCalendars =
        foundCalendar && readTooCalendars.length
          ? readTooCalendars.filter(
              (calendar) => calendar.id !== foundCalendar.id
            )
          : readTooCalendars

      // don't replace readTooCalendars to maintain source of truth
      this.setState({
        filteredDestinationCalendars,
      })
    }
  }

  componentDidMount() {
    var otherError = ''
    const readTooCalendars = this.props.accounts
      .reduce((all, a) => [...all, ...a.calendars], [])

      .filter((item, index, self) => {
        // Find the index of the current item in the array
        const currentIndex = self.findIndex(
          (innerItem) => innerItem.id === item.id
        )

        // If the current index is the same as the current loop index, it's a unique item
        return currentIndex === index
      })
      .filter((calendar) => {
        if (
          calendar.name == 'Birthdays' ||
          calendar.name == 'United States holidays' ||
          calendar.summary == 'Birthdays' ||
          calendar.summary == 'Holidays in United States'
        ) {
          return false
        } else {
          return true
        }
      })

    const allCalendars = this.props.accounts
      .reduce((all, a) => [...all, ...a.calendars], [])
      .filter((c, i) => c.canEdit)

    // this.setState({

    // })
    this.setState({
      allCalendars,
      readTooCalendars,
      filteredDestinationCalendars: readTooCalendars,
    })

    if (this.props.id) {
      const existingSchedule = this.props.schedules.find(
        (s) => s.id === this.props.id
      )
      const allCalendarIdx = allCalendars.findIndex(
        (c) => c.id === existingSchedule.calendarId
      )

      //figure out others
      var moreCals = []

      var filteredReadToo = readTooCalendars.filter(
        (cal) => cal.id != existingSchedule.calendarId
      )

      if (existingSchedule.others != null) {
        var indexes = existingSchedule.others.map((e) => {
          var idx = filteredReadToo.findIndex((c) => c.id === e.calendarId)

          return idx
        })

        moreCals = indexes
      }

      if (moreCals.includes(-1) || allCalendarIdx == -1) {
        otherError =
          'Missing calendar, please remove missing calendar or re-authenticate missing account. You will not be able to save until this is resolved.'
      }

      var timeMatrix = existingSchedule.timeMatrix
      const existingStartTime = moment({
        hour: existingSchedule.startHour,
        minute: existingSchedule.startMin,
      })
      const existingEndTime = moment({
        hour: existingSchedule.endHour,
        minute: existingSchedule.endMin,
      })
      var timeTable = {}
      if (timeMatrix) {
        daysOfWeek.forEach((day) => {
          const dayObj = timeMatrix[day]
          if (dayObj) {
            var start = moment({
              hour: dayObj.startHour,
              minute: dayObj.startMin,
            })
            var end = moment({ hour: dayObj.endHour, minute: dayObj.endMin })
            timeTable['startTime' + day] = start
            timeTable['endTime' + day] = end
          } else {
            timeTable['startTime' + day] = existingStartTime
            timeTable['endTime' + day] = existingEndTime
          }
        })
      }
      const schedule = {
        source: allCalendarIdx,
        startTime: existingStartTime,
        endTime: existingEndTime,
        days:
          existingSchedule.dayOptions == ''
            ? []
            : existingSchedule.dayOptions
                .split(',')
                .map((d) => d[0].toUpperCase() + d.slice(1)),
        meetingIncrement: existingSchedule.meetingIncrement,
        bodyTemplate: existingSchedule.bodyTemplate.replace(
          /\<br\/\>/g,
          '\r\n'
        ),
        timezone: existingSchedule.tz,
        dayRange: existingSchedule.dayRange,
        others: moreCals,
        greeting:
          existingSchedule.greeting != ''
            ? existingSchedule.greeting
            : `Find a time to meet ${existingSchedule.ownerName}`,
        label:
          existingSchedule.label != ''
            ? existingSchedule.label
            : existingSchedule.displayName,
        questions:
          existingSchedule.questions != null ? existingSchedule.questions : [],
        bufferBefore: existingSchedule.bufferBefore,
        bufferAfter: existingSchedule.bufferAfter,
        leadTime: existingSchedule.leadTime,
        meeting: existingSchedule.meeting,
        destinationColor:
          existingSchedule.destinationColor != 'default'
            ? existingSchedule.destinationColor
            : 'default',
        integrations: existingSchedule.integrations,
        //convert day matrix o_O
        ...timeTable,
        alias: existingSchedule.alias || '',
        iconImage: existingSchedule.iconImage,
        avatarImage: existingSchedule.avatarImage,
        militaryTime: existingSchedule.militaryTime,
        customSubject: existingSchedule.customSubject,
        additionalAttendees:
          existingSchedule.additionalAttendees === ''
            ? []
            : existingSchedule.additionalAttendees.split(','),
        startCrement: existingSchedule.startCrement,
        defaultTime: existingSchedule.defaultTime,
      }

      var minArray = this.state.minArray
      console.log('MIN ARRAY:' + minArray)
      var additional = existingSchedule.meetingIncrement.filter(
        (e) => (e != '60') & (e != '30') && e != '15'
      )
      // minArray = minArray.push(additional)
      minArray.push.apply(minArray, additional)
      this.setState({
        minArray: minArray
          .map((e) => parseInt(e, 10))
          .sort(function (a, b) {
            return a - b
          })
          .map((e) => e.toString()),
        existingScheduleId: this.props.id,
        schedule,

        otherError,
      })
    }
  }

  createFauxItem = () => {
    const {
      source,
      startTime,
      endTime,
      days,
      meetingIncrement,
      bodyTemplate,
      timezone,
      destinationColor,
      dayRange,
      others,
      greeting,
      label,
      questions,
      bufferBefore,
      bufferAfter,
      leadTime,
      meeting,
      integrations,
      startTimeSun,
      endTimeSun,
      startTimeMon,
      endTimeMon,
      startTimeTue,
      endTimeTue,
      startTimeWed,
      endTimeWed,
      startTimeThu,
      endTimeThu,
      startTimeFri,
      endTimeFri,
      startTimeSat,
      endTimeSat,
      alias,
      iconImage,
      avatarImage,
      militaryTime,
      additionalAttendees,
      startCrement,
      defaultTime,
    } = this.state.schedule
    const { allCalendars, filteredDestinationCalendars } = this.state

    const sourceCal = allCalendars[+source]

    const moreCals = others.map((e) => {
      var cal = filteredDestinationCalendars[e]
      return {
        id: cal.id,
        calendarAccountId: cal.calendarAccountId,
        type: cal.calendarAccountType,
      }
    })
    // startHourMon: startTimeMon.get('hour'),
    //   endHourMon: endTimeMon.get('hour'),
    //   startMinMon: startTimeMon.get('minute'),
    //   endMinMon: endTimeMon.get('minute'),
    var timeMatrix = {
      Mon: {
        startHour: startTimeMon.get('hour'),
        endHour: endTimeMon.get('hour'),
        startMin: startTimeMon.get('minute'),
        endMin: endTimeMon.get('minute'),
      },
      Tue: {
        startHour: startTimeTue.get('hour'),
        endHour: endTimeTue.get('hour'),
        startMin: startTimeTue.get('minute'),
        endMin: endTimeTue.get('minute'),
      },
      Wed: {
        startHour: startTimeWed.get('hour'),
        endHour: endTimeWed.get('hour'),
        startMin: startTimeWed.get('minute'),
        endMin: endTimeWed.get('minute'),
      },
      Thu: {
        startHour: startTimeThu.get('hour'),
        endHour: endTimeThu.get('hour'),
        startMin: startTimeThu.get('minute'),
        endMin: endTimeThu.get('minute'),
      },
      Fri: {
        startHour: startTimeFri.get('hour'),
        endHour: endTimeFri.get('hour'),
        startMin: startTimeFri.get('minute'),
        endMin: endTimeFri.get('minute'),
      },
      Sat: {
        startHour: startTimeSat.get('hour'),
        endHour: endTimeSat.get('hour'),
        startMin: startTimeSat.get('minute'),
        endMin: endTimeSat.get('minute'),
      },
      Sun: {
        startHour: startTimeSun.get('hour'),
        endHour: endTimeSun.get('hour'),
        startMin: startTimeSun.get('minute'),
        endMin: endTimeSun.get('minute'),
      },
    }

    // console.log({
    //   id: this.props.id || undefined,
    //   sourceCalendarId: sourceCal.id,
    //   sourceAccountId: sourceCal.calendarAccountId,
    //   displayName: `${
    //     sourceCal.name || sourceCal.summary
    //   } - ${meetingIncrement} mins`,
    //   type: sourceCal.calendarAccountType,
    //   dayOptions: days.join(',').toLocaleLowerCase(),
    //   meetingIncrement,
    //   bodyTemplate: bodyTemplate.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
    //   startHour: startTime.get('hour'),
    //   endHour: endTime.get('hour'),
    //   startMin: startTime.get('minute'),
    //   endMin: endTime.get('minute'),
    //   tz: timezone,
    //   destinationColor: destinationColor ? destinationColor : 'default',
    //   dayRange: dayRange,
    //   others: moreCals,
    //   greeting,
    //   label,
    //   questions,
    //   bufferBefore,
    //   bufferAfter,
    //   leadTime,
    //   meeting: meeting,
    //   integrations,
    //   timeMatrix,
    //   alias,
    //   iconImage,
    // avatarImage,
    //   ownerName: sourceCal.name || sourceCal.summary,
    //   ownerEmail: sourceCal.email
    // })

    return {
      id: this.props.id || undefined,
      sourceCalendarId: sourceCal.id,
      sourceAccountId: sourceCal.calendarAccountId,
      type: sourceCal.calendarAccountType,
      displayName: `${
        sourceCal.name || sourceCal.summary
      } - ${meetingIncrement} mins`,
      dayOptions: this.getOrderedDayOptions(),
      meetingIncrement,
      bodyTemplate: bodyTemplate.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
      startHour: startTime.get('hour'),
      endHour: endTime.get('hour'),
      startMin: startTime.get('minute'),
      endMin: endTime.get('minute'),
      tz: timezone,
      destinationColor: destinationColor ? destinationColor : 'default',
      dayRange: dayRange,
      others: moreCals,
      greeting,
      label,
      questions,
      bufferBefore: parseInt(bufferBefore),
      bufferAfter: parseInt(bufferAfter),
      leadTime: parseInt(leadTime),
      meeting: meeting,
      integrations,
      timeMatrix,
      alias,
      iconImage,
      avatarImage,
      ownerName: sourceCal.name || sourceCal.summary,
      ownerEmail: sourceCal.email,
      militaryTime,
      additionalAttendees: additionalAttendees.join(','),
      startCrement: startCrement,
      defaultTime,
    }
  }

  getOrderedDayOptions = () => {
    const { days } = this.state.schedule
    const activeDays = reduce(
      daysOfWeek,
      (acc, day) => (days.includes(day) ? [...acc, day] : acc),
      []
    )
    return activeDays.join(',').toLocaleLowerCase()
  }

  submitSync = async (redirect = 'normal') => {
    const {
      source,
      startTime,
      endTime,
      days,
      meetingIncrement,
      bodyTemplate,
      timezone,
      destinationColor,
      dayRange,
      others,
      greeting,
      label,
      questions,
      bufferBefore,
      bufferAfter,
      leadTime,
      meeting,
      integrations,
      startTimeSun,
      endTimeSun,
      startTimeMon,
      endTimeMon,
      startTimeTue,
      endTimeTue,
      startTimeWed,
      endTimeWed,
      startTimeThu,
      endTimeThu,
      startTimeFri,
      endTimeFri,
      startTimeSat,
      endTimeSat,
      alias,
      iconImage,
      avatarImage,
      militaryTime,
      customSubject,
      additionalAttendees,
      startCrement,
      defaultTime,
    } = this.state.schedule
    if (meetingIncrement.length == 0) {
      this.setState({ isLoading: false })
      alert('At least ONE meeting length must be selected')
      return
    } else {
      const { allCalendars, filteredDestinationCalendars } = this.state

      const sourceCal = allCalendars[+source]

      const moreCals = others.map((e) => {
        var cal = filteredDestinationCalendars[e]
        return {
          id: cal.id,
          calendarAccountId: cal.calendarAccountId,
          type: cal.calendarAccountType,
        }
      })
      // startHourMon: startTimeMon.get('hour'),
      //   endHourMon: endTimeMon.get('hour'),
      //   startMinMon: startTimeMon.get('minute'),
      //   endMinMon: endTimeMon.get('minute'),
      var timeMatrix = {
        Mon: {
          startHour: startTimeMon.get('hour'),
          endHour: endTimeMon.get('hour'),
          startMin: startTimeMon.get('minute'),
          endMin: endTimeMon.get('minute'),
        },
        Tue: {
          startHour: startTimeTue.get('hour'),
          endHour: endTimeTue.get('hour'),
          startMin: startTimeTue.get('minute'),
          endMin: endTimeTue.get('minute'),
        },
        Wed: {
          startHour: startTimeWed.get('hour'),
          endHour: endTimeWed.get('hour'),
          startMin: startTimeWed.get('minute'),
          endMin: endTimeWed.get('minute'),
        },
        Thu: {
          startHour: startTimeThu.get('hour'),
          endHour: endTimeThu.get('hour'),
          startMin: startTimeThu.get('minute'),
          endMin: endTimeThu.get('minute'),
        },
        Fri: {
          startHour: startTimeFri.get('hour'),
          endHour: endTimeFri.get('hour'),
          startMin: startTimeFri.get('minute'),
          endMin: endTimeFri.get('minute'),
        },
        Sat: {
          startHour: startTimeSat.get('hour'),
          endHour: endTimeSat.get('hour'),
          startMin: startTimeSat.get('minute'),
          endMin: endTimeSat.get('minute'),
        },
        Sun: {
          startHour: startTimeSun.get('hour'),
          endHour: endTimeSun.get('hour'),
          startMin: startTimeSun.get('minute'),
          endMin: endTimeSun.get('minute'),
        },
      }

      // console.log({
      //   id: this.props.id || undefined,
      //   sourceCalendarId: sourceCal.id,
      //   sourceAccountId: sourceCal.calendarAccountId,
      //   displayName: `${
      //     sourceCal.name || sourceCal.summary
      //   } - ${meetingIncrement} mins`,
      //   type: sourceCal.calendarAccountType,
      //   dayOptions: days.join(',').toLocaleLowerCase(),
      //   meetingIncrement,
      //   bodyTemplate: bodyTemplate.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
      //   startHour: startTime.get('hour'),
      //   endHour: endTime.get('hour'),
      //   startMin: startTime.get('minute'),
      //   endMin: endTime.get('minute'),
      //   tz: timezone,
      //   destinationColor: destinationColor ? destinationColor : 'default',
      //   dayRange: dayRange,
      //   others: moreCals,
      //   greeting,
      //   label,
      //   questions,
      //   bufferBefore,
      //   bufferAfter,
      //   leadTime,
      //   meeting: meeting,
      //   integrations,
      //   timeMatrix,
      //   alias,
      // })

      await API.post('schedules', '/schedules', {
        body: {
          id: this.props.id || undefined,
          sourceCalendarId: sourceCal.id,
          sourceAccountId: sourceCal.calendarAccountId,
          displayName: `${
            sourceCal.name || sourceCal.summary
          } - ${meetingIncrement} mins`,
          type: sourceCal.calendarAccountType,
          dayOptions: days.join(',').toLocaleLowerCase(),
          meetingIncrement,
          bodyTemplate: bodyTemplate.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
          startHour: startTime.get('hour'),
          endHour: endTime.get('hour'),
          startMin: startTime.get('minute'),
          endMin: endTime.get('minute'),
          tz: timezone,
          destinationColor: destinationColor ? destinationColor : 'default',
          dayRange: dayRange,
          others: moreCals,
          greeting,
          label,
          questions,
          bufferBefore: parseInt(bufferBefore),
          bufferAfter: parseInt(bufferAfter),
          leadTime: parseInt(leadTime),
          meeting: meeting,
          integrations,
          timeMatrix,
          alias,
          iconImage,
          avatarImage,
          militaryTime,
          customSubject,
          additionalAttendees: additionalAttendees.join(','),
          startCrement,
          defaultTime,
        },
      })
      if (redirect != 'zoom') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'createScheduler',
          label: this.props.license.userId,
          email: this.props.license.email,
        })

        // navigate('/dashboard/schedules/')
        window.location.href = window.location.origin + '/dashboard/schedules/'
      } else {
        window.location =
          'https://zoom.us/oauth/authorize?response_type=code&client_id=' +
          process.env.GATSBY_OAUTH_ZOOM_CLIENT +
          '&redirect_uri=' +
          encodeURIComponent(window.location.origin + '/dashboard/zoom/')
      }
    }
  }

  nextPage = () => {
    const { page, lastPage } = this.state
    if (page === lastPage) {
      this.setState({ isLoading: false, showModal: true })

      return
    } else {
      this.setState({
        page: this.state.page + 1,
      })
      window.scrollTo(0, 0)
    }
  }

  changePage = (page) => {
    this.setState({ page })
  }

  changeScheduleValue = (field, value) => {
    //  console.log(field, value)
    var changeColor = false
    if (field == 'source') {
      changeColor = true
    }

    this.setState(
      {
        schedule: {
          ...this.state.schedule,
          [field]: value,
        },
      },
      () => {
        // console.log("CHANGE " + field)
        // console.log(this.state.schedule)
        if (
          this.state.existingScheduleId != null &&
          this.state.existingScheduleId != ''
        ) {
          this.setState({
            quickSave: true,
          })
        }
        if (changeColor) {
          this.changeScheduleValue('destinationColor', 'default')
        }

        if (field == 'meetingIncrement') {
          if (this.state.schedule.meetingIncrement.length != 0) {
            this.setState({ meetingError: '' })
          }
          if (this.state.schedule.meetingIncrement.length == 0) {
            this.changeScheduleValue('defaultTime', '-1')
            this.setState({ meetingError: 'Error' })
          } else if (
            this.state.schedule.meetingIncrement.indexOf(
              '' + this.state.schedule.defaultTime
            ) == -1
          ) {
            this.changeScheduleValue(
              'defaultTime',
              this.state.schedule.meetingIncrement[0]
            )
          }
        }
      }
    )
  }

  SourceForm = () => {
    return (
      <>
        <CBHeader
          step={1}
          label="Select Calendars"
          buttonLabel="Cancel scheduler"
          buttonIcon={<Edit></Edit>}
          buttonAction={(e) => {
            navigate('/dashboard/schedules/')
          }}
        ></CBHeader>
        {this.props.license.userId != null &&
          this.props.accounts.length == 0 && (
            <center className="mt-8 mb-8 text-lg">
              <a
                onClick={(e) => {
                  navigate('/dashboard/accounts/new')
                }}
              >
                Add calendar account to create a scheduler
              </a>
            </center>
          )}

        <CBHeader label="Select Booking Calendar" subHeader={true} />

        <div style={{}}>
          Select the calendar you would like to use for scheduling.
        </div>
        <div>
          <Select
            style={{ 'font-family': 'sarabun', width: '100%' }}
            className="bg-neutral_3"
            size="large"
            placeholder="Select Calendar"
            showSearch
            optionFilterProp="children"
            autoFocus
            onChange={(v) => {
              this.changeScheduleValue('source', v)
            }}
            value={this.state.schedule.source}
          >
            {this.state.schedule.source == -1 &&
              this.state.otherError &&
              this.state.otherError != '' && (
                <Select.Option
                  key={-1}
                  value={-1}
                  style={{ 'font-family': 'sarabun' }}
                >
                  {' '}
                  <strong>Missing Calendar</strong>{' '}
                </Select.Option>
              )}
            {this.state.allCalendars.map((c, i) => (
              <Select.Option
                key={i}
                value={i}
                style={{ 'font-family': 'sarabun' }}
              >
                <strong>{c.name || c.summary}</strong>{' '}
                {
                  this.props.accounts.filter(
                    (a) => a.calendarAccountId === c.calendarAccountId
                  )[0].email
                }
              </Select.Option>
            ))}
          </Select>
          {this.state.schedule.source == -1 &&
            this.state.otherError &&
            this.state.otherError != '' && (
              <Alert
                type="error"
                style={{ marginBottom: 10, marginTop: 10 }}
                message={'Error: ' + this.state.otherError}
              ></Alert>
            )}

          <div className="mb-4"></div>
          <CBListItem
            borderLight={true}
            onClick={(e) => {
              navigate('/dashboard/accounts/new')
            }}
          >
            Calendar not showing? You might need to{' '}
            <Link to="/dashboard/accounts/new">add another account</Link>.
          </CBListItem>
        </div>

        <div className="title_label">
          Check availability on up to 3 other calendars, optional.
        </div>

        <Select
          key="otherz"
          size="large"
          mode="multiple"
          placeholder="Select Calendar"
          showSearch
          optionFilterProp="children"
          style={{ width: '100%', marginTop: 16 }}
          onChange={(v) => {
            if (v.length < 4) {
              this.changeScheduleValue('others', v)
            }
          }}
          value={this.state.schedule.others}
        >
          {this.state.schedule.others.includes(-1) &&
            this.state.otherError &&
            this.state.otherError != '' && (
              <Select.Option key={-1} value={-1}>
                {' '}
                <strong>Missing Calendar</strong>{' '}
              </Select.Option>
            )}
          {this.state.filteredDestinationCalendars.map((c, i) => (
            <Select.Option key={i} value={i}>
              <strong>{c.name || c.summary}</strong>{' '}
              {
                this.props.accounts.filter(
                  (a) => a.calendarAccountId === c.calendarAccountId
                )[0].email
              }
            </Select.Option>
          ))}
        </Select>

        {this.state.schedule.others.includes(-1) &&
          this.state.otherError &&
          this.state.otherError != '' && (
            <Alert
              type="error"
              style={{ marginBottom: 10, marginTop: 10 }}
              message={'Error: ' + this.state.otherError}
            ></Alert>
          )}
        {this.props.license.vanityAlias &&
          this.props.license.vanityAlias != '' && (
            <>
              <div className="title_label">Set custom Url path</div>

              {this.state.schedule.alias != '__me__' && (
                <CBUrlField
                  hint="scheduler_alias"
                  maxLength={25}
                  value={this.state.schedule.alias}
                  style={{ width: 450 }}
                  onChange={(e) => {
                    var name = e.toLowerCase()
                    var usernameRegex = /^[a-zA-Z0-9-_]+$/

                    if (name.match(usernameRegex)) {
                      this.changeScheduleValue('alias', name)
                      var test = this.props.schedules.filter(
                        (sched) =>
                          sched.id != this.props.id && sched.alias == name
                      )
                      if (test.length > 0) {
                        this.setState({
                          pathError:
                            'Path already in use, will be replaced by this change',
                        })
                      } else {
                        this.setState({ pathError: null })
                      }
                    } else if (name == '') {
                      this.changeScheduleValue('alias', '')
                      this.setState({ pathError: null })
                    }
                  }}
                  label={
                    window.location.origin +
                    '/book/' +
                    this.props.license.vanityAlias +
                    '/'
                  }
                ></CBUrlField>
              )}
              {this.state.schedule.alias == '__me__' && (
                <CBUrlField
                  disabled={true}
                  label={
                    window.location.origin +
                    '/book/' +
                    this.props.license.vanityAlias +
                    '/'
                  }
                ></CBUrlField>
              )}
              <div>
                <CBCheckItem
                  label={
                    <div className="font-sarabun">
                      Set As Default Path:{' '}
                      <Text
                        code
                      >{`/book/${this.props.license.vanityAlias}/`}</Text>
                    </div>
                  }
                  checked={this.state.schedule.alias == '__me__'}
                  onChange={(e) => {
                    if (e) {
                      this.changeScheduleValue('alias', '__me__')
                      var test = this.props.schedules.filter(
                        (sched) =>
                          sched.id != this.props.id && sched.alias == '__me__'
                      )
                      if (test.length > 0) {
                        this.setState({
                          pathError:
                            'Default path already in use, will be replaced by this change',
                        })
                      } else {
                        this.setState({ pathError: null })
                      }
                    } else {
                      this.changeScheduleValue('alias', '')
                      this.setState({ pathError: null })
                    }
                  }}
                ></CBCheckItem>
                {this.state.pathError && (
                  <div>
                    {' '}
                    <Text type="danger">{this.state.pathError}</Text>
                  </div>
                )}
              </div>
            </>
          )}

        <div className="title_label">
          How your scheduler appears in your list, only visible to you.
        </div>
        <div>
          {this.state.existingScheduleId != null && (
            <CBInput
              maxLength={100}
              hint={'Scheduler Name'}
              value={this.state.schedule.label}
              onChange={(e) => this.changeScheduleValue('label', e)}
            ></CBInput>
          )}
          {this.state.existingScheduleId == null && (
            <CBInput
              maxLength={100}
              hint={'Scheduler Name'}
              value={this.state.schedule.label}
              onChange={(e) => this.changeScheduleValue('label', e)}
            ></CBInput>
          )}
        </div>

        <div className="title_label">
          <Text style={{}}>
            Customize the welcome text that appears booking page
          </Text>
          <div style={{ marginTop: 16 }}>
            {this.state.existingScheduleId != null && (
              <CBInput
                maxLength={100}
                hint={'Find Time To Book With ...'}
                value={this.state.schedule.greeting}
                onChange={(e) => this.changeScheduleValue('greeting', e)}
              ></CBInput>
            )}

            {this.state.existingScheduleId == null && (
              <CBInput
                maxLength={100}
                hint={'Find Time To Book With ...'}
                value={this.state.schedule.greeting}
                onChange={(e) => this.changeScheduleValue('greeting', e)}
              ></CBInput>
            )}
            {/* {this.state.schedule.greeting && <CBInput
              maxLength={100}
              hint={'Find Time To Book With ...'}
              value={this.state.schedule.greeting}
              onChange={(e) => this.changeScheduleValue('greeting', e)}
            ></CBInput>} */}
            <ScheduleImageUpload
              iconImage={this.state.schedule.iconImage}
              avatarImage={this.state.schedule.avatarImage}
              setIconImage={(img) => {
                this.changeScheduleValue('iconImage', img)
              }}
              setAvatarImage={(img) => {
                this.changeScheduleValue('avatarImage', img)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  changeTimezone = (tz) => {
    this.setState({
      schedule: {
        ...this.state.schedule,
        timezone: tz,
      },
    })
  }

  DateRangeForm = () => {
    const [minArray, setMinArray] = useState(this.state.minArray)
    const [addTimeVisible, setAddTimeVisible] = useState(false)
    const [showAdvance, setShowAdvance] = useState(false)
    const [addTimeValue, setAddTimeValue] = useState(undefined)

    const options = moment.tz.names().map((tz) => (
      <Select.Option key={tz} style={{ 'font-family': 'sarabun' }}>
        {tz} ({moment.tz(this.state.currentDate, tz).format('Z z')})
      </Select.Option>
    ))
    return (
      <>
        <CBHeader
          step={2}
          label="Availability"
          buttonLabel="Edit Previous Step"
          buttonIcon={<Up></Up>}
          buttonAction={(e) => {
            this.setState({ page: this.state.page - 1 })
          }}
        ></CBHeader>

        <div>
          <div className="title_label">
            Select how long meetings should be for this scheduler.
          </div>

          <Paragraph
            style={{ marginLeft: 16, marginTop: 40, marginBottom: 40 }}
          >
            <Checkbox.Group
              options={minArray}
              defaultValue={this.state.schedule.meetingIncrement}
              onChange={(v) => {
                
                this.changeScheduleValue('meetingIncrement', v)
              }}
            />
            {this.state.meetingError && this.state.meetingError != '' && (
              <div className="mt-2 text-red-500">
                At least one meeting length is required to be selected
              </div>
            )}
            <a
              className="flex flex-row items-center gap-x-2 underline mt-4 mb-2 text-darkblue_highlight"
              onClick={(e) => {
                setAddTimeVisible(!addTimeVisible)
              }}
            >
              {' '}
              {addTimeVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              )}
              {addTimeVisible ? 'Hide' : 'Add Custom Time'}
            </a>
            <div
              hidden={!addTimeVisible}
              className="flex flex-row gap-x-4 mt-2"
            >
              <div className="w-1/4">
                <Input
                  id="addTime"
                  onChange={(e) => {
                    setAddTimeValue(
                      e.target.value.replaceAll('-', '').split('.')[0]
                    )
                  }}
                  value={addTimeValue}
                  type="number"
                  step="1"
                  min="5"
                  max="480"
                ></Input>
              </div>

              <Button
                disabled={!addTimeValue || addTimeValue == ''}
                onClick={(e) => {
                  var add = '' + addTimeValue
                  if (!minArray.includes(add)) {
                    var arr = [...minArray, add]
                    setMinArray(arr)
                    setAddTimeValue(undefined)
                    setAddTimeVisible(false)
                  }
                }}
              >
                Add
              </Button>
            </div>
            <div>
              <CBRadioGroup
                center={false}
                title="Default time when scheduler page is first loaded"
                labels={this.state.schedule.meetingIncrement}
                values={this.state.schedule.meetingIncrement}
                selected={this.state.schedule.meetingIncrement.indexOf(
                  '' + this.state.schedule.defaultTime
                )}
                onChange={(v) => {
                  this.changeScheduleValue(
                    'defaultTime',
                    this.state.schedule.meetingIncrement[v]
                  )
                }}
              />
            </div>
            {/* <Input id="incr" onChange={e=>setStartCrement(e.target.value)} value={startCrement} type="number" min="5" max="480"></Input>
             
            <Button onClick={e=>{
              this.changeScheduleValue('startCrement', startCrement)
            }}>Set</Button> */}
          </Paragraph>
        </div>
        <div>
          <CBRadioGroup
            center={false}
            title="Time format:"
            selected={this.state.schedule.militaryTime ? 1 : 0}
            labels={['12h (am/pm)', '24h']}
            values={[false, true]}
            onChange={(e) => {
              this.changeScheduleValue('militaryTime', e == 0 ? false : true)
            }}
          ></CBRadioGroup>
        </div>
        <div className="mb-4">
          <div className="title_label">
            Select the range of days and times you would like available
          </div>

          <div>
            {daysOfWeek.map((day) => {
              const active = this.state.schedule.days.includes(day)
              return (
                <div className="mb-2">
                  <div>
                    <CBCheckItem
                      label={day}
                      key={day}
                      onChange={(e) => {
                        var days = this.state.schedule.days

                        if (e) {
                          if (!days.includes(day)) {
                            days.push(day)
                            this.changeScheduleValue('days', days)
                          }
                        } else {
                          if (days.includes(day)) {
                            this.changeScheduleValue(
                              'days',
                              days.filter((d) => d != day)
                            )
                          }
                        }
                      }}
                      style={{ width: 75 }}
                      checked={active}
                    ></CBCheckItem>
                  </div>

                  {active && (
                    <div className="mt-2 mb-2 flex justify-center items-center gap-x-4">
                      <TimePicker
                        style={{ 'font-family': 'sarabun' }}
                        className="focus:ring-2 focus:ring-offset-1 focus:ring-neutral_4"
                        disabled={!active}
                        size="large"
                        use12Hours={!this.state.schedule.militaryTime}
                        minuteStep={15}
                        format="h:mm a"
                        allowClear={false}
                        inputReadOnly
                        defaultValue={
                          this.state.schedule['startTime' + day] ||
                          this.state.schedule.startTime
                        }
                        onChange={(v) =>
                          this.changeScheduleValue('startTime' + day, v)
                        }
                      />
                      {' to '}
                      <TimePicker
                        style={{ 'font-family': 'sarabun' }}
                        disabled={!active}
                        size="large"
                        use12Hours={!this.state.schedule.militaryTime}
                        minuteStep={15}
                        format="h:mm a"
                        allowClear={false}
                        inputReadOnly
                        defaultValue={
                          this.state.schedule['endTime' + day] ||
                          this.state.schedule.endTime
                        }
                        onChange={(v) =>
                          this.changeScheduleValue('endTime' + day, v)
                        }
                      />
                    </div>
                  )}
                </div>
              )
            })}
            <div className="mb-4">
              <div className="title_label">Timezone</div>
              <Select
                style={{ 'font-family': 'sarabun', width: '280' }}
                showSearch
                placeholder="Select a timezone"
                filterOption={(input, option) => {
                  return (
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  )
                }}
                value={this.state.schedule.timezone}
                onChange={this.changeTimezone}
              >
                {options}
              </Select>
            </div>
          </div>
        </div>

        <div>
          <div className="title_label">
            How far in advance can someone book with you?
          </div>

          <Select
            style={{ width: 200 }}
            defaultValue={this.state.schedule.dayRange}
            onChange={(v) => this.changeScheduleValue('dayRange', v)}
          >
            <Select.Option value="7">1 week</Select.Option>
            <Select.Option value="14">2 weeks</Select.Option>
            <Select.Option value="21">3 weeks</Select.Option>
            <Select.Option value="30">1 month</Select.Option>
            <Select.Option value="60">2 months</Select.Option>
            <Select.Option value="90">3 months</Select.Option>
            <Select.Option value="120">4 months</Select.Option>
            <Select.Option value="150">5 months</Select.Option>
            <Select.Option value="180">6 months</Select.Option>
            <Select.Option value="240">8 months</Select.Option>
            <Select.Option value="300">10 months</Select.Option>
            <Select.Option value="365">1 year</Select.Option>
          </Select>

          <div className="title_label">
            How much notice do you need before a meeting starts?
          </div>
          <Select
            key="leadtime"
            style={{ width: 200 }}
            defaultValue={`${this.state.schedule.leadTime}`}
            onChange={(v) => this.changeScheduleValue('leadTime', v)}
          >
            <Select.Option value="0">0 minutes</Select.Option>
            <Select.Option value="5">5 minutes</Select.Option>
            <Select.Option value="10">10 minutes</Select.Option>
            <Select.Option value="15">15 minutes</Select.Option>
            <Select.Option value="30">30 minutes</Select.Option>
            <Select.Option value="45">45 minutes</Select.Option>
            <Select.Option value="60">1 hour</Select.Option>
            <Select.Option value="90">90 Minutes</Select.Option>
            <Select.Option value="120">2 hours</Select.Option>
            <Select.Option value="180">3 hours</Select.Option>
            <Select.Option value="240">4 hours</Select.Option>
            <Select.Option value="480">8 hours</Select.Option>
            <Select.Option value="720">12 hours</Select.Option>
            <Select.Option value="1440">24 hours</Select.Option>
            <Select.Option value="2880">48 hours</Select.Option>
            <Select.Option value="4320">3 Days</Select.Option>
            <Select.Option value="10080">1 week</Select.Option>
          </Select>
        </div>

        <div className="mt-2">
          <a
            className="flex flex-row items-center gap-x-2 underline mt-8 mb-4 lg:mb-0 text-darkblue_highlight text-lg"
            onClick={(e) => {
              setShowAdvance(!showAdvance)
            }}
          >
            {showAdvance ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            )}{' '}
            {showAdvance ? 'Hide' : 'Show'} Advanced
          </a>
          <div hidden={!showAdvance}>
            <div className="title_label">Buffer Before Event: </div>
            <Select
              key="before"
              style={{ width: 200 }}
              defaultValue={`${this.state.schedule.bufferBefore}`}
              onChange={(v) => this.changeScheduleValue('bufferBefore', v)}
            >
              <Select.Option value="0">0 minutes</Select.Option>
              <Select.Option value="5">5 minutes</Select.Option>
              <Select.Option value="10">10 minutes</Select.Option>
              <Select.Option value="15">15 minutes</Select.Option>
              <Select.Option value="30">30 minutes</Select.Option>
              <Select.Option value="45">45 minutes</Select.Option>
              <Select.Option value="60">1 hour</Select.Option>
              <Select.Option value="90">90 Minutes</Select.Option>
              <Select.Option value="120">2 hours</Select.Option>
              <Select.Option value="180">3 hours</Select.Option>
              <Select.Option value="240">4 hours</Select.Option>
            </Select>
            <div className="mb-8 ">
              <div className="title_label">Buffer After Event:</div>

              <Select
                key="after"
                style={{ width: 200 }}
                defaultValue={`${this.state.schedule.bufferAfter}`}
                onChange={(v) => this.changeScheduleValue('bufferAfter', v)}
              >
                <Select.Option value="0">0 minutes</Select.Option>
                <Select.Option value="5">5 minutes</Select.Option>
                <Select.Option value="10">10 minutes</Select.Option>
                <Select.Option value="15">15 minutes</Select.Option>
                <Select.Option value="30">30 minutes</Select.Option>
                <Select.Option value="45">45 minutes</Select.Option>
                <Select.Option value="60">1 hour</Select.Option>
                <Select.Option value="90">90 Minutes</Select.Option>
                <Select.Option value="120">2 hours</Select.Option>
                <Select.Option value="180">3 hours</Select.Option>
                <Select.Option value="240">4 hours</Select.Option>
              </Select>
            </div>

            <div className="title_label">Start time increments:</div>

            <div className="flex flex-row gap-x-4 mt-2 mb-4">
              <div className="w-1/4">
                <Select
                  style={{ width: 200 }}
                  value={`${this.state.schedule.startCrement}`}
                  onChange={(v) =>
                    this.changeScheduleValue('startCrement', parseInt(v))
                  }
                >
                  <Select.Option value="0">Default</Select.Option>
                  <Select.Option value="5">5 min</Select.Option>
                  <Select.Option value="10">10 min</Select.Option>
                  <Select.Option value="15">15 min</Select.Option>
                  <Select.Option value="20">20 min</Select.Option>
                  <Select.Option value="30">30 min</Select.Option>
                  <Select.Option value="40">40 min</Select.Option>
                  <Select.Option value="50">50 min</Select.Option>
                  <Select.Option value="60">60 min</Select.Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  EventDetailForm = () => {
    return (
      <>
        <Title style={{ textAlign: 'center' }}>Meeting Length</Title>

        <Title level={3} style={{ textAlign: 'center' }}>
          Select how long meetings should be for this scheduler.
        </Title>

        <Paragraph style={{ marginTop: 40, marginBottom: 40 }}>
          {/* <CheckboxGroup
              options={['60', '30', '15']}
              defaultValue={this.state.schedule.meetingIncrement}
              onChange={(v) => this.changeScheduleValue('meetingIncrement', v)}
            /> */}
        </Paragraph>
      </>
    )
  }

  EventBodyForm = () => {
    // const [question, setQuestion] = useState('');

    // const [questions, setQuestions] = useState(
    // []
    // )

    return (
      <>
        <CBHeader
          step={3}
          label="Event Details"
          buttonLabel="Edit Previous Step"
          buttonIcon={<Up></Up>}
          buttonAction={(e) => {
            this.setState({ page: this.state.page - 1 })
          }}
        ></CBHeader>
        <div>
          <div className="title_label">
            Set custom subject line, use custom field variables:{' '}
            {
              '{invitee_name}, {invitee_email}, {my_name}, {my_email}, {subject} '
            }{' '}
            to fill data.{' '}
            <a
              target="_blank"
              href="https://help.calendarbridge.com/doc/getting-started/creating-a-scheduling-link/#custom-subject"
            >
              Learn more.
            </a>
          </div>
          {this.state.existingScheduleId != null && (
            <CBInput
              maxLength={100}
              hint={'{invitee_name} and {my_name} - {subject}'}
              value={this.state.schedule.customSubject}
              onChange={(e) => this.changeScheduleValue('customSubject', e)}
            ></CBInput>
          )}

          {this.state.existingScheduleId == null && (
            <CBInput
              maxLength={100}
              hint={'{invitee_name} and {my_name} - {subject}'}
              value={this.state.schedule.customSubject}
              onChange={(e) => this.changeScheduleValue('customSubject', e)}
            ></CBInput>
          )}
        </div>
        <div>
          <div className="title_label">
            Add details that will be included in the body of the calendar event.
            This is where you can add links for Zoom, GoToMeeting, etc.
          </div>

          <Paragraph style={{ textAlign: 'center', marginTop: 40 }}>
            <CBTextArea
              value={this.state.schedule.bodyTemplate}
              size="large"
              rows={6}
              onChange={(e) => this.changeScheduleValue('bodyTemplate', e)}
            />
          </Paragraph>
        </div>

        <CBHeader subLabel={true} label="Booked Event Color" />
        <div>
          <CBColorPicker
            key={`source-${this.state.schedule.source}-${
              this.state.allCalendars[parseInt(this.state.schedule.source)]
                .calendarAccountType
            }`}
            type={
              this.state.allCalendars[parseInt(this.state.schedule.source)]
                .calendarAccountType
            }
            onColorChangeCallback={(color) => {
              this.changeScheduleValue('destinationColor', color)
            }}
            color={this.state.schedule.destinationColor}
          ></CBColorPicker>
          <div tyle={{ marginTop: 20 }}>
            {/* Label: <Input defaultValue={this.state.schedule.displayName}></Input>
            Greeting: <Input defaultValue={this.state.schedule.ownerName}></Input> */}
          </div>
        </div>
        <div className="title_label">Create online meeting:</div>

        <div>
          {this.state.schedule && (
            <CBCheckItem
              checked={this.state.schedule.meeting}
              onChange={(e) => {
                if (e) {
                  var schedule = this.state.schedule
                  schedule.meeting = true
                  // schedule.integrations = []
                  this.setState({
                    schedule,
                  })
                } else {
                  var schedule = this.state.schedule
                  schedule.meeting = false
                  this.setState({
                    schedule,
                  })
                }
              }}
              label={
                <>
                  Online Meeting for{' '}
                  <b>
                    {this.state.allCalendars[
                      parseInt(this.state.schedule.source)
                    ].calendarAccountType == 'ms'
                      ? 'Microsoft'
                      : 'Google'}
                  </b>
                </>
              }
            ></CBCheckItem>
          )}
          {this.state.schedule.integrations &&
            this.props.integrations.map((integration) => {
              return (
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <CBCheckItem
                    checked={this.state.schedule.integrations.includes(
                      integration.accountId
                    )}
                    key={integration.accountId}
                    defaultChecked={this.state.schedule.integrations.includes(
                      integration.accountId
                    )}
                    onChange={(e) => {
                      if (e) {
                        var schedule = this.state.schedule
                        // schedule.meeting = false
                        schedule.integrations = [integration.accountId]

                        this.setState({
                          schedule,
                        })
                      } else {
                        var schedule = this.state.schedule
                        schedule.integrations = []
                        this.setState({
                          schedule,
                        })
                      }
                    }}
                    label={
                      <div className="flex flex-row text-sm">
                        Zoom - {integration.email}
                      </div>
                    }
                  ></CBCheckItem>
                  {this.state.schedule.meeting &&
                    this.state.schedule.integrations.length > 0 && (
                      <div className="font-sarabun text-gray-900 mt-2">
                        Bookers will be able to choose Zoom or{' '}
                        {this.state.allCalendars[
                          parseInt(this.state.schedule.source)
                        ].calendarAccountType == 'google'
                          ? 'Google Meets'
                          : 'Microsoft Teams'}
                      </div>
                    )}
                </div>
              )
            })}

          {this.props.integrations.length == 0 && (
            <div>
              <a
                onClick={(e) => {
                  //save first
                  if (this.state.isLoading) {
                    // don't let them submit again if they already tapped
                  } else {
                    this.setState({ isLoading: true })
                    this.submitSync('zoom')
                  }
                }}
              >
                Add Zoom Account
              </a>
            </div>
          )}
        </div>
        <div style={{ marginBottom: 8 }}>
          <div className="title_label">Additional attendees</div>
          <div className="flex gap-x-4 items-center">
            <div className="flex-grow mb-2">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                tokenSeparators={[',', ' ']}
                placeholder="bobby@corp.com, jessica@corp.com"
                open={false}
                value={this.state.schedule.additionalAttendees}
                onChange={(value) => {
                  const filteredGuests = value.filter(
                    (guest) => guest != '' && validateEmail(guest)
                  )
                  const difference = value.filter(
                    (x) => !filteredGuests.includes(x)
                  )
                  if (difference.length > 0) {
                    message.error('Invalid email(s): ' + difference.join(', '))
                  }
                  this.changeScheduleValue(
                    'additionalAttendees',
                    filteredGuests
                  )
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 8 }}>
          <div className="title_label">
            Add Question: (appears in event detail)
          </div>
          <div className="flex gap-x-4 items-center">
            <div className="flex-grow mb-2">
              <CBInput
                maxLength={128}
                style={{ marginTop: 8, marginBottom: 8 }}
                placeholder={'Your Question?'}
                value={this.state.questionInputValue}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    ...prevState,
                    questionInputValue: e,
                  }))
                }
              ></CBInput>
            </div>
            <div>
              <CBButton
                id="questions"
                key="questions"
                disabled={
                  this.state.questionInputValue == '' ||
                  this.state.questionInputValue == null
                }
                onClick={(e) => {
                  this.state.schedule.questions.push(
                    this.state.questionInputValue
                  )
                  this.setState((prevState) => ({
                    ...prevState,
                    questionInputValue: '',
                  }))
                  // setQuestions(questions)

                  this.changeScheduleValue(
                    'questions',
                    this.state.schedule.questions
                  )
                }}
              >
                Add
              </CBButton>
            </div>
          </div>
        </div>
        {this.state.schedule.questions.length > 0 && (
          <>
            <div className="title_label">Questions:</div>
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              {this.state.schedule.questions.map((e) => {
                return (
                  <div className="mb-2">
                    <CBListItem
                      borderLight={true}
                      icon={<Trash></Trash>}
                      onClick={(ev) => {
                        var qs = this.state.schedule.questions.filter((q) => {
                          return q != e
                        })
                        // setQuestions(qs)
                        this.changeScheduleValue('questions', qs)
                      }}
                    >
                      {e}
                    </CBListItem>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </>
    )
  }

  render() {
    const sourceCalendar = this.state.allCalendars[
      +this.state.schedule.source
    ] || {
      name: 'Not Selected Yet',
      incomplete: true,
    }

    const pages = [this.SourceForm, this.DateRangeForm, this.EventBodyForm]

    return (
      <Layout>
        <Content className="cb-content-frame" style={{ background: 'white' }}>
          <div className="cb-content-pane mobile_padding min-h-screen">
            <MediaQuery maxDeviceWidth={1224}>
              {this.renderSteps(true)}
            </MediaQuery>

            {pages[this.state.page]()}
            {this.state.quickSave && (
              <div className="fixed inset-x-0 bottom-0 flex justify-center p-4">
                <CBButton
                  loading={this.state.isLoading}
                  onClick={(e) => {
                    this.setState({ isLoading: true })
                    this.submitSync()
                  }}
                >
                  Save Changes and Finish
                </CBButton>
              </div>
            )}
            <SubmitButton
              badge={this.state.page + 1}
              type="primary"
              block
              size="large"
              onClick={this.nextPage}
              disabled={
                this.state.isLoading || this.state.schedule.source == null
              }
            >
              {this.getButtonLabelByStep()}
            </SubmitButton>
            <CBModal
              visible={this.state.showModal}
              disabled={this.state.isLoading}
              onPositiveClick={(e) => {
                this.setState({ isLoading: true })
                this.submitSync()
              }}
              onCancelClick={(e) =>
                this.setState({ isLoading: false, showModal: false })
              }
            >
              {this.state.showModal && (
                <ScheduleItem
                  item={this.createFauxItem()}
                  preview={true}
                ></ScheduleItem>
              )}
            </CBModal>
          </div>
        </Content>
        <MediaQuery minDeviceWidth={1224}>
          <Sider width={400} className="sider-style">
            <div className="ml-12 mr-12 mt-4">
              <div className="ml-5 mb-2">
                <CBHeader
                  label="Create Scheduler"
                  icon={<NewSchedule />}
                ></CBHeader>
              </div>
              {this.renderSteps()}
            </div>
          </Sider>
        </MediaQuery>
      </Layout>
    )
  }

  getTimeMatrixDisplay() {
    // return "FUCL"
    const {
      startTimeSun,
      endTimeSun,
      startTimeMon,
      endTimeMon,
      startTimeTue,
      endTimeTue,
      startTimeWed,
      endTimeWed,
      startTimeThu,
      endTimeThu,
      startTimeFri,
      endTimeFri,
      startTimeSat,
      endTimeSat,
    } = this.state.schedule
    var timeMatrix = {
      Mon: {
        start: startTimeMon,
        end: endTimeMon,
      },
      Tue: {
        start: startTimeTue,
        end: endTimeTue,
      },
      Wed: {
        start: startTimeWed,
        end: endTimeWed,
      },
      Thu: {
        start: startTimeThu,
        end: endTimeThu,
      },
      Fri: {
        start: startTimeFri,
        end: endTimeFri,
      },
      Sat: {
        start: startTimeSat,
        end: endTimeSat,
      },
      Sun: {
        start: startTimeSun,
        end: endTimeSun,
      },
    }

    return this.state.schedule.days.map((d) => (
      <div>
        {d} {timeMatrix[d].start.format('h:mm a')} -{' '}
        {timeMatrix[d].end.format('h:mm a')}
      </div>
    ))
  }
  renderSteps(mobile = false) {
    const sourceCalendar = this.state.allCalendars[
      +this.state.schedule.source
    ] || {
      name: 'Not Selected Yet',
      incomplete: true,
    }
    return (
      <CBSteps
        direction="vertical"
        mobile={mobile}
        page={this.state.page}
        onPageChanged={(p) => this.changePage(p)}
        style={{ marginBottom: 40, marginTop: 40 }}
      >
        <CBStep
          primary="Select Calendar"
          secondary={sourceCalendar.name || sourceCalendar.summary}
        />
        <CBStep
          primary="Availability"
          secondary={this.getTimeMatrixDisplay()}
          disabled={sourceCalendar.incomplete}
        />

        <CBStep
          primary="Event Details"
          secondary={
            this.state.schedule.bodyTemplate.length === 0
              ? 'Empty'
              : this.state.schedule.bodyTemplate.substring(0, 20) + '...'
          }
          disabled={sourceCalendar.incomplete}
        />
      </CBSteps>
    )
  }
}

const ScheduleCreatePageState = ({ id }) => {
  const [accounts] = useGlobalState('accounts')
  const [schedules] = useGlobalState('schedules')
  const [license] = useGlobalState('license')
  const [integrations] = useGlobalState('integrations')

  return (
    <>
      <SEO title="Create a Scheduler" />
      <ScheduleCreatePage
        id={id}
        accounts={accounts}
        schedules={schedules}
        license={license}
        integrations={integrations}
      />
    </>
  )
}

export default ScheduleCreatePageState
