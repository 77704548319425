import React, { useCallback, useEffect, useMemo, useState } from 'react'
import momentTz from 'moment-timezone'
import { CBCalendar } from '../../../../../core/components/CBCalendar'
import { useGroupScheduleContext } from '../../GroupScheduleContext'
import get from 'lodash/get'
import map from 'lodash/map'

const selectedButton =
  'shadow-sm hover:border-neutral_4 cursor-pointer p-1 text-center font-sarabun bg-burnt shadow-circle rounded-sm border-2 border-burnt text-white flex-1'
const unselectedButton =
  'shadow-sm cursor-pointer hover:border-neutral_2 p-1 font-sarabun text-center rounded-sm border-2 border-neutral_1 text-neutral_4 flex-1'

const tz = momentTz.tz.guess()

const TimeButton = ({ time, onSelect, selectedItems }) => {
  const eventStart = momentTz(time.start).tz(tz)
  const className = useMemo(
    () => (selectedItems.includes(time) ? selectedButton : unselectedButton),
    [time, selectedItems]
  )

  const onClick = useCallback(() => {
    onSelect(time)
  }, [time, onSelect])

  return (
    <div className={className} key={time.start + time.end} onClick={onClick}>
      {`${eventStart.format('h:mm a')}`}
    </div>
  )
}

const TimeButtons = ({ freeTimes, currentDay, isSingleSelect }) => {
  const { state, dispatch } = useGroupScheduleContext()
  const selectedItems = useMemo(() => get(state, 'selectedItems'), [state])

  const onGetNewSelectedItems = useCallback(
    (time) => {
      if (selectedItems.includes(time)) {
        return selectedItems
      }

      const sortableItems = [...selectedItems, time]
      return sortableItems.sort((a, b) => {
        const startA = momentTz(a.start)
        const startB = momentTz(b.start)
        return startA.unix() - startB.unix()
      })
    },
    [selectedItems]
  )

  const onSelect = useCallback(
    (time) => {
      const newState = isSingleSelect ? [time] : onGetNewSelectedItems(time)
      dispatch({ type: 'setSelectedItems', selectedItems: newState })
    },
    [isSingleSelect, currentDay, onGetNewSelectedItems]
  )

  const times = useMemo(
    () => get(freeTimes, currentDay.format('YYYY-MM-DD'), []),
    [freeTimes, currentDay]
  )

  return (
    !!currentDay && (
      <div className="flex flex-col justify-center mt-8 mb-8">
        <b className="mb-2">{currentDay.format('LL z')}</b>
        <div className="grid grid-cols-4 gap-x-2 gap-y-2">
          {map(times, (time) => {
            return (
              <TimeButton
                key={get(time, 'start')}
                onSelect={onSelect}
                time={time}
                selectedItems={selectedItems}
              />
            )
          })}
        </div>
      </div>
    )
  )
}

export const GroupCalendarView = ({ firstFree, range, isSingleSelect }) => {
  const { state, dispatch } = useGroupScheduleContext()
  const excludedDays = useMemo(() => get(state, 'excludedDays'), [state])
  const freeTimes = useMemo(() => get(state, 'freeTimes'), [state])
  const excluded = useMemo(() => Object.keys(excludedDays), [excludedDays])

  const onSetCurrentDay = useCallback(
    (time) => {
      dispatch({ type: 'setFirstFree', firstFree: time })
    },
    [dispatch]
  )

  // removed letChoose since it was always false
  return (
    <>
      <div>
        {firstFree && (
          <>
            <div className="m-2 font-sarabun">
              {isSingleSelect
                ? 'Choose a time to book'
                : 'Select some times that work for you'}
            </div>
            <CBCalendar
              passDate={firstFree}
              range={range}
              excludedDays={excluded}
              onSelect={onSetCurrentDay}
            />
          </>
        )}
        
        <div className="flex justify-center">
       
          <TimeButtons
            freeTimes={freeTimes}
            currentDay={firstFree}
            isSingleSelect={isSingleSelect}
          />
           
        </div>
      </div>
    </>
  )
}
