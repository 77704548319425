import React, { useState, useEffect } from 'react'
import { useGlobalState } from '../../../store'
import { Button, Grid, Row, Col, Checkbox, Modal, Typography } from 'antd'
import { API } from 'aws-amplify'
import moment from 'moment'
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'
import {
  TwitterShareButton,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  WhatsappShareButton,
} from 'react-share'
import Title from 'antd/lib/typography/Title'

import objectToGetParams from 'react-share/es/utils/objectToGetParams'
import createShareButton from 'react-share/es/hocs/createShareButton'

function emailLink(url, _a) {
  var subject = _a.subject,
    body = _a.body,
    separator = _a.separator
  return (
    'mailto:' +
    objectToGetParams({
      subject: subject,
      body: body ? body + separator + url : url,
    })
  )
}

var EmailBlankTargetShareButton = createShareButton(
  'email',
  emailLink,
  function (props) {
    return {
      subject: props.subject,
      body: props.body,
      separator: props.separator || ' ',
    }
  },
  {
    openShareDialogOnClick: false,
    onClick: function (_, link) {
      // you should put here the code that opens the link in a new window.
      // something like this :
      window.open(link, '_blank')
    },
  }
)

const CALENDARBRIDGE_URL = 'https://www.calendarbridge.com'
const ReferralList = () => {
  const [events, setEvents] = useState([])
  const [license, setLicense] = useGlobalState('license')
  useEffect(() => {
    async function load() {
      var refs = await API.get('billing', '/billing?referrals=true')
      setEvents(refs.referrals)
    }
    load()
  }, [])
  const list = events.map((event) => (
    <div style={{ padding: 8, color: 'rgba(0,0,0,.65)' }}>
      {event.redeemerEmail} -{' '}
      {event.awarded == 'awarded' ? (
        <b style={{ color: 'green' }}>Awarded</b>
      ) : (
        <>
          <b style={{ color: 'red' }}>{event.awarded}</b> -{' '}
          <b>{moment(event.started * 1000).format('L')}</b>
        </>
      )}
    </div>
  ))

  return (
    <div className="columns my-3" style={{ marginTop: 40, marginBottom: 40 }}>
      <p style={{ color: 'rgba(0,0,0,.65)' }}>Referrals</p>
      {list}
    </div>
  )
}

const ShareList = ({ code }) => {
  const codeShare = code
  const buttonRadius = 40
  return (
    <div className="mb-2 mt-3">
      <LinkedinShareButton
        url={CALENDARBRIDGE_URL}
        source={CALENDARBRIDGE_URL}
        title={'CalendarBridge'}
        summary={`${codeShare}`}
      >
        <LinkedinIcon size={buttonRadius} round={true} />
      </LinkedinShareButton>
      <FacebookShareButton url={CALENDARBRIDGE_URL} quote={`${codeShare}`}>
        <FacebookIcon size={buttonRadius} round={true} />
      </FacebookShareButton>
      <TwitterShareButton
        size={buttonRadius}
        url={CALENDARBRIDGE_URL}
        hashtags={['calendarbridge', 'calendarmagic']}
        title={` ${codeShare}`}
      >
        <TwitterIcon size={buttonRadius} round={true} />
      </TwitterShareButton>
      <EmailBlankTargetShareButton
        url={CALENDARBRIDGE_URL}
        subject="CalendarBridge"
        body={`${codeShare}`}
      >
        <EmailIcon size={buttonRadius} round={true} />
      </EmailBlankTargetShareButton>
    </div>
    
  )
}

const ReferralPage = () => {
  const [license, setLicense] = useGlobalState('license')
  const [isProcessing, setIsProcessing] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [visible, setVisible] = useState(false)

  async function optIn(option) {
    setIsProcessing(true)
    try {
      var opt = await API.post('billing', '/billing?optIn=' + option, {
        body: {},
      })

      setLicense(opt.license)
    } catch (ex) {}
    setIsProcessing(false)
  }

  function onChange(e) {
    setTermsAccepted(!termsAccepted)
  }

  function showModal(e) {
    e.preventDefault()
    setVisible(1)
  }

  function handleOk(e) {
    setVisible(0)
  }

  function handleCancel(e) {
    setVisible(0)
  }

  return (
    <>
    <div className="cb-content-frame">
    <div className="cb-content-pane font-sarabun text-neutral_4 mobile_padding min-h-screen" >
      <Row style={{ margin: '0px 10px' }}>
        <Col span={24}>
          <h1 style={{ textAlign: 'center' }}>Refer a Friend Program</h1>
          {license && (license.trial || license.code == 'site') && (
            <h2>
              Referral program is only for paid non-enterprise customers, sorry!
            </h2>
          )}
          {license &&
            !license.trial &&
            !license.code != 'site' &&
            license.referralCode != '' &&
            license.referralCode && (
              <div style={{ textAlign: 'center', marginBottom: 100 }}>
                <Title
                  level={3}
                  style={{
                    fontSize: '1.5em',
                    lineHeight: '1.8em',
                    marginBottom: 20,
                  }}
                >
                  Share this code. Be a Calendar Hero. (And save $$).
                </Title>
                <div className="text-lg mb-4 mt-4">
                <a href="#" onClick={showModal}>
                    View Referral Terms 
                  </a>
                  </div>
                <Title level={3} style={{ display: 'inline' }}>
                  Your Code:
                </Title>{' '}
                <Title level={3} copyable style={{ display: 'inline' }}>
                  {license.referralCode}
                </Title>
                <ShareList code={license.referralCode} />
                <p
                  style={{
                    fontSize: '1.2em',
                    lineHeight: '1.8em',
                    marginTop: 25,
                    color: 'rgba(0,0,0,.65)',
                  }}
                >
                  Every time a new customer subscribes to CalendarBridge using
                  your referral code, they get their first month free and you
                  get a credit for a free month of your subscription.
                </p>
                <ReferralList />
                <Button
                  loading={isProcessing}
                  disabled={isProcessing}
                  onClick={(e) => optIn(false)}
                >
                  Opt Out
                </Button>

              </div>
            )}

          {license &&
            !license.trial &&
            license.code != 'site' &&
            license.referralCode == '' && (
              <>
                <p
                  style={{
                    fontSize: '1.2em',
                    lineHeight: '1.8em',
                    color: 'rgba(0,0,0,.65)',
                  }}
                >
                  Remember when you first discovered CalendarBridge and how easy
                  it was to finally get all your calendars under control? Now
                  you can share that feeling with a friend &mdash; and you both
                  get rewarded for it!
                </p>

                <h2 style={{ textAlign: 'center' }}>
                  Give a Month, Get a Month
                </h2>
                <p
                  style={{
                    fontSize: '1.2em',
                    lineHeight: '1.8em',
                    color: 'rgba(0,0,0,.65)',
                  }}
                >
                  With the CalendarBridge Refer a Friend program, every time
                  someone subscribes to CalendarBridge using your referral code,
                  they get their first month free and you also get a credit for
                  a free month of your subscription.
                </p>

                <div style={{ textAlign: 'center' }}>
                  <Button
                    loading={isProcessing}
                    disabled={!termsAccepted}
                    onClick={(e) => optIn(true)}
                    type="primary"
                    style={{ width: 215 }}
                  >
                    Sign me up!
                  </Button>
                </div>
                <div style={{ textAlign: 'center', marginBottom: 100 }}>
                  <Checkbox onChange={onChange} checked={termsAccepted}>
                    I Accept the
                  </Checkbox>
                  <a href="#" onClick={showModal}>
                    View Referral Terms
                  </a>
                </div>
              </>
            )}
        </Col>
      </Row>
      <Modal
        title="Refer A Friend Terms &amp; Conditions"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Close
          </Button>,
        ]}
      >
        <ol>
          <li>The Promoter is: CalendarBridge, Inc.</li>
          <li>
            The "Referring Customer" is the person whose referral code is
            submitted with payment for a new CalendarBridge plan by the
            "Referred Customer."
          </li>
          <li>
            As the Referring Customer, you accept these terms by clicking the
            "accept" button below or by sharing your referral code with anyone.
          </li>
          <li>
            As the Referred Customer, you accept these terms by submitting a
            referral code with payment.
          </li>
          <li>
            Accepting these terms constiutes agreement for Promoter to send you
            further correspondence related to this promotion. This will not
            affect any other of your marketing preferences. 
          </li>
          <li>
            The Promotion may be withdrawn or amended by Promoter at any time
            without prior notice. 
          </li>
          <li>
            The Referring Customer Reward is an account credit equal to the
            price of 1 month of the Referred Customer's initial CalendarBridge
            subscription.
          </li>
          <li>
            The Referred Customer Reward is a free first month of their initial
            CalendarBridge Subscription.
          </li>
          <li>
            The Referred Customer Reward and the Referring Customer Reward are
            non transferable, and no cash or other alternative will be offered. 
          </li>
          <li>This Promotion is governed by the laws of Delaware.</li>
          <li>
            To be eligible as a Referring Customer, you must: (a) be an existing
            CalendarBridge customer whose account is not past due; (b) be 18
            years of age or older, with a valid email address; and (c) accept
            these terms and click the "sign me up" button on the referr page. 
          </li>
          <li>
            Unique referral codes may be shared with friends, family members, or
            any other member of the public, provided they are aged 18 or over. 
          </li>
          <li>For the avoidance of doubt, you cannot refer yourself.</li>
          <li>
            To be eligible as a Referred Customer, you must: (a) Be 18 years or
            over, with a valid email address; (b) not be, or previously have been, a
            CalendarBridge customer; and (c) submit a referral code with your
            first payment to CalendarBridge. 
          </li>
          <li>
            The Referred Customer Reward will be applied to the initial term of
            the Referred Customer's first CalendarBridge subscription.
          </li>
          <li>
            The Referring Customer Reward will be earned only after the Referred
            Customer has been subscribed to a paid plan for 40 days.
          </li>

          <li>
            By submitting the referral code, the Friend agrees to the
            CalendarBridge Terms of Service and Privacy Policy, which can be
            found 
            <a
              href="https://calendarbridge.com/terms-and-privacy"
              target="_blank"
            >
              here
            </a>
          </li>
        </ol>
      </Modal>
      </div>
      </div>
    </>
  )
}

export default ReferralPage
