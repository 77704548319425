import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Select, Spin, Modal, Col } from 'antd'
import debounce from 'lodash/debounce'
import { API } from 'aws-amplify'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { useNavigate } from '@reach/router'
import { DeleteOutlined, Loading3QuartersOutlined, PlusOutlined } from '@ant-design/icons'
import {
  CBHeader,
  CBList,
  SubmitButton,
  CBButton,
} from '../../../../../core/components/cbd'
import Up from '../../../../../../images/svg/arrow-up-chevron.svg'
import { useGroupScheduleContext } from '../../GroupScheduleContext'
import { GroupListItem } from './GroupListItem'
import { SaveGroup } from './SaveGroup'
import { OptionItem } from './OptionItem'
import { GuestListItem } from './GuestListItem'

export const Invitees = () => {
  const navigate = useNavigate()
  const { state, dispatch, onSetCurrentPage } = useGroupScheduleContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchingContactGroups, setIsFetchingContactGroups] = useState(false)
  const [userSearchResults, setUserSearchResults] = useState([])
  const [contactsForSearch, setContactsForSearch] = useState([])

  const { guests, contactGroups } = useMemo(() => {
    return {
      guests: get(state, 'guests'),
      contactGroups: get(state, 'contactGroups'),
    }
  }, [state])

  const validateEmail = useCallback((email) => {
    return email.length && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }, [])

  // returns new guests state
  const validateAndFilterDuplicateEmails = useCallback(
    (newGuests) => {
      // removes duplicates
      return filter(
        Array.from(new Set([...guests, ...newGuests])),
        validateEmail
      )
    },
    [guests]
  )

  const onSearchUsers = useCallback(async (term) => {
    setUserSearchResults([])

    if (term) {
      setIsLoading(true)

      try {
        const { contacts } = await API.get(
          'contactSearch',
          `/contactSearch?term=${term}`,
          {}
        )
        setUserSearchResults(contacts)
        setContactsForSearch((prevState) =>
          Array.from(
            new Set([
              ...prevState,
              ...map(contacts, (contact) => contact.email),
            ])
          )
        )
      } catch (err) {
        console.log(':err', err)
      }
    }

    setIsLoading(false)
  }, [])

  const debouncedOnSearchUsers = debounce((term) => onSearchUsers(term), 1000)

  const onSetGuests = useCallback(
    (newGuests) => {
      if (guests.length < 10) {
        dispatch({
          type: 'setGuests',
          guests: validateAndFilterDuplicateEmails(newGuests),
        })
      }
    },
    [dispatch, guests, validateAndFilterDuplicateEmails]
  )

  const onBack = useCallback(() => {
    onSetCurrentPage(1)
  }, [onSetCurrentPage])

  const onSubmit = useCallback(() => {
    onSetCurrentPage(3)
  }, [onSetCurrentPage])

  const onEnableContactSearch = useCallback(() => {
    navigate('/dashboard/group/contacts/', { replace: true })
  }, [])

  const onConfirmDeleteGroup = useCallback(async (groupId) => {
    const response = await API.post(
      'group-manage',
      '/group-manage?contactGroup=true&delete=true',
      {
        body: { groupId, emails: [] },
      }
    )
    dispatch({
      type: 'setContactGroups',
      contactGroups: get(response, 'groups', []),
    })
  }, [])

  const onDeleteContactGroup = useCallback(
    (groupId) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this contact group?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        icon: <DeleteOutlined />,
        async onOk() {
          onConfirmDeleteGroup(groupId)
        },
      })
    },
    [contactGroups, onConfirmDeleteGroup]
  )

  const onAddContactsFromGroup = useCallback(
    (newGroupContacts) => {
      onSetGuests(newGroupContacts)
      setContactsForSearch(newGroupContacts)
    },
    [dispatch, contactGroups, onSetGuests]
  )

  const onGetContactGroups = useCallback(async () => {
    setIsFetchingContactGroups(true)
    try {
      const response = await API.get(
        'group-manage',
        '/group-manage?contactGroup=true',
        {}
      )
      dispatch({
        type: 'setContactGroups',
        contactGroups: get(response, 'groups', []),
      })
    } catch (err) {
      console.log(':failed to get contact groups', err)
    }

    setIsFetchingContactGroups(false)
  }, [])

  useEffect(() => {
    if (!contactGroups.length) onGetContactGroups()
  }, [])

  return (
    <div>
      <CBHeader
        step={3}
        label="Invitees"
        buttonLabel="Edit Previous Step"
        buttonIcon={<Up />}
        buttonAction={onBack}
      />
      <div style={{ marginTop: 24, marginBottom: 24 }}>
        Invite your contacts by name or anyone with an email address.
        CalendarBridge users will be able to have times selected automatically
        based on their availability.
      </div>

      <Select
        showSearch
        disabled={Boolean(guests.length > 9)}
        mode="tags"
        style={{ width: '100%' }}
        tokenSeparators={[',', ' ']}
        placeholder="bobby@corp.com"
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        value={[]}
        showArrow={false}
        filterOption={false}
        onSearch={debouncedOnSearchUsers}
        onChange={onSetGuests}
      >
        {userSearchResults.map((user) => {
          return <OptionItem key={user.name} user={user} />
        })}
      </Select>
      {!!(process.env.GATSBY_DEBUG_UI == 'true') && (
        <div className="flex justify-end mt-2 mb-2">
          <CBButton
            type="ternary"
            style={{ marginTop: 8, marginBottom: 8 }}
            icon={<PlusOutlined />}
            onClick={onEnableContactSearch}
          >
            Enable Contact Search (staging only)
          </CBButton>
        </div>
      )}
      {isFetchingContactGroups && (
        <Col
          style={{ textAlign: 'center', paddingTop: 100, minWidth: '380px' }}
        >
          <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{ fontSize: '100px' }} />
            }
          />
        </Col>
      )}

      {contactGroups.length > 0 && (
        <div>
          <div>Select Contact Group:</div>
          <CBList borderLight={true}>
            {contactGroups.map((group) => {
              return (
                <GroupListItem
                  key={group.id}
                  group={group}
                  onDeleteContactGroup={onDeleteContactGroup}
                  onAddContactsFromGroup={onAddContactsFromGroup}
                />
              )
            })}
          </CBList>
        </div>
      )}
      {guests.length > 0 && (
        <div style={{ marginTop: 24 }}>
          <div style={{ marginBottom: 8 }}>Review Invitees</div>
          <CBList borderLight>
            {guests.map((guest) => {
              return (
                <GuestListItem
                  key={guest}
                  guest={guest}
                  contactsForSearch={contactsForSearch}
                />
              )
            })}
          </CBList>
          {guests.length == 10 && (
            <div className="mt-2 mb-2 text-red-700">
              Maximum of 10 invitees allowed during beta
            </div>
          )}

          {!!(guests.length > 0) && <SaveGroup />}
        </div>
      )}

      <div className="mt-8">
        <SubmitButton
          badge={4}
          disabled={guests.length == 0}
          type="primary"
          onClick={onSubmit}
        >
          Continue to times
        </SubmitButton>
      </div>
    </div>
  )
}
