import React from 'react'

import { Modal, Input, Alert } from 'antd'

const NewEmailModal = ({
  currentEmail,
  visible,
  handleOk,
  handleCancel,
  newEmail,
  setNewEmail,
  confirmLoading,
  emailInvalid,
}) => {
  const handleInputChange = (e) => {
    setNewEmail(e.target.value)
  }

  return (
    <Modal
      title="Edit License"
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <>
        <p>Change the email address for this account</p>
        <Input.Group compact={false}>
          <Input
            style={{ paddingBottom: '1em' }}
            addonBefore={'Current Email'}
            value={currentEmail}
            readOnly={true}
          />
          <Input
            style={{ paddingBottom: '1em' }}
            type={'email'}
            value={newEmail}
            addonBefore={'New Email'}
            placeholder={'New Email'}
            onChange={handleInputChange}
          />
        </Input.Group>
        {emailInvalid && (
          <Alert
            message="Error"
            description="New Email is not a valid email address"
            type="error"
            showIcon
          />
        )}
      </>
    </Modal>
  )
}

export default NewEmailModal
