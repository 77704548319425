import React, { useCallback } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { CBListItem } from '../../../../../core/components/cbd'
import Trash from '../../../../../../images/delete.png'

export const GroupListItem = ({
  group,
  onDeleteContactGroup,
  onAddContactsFromGroup,
}) => {
  const onDeleteGroup = useCallback(() => {
    onDeleteContactGroup(group.id)
  }, [group, onDeleteContactGroup])

  const onItemClick = useCallback(() => {
    const contacts = map(get(group, 'contacts', {}), (contact) => contact.email)
    onAddContactsFromGroup(contacts)
  }, [onAddContactsFromGroup, group])
//fa
  return (
    <>
      <CBListItem
        borderLight={true}
        icon={<img src={Trash} onClick={onDeleteGroup} />}
        onClick={onItemClick}
      >
        <div>
          <div>
            {group.id.split('#')[1]} - {group.contacts.length}
          </div>
          <div className="text-sm text-gray-500">
            {group.contacts
              .map((e) => e.email)
              .slice(0, 4)
              .join(', ')}{' '}
            {group.contacts.length > 4 && (
              <>{`+ ${group.contacts.length - 4} more`}</>
            )}
          </div>
        </div>
      </CBListItem>
    </>
  )
}
