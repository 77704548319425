import React from 'react'
import AccountSelectList from '../components/AccountSelectList'

import Connections from "../../../images/svg/connections.svg";

import Info from "../../../images/svg/info.svg";
import { CBStep, CBSteps, CBInput,CBButton,SubmitButton, CBHeader,
  CBCheckItem, CBRadioGroupBig, CBRadioGroup, CBListItem } from '../../core/components/cbd'

import SEO from '../../../components/SEO'

const AccountListPage = props => (
  <div className="cb-content-frame">
    <SEO title="Accounts Dashboard" />
    <div className="cb-content-pane mobile_padding" >
    <CBHeader icon={<Connections></Connections>} label={"Accounts"} buttonLabel={"Help"} buttonIcon={<Info></Info>} buttonAction={e=> {
        window.open('https://help.calendarbridge.com/doc/getting-started/connecting-microsoft-calendars/', "_blank")
      }}></CBHeader>
   
    <AccountSelectList {...props} />
    </div>
  </div>
)

export default AccountListPage
