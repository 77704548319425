import React, { useMemo, useCallback, useState } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import { useNavigate } from '@reach/router'
import { Button } from 'antd'
import { useGroupScheduleContext } from '../GroupScheduleContext'
import Up from '../../../../../images/svg/arrow-up-chevron.svg'
import { faOutlook } from '../../../../../utils/customIcons'
import { colorOutlook } from '../../../../../utils/colors'
import GoogleLogo from '../../../../../images/svg/google-logo.svg'
import { useGlobalState } from '../../../../../store'
import {
  CBRadioGroupBig,
  CBRadioGroup,
  SubmitButton,
  CBHeader,
} from '../../../../core/components/cbd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const defaultIncrements = [60, 30, 15]

export const Availability = () => {
  const navigate = useNavigate()
  const { state, dispatch, onSetCurrentPage } = useGroupScheduleContext()
  const [schedules] = useGlobalState('schedules')
  
  const { parentId, increment } = useMemo(() => {
    return {
      parentId: get(state, 'parentId'),
      increment: get(state, 'increment'),
    }
  }, [state])

  const selectedSchedule = useMemo(() => {
    return findIndex(schedules, { id: parentId })
  }, [parentId, schedules])

  const scheduleLabels = useMemo(
    () => map(schedules, (schedule) => schedule.label || schedule.displayName),
    []
  )
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  const {increments, incrementLabels} = useMemo(()=> {
    
    let id = findIndex(schedules, { id: parentId })
    let increments = defaultIncrements
    var incrementLabel
    if(id != -1) {
    var newParentId = schedules[id]
    var additional = newParentId.meetingIncrement
          .filter((e) => (e != '60') & (e != '30') && e != '15')
          .map((e) => parseInt(e))
        
        increments.push.apply(increments, additional)
        increments = increments.filter(onlyUnique).sort(function (a,b) {return b-a;})
        
        
      }
      incrementLabel = increments.map((e) => `${e} Min`)
      
    return {
      increments: increments,
      incrementLabels: incrementLabel,
    }
  },[parentId])

  const scheduleIcons = useMemo(
    () =>
      map(schedules, (schedule) =>
        schedule.type === 'google' ? (
          <GoogleLogo className="mt-2" />
        ) : (
          <FontAwesomeIcon
            icon={faOutlook}
            size="2x"
            style={{ width: 24, height: 24 }}
            color={colorOutlook}
          />
        )
      ),
    [schedules]
  )

  const scheduleSubLabels = useMemo(
    () =>
      map(
        schedules,
        (schedule) => `${schedule.ownerName} - ${schedule.ownerEmail}`
      ),
    [schedules]
  )

  const onBack = useCallback(() => {
    onSetCurrentPage(0)
  }, [onSetCurrentPage])

  const onSubmit = useCallback(() => {
    onSetCurrentPage(2)
  }, [onSetCurrentPage])

  const onCreateScheduler = useCallback(() => {
    navigate('/dashboard/schedules/new', { replace: true })
  }, [])

  const onSetParentId = useCallback(
    (index) => {
      
      const newParentId = get(schedules, [index])
      
      dispatch({
        type: 'setParentId',
        parentId: newParentId ? newParentId.id : null,
      })
    },
    [dispatch]
  )

  const onSetIncrement = useCallback(
    (index) => {
      
      let sortIncrements = increments.sort().reverse()

      
      dispatch({ type: 'setIncrement', increment: sortIncrements[index] ?? null })
    },
    [dispatch]
  )

  return (
    <div>
      {schedules.length ? (
        <>
          <CBHeader
            step={2}
            label="Availability"
            buttonLabel="Edit Previous Step"
            buttonIcon={<Up />}
            buttonAction={onBack}
          />
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            Select a scheduler and duration for this event.
          </div>
          <div style={{ marginBottom: 24 }}>
            CalendarBridge will automatically accommodate scheduling needs for
            attendees with linked accounts and provide you the needed inputs for
            attendees outside of service.
          </div>
          <div className="mb-8">
            <CBRadioGroupBig
              title="Select availability"
              selected={selectedSchedule}
              style={{ width: '100%', marginTop: 8, marginBottom: 8 }}
              values={schedules}
              labels={scheduleLabels}
              icons={scheduleIcons}
              selectedComponent={<div />}
              subLabels={scheduleSubLabels}
              onChange={onSetParentId}
            />
          </div>
        </>
      ) : (
        <>
          Create a scheduler first!
          <Button onClick={onCreateScheduler}>Create Scheduler</Button>
        </>
      )}
      <CBRadioGroup
        title="Duration"
        selected={increments.indexOf(increment)}
        labels={incrementLabels}
        values={increments}
        onChange={onSetIncrement}
      />
      <div className="mt-8">
        <SubmitButton
          disabled={!increment || selectedSchedule == -1}
          badge={3}
          type="primary"
          onClick={onSubmit}
        >
          Continue to Invitees
        </SubmitButton>
      </div>
    </div>
  )
}
