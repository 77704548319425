import React, { useEffect, useState } from 'react'
import Title from 'antd/lib/typography/Title'
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Row, Col, Card, Button, Result, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faOutlook } from '../../../utils/customIcons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { colorOutlook, colorGoogle, colorExchange } from '../../../utils/colors'
import QueryString from 'query-string'
import { API, Auth } from 'aws-amplify'
import { navigate } from 'gatsby'
import {Cookies} from 'js-cookie'
import { useGlobalState } from '../../../store'
import { loadAccounts } from '../../../utils/fetchData'



const ZoomAuthPage = () => {
  const [showList, setShowList] = useState(false)
  const [callbackStatus, setCallbackStatus] = useState('loading')
  const [errorMessage, setErrorMessage] = useState(null)
  const [, setAccounts] = useGlobalState('accounts')
  const [, setLoadingCoreData] = useGlobalState('loadingCoreData')

  useEffect(() => {
    let params = QueryString.parse(window.location.search)
    //console.log(params.code)
    setCallbackStatus(params.code)
    if (params.code) {
    //   if(params.scope && params.scope.includes("google")){

        codeCallbackAccountCreation(params.code, "google", params.state,params.scope)
    }
    //   } else {

    //     codeCallbackAccountCreation(params.code, "ms", params.state,params.scope)
    //   }
    // } else {
    //   setErrorMessage('/book/' + params.state)
    // }
  }, [])

  const codeCallbackAccountCreation = async (code, type, state,scope) => {
    
    try{
    var auth = await API.post('group-bookings', '/integrations', {
      body: {
        code,
        redirect:
            window.location.origin + '/dashboard/zoom/'
            
      },
    })
    
    
    
      window.location.href = window.location.origin + '/dashboard/schedules'
  
    } catch(error) {
      //console.log(error)
      setErrorMessage('There was an issue adding your Zoom Account')
    }

  }

  function renderError() {
    return (
      
      <Result
        status="warning"
        title="Darn! We had trouble linking your account."
        subTitle={errorMessage}
        extra={
          <Button onClick={() => navigate(errorMessage)}>
            Try Again
          </Button>
        }
      />
    )
  }

  return (
    <div className="bg-white flex justify-center" style={{minHeight:800, textAlign: 'center' }}>
      
      {errorMessage && (renderError())}
      {!errorMessage && 
      <div className="flex flex-col justify-center gap-y-4">
        <div className="font-manrope text-lg text-neutral_4">Adding Zoom Account</div>
      <Spin
      indicator={
        <Loading3QuartersOutlined spin style={{  fontSize: '100px' }} />
      }
    />
    </div>}
  </div>
  );
  
}


export default ZoomAuthPage
