import React, { useState } from 'react'
import { API } from 'aws-amplify'

import { Table, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import NewEmailModal from './Modals/NewEmailModal'
import EditPlanModal from './Modals/EditPlanModal'
import EditActiveModal from './Modals/EditActiveModal'
import EditOrgModal from './Modals/EditOrgModal'
import DeleteAccountModal from './Modals/DeleteAccountModal'
import EditOrgAdminModal from './Modals/EditOrgAdminModal'
import EditStripeModal from './Modals/EditStripeModal'
/*
Email checker validation.
*/
function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}
const defaultModalVisibility = {
	deleteAccount: false,
	changeEmail: false,
	editActive: false,
	editOrg: false,
	editPlan: false,
	editOrgAdmin: false
}
//make this a true constant to track state appropriately for which
// modal is displayed while editing
Object.freeze(defaultModalVisibility)

async function requestScanApi(params) {
	// console.log('Sending request with params', params)
	const apiData = await API.post('scan', '/scan', { body: params });
	// console.log(apiData);
	return apiData;
}

const LicenseInfo = ({ license, updateEmailAndLoadNewData }) => {
	// console.log(license)
	const [modalVisible, setModalVisible] = useState(defaultModalVisibility)
	const [confirmLoading, setConfirmLoading] = useState(false)

	const [newActiveState, setNewActiveState] = useState(license.active)
	const [newAdminStatus, setNewAdminStatus] = useState(license.orgAdmin)
	const [newPlan, setNewPlan] = useState(license.planId)

	const [newOrgId, setNewOrgId] = useState('')

	const [newStripeId, setNewStripeId] = useState('')
	const [newSubId, setNewSubId] = useState('')
	const [newEmail, setNewEmail] = useState('')
	const [emailInvalid, setEmailInvalid] = useState(false)

	const showModal = (modalToShow) => {
		//default all other modals and put the need modal as visible
		setModalVisible({
			...defaultModalVisibility,
			[modalToShow]: true
		});
	}
	const handleCancel = () => {
		//reset state of the rest of the items
		setNewActiveState(license.active);
		setNewAdminStatus(license.orgAdmin);
		setNewPlan(license.planId);
		setNewOrgId('');
		setNewStripeId('');
		setNewSubId('');
		setNewEmail('');
		setEmailInvalid(false);

		//set all modals to not visible
		setModalVisible(defaultModalVisibility);
	}
	async function handleUpdateEmail(event) {
		setEmailInvalid(false)
		setConfirmLoading(true)

		//verify email.
		if (validateEmail(newEmail) === false) {
			setEmailInvalid(true)
			setConfirmLoading(false)
			return
		}
		//send request to the API
		const apiRequestBody = {
			currentEmail: license.email,
			newEmail: newEmail,
		}

		try {
			await requestScanApi(apiRequestBody);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)
			setNewEmail('')
			updateEmailAndLoadNewData(newEmail)
		}
	}

	async function handleExtendTrial(event) {

		setConfirmLoading(true)

		//send request to the API
		const apiRequestBody = {
			action: 'extendTrial',
			userId: license.userId
		}

		try {
			await requestScanApi(apiRequestBody);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)

			updateEmailAndLoadNewData(license.email);
		}
	}

	async function fixTrial(event) {

		setConfirmLoading(true)

		//send request to the API
		const apiRequestBody = {
			action: 'fixTrial',
			userId: license.userId
		}

		try {
			await requestScanApi(apiRequestBody);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)

			updateEmailAndLoadNewData(license.email);
		}
	}

	async function handleGCC(event) {

		setConfirmLoading(true)

		//send request to the API
		const apiRequestBody = {
			action: 'gcc',
			userId: license.userId
		}

		try {
			await requestScanApi(apiRequestBody);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)

			updateEmailAndLoadNewData(license.email);
		}
	}

	async function cancelAtEnd(event) {

		setConfirmLoading(true)

		//send request to the API
		const apiRequestBody = {
			action: 'cancelAtEnd',
			userId: license.userId
		}

		try {
			await requestScanApi(apiRequestBody);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)

			updateEmailAndLoadNewData(license.email);
		}
	}

	async function handleDeleteAccount(event) {
		setConfirmLoading(true)
		const apiRequest = {
			action: 'deleteAccount',
			email: license.email
		};
		try {
			await API.del('scan', '/scan', { body: apiRequest })
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)
			//for reloading the data
			updateEmailAndLoadNewData(license.email);
		}
	}
	async function handleEditActive(event) {
		setConfirmLoading(true)
		const apiRequest = {
			action: 'toggleActive',
			newActiveState: newActiveState,
			userId: license.userId
		};
		try {
			await requestScanApi(apiRequest);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)
			//for reloading the data
			updateEmailAndLoadNewData(license.email);
		}
	}
	async function handleEditOrg(event) {
		setConfirmLoading(true)
		const apiRequest = {
			action: 'editOrg',
			newOrg: newOrgId,
			email: license.email,
			userId: license.userId
		};
		try {
			await requestScanApi(apiRequest);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)
			setNewOrgId('')
			//for reloading the data
			updateEmailAndLoadNewData(license.email);
		}
	}

	async function handleEditStripe(event) {
		setConfirmLoading(true)
		const apiRequest = {
			action: 'editStripe',
			stripeId: newStripeId,
			subId: newSubId,
			userId: license.userId
		};
		try {
			await requestScanApi(apiRequest);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)
			setNewStripeId('')
			setNewSubId('')
			//for reloading the data
			updateEmailAndLoadNewData(license.email);
		}
	}

	async function handleEditAdminStatus(event) {
		setConfirmLoading(true)
		const apiRequest = {
			action: 'editOrgAdminStatus',
			newAdminStatus: newAdminStatus,
			userId: license.userId
		};
		try {
			await requestScanApi(apiRequest);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)
			//for reloading the data
			updateEmailAndLoadNewData(license.email);
		}
	}
	async function handleEditPlan(event) {
		setConfirmLoading(true)
		const apiRequest = {
			action: 'editPlan',
			newPlan: newPlan,
			userId: license.userId
		};
		try {
			await requestScanApi(apiRequest);
		} finally {
			setConfirmLoading(false)
			setModalVisible(defaultModalVisibility)
			//for reloading the data
			updateEmailAndLoadNewData(license.email);
		}
	}

	const columns = [
		{
			title: 'active',
			dataIndex: 'active',
			key: 'active',
			render: (text, record) => (
				<EditableField
					onClick={() => {
						showModal('editActive')
					}}
					text={text == true ? 'true' : 'false'}
				/>
			),
		},
		{
			title: 'userId',
			dataIndex: 'userId',
			key: 'userId',
		},
		{
			title: 'email',
			dataIndex: 'email',
			key: 'email',
			render: (text, record) => (
				<EditableField
					onClick={() => {
						showModal('changeEmail')
					}}
					text={text}
				/>
			),
		},
		{
			title: 'planId',
			dataIndex: 'planId',
			key: 'planId',
			render: (text, record) => (
				<EditableField
					onClick={() => {
						showModal('editPlan')
					}}
					text={text}
				/>
			),
		},{
			title: 'Gcc',
			dataIndex: 'gcc',
			key: 'gcc',
			render: (text, record) => (
				<>{text == true ? 'true' : 'false'}</>
			),
		},

		{
			title: 'stripe id',
			dataIndex: 'stripeUserId',
			key: 'stripeUserId',
			render: (text, record) => (

				<EditableField
					onClick={() => {
						showModal('editStripe')
					}}
					text={text}
				/>
			),
		},
		{
			title: 'sub id',
			dataIndex: 'subscriptionId',
			key: 'subscriptionId',
			render: (text, record) => (

				<EditableField
					onClick={() => {
						showModal('editStripe')
					}}
					text={text}
				/>
			),
		},
		{
			title: 'orgId',
			dataIndex: 'orgId',
			key: 'orgId',
			render: (text, record) => (
				<EditableField
					onClick={() => {
						showModal('editOrg')
					}}
					text={text}
				/>
			),
		},
		{
			title: 'orgAdmin',
			dataIndex: 'orgAdmin',
			key: 'orgAdmin',
			render: (text, record) => (
				<EditableField
					onClick={() => {
						showModal('editOrgAdmin')
					}}
					text={text ? 'true' : 'false'}
				/>
			),
		},
		{
			title: 'State',
			dataIndex: 'state',
			key: 'state',
			render: (text, record) => (

				<>{text}</>
			),
		},
		{
			title: 'Vanity Alias',
			dataIndex: 'vanityAlias',
			key: 'vanityAlias',
			render: (text, record) => (
				<>{text}</>
			),
		},
		{
			title: 'V3 Limited',
			dataIndex: 'v3limit',
			key: 'v3limit',
			render: (text, record) => (
				<>{text ? 'true' : 'false'}</>
			),
		},
		{
			title: 'Delete User',
			dataIndex: 'delete',
			key: 'delete',
			render: (text, record) => (
				<Button
					type={'primary'}
					onClick={() => {
						showModal('deleteAccount')
					}}
				>
					Delete
				</Button>
			),
		},



	]
	const dataSource = [
		{
			key: '1',
			...license,
		},
	]

	return (
		<>
			<h4>License:</h4>
			<Table
				scroll={{ x: 'max-content' }}
				dataSource={dataSource}
				columns={columns}
				pagination={false}
				bordered={true}
			/>
			<NewEmailModal
				currentEmail={license.email}
				handleOk={handleUpdateEmail}
				handleCancel={handleCancel}
				visible={modalVisible.changeEmail}
				newEmail={newEmail}
				setNewEmail={setNewEmail}
				confirmLoading={confirmLoading}
				emailInvalid={emailInvalid}
			/>
			<EditPlanModal
				currentPlan={license.planId}
				handleOk={handleEditPlan}
				handleCancel={handleCancel}
				visible={modalVisible.editPlan}
				setNewPlan={setNewPlan}
				confirmLoading={confirmLoading}
			/>
			<EditOrgModal
				currentOrg={license.orgId}
				handleOk={handleEditOrg}
				handleCancel={handleCancel}
				newOrgId={newOrgId}
				setNewOrgId={setNewOrgId}
				visible={modalVisible.editOrg}
				confirmLoading={confirmLoading}
			/>
			<EditStripeModal
				stripeId={license.stripeUserId}
				subId={license.subscriptionId}
				handleOk={handleEditStripe}
				handleCancel={handleCancel}
				newStripeId={newStripeId}
				setNewStripeId={setNewStripeId}
				newSubId={newSubId}
				setNewSubId={setNewSubId}
				visible={modalVisible.editStripe}
				confirmLoading={confirmLoading}
			/>
			<EditOrgAdminModal
				currentOrg={license.orgAdmin}
				handleOk={handleEditAdminStatus}
				handleCancel={handleCancel}
				visible={modalVisible.editOrgAdmin}
				setNewAdminStatus={setNewAdminStatus}
				confirmLoading={confirmLoading}
			/>
			<EditActiveModal
				currentActive={license.active}
				handleOk={handleEditActive}
				handleCancel={handleCancel}
				visible={modalVisible.editActive}
				setNewActiveState={setNewActiveState}
				confirmLoading={confirmLoading}
			/>
			<DeleteAccountModal
				visible={modalVisible.deleteAccount}
				handleCancel={handleCancel}
				confirmLoading={confirmLoading}
				handleOk={handleDeleteAccount}
			/>
			
			{/* {!license.gcc && (<div><Button type="primary" onClick={e => {
				handleGCC()
			}}>Enable GCC High</Button></div>)} */}
			{license.trial && license.nagStatus != 'extended' && (<div><Button type="primary" onClick={e => {
				handleExtendTrial()
			}}>Extend Trial</Button></div>)}
			{license.orgId == 'single' && license.stripeUserId == 'trial' && license.trial == false && (<div><Button type="primary" onClick={e => {
				fixTrial()
			}}>FIX NO SUCH USER 'TRIAL'</Button></div>)}
			{!license.trial && (license.stripeUserId != 'trial' && license.stripeUserId != 'site') && license.state != 'cancel_at_end' &&  (<div className="flex items-end justify-end"><Button type="primary" onClick={e => {
				cancelAtEnd()
			}}>Cancel At End Of Billing Period</Button></div>)}
		</>
	)
}

const EditableField = ({ onClick, text }) => {
	return (
		<div style={{ display: 'block' }}>
			{text}
			<span style={{ cursor: 'pointer', color: 'red' }} onClick={onClick}>
				<FontAwesomeIcon icon={faPencilAlt} />
			</span>
		</div>
	)
}

export default LicenseInfo
