import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'
import {
  CBButton,
  CBHeader,
  CBListItem,
  CBList,
  CBAlert,
} from '../../core/components/cbd'

import Sync from '../../../images/svg/sync.svg'
import moment from 'moment'
import {
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import {
  Card,
  Col,
  Row,
  Switch,
  Popconfirm,
  Button,
  Dropdown,
  Menu,
  Modal,
  Progress,
  Spin,
  Alert,
} from 'antd'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import {
  colorPositive,
  colorNeutral,
  colorNeutralDark,
  colorNegative,
} from '../../../utils/colors'
import { faOutlook, accountIconMap } from '../../../utils/customIcons'
import { syncTypeText, syncFilterText } from '../../../utils/words'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import ColorPicker from './ColorPicker'
import GoogleLogo from '../../../images/svg/google-logo.svg'

import Connections from '../../../images/svg/connections.svg'
import Trash from '../../../images/svg/trash-delete.svg'

import Edit from '../../../images/svg/edit.svg'

import More from '../../../images/svg/menu-more-horizontal.svg'
import { colorOutlook } from '../../../utils/colors'
import { navigate } from 'gatsby'
// const syncStatusMap = {
//   synced: colorNeutral,
//   syncing: colorPositive,
//   synced_error: colorNegative,
//   deleting: colorNegative,
// }

const syncStatusMap = {
  synced: 'normal',
  syncing: 'normal',
  delete_error: 'exception',
  synced_error: 'exception',
  sync_conflict: 'exception',
  sync_conflict_onecal: 'exception',
  deleting: 'exception',
  synced_renew: 'exception',
}

const SyncItem = ({ item, preview = false }) => {
  const [accounts] = useGlobalState('accounts')
  const [license] = useGlobalState('license')
  const [disableButton, setDisableButton] = useState(false)
  const findSource = accounts.filter(
    (a) => item.sourceCalendarAccountId === a.calendarAccountId
  )
  {
    // console.log(item)
  }
  const sourceAccount =
    findSource.length > 0
      ? findSource[0]
      : item.sourceCalendarType == 'ics'
      ? { email: 'ICS URL' }
      : { email: 'Account missing please add again or contact support' }
  console.log(sourceAccount)
  const findDest = accounts.filter(
    (a) => item.destCalendarAccountId === a.calendarAccountId
  )
  const destAccount =
    findDest.length > 0
      ? findDest[0]
      : { email: 'Account missing please add again or contact support' }

  if (!sourceAccount || !destAccount) {
    console.log('FAILED')
    return null
  }

  function getTypeIcon(type) {
    if (type == 'ics') {
      return (
        <div className="flex justify-center mt-1">
          <Connections></Connections>
        </div>
      )
    }
    return (
      <FontAwesomeIcon
        icon={accountIconMap[type].icon}
        style={{
          marginLeft: 0,
          marginRight: 0,
          display: 'inline-block',
        }}
      />
    )
  }

  function resolveError() {
    var str = item.errorState
    if (str.includes('Auth token refresh failed for')) {
      //re-authenticate account with link
      var accountId = str.substring(str.indexOf('for ') + 4)

      if (item.status == 'delete_error') {
        return (
          <>
            <a href={`/dashboard/accounts/`}>
              <CBAlert
                message={`Delete Sync Issue: please Re-Authenticate Account: ${
                  accountId == destAccount.calendarAccountId
                    ? destAccount.email
                    : sourceAccount.email
                } or Force Delete`}
                type="error"
                showIcon
              >{`Delete Sync Issue: please Re-Authenticate Account: ${
                accountId == destAccount.calendarAccountId
                  ? destAccount.email
                  : sourceAccount.email
              } or Force Delete`}</CBAlert>
            </a>
          </>
        )
      } else {
        return (
          <>
            <a href={`/dashboard/accounts/`}>
              <CBAlert
                message={`Account Authentication Issue: Please tap here to Re-Authenticate: ${
                  accountId == destAccount.calendarAccountId
                    ? destAccount.email
                    : sourceAccount.email
                }`}
                type="error"
                showIcon
              >{`Account Authentication Issue: Please tap here to Re-Authenticate: ${
                accountId == destAccount.calendarAccountId
                  ? destAccount.email
                  : sourceAccount.email
              }`}</CBAlert>
            </a>
          </>
        )
      }
    } else if (str.includes('timeout') || str.includes('timed out')) {
      return (
        <>
          <a href="https://help.calendarbridge.com/doc/sync-troubleshooting/temporary-connection-issues/">
            <CBAlert
              message="Temporary connection issue with calendar services"
              type="info"
              showIcon
            >
              Temporary connection issue with calendar services
            </CBAlert>
          </a>
        </>
      )
    } else if (str.includes('Forbidden') && str.includes('Google')) {
      return (
        <>
          <a href="https://help.calendarbridge.com/doc/account-management/reauthenticating-with-a-google-account/">
            <CBAlert
              message="Google Permissions Error: Forbidden, please make sure permissions are correct, click for help"
              type="error"
              showIcon
            >
              Google Permissions Error: Forbidden, please make sure permissions
              are correct, click for help
            </CBAlert>
          </a>
        </>
      )
    } else if (
      str.includes('insufficientPermissions') &&
      str.includes('Google')
    ) {
      return (
        <>
          <a href="https://help.calendarbridge.com/doc/account-management/reauthenticating-with-a-google-account/">
            <CBAlert
              message="Google Permissions Error: Forbidden, please make sure permissions are correct, click for help"
              type="error"
              showIcon
            >
              Google Permissions Error: Forbidden, please make sure permissions
              are correct, click for help
            </CBAlert>
          </a>
        </>
      )
    } else if (
      str.includes('rateLimitExceeded') ||
      str.includes('usageLimits')
    ) {
      return (
        <>
          <a href="https://help.calendarbridge.com/doc/sync-troubleshooting/rate-limiting/">
            <CBAlert
              message="Calendar Service API Rate Limit Reached: Please refrain from deleting, editing, or creating new syncs until this message is cleared"
              type="info"
              showIcon
            >
              Calendar Service API Rate Limit Reached: Please refrain from
              deleting, editing, or creating new syncs until this message is
              cleared
            </CBAlert>
          </a>
        </>
      )
    } else if (item.status == 'sync_conflict_onecal') {
      return (
        <>
          <a href="https://help.calendarbridge.com/doc/sync-troubleshooting/">
            <CBAlert
              message={
                'Conflicting sync software detected. Onecal has been flagged as a security risk.'
              }
              type="error"
              showIcon
            >
              Conflicting sync software detected. Onecal has been flagged as a
              security risk.
            </CBAlert>
          </a>
        </>
      )
    } else if (item.status == 'sync_conflict') {
      return (
        <>
          <a href="https://help.calendarbridge.com/doc/sync-troubleshooting/">
            <CBAlert
              message={
                'Conflicting sync software detected (Reclaim or Clockwise). Due to privacy, security, and quality of service concerns events managed by those services have not been synced.'
              }
              type="error"
              showIcon
            >
              Conflicting sync software detected (Reclaim or Clockwise). Due to
              privacy, security, and quality of service concerns events managed
              by those services have not been synced.
            </CBAlert>
          </a>
        </>
      )
    } else if (str.includes('Invalid User')) {
      return (
        <>
          <a href="https://help.calendarbridge.com/doc/sync-troubleshooting/">
            <CBAlert
              message={
                'Destination Microsoft account has changed. Please delete and recreate sync connection'
              }
              type="info"
              showIcon
            >
              Destination Microsoft account calendar has changed. Please delete
              and recreate sync connection
            </CBAlert>
          </a>
        </>
      )
    } else {
      //divine the 403
      if (item.status == 'delete_error') {
        return (
          <>
            <a href={`/dashboard/accounts/`}>
              <CBAlert
                message="Delete Sync Issue: Please Re-Authenticate Accounts or 'Force Delete' "
                type="error"
                showIcon
              >
                Delete Sync Issue: Please Re-Authenticate Accounts or 'Force
                Delete' from pulldown
              </CBAlert>
            </a>{' '}
          </>
        )
      } else if (item.status == 'deleting') {
        return (
          <a onClick={(e) => window.location.reload()}>
            <CBAlert message="De-Syncing, tap to refresh" type="info" showIcon>
              De-Syncing, tap to refresh
            </CBAlert>
          </a>
        )
      } else if (item.status == 'synced_renew') {
        return (
          <a
            onClick={(e) =>
              navigate(
                `/dashboard/syncs/${encodeURIComponent(
                  item.id.replace('#', '___')
                )}/edit`
              )
            }
          >
            <CBAlert message="Tap to Edit" type="info" showIcon>
              Tap to Edit
            </CBAlert>
          </a>
        )
      } else if (str == '' || str == null || str == undefined) {
        return <></>
      } else {
        return <></>
        //flesh out 403 google shit
        // return (
        //   <a href={`https://help.calendarbridge.com/request-support/`}>
        //     <Alert
        //       message="Sync issue please tap here to contact support. Do not delete or edit sync or re-authenticate any accounts"
        //       type="error"
        //       showIcon
        //     ></Alert>
        //   </a>
        // )
      }
    }
  }

  function showForceDeleteConfirm() {
    Modal.confirm({
      title:
        'Are you sure you want to force delete this sync? Copies will not be deleted!',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        await API.del('bridge', '/bridge?force=true', {
          body: {
            sourceCalendarId: item.sourceCalendarId,
            destCalendarId: item.destCalendarId,
          },
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'dashboardEvent',
          category: 'userActivity',
          action: 'deleteSync',
          label: license.userId,
          email: license.email,
        })

        window.location.reload()
      },
    })
  }

  async function bumpSync() {
    try {
      setDisableButton(true)
      await API.del('bridge', '/bridge?resync=true&bump=true', {
        body: {
          sourceCalendarId: item.sourceCalendarId,
          destCalendarId: item.destCalendarId,
        },
      })
      window.location.reload()
    } catch (ex) {
      setDisableButton(false)
      window.location.reload()
    }
  }
  function showResyncConfirm() {
    Modal.confirm({
      title:
        'Resyncing requires all copies to be recreated. This process can take time based on how many events you have synced. Continue?',
      okText: 'Start Resync',
      okType: 'danger',
      cancelText: 'Cancel',
      async onOk() {
        try {
          await API.del('bridge', '/bridge?resync=true', {
            body: {
              sourceCalendarId: item.sourceCalendarId,
              destCalendarId: item.destCalendarId,
            },
          })
          window.location.reload()
        } catch (ex) {
          alert(
            'You may only resync once every 8 hours. Next resync at: ' +
              new Date(item.resynced + 1000 * 60 * 60 * 8).toLocaleString()+ ", if you can't wait you can do an *Edit*"
          )
        }
      },
    })
  }

  function showEditConfirm() {
    Modal.confirm({
      title:
        'Editing requires all copies to be deleted. This process can take time based on how many events you have synced. Continue?',
      okText: 'Start Edit',
      okType: 'danger',
      cancelText: 'Cancel',
      async onOk() {
        await API.del('bridge', '/bridge?edit=true', {
          body: {
            sourceCalendarId: item.sourceCalendarId,
            destCalendarId: item.destCalendarId,
          },
        })

        window.location.reload()
      },
    })
  }
  function showDeleteConfirm() {
    Modal.confirm({
      title: 'Are you sure you want to delete this sync?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <DeleteOutlined />,
      async onOk() {
        await API.del('bridge', '/bridge', {
          body: {
            sourceCalendarId: item.sourceCalendarId,
            destCalendarId: item.destCalendarId,
          },
        })

        window.location.reload()
      },
    })
  }

  const handleClick = (e) => {
    if (e === '0') {
      showForceDeleteConfirm()
    } else if (e === '1') {
      showDeleteConfirm()
    } else if (e === '2') {
      showEditConfirm()
    } else if (e === '3') {
      navigate(
        `/dashboard/syncs/${encodeURIComponent(
          item.id.replace(item.id.replace('#', '___'))
        )}/edit`
      )
    } else if (e === '4') {
      showResyncConfirm()
    } else if (e === '5') {
      bumpSync()
    }
  }

  const menu = (
    <Menu onClick={handleClick} style={{ width: 125 }}>
      {/* <Menu.Item key="0">
        <Icon type="stop" />
        Disable
      </Menu.Item> */}

      {item.status == 'delete_error' && (
        <Menu.Item key="0">
          <div className="flex gap-x-4 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
            Force Delete
          </div>
        </Menu.Item>
      )}
      {item.status != 'delete_error' && (
        <Menu.Item key="1">
          <div className="flex gap-x-4 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
            Delete
          </div>
        </Menu.Item>
      )}
      {item.status == 'synced_renew' && (
        <Menu.Item key="3">
          <div className="flex gap-x-4 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
            Finish Edit
          </div>
        </Menu.Item>
      )}
      {item.status == 'synced' && (
        <Menu.Item key="2">
          <div className="flex gap-x-4 items-center">
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
            Edit
          </div>
        </Menu.Item>
      )}
      {!disableButton &&
        item.lastSyncTime + 5 * 60 * 1000 < new Date().getTime() &&
        item.status == 'syncing' && (
          <Menu.Item key="5">
            <div className="flex gap-x-4 items-center">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
              Repair
            </div>
          </Menu.Item>
        )}
      {!item.twoWay &&
        item.status != 'syncing' &&
        item.status != 'deleting' &&
        (item.status == 'synced' || item.status == 'synced_error') && (
          <Menu.Item key="4">
            <div className="flex gap-x-4 items-center">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
              Resync
            </div>
          </Menu.Item>
        )}
    </Menu>
  )

  function renderMenuItems() {
    return (
      <div className="flex flex-row gap-2">
        {/* <Menu.Item key="0">
          <Icon type="stop" />
          Disable
        </Menu.Item> */}

        {item.status == 'synced_renew' && (
          <div key="3" onClick={(e) => handleClick('3')}>
            <div className="menu-thing">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                />
              </svg>
              Finish Edit
            </div>
          </div>
        )}
        {item.status == 'synced' && (
          <div key="2" onClick={(e) => handleClick('2')}>
            <div className="menu-thing">
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                />
              </svg>
              Edit
            </div>
          </div>
        )}
        {!item.twoWay &&
          item.status != 'syncing' &&
          item.status != 'deleting' &&
          (item.status == 'synced' || item.status == 'synced_error') && (
            <div key="4" onClick={(e) => handleClick('4')}>
              <div className="menu-thing">
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                Resync
              </div>
            </div>
          )}
        {!disableButton &&
          item.lastSyncTime + 5 * 60 * 1000 < new Date().getTime() &&
          item.status == 'syncing' && (
            <div key="4" onClick={(e) => handleClick('5')}>
              <div className="menu-thing">
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                Repair
              </div>
            </div>
          )}
        {item.status == 'delete_error' && (
          <div key="0" onClick={(e) => handleClick('0')}>
            <div className="menu-thing">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
              Force Delete
            </div>
          </div>
        )}
        {item.status != 'deleting' &&
          item.status != 'syncing' &&
          item.status != 'delete_error' && (
            <div key="1" onClick={(e) => handleClick('1')}>
              <div className="menu-thing">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                Delete
              </div>
            </div>
          )}
      </div>
    )
  }

  function getTime() {
    if (item.startCutOff == -1) {
      return 'None'
    } else {
      return `${item.startCutOff % 12} ${
        item.startCutOff < 12 ? 'AM' : 'PM'
      } - ${item.endCutOff % 12} ${item.endCutOff < 12 ? 'AM' : 'PM'}`
    }
  }
  function getColor() {
    if (item.filterColor == '11') {
      return (
        <>
          <div
            className="w-4 h-4 rounded-full"
            style={{ background: '#D50000' }}
          />
        </>
      )
    } else if (item.filterColor == '10') {
      return (
        <>
          <div
            className="w-4 h-4 rounded-full"
            style={{ background: '#0B8043' }}
          />
        </>
      )
    } else if (item.filterColor == '3') {
      return (
        <>
          <div
            className="w-4 h-4 rounded-full"
            style={{ background: '#3F51B5' }}
          />
        </>
      )
    } else if (item.filterColor == 'Green category') {
      return (
        <>
          <div
            className="w-4 h-4 rounded-full"
            style={{ background: '#00ff00' }}
          />
        </>
      )
    } else if (item.filterColor == 'Yellow category') {
      return (
        <>
          <div className="w-4 h-4 rounded-full bg-yellow-200" />
          <div>Yellow</div>
        </>
      )
    } else if (item.filterColor == 'Red category') {
      return (
        <>
          <div className="w-4 h-4 rounded-full bg-red-500" />
        </>
      )
    } else if (item.filterColor == 'none') {
      return 'None'
    } else {
      return item.filterColor
    }
  }

  function renderSyncInfo() {
    return (
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-y-2 gap-x-1 items-start flex-wrap ">
        {/* <div className="flex-row flex justify-center gap-2 border pt-2 pb-2">
          <div>Tag:</div>
          <div>
            {item.tag == 'default'
              ? '(copy)'
              : item.tag == ' '
              ? 'No Tag'
              : item.tag}
          </div>
        </div> */}
        <div className="flex-row flex justify-start gap-2 pt-2 pb-2 lg:col-span-2">
          <div className="font-bold">Fields:</div>
          {syncTypeText(
            item.securityPolicy.includes('subject'),
            item.securityPolicy.includes('description'),
            item.securityPolicy.includes('location'),
            item.securityPolicy.includes('reminder'),
            item.securityPolicy.includes('private'),
            item.securityPolicy.includes('meeting'),
            item.securityPolicy.includes('contact'),
            item.securityPolicy.includes('tag')
          )}
        </div>

        <div className="flex-row flex justify-center gap-2 pt-2 pb-2">
          <div className="font-bold">Status:</div>{' '}
          <div>
            {!preview && item.status === 'synced' && 'Active and Up-to-Date'}
            {!preview && item.status === 'syncing' && 'Active and Up-to-Date'}
            {!preview && item.status === 'synced_renew' && (
              <div>Please Complete Edit</div>
            )}
            {!preview && item.status === 'deleting' && <div>Clearing sync</div>}
            {!preview && item.status === 'synced_error' && (
              <div>Sync issue</div>
            )}
            {!preview && item.status === 'sync_conflict' && (
              <div>Sync software conflict</div>
            )}
            {!preview && item.status === 'sync_conflict_onecal' && (
              <div>Sync Disabled</div>
            )}
            {!preview &&
              (item.status === 'synced' || item.status === 'syncing') &&
              (item.sourceCalendarType == 'ics' ||
                item.sourceCalendarType == 'caldav') && (
                <div className="text-xs">
                  ({item.sourceCalendarType == 'caldav' ? 'iCloud' : 'ICS'}{' '}
                  Updates every 5-10 min)
                </div>
              )}
            {item.status !== 'synced' &&
              item.status !== 'synced_renew' &&
              item.status !== 'sync_conflict' &&
              item.status !== 'synced_error' &&
              item.status !== 'deleting' && (
                <div className="flex items-center p-2 flex-grow justify-center">
                  <Progress
                    type="circle"
                    percent={Math.round((item.syncProgress / 12) * 100)}
                    width={30}
                    status={syncStatusMap[item.status]}
                  />
                </div>
              )}
            {syncStatusMap[item.status] == 'exception' && <>{''}</>}
          </div>
        </div>

        {item.hasFilters && (
          <div className="flex-row flex justify-center gap-2 border pt-2 pb-2">
            <div className="font-bold">Filter Status:</div>{' '}
            {syncFilterText(
              item.sourceCalendarType,
              item.syncLevel.includes('free'),
              item.syncLevel.includes('busy'),
              item.syncLevel.includes('tentative'),
              item.syncLevel.includes('oof'),
              item.syncLevel.includes('workingElsewhere')
            )}
          </div>
        )}
        {item.hasFilters && (
          <div className="flex-row flex justify-center gap-2 border pt-2 pb-2 items-center">
            <div className="font-bold">Filter Color:</div> {getColor()}
          </div>
        )}
        {item.hasFilters && item.sourceCalendarType == 'google' ? (
          <div className="flex-row flex justify-center gap-2 border pt-2 pb-2">
            <div className="font-bold">Exclude unconfirmed:</div>{' '}
            {item.excludeInvites ? 'Yes' : 'No'}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="rounded-sm border border-neutral_1 mb-10 flex flex-col shadow-md">
        <div className="flex flex-col pl-4 pr-4">
          {/* <div>
            {!preview && (
              <CBHeader
                label={
                  <div>
                    {`${item.sourceCalendarName}`} {item.twoWay ? '<-->' : '->'}{' '}
                    {`${item.destCalendarName}`}
                  </div>
                }
                icon={<Sync></Sync>}
                menu={
                  <div className="flex-grow">
                    {!preview && (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <CBButton type="ternary" icon={<More />} />
                      </Dropdown>
                    )}
                  </div>
                }
              ></CBHeader>
            )}
          </div> */}

          {renderSyncLeg(
            getTypeIcon,
            item,
            sourceAccount,
            preview,
            destAccount,
            'source'
          )}
          {/* {item.twoWay && <div className="flex justify-center"><div className="font-manrope text-2xl">{'<-'}</div></div>} */}
          {/* {item.twoWay && renderSyncLeg(getTypeIcon, item, sourceAccount, preview, destAccount,"dest")} */}
          {/* <div className="flex flex-row items-center text-center justify-items-center">
            {item.status !== 'synced' && (
              <div className="flex items-center p-2 flex-grow justify-end">
                <Progress
                  type="circle"
                  percent={Math.round((item.syncProgress / 12) * 100)}
                  width={30}
                  status={syncStatusMap[item.status]}
                />
              </div>
            )}
          </div> */}
        </div>
        <div className="pl-4 p-2 flex flex-col justify-center">
          {!preview ? (
            <>
              <div>{renderSyncInfo()}</div>
              {/* <CBList>
                <CBListItem
                  border={false}
                  icon={
                    <div className={'rounded-full p-2 bg-white text-sm'}>
                      {item.tag == 'default'
                        ? '(copy)'
                        : item.tag == ' '
                        ? 'No Tag'
                        : item.tag}
                    </div>
                  }
                >
                  Tag
                </CBListItem>
                <CBListItem
                  border={false}
                  icon={
                    <div className={'rounded-full p-2 lg:bg-white text-sm'}>
                      {syncTypeText(
                        item.securityPolicy.includes('subject'),
                        item.securityPolicy.includes('description'),
                        item.securityPolicy.includes('location'),
                        item.securityPolicy.includes('reminder'),
                        item.securityPolicy.includes('private'),
                        item.securityPolicy.includes('meeting'),
                        item.securityPolicy.includes('contact'),
                        item.securityPolicy.includes('tag')
                      )}
                    </div>
                  }
                >
                  Fields
                </CBListItem>

                <CBListItem
                  border={false}
                  icon={
                    <div className={'rounded-full p-2 bg-white text-sm'}>
                      {!preview &&
                        item.status === 'synced' &&
                        'Active and Up-to-Date'}
                      {!preview &&
                        item.status === 'syncing' &&
                        'Active and Up-to-Date'}
                      {!preview && item.status === 'synced_renew' && (
                        <div>Please Complete Edit</div>
                      )}
                      {!preview && item.status === 'deleting' && (
                        <div>Clearing sync</div>
                      )}
                      {!preview && item.status === 'synced_error' && (
                        <div>Sync issue</div>
                      )}
                      {!preview && item.status === 'sync_conflict' && (
                        <div>Sync software conflict</div>
                      )}
                      {item.status !== 'synced' &&
                        item.status !== 'synced_renew' &&
                        item.status !== 'sync_conflict' &&
                        item.status !== 'synced_error' &&
                        item.status !== 'deleting' && (
                          <div className="flex items-center p-2 flex-grow justify-center">
                            <Progress
                              type="circle"
                              percent={Math.round(
                                (item.syncProgress / 12) * 100
                              )}
                              width={30}
                              status={syncStatusMap[item.status]}
                            />
                          </div>
                        )}
                      {syncStatusMap[item.status] == 'exception' && <>{''}</>}
                    </div>
                  }
                >
                  Status
                </CBListItem>
              </CBList> */}
            </>
          ) : (
            <CBList>
              <CBListItem
                border={false}
                icon={
                  <div className={'rounded-full p-2 bg-white text-sm'}>
                    {item.tag == 'default'
                      ? '(copy)'
                      : item.tag == ' '
                      ? 'No Tag'
                      : item.tag}
                  </div>
                }
              >
                Tag
              </CBListItem>
              <CBListItem
                border={false}
                icon={
                  <div className={'rounded-full p-2 bg-white text-sm'}>
                    {syncTypeText(
                      item.securityPolicy.includes('subject'),
                      item.securityPolicy.includes('description'),
                      item.securityPolicy.includes('location'),
                      item.securityPolicy.includes('reminder'),
                      item.securityPolicy.includes('private'),
                      item.securityPolicy.includes('meeting'),
                      item.securityPolicy.includes('contact')
                    )}
                  </div>
                }
              >
                Fields
              </CBListItem>

              <CBListItem
                border={false}
                icon={
                  <div className={'rounded-full p-2 bg-white text-sm'}>
                    {item.destColor != 'default' ? (
                      <ColorPicker
                        type={item.destCalendarType}
                        color={item.destColor}
                        preview={true}
                      ></ColorPicker>
                    ) : (
                      <div>None</div>
                    )}
                    {item.matchColors && (
                      <div className="mt-2">Match Source Colors</div>
                    )}
                  </div>
                }
              >
                Color
              </CBListItem>
            </CBList>
          )}
          {preview && item.hasFilters && (
            <CBList>
              <CBListItem border={false} icon={<div className={''}></div>}>
                Event Filtering
              </CBListItem>

              <CBListItem
                border={false}
                icon={
                  <div className={'rounded-full p-2 lg:bg-white text-sm'}>
                    {syncFilterText(
                      item.sourceCalendarType,
                      item.syncLevel.includes('free'),
                      item.syncLevel.includes('busy'),
                      item.syncLevel.includes('tentative'),
                      item.syncLevel.includes('oof'),
                      item.syncLevel.includes('workingElsewhere')
                    )}
                  </div>
                }
              >
                Filter Status
              </CBListItem>

              <CBListItem
                border={false}
                icon={
                  <div className={'rounded-full p-2 lg:bg-white text-sm'}>
                    {getColor()}
                  </div>
                }
              >
                Filter Color
              </CBListItem>
              {item.sourceCalendarType == 'google' ? (
                <CBListItem
                  border={false}
                  icon={
                    <div className={'rounded-full p-2 lg:bg-white text-sm'}>
                      {item.excludeInvites ? 'Yes' : 'No'}
                    </div>
                  }
                >
                  Exclude unconfirmed invites
                </CBListItem>
              ) : (
                <CBListItem
                  border={false}
                  icon={<div className={''}></div>}
                ></CBListItem>
              )}
            </CBList>
          )}
          {syncStatusMap[item.status] == 'exception' && (
            <div className="mt-2">{resolveError()}</div>
          )}
          {!preview && (
            <div className="relative">
              <div className="absolute lg:-right-0 -right-0 -top-1">
                {renderMenuItems()}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SyncItem
function renderSyncLeg(
  getTypeIcon,
  item,
  sourceAccount,
  preview,
  destAccount,
  leg = 'source'
) {
  const sourceAccountType =
    leg == 'source' ? item.sourceCalendarType : item.destCalendarType
  const sourceCalendarName =
    leg == 'source' ? item.sourceCalendarName : item.destCalendarName
  const sourceCalendarEmail =
    leg == 'source' ? sourceAccount.email : destAccount.email
  const destAccountType =
    leg == 'source' ? item.destCalendarType : item.sourceCalendarType
  const destCalendarName =
    leg == 'source' ? item.destCalendarName : item.sourceCalendarName
  const destCalendarEmail =
    leg == 'source' ? destAccount.email : sourceAccount.email
  const destinationColor =
    leg == 'source' ? item.destinationColor : item.sourceColor

  return (
    <div
      className={`grid grid-cols-1 lg:grid-cols-${
        preview ? '1' : '3'
      } items-center justify-center justify-items-center p-2  `}
    >
      <div className="flex flex-col text-center justify-items-center pl-2 ">
        <div className="text-lg gap-2 flex flex-row items-center justify-start">
          <div className="">{getTypeIcon(sourceAccountType)}</div>

          <div className="w-40 truncate text-left ">{sourceCalendarName}</div>
        </div>

        <div className="rounded-full p-2  text-sm">
          {item.sourceCalendarType != 'ics' && (
            <div className="w-40 truncate">{sourceCalendarEmail}</div>
          )}
          {item.sourceCalendarType == 'ics' && (
            <div className="w-40 truncate">
              ...{item.sourceCalendarId.slice(-30)}
            </div>
          )}
        </div>
        <div></div>
      </div>
      {
        <div className="flex flex-col justify-center font-manrope text-2xl ml-1 text-center">
          <div>{item.twoWay ? '<-->' : '->'}</div>
          {!preview && (
            <div className="text-sm flex flex-row gap-1 mt-2 mb-2">
              <div>Tag:</div>
              <div>
                {item.tag == 'default'
                  ? '(copy)'
                  : item.tag == ' '
                  ? 'No Tag'
                  : item.tag}
              </div>
            </div>
          )}
          {destinationColor != null &&
            item.destinationColor != 'default' &&
            item.destinationColor != 'same' && (
              <div className="flex flex-col justify-center">
                <ColorPicker
                  type={destAccountType}
                  color={destinationColor}
                  preview={true}
                ></ColorPicker>
                {item.matchColors && <div>Match Source Colors</div>}
              </div>
            )}
        </div>
      }

      <div className="flex flex-col text-center justify-items-center">
        <div className="text-lg gap-2 flex flex-row items-center justify-start">
          <div>{getTypeIcon(destAccountType)}</div>
          <div className="w-40 truncate text-left">{destCalendarName}</div>
        </div>
        <div className="rounded-full p-2  text-sm">
          <div className="w-40 truncate">{destCalendarEmail}</div>
        </div>
        <div></div>
      </div>
    </div>
  )
}
