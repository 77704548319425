import momentTz from 'moment-timezone'

export const DEFAULT_STATE = {
  currentPage: 0,

  // Name and Description
  eventSubject: '',
  eventDescription: '',

  // Availability
  parentId: null,
  increment: null,

  // Invitees
  guests: [],
  contactGroups: [],
  guestsToSave: [],

  // Time
  range: [momentTz(), momentTz().add(1, 'month')],
  freeTimes: {}, // TODO verify that this is an object and not an array
  excludedDays: [],
  friends: [],
  selectedItems: [],
  singleSelect: false,
	firstFree: momentTz()
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setCurrentPage':
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case 'setEventSubject':
      return {
        ...state,
        eventSubject: action.eventSubject,
      }
    case 'setEventDescription':
      return {
        ...state,
        eventDescription: action.eventDescription,
      }
    case 'setParentId':
      return {
        ...state,
        parentId: action.parentId,
      }
    case 'setIncrement':
      return {
        ...state,
        increment: action.increment,
      }
    case 'setGuests':
      return {
        ...state,
        guests: action.guests,
      }
    case 'setContactGroups':
      return {
        ...state,
        contactGroups: action.contactGroups,
      }
    case 'setGuestsToSave':
      return {
        ...state,
        guestsToSave: action.guestsToSave,
      }
    case 'setRange':
      return {
        ...state,
        range: [...action.range],
      }
    case 'setFreeTimes':
      return {
        ...state,
        freeTimes: action.freeTimes,
      }
    case 'setExcludedDays':
      return {
        ...state,
        excludedDays: action.excludedDays,
      }
    case 'setFriends':
      return {
        ...state,
        friends: action.friends,
      }
    case 'setSelectedItems':
      return {
        ...state,
        selectedItems: action.selectedItems,
      }
    case 'setSingleSelect':
      return {
        ...state,
        singleSelect: action.singleSelect,
      }
    case 'setFirstFree':
      return {
        ...state,
        firstFree: action.firstFree,
      }
    default:
      throw new Error()
  }
}
