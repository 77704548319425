const v2_plans = {
    basic: {
      monthly: {
        value: 5,
        planId: process.env.GATSBY_STRIPE_MONTHLY_BASIC_PLAN,
        fullPrice: 6,
        book: 5,
      },
  
      yearly: {
        value: 4,
        planId: process.env.GATSBY_STRIPE_YEARLY_BASIC_PLAN,
        fullPrice: 5,
        book: 5,
      },
    },
    premium: {
      monthly: {
        syncs: 16,
        value: 10,
        planId: process.env.GATSBY_STRIPE_MONTHLY_PREMIUM_PLAN,
        fullPrice: 12,
        book: 5,
      },
  
      yearly: {
        syncs: 16,
        value: 8,
        planId: process.env.GATSBY_STRIPE_YEARLY_PREMIUM_PLAN,
        fullPrice: 10,
        book: 5,
      },
    },
    pro: {
      monthly: {
        syncs: 36,
        value: 20,
        planId: process.env.GATSBY_STRIPE_MONTHLY_PRO_PLAN,
        fullPrice: 24,
        book: 10,
      },
  
      yearly: {
        syncs: 36,
        planId: process.env.GATSBY_STRIPE_YEARLY_PRO_PLAN,
        fullPrice: 20,
        book: 10,
      },
    },
    pro: {
        monthly: {
          syncs: 36,
          value: 20,
          planId: process.env.GATSBY_STRIPE_MONTHLY_PRO_PLAN,
          fullPrice: 24,
          book: 10,
        },
    
        yearly: {
          syncs: 36,
          planId: process.env.GATSBY_STRIPE_YEARLY_PRO_PLAN,
          fullPrice: 20,
          book: 10,
          value:16*12
        },
      },
  }
  
  const v3_plans = {
    admin: {
      monthly: {
        syncs: 0,
        value: 0,
        planId: process.env.GATSBY_STRIPE_MONTHLY_BASIC_PLAN,
        fullPrice: 0,
        book: 0,
      },
  
      yearly: {
        syncs: 0,
        value: 0,
        planId: process.env.GATSBY_STRIPE_YEARLY_BASIC_PLAN,
        fullPrice: 0,
        book: 0,
      },
    },
    basic: {
      monthly: {
        syncs: 2,
        value: 5,
        planId: process.env.GATSBY_STRIPE_MONTHLY_BASIC_PLAN,
        fullPrice: 6,
        book: 5,
      },
  
      yearly: {
        syncs: 2,
        value: 4*12,
        planId: process.env.GATSBY_STRIPE_YEARLY_BASIC_PLAN,
        fullPrice: 5,
        book: 5,
      },
    },
    premium: {
      monthly: {
        syncs: 16,
        value: 10,
        planId: process.env.GATSBY_STRIPE_MONTHLY_PREMIUM_PLAN,
        fullPrice: 12,
        book: 5,
      },
  
      yearly: {
        syncs: 16,
        value: 8*12,
        planId: process.env.GATSBY_STRIPE_YEARLY_PREMIUM_PLAN,
        fullPrice: 10,
        book: 5,
      },
    },
    pro: {
      monthly: {
        syncs: 36,
        value: 40,
        planId: process.env.GATSBY_STRIPE_MONTHLY_PRO_V3_PLAN, 
        fullPrice: 48,
        book: 10,
      },
  
      yearly: {
        syncs: 36,
        value: 32*12,
        planId: process.env.GATSBY_STRIPE_YEARLY_PRO_v3_PLAN,
        fullPrice: 40,
        book: 10,
      },
    },
  }

  const sync_plans_old_monthly = 2.50
  const sync_plans_old_yearly = 24
  const sync_plans_new_monthly = 4
  const sync_plans_new_yearly = 38.4

  export {v2_plans, v3_plans,sync_plans_old_monthly,sync_plans_old_yearly,sync_plans_new_monthly,sync_plans_new_yearly}