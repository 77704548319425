import React, { useEffect } from 'react'
import Title from 'antd/lib/typography/Title'
import { Button, Row, Col } from 'antd'
import { Link } from 'gatsby'
import { useGlobalState } from '../../../store'
import { loadSchedules } from '../../../utils/fetchData'

const ScheduleCreateCompletePage = () => {
  const [, setLoadingCoreData] = useGlobalState('loadingCoreData')
  const [, setSchedules] = useGlobalState('schedules')
  useEffect(() => {
    setLoadingCoreData(true)
    async function fetchData() {
      const schedules = await loadSchedules()
      setSchedules(schedules)
      setLoadingCoreData(false)
    }
    fetchData()
  }, [])

  return (
    <>
      <SEO title="Scheduler Setup Success" />
      <Title style={{ textAlign: 'center', marginTop: 40 }}>
        Your scheduler is ready.
      </Title>
      <Title
        level={3}
        style={{ textAlign: 'center', marginTop: 0, marginBottom: 40 }}
      >
        Booking meetings just got a whole lot easier!
      </Title>
      <Row gutter={15}>
        <Col span={12}>
          <Link to="/dashboard/schedules/new">
            <Button block size="large" type="primary">
              Add Another
            </Button>
          </Link>
        </Col>
        <Col span={12}>
          <Link to="/dashboard/schedules">
            <Button block size="large">
              All Done!
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  )
}

export default ScheduleCreateCompletePage
