import React, { useState } from 'react'
import { CirclePicker } from 'react-color';

import { Col,Button,Row, Checkbox } from 'antd';

const ColorPicker = ({ type, color="default", onColorChangeCallback, preview=false, radius=50 }) => {
    
    const [pickedColor, setPickedColor] = useState("#fff")
    const msColors = ["#ffff00",
    "#ff0000",
    "#800080",
    "#ffa500",
    "#008000",
    "#0000ff"]
    const msMapping = ["Yellow category",
    "Red category",
    "Purple category",
    "Orange category",
    "Green category",
    "Blue category"]
   
    const googleColors = [
        "#a4bdfc",
        "#7ae7bf",
        "#dbadff",
        "#ff887c",
        "#fbd75b",
        "#ffb878",
        "#46d6db",
        "#e1e1e1",
        "#5484ed",
        "#51b749",
    ]
    const calendarType = type
    // console.log("CALENDAR TYPE")
    // console.log(calendarType)
    function colorChange(color,event) {
        
        //console.log(color)
        // if(color == "default") {
        //     setPickedColor("#fff")
        //     onColorChangeCallback("default")
        // } else {
        setPickedColor(color.hex)

        if(calendarType == "ms" || calendarType == "ews"){
            
            onColorChangeCallback(msMapping[msColors.indexOf(color.hex) != -1 ? msColors.indexOf(color.hex)  : 0])
        } else {
            onColorChangeCallback(`${(googleColors.indexOf(color.hex)+1)}`) 
        }
    // }
        
    }
    
const colors = !preview ? ((calendarType == "ms"||calendarType == "ews") ? msColors : googleColors) :
                            ((calendarType == "ms"||calendarType == "ews") ? [msMapping.indexOf(color) != -1 ? msColors[msMapping.indexOf(color)] : '#fff'] : [googleColors[parseInt(color)-1]])
if( color != 'default' && !preview && pickedColor=='#fff') {
    
    var colorPicked = '#fff'
    if (calendarType == "ms"||calendarType == "ews") {
        var idx = msMapping.indexOf(color)
        if(idx == -1){
            colorPicked = 'default'
        } else {
         colorPicked = msColors[idx]
        } 
         
    } else {
        colorPicked = googleColors[color-1]
    }
    
    setPickedColor(colorPicked)
} else {
    if(color == 'default' && pickedColor == '#fff') {
         setPickedColor('default')
    } else {
        //setPickedColor(color)
    }
}
    


      return <>
          {!preview && (
              <Row>
             <Col span={24}>
            <CirclePicker color={pickedColor} radius={radius} colors={colors} onChangeComplete={colorChange} />
            {/* {pickedColor != "#fff" && <center><Button onClick={e=> colorChange("default",e)}>x</Button></center>} */}
            </Col>   
                <Col span={24}>
            <Checkbox style={{marginLeft:6, marginTop:8}} onChange={(e)=>{
              if(e.target.checked) {
                  setPickedColor('default')
                  onColorChangeCallback('default')
              }
              }}
              checked={pickedColor == 'default'}>Calendar Default</Checkbox>
              </Col>
             
             
            </Row>
            )}
            {preview && (
            <center style={{marginTop:5, marginRight:20}}><CirclePicker width="5px" circleSize={20} colors={colors} /></center>
            )}
          </>;
    
  }

export default ColorPicker