import React from 'react'

import { Modal, Input } from 'antd'

const EditOrgModal = ({
	currentOrg,
	visible,
	handleOk,
	confirmLoading,
	handleCancel,
	newOrgId,
	setNewOrgId
}) => {

	const handleInputChange = (e) => {
		setNewOrgId(e.target.value)
	}
	return (
		<Modal
			title="Edit Org"
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<p>Change the Organization for a User</p>
			<Input.Group compact={false}>
				<Input
					style={{ paddingBottom: '1em' }}
					addonBefore={'Current Org'}
					value={currentOrg}
					readOnly={true}
				/>
				<Input
					style={{ paddingBottom: '1em' }}
					value={newOrgId}
					addonBefore={'New Org'}
					placeholder={'New Org'}
					onChange={handleInputChange}
				/>
			</Input.Group>
		</Modal>
	)
}

export default EditOrgModal
