import React, { useCallback, useMemo } from 'react'
import get from 'lodash/get'
import { useGroupScheduleContext } from './GroupScheduleContext'
import { CBSteps } from '../../../core/components/CBSteps'
import { CBStep } from '../../../core/components/CBStep'

export const Steps = () => {
  const { state, dispatch } = useGroupScheduleContext()

  const getGuestsSummary = useCallback((guestsState) => {
    if (guestsState.length) {
      if (guestsState.length > 2) {
        const first = guestsState.slice(0, 2).join(', ')
        const rest = ` +${guestsState.length - 2} more`
        return `${first}${rest}`
      }

			return guestsState.join(', ')
    }

    return ''
  }, [])

  const { currentPage, eventSubject, eventDescription, increment, guests } =
    useMemo(() => {
      const incrementState = get(state, 'increment')
      const guestsState = get(state, 'guests', [])
      
      return {
        currentPage: get(state, 'currentPage'),
        eventSubject: get(state, 'eventSubject'),
        eventDescription: get(state, 'eventDescription'),
        increment: incrementState ? `${incrementState} mins` : '',
        guests: getGuestsSummary(guestsState),
      }
    }, [state])

  const onPageChanged = useCallback(
    (newPage) => {
      dispatch({ type: 'setCurrentPage', currentPage: newPage })
    },
    [dispatch]
  )

  return (
    <div className="ml-8 mr-8">
      <CBSteps page={currentPage} onPageChanged={onPageChanged}>
        <CBStep
          primary="Name"
          secondary={`${eventSubject} ${eventDescription}`}
        />
        <CBStep primary="Availability" secondary={increment} />
        <CBStep primary="Invitees" secondary={guests} />
        <CBStep primary="Times" />
      </CBSteps>
    </div>
  )
}
