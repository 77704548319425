import React, { useCallback, useMemo, useEffect, useState } from 'react'
import {
  CBHeader,
  CBInput,
  CBCalendar,
  CBModal,
  SubmitButton,
} from '../../../../../core/components/cbd'
import { Col, Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { useGroupScheduleContext } from '../../GroupScheduleContext'
import Up from '../../../../../../images/svg/arrow-up-chevron.svg'
import get from 'lodash/get'
import map from 'lodash/map'
import { API } from 'aws-amplify'
import momentTz from 'moment-timezone'
import moment from 'moment'
import Edit from '../../../../../../images/svg/pencil.svg'
import { SelectedButtons } from './SelectedButtons'
import { GroupCalendarView } from './GroupCalendarView'
import GroupItem from '../../../../components/GroupItem'
import { useNavigate } from '@reach/router'

export const Times = () => {
  const { state, dispatch, onSetCurrentPage, isFetchingData } =
    useGroupScheduleContext()
  const [isLoading, setIsLoading] = useState(false)
  const [showRange, setShowRange] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const range = useMemo(() => get(state, 'range'), [state])
  const parentId = useMemo(() => get(state, 'parentId'), [state])
  const selectedItems = useMemo(() => get(state, 'selectedItems'), [state])
  const subject = useMemo(() => get(state, 'eventSubject'), [state])
  const body = useMemo(() => get(state, 'eventDescription'), [state])
  const singleSelect = useMemo(() => get(state, 'singleSelect'), [state])
  const firstFree = useMemo(() => get(state, 'firstFree'), [state])

  const calendarRange = useMemo(
    () => [
      momentTz().startOf('day'),
      momentTz().startOf('day').add(6, 'months'),
    ],
    []
  )

  const firstDate = useMemo(() => get(state, 'range[0]'), [state])
  const secondDate = useMemo(() => get(state, 'range[1]'), [state])
  const guestsToSave = useMemo(() => get(state, 'guestsToSave'), [state])

  const firstDateFormatted = useMemo(() => {
    return firstDate ? firstDate.format('LL') : ''
  }, [firstDate])

  const secondDateFormatted = useMemo(() => {
    return secondDate ? secondDate.format('LL') : ''
  }, [secondDate])

  const guests = useMemo(() => get(state, 'guests'), [state])

  const group = useMemo(() => {
    const attendees = map(guests, (guest) => ({ name: guest, email: guest }))
    const choices = map(selectedItems, (item) => ({
      start: item.start,
      end: item.end,
    }))
    const entries = map(guests, (email, i) => ({
      [`${email}-${i}`]: [],
      userId: `${email}-${i}`,
      choices: [],
    }))

    const rangeStart = get(range, '[0]', null)
    const rangeEnd = get(range, '[1]', null)

    return {
      group: {
        parentId,
        subject,
        body,
        groupEmail: true,
        attendees,
        rangeStart: rangeStart
          ? rangeStart.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          : null,
        rangeEnd: rangeEnd ? rangeEnd.format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null,
        choices,
      },
      entries,
    }
  }, [parentId, subject, body, guests, range, selectedItems])

  const onToggleRange = useCallback(() => {
    setShowRange((prevState) => !prevState)
  }, [])

  const onBack = useCallback(() => {
    onSetCurrentPage(2)
  }, [onSetCurrentPage])

  const onSetRange = useCallback((range) => {
    const startRange = get(range, '[0]')
    const endRange = get(range, '[1]')

    dispatch({
      type: 'setRange',
      range: [
        startRange ? startRange.startOf('day') : null,
        endRange ? endRange.endOf('day') : null,
      ],
    })

    if (startRange && endRange) {
      setShowRange(false)
    }
  }, [])

  const onShowModal = useCallback(() => {
    setShowModal((prevState) => !prevState)
  }, [])

  const onBookMeeting = useCallback(async () => {
    try {
      const tz = momentTz.tz.guess()
      await API.post('bookings', '/bookings', {
        body: {
          id: parentId,
          sourceAccountId: parentId,
          sourceCalendarId: parentId,
          startTimezone: tz,
          endTimezone: tz,
          ownerEmail: 'test',
          startTime: new Date(momentTz(get(selectedItems, '[0].start'))),
          endTime: new Date(momentTz(get(selectedItems, '[0].end'))),
          email: get(guests, '[0]'),
          guests: guests.length > 1 ? guests.slice(1) : [],
          subject,
          body,
          group: true,
        },
      })

      navigate('/dashboard/group', { replace: true })
    } catch (err) {
      //console.log(':failed to book meeting', err)
    }
  }, [parentId, selectedItems, guests, subject, body])

  const onCreateGroup = useCallback(
    async (sendEmail = true) => {
      setIsLoading(true)

      const choices = map(selectedItems, (item) => ({
        start: item.start,
        end: item.end,
      }))

      const emails = map(guests, (guest) => ({
        name: guest,
        email: guest,
        searchable: guestsToSave.includes(guest),
      }))
      const rangeStart = get(range, '[0]', null)
      const rangeEnd = get(range, '[1]', null)

      try {
        await API.post('group-manage', '/group-manage?createGroup=true', {
          body: {
            parentId,
            title: subject,
            body,
            emails,
            rangeStart: rangeStart
              ? rangeStart.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
              : null,
            rangeEnd: rangeEnd
              ? rangeEnd.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
              : null,
            choices,
            sendEmail,
          },
        })

        navigate('/dashboard/group', { replace: true })
      } catch (err) {
        //console.log(':failed to create group', err)
        setIsLoading(false)
      }
    },
    [selectedItems, parentId, subject, body, guests, range, guestsToSave]
  )

  const onMiddleClick = useCallback(() => {
    onCreateGroup(false)
  }, [onCreateGroup])

  const onPositiveClick = useCallback(() => {
    if (singleSelect) {
      onBookMeeting()
    } else {
      onCreateGroup(true)
    }
  }, [singleSelect, onBookMeeting, onCreateGroup])

  return (
    <div>
      <CBHeader
        step={4}
        label="Times"
        buttonLabel="Edit Previous Step"
        buttonIcon={<Up />}
        buttonAction={onBack}
      />
      <div className="flex flex-col">
        {!!showRange && <>Select a date range for the meeting</>}
        {!showRange && <>Date range</>}
        <div className="flex flex-row justify-center items-center gap-x-4">
          <CBInput
            key={`hi-${range}`}
            disabled={true}
            hint="Select Dates"
            value={firstDateFormatted}
          />
          <div className="pt-2">To</div>
          <CBInput
            key={`hi2-${range}`}
            disabled={true}
            hint="Select Second Date"
            value={secondDateFormatted}
          />
          {!showRange && <Edit onClick={onToggleRange}></Edit>}
        </div>
        {showRange && (
          <CBCalendar
            singleSelect={false}
            range={calendarRange}
            onRangeSelect={onSetRange}
          />
        )}
      </div>
      {isFetchingData && (
        <Col
          style={{ textAlign: 'center', paddingTop: 100, minWidth: '380px' }}
        >
          <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{ fontSize: '100px' }} />
            }
          />
        </Col>
      )}

      {!isFetchingData &&
        range != null &&
        !showRange &&
        firstFree &&
        guests.length > 0 && (
          <>
            {guests.length > 0 && (
              <GroupCalendarView
                firstFree={firstFree}
                range={range}
                isSingleSelect={singleSelect}
              />
            )}
          </>
        )}
      {range && !isFetchingData && guests.length > 0 && (
        <>
          <SelectedButtons
            selectedItems={selectedItems}
            isSingleSelect={singleSelect}
          />
          <SubmitButton
            disabled={selectedItems.length == 0}
            style={{ marginBottom: 40 }}
            onClick={onShowModal}
          >
            {singleSelect ? 'Book Meeting' : 'Review And Send'}
          </SubmitButton>
        </>
      )}
      <CBModal
        middleLabel={
          singleSelect ? false : 'I will manually send links to invitees'
        }
        positiveLabel="Send Invites Now"
        visible={showModal}
        disabled={isLoading}
        loading={isLoading}
        onMiddleClick={onMiddleClick}
        onPositiveClick={onPositiveClick}
        onCancelClick={onShowModal}
      >
        {!!showModal && <GroupItem preview item={group} timezone={
       momentTz.tz.guess()} />}
      </CBModal>
    </div>
  )
}
