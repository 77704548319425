import React, { useMemo } from 'react'
import { Select } from 'antd'

export const OptionItem = (user) => {
  const { name, email } = user
  const label = useMemo(() => {
    return name === email ? name : `${user.name} - ${e.email}`
  }, [user])

  return <Select.Option value={user.email}>{label}</Select.Option>
}
