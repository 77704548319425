import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Row, Skeleton, Empty, Alert, Spin } from 'antd'
import Title from 'antd/lib/typography/Title'
import SyncItem from './SyncItem'
import { useGlobalState } from '../../../store'
import { colorLight, colorDark, colorNeutralLight } from '../../../utils/colors'
import { CBHeader, CBListItem, CBButton } from '../../core/components/cbd'

import { Loading3QuartersOutlined } from '@ant-design/icons';
import {
  faLongArrowAltRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'

import Info from "../../../images/svg/info.svg";
import SyncIcon from "../../../images/svg/logo-syncing.svg";
import LongButton from '../../core/components/LongButton'
import Add from "../../../images/svg/plus.svg";
import { navigate } from '@reach/router'

const SyncSelectList = () => {
  const [syncs] = useGlobalState('syncs')
  const [loadingCoreData] = useGlobalState('loadingCoreData')

  // if (loadingCoreData) {
  //   return (
  //     <div style={{ maxWidth: 700, margin: '0 auto' }}>
  //       <Card style={{ margin: '20px 0' }}>
  //         <Skeleton active paragraph={{ rows: 2 }} title={{ width: 600 }} />
  //       </Card>
  //       <Card style={{ margin: '20px 0' }}>
  //         <Skeleton active paragraph={{ rows: 2 }} title={{ width: 600 }} />
  //       </Card>
  //       <Card style={{ margin: '20px 0' }}>
  //         <Skeleton active paragraph={{ rows: 2 }} title={{ width: 600 }} />
  //       </Card>
  //     </div>
  //   )
  // }

  const renderCreate = (syncs) => {
    const [license] = useGlobalState('license')
    const isAdmin = license.planId.includes('admin')
    const isPremium = license.planId.includes('premium') || license.trial
    const isPro = license.planId.includes('pro') 
    const v3 = license.v3limit
    var syncLegLimit = isPremium ?  16 : (isPro ? 36 : isAdmin ? 0 :2)
    const isLegacy =
    !license.trial &&
    (license.planId == 'calendarbridge-test' ||
      license.planId == 'calendarbridge-pro')
    
      if (isLegacy) {
      syncLegLimit = 14
    }
    
    const twoWayCount = syncs.filter((sync) => sync.props.item.twoWay === true).length
    const syncLegCount = syncs.length + twoWayCount // two way sync counts as two legs
    return (
      <>
        {syncLegCount >= syncLegLimit && (
          <div className="p-4 border bg-neutral mb-2"
            >
              {`You have reached the maximum sync limit of ${syncLegLimit} syncs.` +
              (isPremium ? ' Upgrade to Pro to increase your limit if needed.' : '') +
              (!isPremium && !isPro ? ' Upgrade to Premium or Pro to increase your limit if needed.' : '')}
            </div>
        )}
        <Row type="flex" >
        You have {syncLegLimit - syncLegCount} sync connections left on your plan. 
        </Row>
        
        {/* <Row type="flex" justify="center" style={{ marginBottom:10 }}>
        Remember <FontAwesomeIcon
                  icon={faLongArrowAltRight}
                  size="1x"
                  color="green"
                  style={{ marginLeft: 3, marginRight:3,marginTop:5 }}
                /> requires 1 sync connection and <FontAwesomeIcon
                icon={faExchangeAlt}
                size="1x"
                color="green"
                style={{ marginLeft: 3, marginRight:3,marginTop:5 }} 
              /> requires 2 sync connections 
              </Row> */}
              {(!isPremium && !isPro  <= 0 ? 
              <Row type="flex" style={{ marginBottom:10 }}>
              <Link
                key={5}
                to={`/dashboard/settings`}
                >Upgrade to Premium or Pro {!license.trial && 'to add more syncs!'}</Link>
              </Row> : <div></div>
              )}
              {(isPremium ? 
              <Row type="flex"  style={{ marginBottom:10 }}>
              <Link
                key={5}
                to={`/dashboard/settings`}
                >Upgrade to Pro {!license.trial && 'to add more syncs!'}</Link>
              </Row> : <div></div>
              )}
              <div className="mt-6 mb-2">
              <CBButton  disabled={syncLegCount >= syncLegLimit} type="primary" long={true} onClick={e=>{
      navigate(`/dashboard/syncs/new`)
      }}icon={<Add style={{float:'right'}} ></Add>}>New Sync</CBButton></div>
        {/* <Link
          key={3}
          to={`/dashboard/syncs/new`}
          disabled={syncLegCount >= syncLegLimit}
        >
          <Card
            style={
              syncLegCount >= syncLegLimit
                ? { color: colorLight, backgroundColor: colorNeutralLight }
                : null
            }
          >
            <Row>
              <Col span={12}>
                <Title
                  level={3}
                  style={
                    syncLegCount >= syncLegLimit
                      ? {
                          color: colorLight,
                          backgroundColor: colorNeutralLight,
                        }
                      : null
                  }
                >
                  ADD SYNC
                </Title>
              </Col>
              <Col
                span={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <FontAwesomeIcon icon={faPlus} size="2x" pull="right" />
              </Col>
            </Row>
          </Card>
        </Link> */}
      </>
    )
  }


  const list = syncs.sort((a,b) => (b.modified) - (a.modified)).sort((a,b) => (b.errorState == "" ? 0 : 1) - (a.errorState == "" ? 0 : 1))
    // .filter((sync) => sync.status !== 'deleting')
    .map((sync) => {
      if(sync.errorState.includes("null")) {
        sync.errorState = "SUPRESSED from null"
        sync.status = "synced"
      }
    return <SyncItem key={sync.id} item={sync} />
  }
    )
  return (
    <div className="pb-24">
      <CBHeader label={"Sync"}icon={<SyncIcon></SyncIcon>} buttonLabel={"Help"} buttonIcon={<Info></Info>} buttonAction={e=> {
        window.open('https://help.calendarbridge.com/doc/getting-started/setting-up-calendar-syncing/', "_blank")
      }}></CBHeader>
      {!loadingCoreData &&<div className="mt-5 font-sarabun text">{renderCreate(list)}</div>}
      

{loadingCoreData &&  (
          <div className="v-full">
            <center className="mt-8">
            <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{  fontSize: '100px' }} />
            }
          />
            </center>
          </div>
        )}
      <div className="h-4"></div>
      {list}
      {!loadingCoreData && list.length == 0 && (
        <div className="p-4 padding bg-neutral_0 border text-center"
          
        >No Syncs Yet</div>
      )}
      {/* {renderCreate(list)} */}
    </div>
  )
}

export default SyncSelectList
