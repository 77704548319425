import React, { useState, useEffect } from 'react'
import { useGlobalState } from '../../../store'
import BillingForm from '../components/BillingForm'
import logoMono from '../../../images/logo_mono.png'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Card, Row, Col, message, Modal } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import moment from 'moment'
import { API } from 'aws-amplify'
import { CBButton } from '../../core/components/cbd'
import { navigate } from '@reach/router'
import Logo from '../../../images/svg/cb_log_2024_orange_white.svg'
import { signoutUser } from '../../../utils/auth'
import { WarningOutlined } from '@ant-design/icons'
import {
  DeleteOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { accountIconMap } from '../../../utils/customIcons'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

const TrialBlockPage = () => {
  const [user] = useGlobalState('user')
  const [license] = useGlobalState('license')
  const [schedules] = useGlobalState('schedules')
  const [syncs] = useGlobalState('syncs')
  const [accounts] = useGlobalState('accounts')
  const [loadRedirect, setLoadRedirect] = useState(false)

  // useEffect(()=>{
  //   console.log("HEY!")
  //   console.log(accounts)

  //   console.log(syncs)

  //   console.log(schedules)
  // },[accounts, syncs,schedules])
  async function getPortalUrl() {
    setLoadRedirect(true)
    try {
      const resp = await API.get('billing', '/billing?session=true', {})
      setLoadRedirect(false)
      navigate(resp.message)
    } catch (ex) {
      //console.log(ex)
      setLoadRedirect(false)
      alert('You do not have access this to portal')
    }
  }

  const transfer = (id) => {
    API.del('account', '/account?transfer=true&id=' + id, {
      body: {},
    })
      .then(async () => {
        //const accounts = await loadAccounts()
        // console.log(item)
        if(window != null) {
          window.location.reload()
        }
      })
      .catch((error) => {
        // alert(error.response.data.message)
        alert(error.response.data.message)
      })
  }

  async function handleCancel() {
    setLoadRedirect(true)
    const resp = await API.post('billing', '/billing', {
      body: {
        cancel: true,
      },
    })

    // setLicense(resp.license)
    setLoadRedirect(false)
    if (resp.license.trial) {
      // message.success(
      //   'Your Trial Has Been Cancelled'
      // )
      signoutUser()
    } else {
      signoutUser()
      // message.success(
      //   'Your subscription will be cancelled on ' +
      //     moment(resp.license.cancelEnd * 1000).format('LL')
      // )
    }
  }

  if (!user) return <div></div>

  return (
    <div>
      <div style={{ marginTop: 40, marginBottom: 40 }} className="p-4 lg:p-0">
        <a href="https://calendarbridge.com">
          <Logo width="200px" height="50px"></Logo>
        </a>

        <Row style={{ marginTop: 20 }} gutter={20}>
          <Col xs={24} lg={6}>
            {license.state == 'valid' && (
              <h3>Your CalendarBridge Trial Has Expired</h3>
            )}
            {license.state == 'suspended' && (
              <h3>Account Paused please update payment option</h3>
            )}
            <Paragraph>
              <b>All syncs and schedules are now disabled. </b>
              {license.state == 'valid' && (
                <>
                  Hopefully you took advantage of your trial and enjoyed how
                  CalendarBridge makes calendar management easy. If so, take
                  just one minute to upgrade your account.
                </>
              )}
              <br />
              <br />
              <b>Email:</b> {license.email}
              <br />
              <b>Organization:</b>{' '}
              {license.orgId === 'single' ? 'Individual' : license.orgId}
              <br />
              <b>Account Status:</b> {license.active ? 'Active' : 'Inactive'}
              {license.isTrial && (
                <>
                  <br />
                  Trial: Active (Expires{' '}
                  {moment(license.trialStarted).add(7, 'day').fromNow()})
                </>
              )}
              {accounts && (
                <div className="mt-4">
                  <b>Account Summary:</b>
                  <div>
                    {' '}
                    <b>Connected Accounts:</b> {accounts.length}
                    {license && accounts.length == 0 && (
                      <div className="text-red-500 text-lg">
                        There are no calendar accounts connected! This account
                        is empty.
                      </div>
                    )}
                    {/* {accounts.length > 0 && (
                      <>
                        {accounts.map((account) => {
                          return (
                            <div className="pt-1 text-xs">{account.email}</div>
                          )
                        })}
                      </>
                    )} */}
                  </div>
                  <div>
                    <b>Syncs:</b> {syncs.length}
                  </div>
                  <div>
                    <b>Schedulers:</b> {schedules.length}
                  </div>
                </div>
              )}
              {license.trial && (
                <div className="mt-4">
                  <CBButton
                    type="ternary"
                    loading={loadRedirect}
                    onClick={(e) => {
                      Modal.confirm({
                        title:
                          'Are you sure you want to cancel your trial? All syncs and schedules will be disabled.',
                        okText: 'End Trial Account',
                        okType: 'danger',
                        cancelText: 'Nevermind',
                        icon: <WarningOutlined />,
                        async onOk() {
                          handleCancel()
                        },
                      })
                    }}
                  >
                    Cancel Trial
                  </CBButton>
                </div>
              )}
              {!license.trial &&
                license.stripeUserId != 'site' &&
                license.stripeUserId != 'trial' && (
                  <div className="flex flex-col gap-4 font-sarabun mt-4">
                    For more details on your payments:
                    <CBButton
                      onClick={(e) => getPortalUrl()}
                      type="ternary"
                      loading={loadRedirect}
                    >
                      View Invoices
                    </CBButton>
                    <div className="mt-4">
                      <CBButton
                        type="ternary"
                        loading={loadRedirect}
                        onClick={(e) => {
                          Modal.confirm({
                            title:
                              'Are you sure you want to cancel your subscription? All syncs and schedules will be disabled at end of current billing cycle.',
                            okText: 'Cancel Subscription',
                            okType: 'danger',
                            cancelText: 'Nevermind',
                            icon: <WarningOutlined />,
                            async onOk() {
                              handleCancel()
                            },
                          })
                        }}
                      >
                        Cancel Account
                      </CBButton>
                    </div>
                  </div>
                )}
              <div style={{ marginTop: 60 }}>
                <a
                  onClick={(e) => {
                    signoutUser()
                  }}
                >
                  Log Out
                </a>
              </div>
            </Paragraph>
          </Col>
          <Col xs={24} lg={18}>
            <Card>
              <h3>Billing Information</h3>
              <Elements stripe={stripePromise}>
                <BillingForm
                  isLoading={false}
                  successMessage={
                    'Your card has been charged successfully! Reloading dashboard...'
                  }
                  reloadAfterSuccess={true}
                  showCard
                  showPlan={
                    license.active == false && license.state == 'suspended'
                      ? false
                      : true
                  }
                />
              </Elements>
            </Card>
            {accounts.length > 0 && 
        <div>
          <div className="text-lg ml-4 mt-8">Connected Accounts</div>
          {accounts.map((account) => {
              return (
                <div className=" text-lg  p-4 m-2 border flex flex-row justify-between items-center ">
                  <div className="flex flex-row items-center gap-4"><FontAwesomeIcon
                    icon={accountIconMap[account.calendarAccountType].icon}
                    size="2x"
                    color={accountIconMap[account.calendarAccountType].color}
                  /><div>{account.email}</div></div>
                  <CBButton type="ternary" onClick={async (e) => {
                    Modal.confirm({
                      title: `Are you sure you want to remove ${account.email} calendar from this account? All syncs will be deleted.`,
                      okText: 'Delete',
                      okType: 'danger',
                      cancelText: 'Cancel',
                      icon: <DeleteOutlined />,
                      async onOk() {
                        transfer(account.calendarAccountId)
                
                        // window.location.reload()
                      },
                    })
                   
                  }}>Remove</CBButton>
                </div>
              )
            })}
        </div>}
          </Col>
        </Row>
      </div>
      
      
    </div>
  )
}

export default TrialBlockPage
