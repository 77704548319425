import React, { useState, useCallback, useMemo } from 'react'
import get from 'lodash/get'
import { API } from 'aws-amplify'
import { CBButton, CBInput } from '../../../../../core/components/cbd'
import { useGroupScheduleContext } from '../../GroupScheduleContext'

export const SaveGroup = () => {
  const { state, dispatch } = useGroupScheduleContext()

  const [contactGroupName, setContactGroupName] = useState('')
  const [isSavingGroup, setIsSavingGroup] = useState(false)

  const guests = useMemo(() => get(state, 'guests', []), [state])

  const [showSaveGroup, setShowSaveGroup] = useState(false)
  const [contactGroupLabel, setContactGroupLabel] = useState('')

  const onToggleShowSaveGroup = useCallback(() => {
    setShowSaveGroup((prevState) => !prevState)
  }, [])

  const onSaveGroup = useCallback(async () => {
    setIsSavingGroup(true)

    try {
      const response = await API.post(
        'group-manage',
        '/group-manage?contactGroup=true',
        {
          body: { groupId: contactGroupName, emails: guests },
        }
      )

      dispatch({ type: 'setContactGroups', contactGroups: response.groups })
    } catch (err) {
      console.log(':err', err)
    }

    setIsSavingGroup(false)
    onToggleShowSaveGroup()
  }, [contactGroupName, guests, dispatch])

  return (
    <>
      {showSaveGroup ? (
        <>
          <CBInput
            label="Name"
            value={contactGroupLabel}
            onChange={setContactGroupName}
          />
          <div className="flex flex-row gap-4">
            <CBButton loading={isSavingGroup} onClick={onSaveGroup}>
              Save
            </CBButton>
            <CBButton type="ternary" onClick={onToggleShowSaveGroup}>
              Cancel
            </CBButton>
          </div>
        </>
      ) : (
        <CBButton type="ternary" onClick={onToggleShowSaveGroup}>
          Save Group
        </CBButton>
      )}
    </>
  )
}
