import React from 'react'
import ScheduleSelectList from '../components/ScheduleSelectList'
import Title from 'antd/lib/typography/Title'
import SEO from '../../../components/SEO'

const ScheduleListPage = () => {
  
  
  return (
    <div className="cb-content-frame">
      <SEO title="Schedulers Dashboard" />
    
    {/* Integration list here */}
    
   
    <ScheduleSelectList />
  </div>
)}

export default ScheduleListPage
