
import React, { useEffect, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import { get } from 'lodash';

import {
	CBButton,
	CBHeader
} from '../../core/components/cbd'
import { Upload, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons'


function beforeUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt4M = file.size / 1024 / 1024 < 4;

	if (!isLt4M) {
		message.error('Image must smaller than 4MB!');
	}

	return isJpgOrPng && isLt4M;
}


const ImageDisplay = ({width, height, image}) => {
	return (<div style= {{
		width: `${width}px`,
		height: `${height}px`,
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}}>
		<img 
			src={`${process.env.GATSBY_IMAGE_CDN}/${image}`}
			width={`${width}px`}
			/>
	</div>);
}


const ScheduleImageUploadIndividual = ({ image, setImage, cognitoUsername, type }) => {

	const [loading, setLoading] = useState(false);

	const uploadImage = info => {
		const { onSuccess, onError, file, onProgress } = info;
		message.info(`Uploading ${type}`);
		setLoading(true);
		const { name, type: contentType } = file;
		const fileKey = `${cognitoUsername}/${type}Images/${name}`

		Storage.put(fileKey, file, {
			progressCallback: onProgress,
			contentType
		}).then(complete => {
			setImage(fileKey);
			setLoading(false);
			onSuccess(fileKey);
			message.info(`Upload ${type} complete`);
		}).catch(err => {
			message.error('upload failed.');
			setLoading(false);
			onError({ err });
		});
	}

	const handleChange = () => {
		return false;
	}
	const handleUpload = () => {
		//we're basically overriding everything and just uploading when the user selects
		return false;
	}

	const uploadButton = (
		<div>
			{loading ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : <FontAwesomeIcon icon={faPlus} />}
			<div style={{ marginTop: 8 }}>Upload{loading && 'ing'}</div>
		</div>
	);


	return (<>
		<Upload
			name={type}
			listType="picture-card"
			className={`${type}-uploader`}
			showUploadList={false}
			beforeUpload={beforeUpload}
			handleUpload={handleUpload}
			customRequest={uploadImage}
			accept="image/*"
			onChange={handleChange} >
			{image ? <ImageDisplay image={image} height={100} width={100} /> : uploadButton}
		</Upload>
		<CBButton
			disabled={image == '' || image == null}
			onClick={(e) => {
				setImage('')
			}}
		>Remove Image</CBButton>
	</>);

}

const ScheduleImageUpload = ({ iconImage, avatarImage, setIconImage, setAvatarImage }) => {

	const [cognitoUsername, setCognitoUsername] = useState(null);
	useEffect(() => {
		Auth.currentUserInfo().then(session => {
			setCognitoUsername(get(session, "id"));
		});
	}, []);



	// Lets not even try to save an image if we don't know where to save it to
	// 
	if (!cognitoUsername) {
		return (<FontAwesomeIcon
			icon={faSpinner}
			size="3x"
			spin={true}
		/>);
	}


	return (<>
		<CBHeader
			label={'Upload A Banner Image (optional)'}
			level={3}
			style={{ textAlign: 'center', marginTop: 0, marginBottom: 20 }}
		></CBHeader>
		<ScheduleImageUploadIndividual
			image={iconImage}
			setImage={setIconImage}
			cognitoUsername={cognitoUsername}
			type={'icon'} />
		<CBHeader
			label={'Upload An Avatar Image (optional)'}
			level={3}
			style={{ textAlign: 'center', marginTop: 0, marginBottom: 20 }}
		></CBHeader>
		<ScheduleImageUploadIndividual
			image={avatarImage}
			setImage={setAvatarImage}
			cognitoUsername={cognitoUsername}
			type={'avatar'} />
	</>);
}

export default ScheduleImageUpload;