import React, { useEffect, useState } from 'react'
import logo from '../../../images/logo.png'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Layout, Menu, Button } from 'antd'
import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { colorDark } from '../../../utils/colors'
import { navigate } from '@reach/router'
import { signoutUser } from '../../../utils/auth'
import { useGlobalState } from '../../../store'
import HomeIcon from '../../../images/svg/home.svg'
import SyncIcon from '../../../images/svg/logo-syncing.svg'
import BookingIcon from '../../../images/svg/logo-booking.svg'
import Logo from '../../../images/svg/cb_log_2024_orange_white.svg'
import LogoIcon from '../../../images/svg/new_CB_icon.svg'
import ScheduleIcon from '../../../images/svg/envelope.svg'

import LogOut from '../../../images/svg/log-out.svg'
import Info from '../../../images/svg/info.svg'

import Close from '../../../images/svg/x-close.svg'
import Hamburger from '../../../images/svg/menu.svg'
import Connections from '../../../images/svg/connections.svg'
import { CloseOutlined } from '@ant-design/icons'

import howItWorksImg1 from '../../../images/link-account.png'
const { Sider, Content } = Layout

const CBMenuItem = ({
  selected,
  index,
  icon,
  children,
  onSelect,
  path,
  collapse,
  activeItem,
}) => {
  return (
    <div
      onClick={(e) => {
        onSelect(index, path)
      }}
      className={`${activeItem == path ? 'bg-neutral_1 ' : ''} menu-item ${
        collapse
          ? 'pl-4 mb-3 justify-center flex-initial'
          : 'pl-4 pr-4 pt-1 pb-1 mb-3'
      } items-center cursor-pointer flex text-neutral-4 rounded-sm space-x-4 font-sarabun text-mdlg active:bg-neutral_2 hover:bg-neutral_1`}
    >
      {icon} {!collapse && children}
    </div>
  )
}
const CBMenu = ({
  children,
  shrink = false,
  selected = 1,
  path = '',
  onChange,
  active,
  collapse,
  mobile = false,
  hasErrors = false
}) => {

 
  const [sel, setSel] = useState(selected)
  const [hamburger, setHamburger] = useState(false)

  
  function fullChange(index, key) {
    setHamburger(false)
    onChange(index, key)
  }


  return (
    <div
      className={`z-10 mb-5 ${shrink ? 'border-neutral_4' : 'w-full  pr-2'}`}
    >
      <div className="menu header mb-3 pl-4 flex items-center font-manrope text-lg text-neutral_4 ">
        {!mobile && collapse ? (
          
          <LogoIcon
            onClick={(e) => navigate('/dashboard')}
            className="ml-1"
            width="25px"
            height="25px"
          ></LogoIcon>
         
        ) : (
          <Logo
            onClick={(e) => navigate('/dashboard')}
            width="200px"
            height="50px"
          ></Logo>
        )}
        {mobile && <div className="flex-grow"></div>}

        {mobile && (
          <div
            onClick={(e) => {
              setHamburger(!hamburger)
            }}
            className="pr-2 cursor:pointer"
          >
            {hasErrors && !hamburger && <div className="bg-red-700 w-2 h-2 rounded-full float-right"></div>}
           
             {hamburger ? <Close></Close> : <Hamburger></Hamburger>}
            
            
          </div>
        )}
      </div>
      {hamburger && (
        <div className="absolute bg-white w-full mr-8 overlfow-auto transition-opacity animate-fade-in-down">
          {renderChildrens(
            children,
            sel,
            active,
            shrink,
            collapse,
            setSel,
            fullChange,
          )}
        </div>
      )}
      {!mobile &&
        renderChildrens(
          children,
          sel,
          active,
          shrink,
          collapse,
          setSel,
          onChange
        )}
    </div>
  )
}
const Header = ({ location, authstate, mobile = false, collapsed }) => {
  const path = location.pathname
    .split('/')
    .filter(Boolean)
    .filter((e) => e != 'new')
  const [activeItem, setActiveItem] = useState(path[path.length - 1])
  const [, setUser] = useGlobalState('user')
  const [shrink, setShrink] = useState(false)
  const [license, setLicense] = useGlobalState('license')
  const [accounts] = useGlobalState('accounts')
  const [syncs] = useGlobalState('syncs')
  const [hasErrors, setHasErrors] = useState(false)
  const [hasSyncErrors, setHasSyncErrors] = useState(false)
  const [hasAccountErrors, setHasAccountErrors] = useState(false)
  useEffect(()=>{
    var hasAccountErrors = accounts.filter(account => account.errorState != '').length > 0
    var hasSyncErrors = syncs.filter(sync => sync.status == 'synced_error' && !sync.errorState.includes("null")).length > 0
    setHasErrors(hasAccountErrors || hasSyncErrors)
    setHasSyncErrors(hasSyncErrors)
    setHasAccountErrors(hasAccountErrors)
  }, [accounts,syncs])



  const mobileStyle = mobile ? {} : {}

  const goTo = (e) => {
    if (e.key === 'logout') {
      setUser({})
      signoutUser()
    } else {
      setActiveItem(e.key)
      navigate('/dashboard/' + e.key)
    }
  }
  

  if (activeItem != path[path.length - 1]) {
    var item = path[path.length - 1]
    
    setActiveItem(item)
    
  }

  return (
    <>
      {authstate === 'signedIn' && (
        <>
          <CBMenu
            mobile={mobile}
            collapse={collapsed}
            active={activeItem}
            hasErrors={hasErrors}
            onChange={(index, key) => {
              if (key == 'logout') {
                setUser({})
                signoutUser()
              } else {
                if (key == 'dashboard') {
                  navigate('/dashboard')
                } else {
                  navigate('/dashboard/' + key)
                }
                // setActiveItem(key)
              }
            }}
          >
            <CBMenuItem
              path="dashboard"
              icon={<HomeIcon className="mr-5 "></HomeIcon>}
            >
              Home
            </CBMenuItem>
            <CBMenuItem
              path="syncs"
              icon={<div className="mr-5">{hasSyncErrors && <div className="bg-red-700 w-2 h-2 rounded-full float-right"></div>}<SyncIcon></SyncIcon></div>}
            >
              Sync
            </CBMenuItem>
            
            <CBMenuItem
              path="schedules"
              icon={<BookingIcon className="mr-5"></BookingIcon>}
            >
              Schedulers
            </CBMenuItem>
            {true ? <CBMenuItem
              path="group"
              icon={<ScheduleIcon className="mr-5"></ScheduleIcon>}
            >
              Group Booking 
            </CBMenuItem> :<></>}
            <CBMenuItem
              path="accounts"
              icon={<div className="mr-5">{hasAccountErrors && <div className="bg-red-700 w-2 h-2 rounded-full float-right"></div>}<Connections ></Connections></div>}
            >
              Accounts
            </CBMenuItem>
            <CBMenuItem path="settings" icon={<Info className="mr-5"></Info>}>
              Billing
            </CBMenuItem>
            <CBMenuItem path="logout" icon={<LogOut className="mr-5"></LogOut>}>
              Log Out
            </CBMenuItem>
          </CBMenu>
        </>
        // <Menu
        //   mode={mobile ? "horizontal": "vertical"}
        //   style={{
        //     border: 0,
        //     marginLeft:16,
        //     marginRight:16,
        //     // fontFamily: 'Sarabun, serif',
        //     // fontStyle: 'normal',
        //     // fontWeight: 'bold',

        //     fontSize: 16,
        //     lineHeight: '24px',
        //     ...mobileStyle
        //   }}
        //   onSelect={(e) => goTo(e)}
        //   selectedKeys={[activeItem]}
        // >
        //   <Menu.Item style={{fontFamility: 'Josefin Sans'}} icon={<LegacyIcon component={() => (<LogoIcon style={{verticalAlign: 'middle'}}/>)} />} key="" >
        //     CalendarBridge
        //   </Menu.Item>
        //   {/* <Menu.Item icon={<AppstoreOutlined />} key="accounts" >
        //     Accounts
        //   </Menu.Item> */}
        //   <Menu.Item icon={<LegacyIcon component={() => (<HomeIcon style={{verticalAlign: 'middle'}}  />)} />} key="" >
        //     Account
        //   </Menu.Item>
        //   <Menu.Item icon={<LegacyIcon component={() => (<img style={{marginBottom:8}} src={SyncIcon} />)} />} key="syncs" >
        //     Syncing
        //   </Menu.Item>
        //   <Menu.Item  icon={<LegacyIcon component={() => (<img style={{marginBottom:8}}  src={ScheduleIcon} />)} />} key="group" >
        //     Scheduling
        //   </Menu.Item>
        //   <Menu.Item  icon={<LegacyIcon component={() => (<BookingIcon style={{verticalAlign: 'middle'}}/>)} />} key="schedules" >
        //   Booking
        //   </Menu.Item>
        //   {/* <Menu.Item icon={<SettingOutlined />} key="settings" >
        //     Billing
        //   </Menu.Item>
        //   <Menu.Item icon={<LogoutOutlined />} key="logout" >
        //     Logout
        //   </Menu.Item> */}
        // </Menu>
      )}
      {/* {authstate === 'signedIn' && path.length >= 3 && (
        <div
          style={{
            lineHeight: '64px',
            float: 'top',
          }}
        >
          <Button
            type="link"
            style={{ color: colorDark }}
            
            onClick={() => navigate(`/${path[0]}/${path[1]}`)}
          >
            <FontAwesomeIcon icon={faTimes} size="2x"  />
          </Button>
        </div>
      )} */}
    </>
  )
}

export default Header
function renderChildrens(
  children,
  sel,
  active,
  shrink,
  collapse,
  setSel,
  onChange,

) {
  return React.Children.map(children, (child, i) => {
    const num = i + 1
    // if(mobile && (num) > page) {
    //   return <></>
    // }
    return React.cloneElement(child, {
      selected: sel,
      activeItem: active,
      index: num,
      shrink: shrink,
      collapse: collapse,
      onSelect: (index, key) => {
        // setCurrentPage(num)
        // onPageChanged(num)
        setSel(index)
        onChange(index, key)
      },
    })
  })
}
