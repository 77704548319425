import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import reduce from 'lodash/reduce'
import set from 'lodash/set'
import Title from 'antd/lib/typography/Title'
import {
  Layout,
  Button,
  Select,
  Radio,
  Steps,
  Space,
  Checkbox,
  Row,
  Tag,
  Alert,
  Collapse,
  Card,
  Input,
  Col,
  TimePicker,
} from 'antd'
const { Header, Footer, Sider, Content } = Layout
const { Panel } = Collapse
import Text from 'antd/lib/typography/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'
import Trash from '../../../images/delete.png'
import Edit from '../../../images/svg/pencil.svg'

import Close from '../../../images/svg/x-close.svg'
import NewSync from '../../../images/svg/new-sync.svg'
import CalendarIcon from '../../../images/svg/calendar-event-date.svg'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBigCalendar,
  CBRadioGroup,
  CBListItem,
  CBColorPicker,
  CBColorPickerFilter,
  CBModal,
} from '../../core/components/cbd'
import SyncItem from '../components/SyncItem'
import { navigate } from '@reach/router'
import Paragraph from 'antd/lib/typography/Paragraph'
import { useGlobalState } from '../../../store'
import { syncTypeText } from '../../../utils/words'
import { API } from 'aws-amplify'
import ColorPicker from '../components/ColorPicker'
import moment from 'moment'
import momentTz from 'moment-timezone'
import MediaQuery from 'react-responsive'
import GoogleLogo from '../../../images/svg/google-logo.svg'
import { faOutlook, faApple } from '../../../utils/customIcons'
import { colorOutlook, colorApple } from '../../../utils/colors'

import Up from '../../../images/svg/arrow-up-chevron.svg'
import SEO from '../../../components/SEO'

const { Step } = Steps

const CBSampleEvent = ({ policy, destType, tag, color }) => {
  return (
    <div className="flex pb-2 pr-2 rounded-sm border-2 border-neutral_4 m-2">
      <div className="mr-2">
        {color && (
          <CBColorPicker
            color={color}
            preview={true}
            type={destType}
          ></CBColorPicker>
        )}
      </div>
      <div>
        <div className="font-bold">
          {policy.includes('time') ? 'Free/Busy' : 'Subject'} {tag}
        </div>
        <div>{policy.includes('location') && <div>Meeting Location</div>}</div>
        <div>
          {destType == 'google' && policy.includes('meeting') && (
            <div>Online Meeting</div>
          )}
        </div>
        Event Content
        <div className="m-1 border-2 border-neutral_1">
          {policy.includes('description') && 'Body Text...'}
          {policy.includes('contact') && (
            <div>
              <div>Attendees:</div>
              <div>- Attendee Name - attendee-email@email.com - status</div>
              ===
            </div>
          )}
          <div>
            {destType == 'ms' && policy.includes('meeting') && (
              <div>Online Meeting</div>
            )}
          </div>
          {policy.includes('reminder') && 'Reminder Icon'}
          {policy.includes('private') && 'Lock Icon'}
        </div>
      </div>
    </div>
  )
}
class SyncCreatePage extends React.Component {
  state = {
    showICS: false,
    ics_link: '',
    showModal: false,
    showAdvanced: true,
    timeRangeActive: false,
    page: 0,
    lastPage: 2,
    farthestPage: 0,
    allCalendars: [],
    sourceCalendarTimezone: momentTz.tz.guess(),
    destCalendarTimezone: momentTz.tz.guess(),
    sync: {
      source: null,
      destination: null,
      bidirection: false,
      includeTag: true,
      includeSubject: false,
      includeDescription: false,
      includeLocation: false,
      includeReminders: false,
      fullSync: false,
      sourceColor: 'default',
      destColor: 'default',
      label: '(copy)',
      matchColors: false,
      hasFilters: false,
      syncLevel: ['free', 'tentative', 'busy', 'oof', 'workingElsewhere'],
      filterColor: 'none',
      startCutOff: -1,
      endCutOff: -1,
      excludeInvites: false,
    },
    isLoading: false,
  }

  getOptions() {
    return moment.tz.names().map((tz) => (
      <Select.Option key={tz}>
        {tz} ({moment.tz(this.state.currentDate, tz).format('Z z')})
      </Select.Option>
    ))
  }

  componentDidMount() {
    this.setCalendarsFromProps()
    // console.log(this.props.id)
    // console.log(this.props.syncs)
  }

  componentDidUpdate(prevProps) {
    if (this.props.accounts.length !== prevProps.accounts.length) {
      this.setCalendarsFromProps()
    }
  }
  loadExistingSync() {
    if (this.props.id) {
      var id = decodeURIComponent(this.props.id.replace('___', '#'))
      // console.log(id)
      const existingSync = this.props.syncs.filter((s) => s.id === id)
      var existing = existingSync[0]
      var calendars = this.props.accounts.reduce(
        (all, a) => [...all, ...a.calendars],
        []
      )

      var sourceIndex = -1
      var sourceLabel = null
      for (var i = 0; i < calendars.length; i++) {
        var cal = calendars[i]
        if (
          cal.id == existing.sourceCalendarId &&
          cal.calendarAccountId == existing.sourceCalendarAccountId
        ) {
          sourceIndex = i
          sourceLabel = `${cal.name || cal.summary} - ${cal.email}`
          break
        }
      }

      var destIndex = -1
      var destLabel = null
      for (var i = 0; i < calendars.length; i++) {
        var cal = calendars[i]
        if (
          cal.id == existing.destCalendarId &&
          cal.calendarAccountId == existing.destCalendarAccountId
        ) {
          destIndex = i
          destLabel = `${cal.name || cal.summary} - ${cal.email}`
          break
        }
      }
      if ((destIndex == -1 || sourceIndex == -1) && existing.sourceCalendarType != "ics") {
        //error message about calendars not available
        alert("Source or destination calendar is missing please delete sync and recreate")
      } else {
        
        var tag = existing.tag == 'default' ? '(copy)' : existing.tag
        if (
          existing.securityPolicy.includes('time') &&
          !existing.securityPolicy.includes('tag') &&
          !existing.securityPolicy.includes('subject')
        ) {
          tag = ' '
        }
        var ifIcs = {}
        
        if(existing.sourceCalendarType == "ics") {
          console.log("HELLO?")
          ifIcs = {
            summary: "ICS Link",
            calendarAccountId: existing.sourceCalendarAccountId,
            id: existing.sourceCalendarId,
            calendarAccountType: "ics",
            timeZone: existing.sourceCalendarTimezone
          }
          
          sourceLabel = "ICS Link"
        }
        var newState = {
          originalSyncId: existing.id,
          orginalTwoWay: existing.twoWay,
          sourceCalendarTimezone: existing.sourceCalendarTimezone,
          destCalendarTimezone: existing.destCalendarTimezone,
          sourceLabel,
          destLabel,
          showAdvanced: true,
          sync: {
            ...this.state.sync,
            source: existing.sourceCalendarType == "ics" ? ifIcs : calendars[sourceIndex],
            destination: calendars[destIndex],
            label: tag,
            bidirection: existing.twoWay,
            includeTag: existing.securityPolicy.includes('tag'),
            includeSubject: existing.securityPolicy.includes('subject'),
            includeDescription:
              existing.securityPolicy.includes('description'),
            includeLocation: existing.securityPolicy.includes('location'),
            includeReminders: existing.securityPolicy.includes('reminder'),
            includeMeeting: existing.securityPolicy.includes('meeting'),
            includeAttendees: existing.securityPolicy.includes('contact'),
            allPrivate: existing.securityPolicy.includes('private'),
            sourceColor: existing.sourceColor,
            destColor: existing.destinationColor,
            fullSync: false,
            editBidirection: existing.twoWay,
            matchColors: existing.matchColors == true,
            hasFilters: existing.hasFilters == true,
            filterColor: existing.filterColor,
            syncLevel: existing.syncLevel
              ? existing.syncLevel == 'all'
                ? ['free', 'tentative', 'busy', 'oof', 'workingElsewhere']
                : existing.syncLevel.split('|')
              : ['free', 'busy'],
            excludeInvites: existing.excludeInvites
          },
        }
        
        this.setState(
          newState,
          () => {
            this.setState(
              {
                page: 1,
              },
              () => {
                window.scrollTo(0, 0)
              }
            )
          }
        )
      }

      // this.changeSyncValue('source', sourceIndex)
      // // console.log(existingSync[0])

      // // this.changeSyncValue('source',existing.tag)
      // this.changeSyncValue('label',existing.tag)
    }
  }

  setCalendarsFromProps() {
    this.setState(
      {
        allCalendars: this.props.accounts.reduce(
          (all, a) => [...all, ...a.calendars],
          []
        ),
      },
      () => {
        this.loadExistingSync()
      }
    )
  }

  submitSync = async () => {
    this.setState({
      error: null,
    })

    const {
      source,
      destination,
      bidirection,

      includeTag,
      includeSubject,
      includeDescription,
      includeLocation,
      includeReminders,
      fullSync,
      allPrivate,
      sourceColor,
      destColor,
      label,
      includeMeeting,
      includeAttendees,
      matchColors,
      hasFilters,
      syncLevel,
      filterColor,
      startCutOff,
      endCutOff,
      excludeInvites,
    } = this.state.sync
    const { allCalendars } = this.state
    var fixLabel = label
    if (
      fixLabel != ' ' &&
      fixLabel != '  ' &&
      fixLabel != '' &&
      fixLabel != 'default'
    ) {
      if (
        fixLabel.startsWith('{') ||
        fixLabel.startsWith('[') ||
        fixLabel.startsWith('(') ||
        fixLabel.endsWith(')')
      ) {
      } else {
        fixLabel = '(' + fixLabel + ')'
      }
    }
    const sourceCal = source
    const destCal = destination
    try {
      const syncs = await API.post('bridge', '/bridge', {
        body: {
          originalSyncId: this.state.originalSyncId
            ? this.state.originalSyncId
            : '',
          originalTwoWay: this.state.orginalTwoWay
            ? this.state.originalTwoWay
            : false,
          sourceCalendarId: sourceCal.id,
          sourceCalendarDisplayName: sourceCal.name || sourceCal.summary,
          sourceCalendarType: sourceCal.calendarAccountType,
          sourceCalendarAccountId: sourceCal.calendarAccountId,
          sourceCalendarTimezone:
            sourceCal.timeZone || this.state.sourceCalendarTimezone,
          destCalendarId: destCal.id,
          destCalendarDisplayName: destCal.name || destCal.summary,
          destCalendarType: destCal.calendarAccountType,
          destCalendarTimezone:
            destCal.timeZone || this.state.destCalendarTimezone,
          destCalendarAccountId: destCal.calendarAccountId,
          policy:
            (includeSubject ? 'subject' : 'time') +
            (includeTag ? '|tag' : '') +
            (includeDescription ? '|description' : '') +
            (includeLocation ? '|location' : '') +
            (includeReminders ? '|reminder' : '') +
            (allPrivate ? '|private' : '') +
            (includeMeeting ? '|meeting' : '') +
            (includeAttendees ? '|contact' : ''),
          twoWay: bidirection,
          canEditSource: sourceCal.canEdit,
          fullSync: fullSync,
          sourceColor: sourceColor ? sourceColor : 'default',
          destColor: destColor ? destColor : 'default',
          label: label ? (label != '' ? fixLabel : 'default') : 'default',
          matchColors: matchColors,
          hasFilters: hasFilters,
          syncLevel: syncLevel.join('|'),
          filterColor: filterColor,
          startCutOff: startCutOff,
          endCutOff: endCutOff,
          excludeInvites: excludeInvites,
        },
      })

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'dashboardEvent',
        category: 'userActivity',
        action: 'createSync',
        label: this.props.license.userId,
        email: this.props.license.email,
      })

      //console.log('createSync' + this.props.license.email)

      window.location.href = window.location.origin + '/dashboard/syncs/'
    } catch (ex) {
      console.log(ex.response.data.message)
      this.setState({ isLoading: false, error: ex.response.data.message })
    }
  }

  buildSyncItemForPreview = () => {
    const {
      source,
      destination,
      bidirection,
      includeTag,
      includeSubject,
      includeDescription,
      includeLocation,
      includeReminders,
      fullSync,
      allPrivate,
      sourceColor,
      destColor,
      label,
      includeMeeting,
      includeAttendees,
      matchColors,
      hasFilters,
      syncLevel,
      filterColor,
      startCutOff,
      endCutOff,
      excludeInvites,
    } = this.state.sync
    const { allCalendars } = this.state

    const sourceCal = source
    const destCal = destination
    var fixLabel = label
    if (
      fixLabel != ' ' &&
      fixLabel != '  ' &&
      fixLabel != '' &&
      fixLabel != 'default'
    ) {
      if (
        fixLabel.startsWith('[') ||
        fixLabel.startsWith('(') ||
        fixLabel.endsWith(')')
      ) {
      } else {
        fixLabel = '(' + fixLabel + ')'
      }
    }
    const item = {
      originalSyncId: this.state.originalSyncId
        ? this.state.originalSyncId
        : '',
      originalTwoWay: this.state.orginalTwoWay
        ? this.state.originalTwoWay
        : false,
      sourceCalendarId: sourceCal.id,
      sourceCalendarName: sourceCal.name || sourceCal.summary,
      sourceCalendarType: sourceCal.calendarAccountType,
      sourceCalendarAccountId: sourceCal.calendarAccountId,
      sourceCalendarTimezone:
        sourceCal.timeZone || this.state.sourceCalendarTimezone,
      destCalendarId: destCal.id,
      destCalendarName: destCal.name || destCal.summary,
      destCalendarType: destCal.calendarAccountType,
      destCalendarTimezone: destCal.timeZone || this.state.destCalendarTimezone,
      destCalendarAccountId: destCal.calendarAccountId,
      securityPolicy:
        (includeSubject ? 'subject' : 'time') +
        (includeTag ? '|tag' : '') +
        (includeDescription ? '|description' : '') +
        (includeLocation ? '|location' : '') +
        (includeReminders ? '|reminder' : '') +
        (allPrivate ? '|private' : '') +
        (includeMeeting ? '|meeting' : '') +
        (includeAttendees ? '|contact' : ''),
      twoWay: bidirection,
      canEditSource: sourceCal.canEdit,
      fullSync: fullSync,
      sourceColor: sourceColor ? sourceColor : 'default',
      destColor: destColor ? destColor : 'default',
      desinationColor: destColor ? destColor : 'default',
      tag: label ? (label != '' ? fixLabel : 'default') : 'default',
      matchColors,
      hasFilters: hasFilters,
      syncLevel: syncLevel.join('|'),
      filterColor: filterColor,
      startCutOff: startCutOff,
      endCutOff: endCutOff,
      excludeInvites: excludeInvites,
    }
    
    return item
  }

  nextPage = () => {
    const { page, lastPage, farthestPage } = this.state
    if (page > farthestPage) {
      this.setState({ farthestPage: page })
    }
    if(this.state.sync.destination && this.state.sync.destination.calendarAccountType  == "caldav" && page === lastPage -1)  {
      this.setState({ showModal: true, isLoading: false })
      // this.submitSync()
      return
    } 
    if (page === lastPage) {
      // console.log('SHOW MODAL')
      this.setState({ showModal: true, isLoading: false })
      // this.submitSync()
      return
    } else {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          window.scrollTo(0, 0)
        }
      )
    }
  }

  changePage = (page) => {
    const { farthestPage } = this.state
    if (page > farthestPage) {
      this.setState({ farthestPage: page })
    }
    this.setState({ page })
  }

  checkFilters = () => {
    var hasChanged = false
    if (this.state.sync.source.calendarAccountType == 'ms') {
      //check synclevel here
      //console.log('chjecl')
      //console.log(this.state.sync.syncLevel)
      if (
        !this.state.sync.syncLevel.includes('free') ||
        !this.state.sync.syncLevel.includes('tentative')
      ) {
        hasChanged = true
      }
    } else if (!this.state.sync.syncLevel.includes('free')) {
      hasChanged = true
    }

    if (this.state.sync.excludeInvites) {
      hasChanged = true
    }

    if (this.state.sync.filterColor != 'none') {
      hasChanged = true
    }
    if (this.state.sync.startCutOff != -1) {
      hasChanged = true
    }
    if (this.state.sync.endCutOff != -1) {
      hasChanged = true
    }
    //console.log('Change result check ' + hasChanged)
    return hasChanged
  }

  changeSyncValue = (field, value) => {
    // console.log(field, value)
    var changeSourceColor = false
    var changeDestColor = false
    var filterChange = false
    if (
      field == 'syncLevel' ||
      field == 'filterColor' ||
      field == 'startCutOff' ||
      field == 'endCutOff' ||
      field == 'excludeInvites'
    ) {
      filterChange = true
    }
    if (field == 'source') {
      changeSourceColor = true
    }
    if (field == 'destination') {
      changeDestColor = true
    }
    if (field == 'clearTag') {
      this.setState({
        sync: {
          ...this.state.sync,
          includeSubject: this.state.sync.includeSubject,
          includeTag: this.state.sync.label != ' ',
          toggle: value,
        },
      })
      return
    }
    if (field == 'includeSubject') {
      this.setState({
        sync: {
          ...this.state.sync,
          includeSubject: value,
          includeTag: !value && this.state.sync.label != ' ',
        },
      })
      return
    }
    if (field == 'includeTag') {
      this.setState({
        sync: {
          ...this.state.sync,
          includeSubject: this.state.sync.includeSubject,
          includeTag: value,
        },
      })
      return
    }
    this.setState(
      {
        sync: {
          ...this.state.sync,
          [field]: value,
        },
      },
      () => {
        // console.log("CHANGE " + field)
        // console.log(this.state.schedule)
        if (field == 'label') {
          if (value == ' ') {
            this.changeSyncValue('includeTag', false)
          } else if (value != '') {
            this.changeSyncValue('includeTag', true)
          }
        }
        if (changeSourceColor) {
          this.changeSyncValue('sourceColor', 'default')
          if (value == null) {
            this.changeSyncValue('destination', null)
          }
        }
        if (changeDestColor) {
          this.changeSyncValue('destColor', 'default')
        }
        if (filterChange) {
          this.changeSyncValue('hasFilters', this.checkFilters())
        }
      }
    )
    // this.setState({
    //   sync: {
    //     ...this.state.sync,
    //     [field]: value,
    //   },
    // })
  }

  getAccountInfo = (c) => {
    // console.log(c)
    const account = this.props.accounts.filter(
      (a) => a.calendarAccountId === c.calendarAccountId
    )[0]
    let prepend = ''
    if (
      c.calendarAccountType == 'google' &&
      c.id != account.email &&
      !c.id.endsWith('calendar.google.com')
    ) {
      prepend = `${c.id}`
    }

    return (
      <>
        {prepend != '' && (
          <>
            from: <u>{prepend}</u> shared to:
          </>
        )}
        {account.email}
        {c.name == 'Calendar' &&
        c.owner != null &&
        c.owner.address == account.email ? (
          <div>Primary Calendar</div>
        ) : (
          ''
        )}
        {c.id == account.email ? <div>Primary Calendar</div> : ''}
        <div className="flex">
          {!c.canEdit && (
            <div className="mt-1">
              <Tag color="red">Read Only</Tag>
            </div>
          )}
          {c.id.endsWith('import.calendar.google.com') && (
            <div className="mt-1">
              <Tag color="blue">Imported</Tag>
            </div>
          )}
        </div>
      </>
    )
  }
  SourceForm = () => {
    return (
      <>
        {/* {this.props.id != null && <>Cannot edit source calendar</>} */}
        {/* {this.props.id == null && */}
        <div style={{ marginBottom: 40 }}>
          <CBHeader
            step={1}
            label="Select Calendars"
            buttonLabel="Cancel Sync"
            buttonIcon={<Close></Close>}
            buttonAction={(e) => {
              navigate('/dashboard/syncs/')
            }}
          ></CBHeader>
          {this.props.license.userId != null &&
            this.props.accounts.length == 0 && (
              <center className="mt-8 mb-8 text-lg">
                <a
                  onClick={(e) => {
                    navigate('/dashboard/accounts/new')
                  }}
                >
                  Add calendar account to begin syncing
                </a>
              </center>
            )}
          {this.state.destLabel && this.state.sourceLabel != '' && (
            <div className="text-sarabun text-lg">
              Selected Source Calendar: <p />
              <b>{this.state.sourceLabel}</b>
              <p />
            </div>
          )}
          {this.state.sourceLabel == null &&
            this.state.allCalendars.length > 0 && (
              <>
                <CBHeader
                  icon={<CalendarIcon></CalendarIcon>}
                  label="Select Source Calendar"
                ></CBHeader>
                <div>
                  Select the calendar you would like to sync events from.
                </div>

                {(this.state.sync.source == null ||
                  this.state.sync.source.calendarAccountType != 'ics') && (
                  <CBRadioGroupBigCalendar
                    title=""
                    // selected={this.state.allCalendars.indexOf(this.state.sync.source)}
                    style={{ width: '100%', marginTop: 8, marginBottom: 8 }}
                    values={this.state.allCalendars}
                    selected={this.state.sync.source}
                    labels={this.state.allCalendars.map(
                      (c) => c.name || c.summary
                    )}
                    icons={reduce(
                      this.state.allCalendars,
                      (acc, calendar) => {
                        const Component =
                          calendar.calendarAccountType == 'google' ? (
                            <GoogleLogo className="mr-2" />
                          ) : calendar.calendarAccountType == 'ms' ? (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faOutlook}
                              size="2x"
                              style={{ width: 24, height: 24 }}
                              color={colorOutlook}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faApple}
                              size="2x"
                              style={{ width: 24, height: 24 }}
                              color={colorApple}
                            />
                          )

                        // key this off via primary email
                        return set(acc, [calendar.id], Component)
                      },
                      {}
                    )}
                    subLabels={reduce(
                      this.state.allCalendars,
                      (acc, calendar) => {
                        return set(
                          acc,
                          [calendar.id],
                          this.getAccountInfo(calendar)
                        )
                      },
                      {}
                    )}
                    selectedComponent={
                      <>
                        <div className="mt-2 mb-2">Calendar Timezone:</div>
                        {this.state.sync.source != null &&
                          this.state.sync.source.calendarAccountType ==
                            'google' && (
                            <div className="ml-2 p-1  border-b-2 border-neutral_2 font-sarabun text-bold">
                              {this.state.sync.source.timeZone}
                            </div>
                          )}
                        {this.state.sync.source != null &&
                          this.state.sync.source.calendarAccountType !=
                            'google' && (
                            <>
                              <Select
                                showSearch
                                size="small"
                                style={{
                                  width: '100%',
                                  margin: '0px 0px 20px 0px',
                                }}
                                placeholder="Select a time zone"
                                filterOption={(input, option) => {
                                  return (
                                    option.key
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  )
                                }}
                                value={this.state.sourceCalendarTimezone}
                                onChange={(e) =>
                                  this.setState({ sourceCalendarTimezone: e })
                                }
                              >
                                {this.getOptions()}
                              </Select>
                            </>
                          )}
                      </>
                    }
                    onChange={(calendar) => {
                      this.changeSyncValue('source', calendar)
                      window.scrollTo(0, 0)
                    }}
                  ></CBRadioGroupBigCalendar>
                )}

                {this.state.sync.source &&
                  this.state.sync.source.calendarAccountType == 'ics' && (
                    <div className="bg-neutral p-4 border border-neutral_2">
                      <div className="flex flex-row justify-between">
                        <div>
                          <div style={{ width: '450px' }} className="text-lg">
                            {this.state.sync.source.id}
                          </div>
                          <div>Timezone:</div>
                          <div>
                            <Select
                              showSearch
                              size="small"
                              style={{
                                width: '100%',
                                margin: '0px 0px 20px 0px',
                              }}
                              placeholder="Select a time zone"
                              filterOption={(input, option) => {
                                return (
                                  option.key
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                )
                              }}
                              value={this.state.sourceCalendarTimezone}
                              onChange={(e) =>
                                this.setState({ sourceCalendarTimezone: e })
                              }
                            >
                              {this.getOptions()}
                            </Select>
                          </div>
                          <div className="mt-1">
                            <Tag color="red">Read Only</Tag>
                          </div>
                        </div>
                        <div
                          onClick={(e) => {
                            this.changeSyncValue('source', null)
                          }}
                        >
                          <Close></Close>
                        </div>
                      </div>
                    </div>
                  )}

                {!this.state.sync.source && (
                  <div
                    className="flex flex-row items-center gap-2 mt-2 font-manrope text-neutral_4 mb-2 cursor-pointer"
                    onClick={(e) => {
                      this.setState({ showICS: !this.state.showICS })
                    }}
                  >
                    {this.state.showICS ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    )}
                    Other Calendar Types
                  </div>
                )}

                {this.state.showICS && (
                  <div className="flex flex-col gap-2 ml-8">
                    <CBInput
                      label=""
                      error={this.state.ics_error}
                      onChange={(e) => {
                        this.setState({ ics_link: e })
                      }}
                      value={this.state.ics_link}
                    ></CBInput>
                    <div className="mb-8">
                      <CBButton
                        onClick={(e) => {
                          //veryify http url
                          if(!this.state.ics_link.startsWith("http")) {
                            this.setState({ics_error: `Must be valid web link starting with http or https. ${this.state.ics_link.startsWith("webcal") ? 'Replace webcal:// with https://' : ``}`})
                          } else {
                          this.changeSyncValue('source', {
                            id: this.state.ics_link,
                            calendarAccountId: this.state.ics_link,
                            calendarAccountType: 'ics',
                            name: 'ICS URL',
                            canEdit: false,
                          })
                          this.setState({ ics_link: '', ics_error: null, showICS: false }) 
                        }
                      }
                    }
                      >
                        Use ICS URL as Source
                      </CBButton>
                      <a classname="mt-3 mb-2 ml-1"
                target="_blank"
                href="https://help.calendarbridge.com/uncategorized/how-to-get-an-ics-url-for-you-calendar/"
              >
                How to access ICS Shared Links
              </a>
                    </div>
                  </div>
                )}

                {this.state.sync.source == null && (
                  <Text>
                    Calendar not showing? You might need to{' '}
                    <Link to="/dashboard/accounts/new">
                      add another account
                    </Link>
                    .
                  </Text>
                )}
              </>
            )}
          {this.state.allCalendars.length > 0 &&
            this.state.sync.source != null &&
            this.DestinationForm()}
        </div>
        {/* } */}
      </>
    )
  }

  DestinationForm = () => {
    // filter out the read only calendars and the source calendar
    const destinationCalendars = reduce(
      this.state.allCalendars,
      (acc, calendar) => {
        if (calendar.canEdit && calendar.id !== this.state.sync.source.id) {
          return [...acc, calendar]
        }

        return acc
      },
      []
    )

    return (
      <>
        <div style={{ marginBottom: 40, marginTop: 40 }}>
          {/* <CBHeader step={2} label="Destination Calendar" buttonLabel="Edit Previous Step" buttonIcon={<Edit></Edit>} buttonAction={e=>
      {

        this.setState({page: this.state.page-1})
      }}></CBHeader> */}
          {this.state.destLabel && this.state.destLabel != '' && (
            <div className="text-sarabun text-lg">
              Selected Destination Calendar: <p />
              <b>{this.state.destLabel}</b>
              <p />
            </div>
          )}
          {this.state.destLabel == null && (
            <>
              <CBHeader
                icon={<CalendarIcon></CalendarIcon>}
                label="Select Destination Calendar"
              ></CBHeader>
              <div>Select the calendar you would like to sync events to.</div>
              <CBRadioGroupBigCalendar
                title=""
                // selected={this.state.allCalendars.indexOf(this.state.sync.source)}
                style={{ width: '100%', marginTop: 8, marginBottom: 8 }}
                values={destinationCalendars}
                labels={destinationCalendars.map((c) => c.name || c.summary)}
                icons={reduce(
                  destinationCalendars,
                  (acc, calendar) => {
                    const Component =
                      calendar.calendarAccountType == 'google' ? (
                        <GoogleLogo className="mr-2" />
                      ) : calendar.calendarAccountType != 'ms' ? (
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faApple}
                          size="2x"
                          style={{ width: 24, height: 24 }}
                          color={colorApple}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faOutlook}
                          size="2x"
                          style={{ width: 24, height: 24 }}
                          color={colorOutlook}
                        />
                      )

                    // key this off via primary email
                    return set(acc, [calendar.id], Component)
                  },
                  {}
                )}
                selected={this.state.sync.destination}
                subLabels={reduce(
                  destinationCalendars,
                  (acc, calendar) => {
                    return set(
                      acc,
                      [calendar.id],
                      this.getAccountInfo(calendar)
                    )
                  },
                  {}
                )}
                onChange={(calendar) => {
                  this.changeSyncValue('destination', calendar) // weird way of unsetting but easiest fix (null => undefined)
                  window.scrollTo(0, 0)
                }}
                selectedComponent={
                  <>
                    <div className="mt-2 mb-2">Calendar Timezone:</div>
                    {this.state.sync.destination != null &&
                      this.state.sync.destination.calendarAccountType ==
                        'google' && (
                        <div className="ml-2 p-1 border-b-2 border-neutral_2 font-sarabun text-bold">
                          {this.state.sync.destination.timeZone}
                        </div>
                      )}
                    {this.state.sync.destination != null &&
                      this.state.sync.destination.calendarAccountType !=
                        'google' && (
                        <>
                          <Select
                            showSearch
                            size="small"
                            style={{
                              width: '100%',
                              margin: '0px 0px 20px 0px',
                            }}
                            placeholder="Select a time zone"
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              )
                            }}
                            value={this.state.destCalendarTimezone}
                            onChange={(e) =>
                              this.setState({ destCalendarTimezone: e })
                            }
                          >
                            {this.getOptions()}
                          </Select>
                        </>
                      )}
                  </>
                }
              ></CBRadioGroupBigCalendar>
              {this.state.sync.destination == null && (
                <Text>
                  Calendar not showing? You might need to{' '}
                  <Link to="/dashboard/accounts/new">add another account</Link>.
                </Text>
              )}
              {/* <Select
          
            size="large"
            placeholder="Select Calendar"
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            onChange={(v) => 
              {
                this.changeSyncValue('destination', v)
                
              }}
            value={this.state.sync.destination}
            autoFocus
            // defaultValue={this.state.destLabel}
          >
            {this.state.allCalendars
              .map((c, i) => {
                c.pos = i
                return c
              })
              .filter((c, i) => i !== +this.state.sync.source && c.canEdit)
              .map((c, i) => (
                <Select.Option key={c.pos}>
                  <strong>{c.name || c.summary}</strong>{' '}
                  {
                    c.email
                  }
                </Select.Option>
              ))}
          </Select> */}
            </>
          )}
        </div>
        {/* } */}
        {this.props.id != null && <>Cannot edit calendars</>}
      </>
    )
  }

  getColorOptions = () => {
    if (this.state.sync.source.calendarAccountType == 'ms') {
      return [
        <Select.Option value="none"> None </Select.Option>,
        <Select.Option value="Green category">
          <Space>
            <div
              className="w-4 h-4 rounded-full"
              style={{ background: '#00ff00' }}
            />
            Green category
          </Space>
        </Select.Option>,

        <Select.Option value="Yellow category">
          <Space>
            <div className="w-4 h-4 rounded-full bg-yellow-200" /> Yellow
            category
          </Space>
        </Select.Option>,
        <Select.Option value="Red category">
          <Space>
            <div className="w-4 h-4 rounded-full bg-red-500" /> Red category
          </Space>
        </Select.Option>,
        <Select.Option value="Orange category">
          <Space>
            <div
              className="w-4 h-4 rounded-full"
              style={{ background: 'orange' }}
            />{' '}
            Orange category
          </Space>
        </Select.Option>,
        <Select.Option value="Blue category">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: 'blue' }}
            />{' '}
            Blue category
          </Space>
        </Select.Option>,
        <Select.Option value="Purple category">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: 'purple' }}
            />{' '}
            Purple category
          </Space>
        </Select.Option>,
      ]
    } else {
      return [
        <Select.Option value="none"> None </Select.Option>,
        <Select.Option value="11">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: '#D50000' }}
            />
            Tomato
          </Space>
        </Select.Option>,
        <Select.Option value="10">
          <Space>
            <div
              className="w-4 h-4 rounded-full"
              style={{ background: '#0B8043' }}
            />
            Basil
          </Space>
        </Select.Option>,
        <Select.Option value="9">
          <Space>
            <div
              className="w-4 h-4 rounded-full "
              style={{ background: '#3F51B5' }}
            />
            Blueberry
          </Space>
        </Select.Option>,
      ]
    }
  }

  DirectionForm = () => {
    const source = this.state.sync.source
    const target = this.state.sync.destination
    const preview = {
      id: 1,
      sourceCalendarAccountId: source.calendarAccountId,
      sourceCalendarName: source.name || source.summary,
      sourceCalendarType: source.calendarAccountType,
      sourceEmail: source.email,
      destEmail: target.email,
      sourceCalendarId: source.id,
      destCalendarId: target.id,
      twoWay: this.state.sync.bidirection,
      lastSyncTime: null,
      destCalendarAccountId: target.calendarAccountId,
      destCalendarName: target.name || target.summary,
      destCalendarType: target.calendarAccountType,
      includeTag: this.state.sync.includeTag,
      includeSubject: this.state.sync.includeSubject,
      includeDescription: this.state.sync.includeDescription,
      includeLocation: this.state.sync.includeLocation,
      includeReminders: this.state.sync.includeReminders,
    }

    return (
      <>
        <CBHeader
          step={3}
          label="Configure Sync Options"
          buttonLabel="Edit Previous Step"
          buttonIcon={<Up></Up>}
          buttonAction={(e) => {
            this.setState({ page: this.state.page - 1 })
          }}
        ></CBHeader>
        <div className="pl-4 pr-4 mt-6 mb-6">
          Select your color and tag options
        </div>
        {/* <Row type="flex" justify="center">
          {!source.canEdit && (
            <Alert
              style={{ marginTop: 40 }}
              message="The source calendar is read-only so you can only sync in one direction."
              type="warning"
            />
          )}
          <Radio.Group
            defaultValue={this.state.sync.bidirection}
            onChange={(e) =>
              this.changeSyncValue('bidirection', e.target.value)
            }
            value={this.state.sync.bidirection}
            disabled={!source.canEdit}
            size="large"
            style={{ padding: 40 }}
          >
            <Radio.Button
              value={true}
              style={{
                padding: 20,
                fontSize: '2em',
                height: 80,
                lineHeight: '38px',
              }}
            >
              <>
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  size="1x"
                  style={{ marginRight: 10 }}
                />
                Both Directions
              </>
            </Radio.Button>
            <Radio.Button
              value={false}
              style={{
                padding: 20,
                fontSize: '2em',
                height: 80,
                lineHeight: '38px',
              }}
            >
              <>
                <FontAwesomeIcon
                  icon={faLongArrowAltRight}
                  size="1x"
                  style={{ marginRight: 10 }}
                />
                One Direction
              </>
            </Radio.Button>
          </Radio.Group>
        </Row> */}

        <div className="pl-4 pr-4 pb-4 border-2 border-neutral_1 rounded-sm">
          <CBHeader
            icon={<CalendarIcon></CalendarIcon>}
            label={`Destination: ${
              this.state.sync.destination.name ||
              this.state.sync.destination.summary
            }  (${this.state.sync.destination.email})`}
            subheader={true}
          ></CBHeader>

          <div className="border-2 border-neutral_1 rounded-sm hover:border-neutral_2 pt-3 pb-1 pl-2 pr-2 lg:pl-4 lg:pr-4 space-x-3 items-center cursor-pointer w-full">
            <div className="font-manrope text-neutral_4 mb-2">
              Default/Uncategorized Copy Color
            </div>
            <CBColorPicker
              key={`dest-${this.state.sync.destination.id}-${this.state.sync.destination.calendarAccountType}`}
              type={this.state.sync.destination.calendarAccountType}
              onColorChangeCallback={(color) => {
                this.changeSyncValue('destColor', color)
              }}
              color={this.state.sync.destColor}
            ></CBColorPicker>
          </div>
          {!this.state.sync.bidrection &&
            this.state.sync.destination.calendarAccountType ==
              this.state.sync.source.calendarAccountType && (
              <div className="mt-4">
                <CBCheckItem
                  onChange={(e) => {
                    this.changeSyncValue('matchColors', e)
                  }}
                  checked={this.state.sync.matchColors}
                  label="Match Source Event Colors"
                ></CBCheckItem>
              </div>
            )}
        </div>
        {this.state.sync.source.calendarAccountType != 'ics' &&
          this.state.sync.source.calendarAccountType != 'caldav' &&
          this.state.sync.destination.calendarAccountType != 'caldav' && (
            <div
              className="flex flex-row items-center gap-2 mt-2 font-manrope text-xl text-neutral_4 mb-2 cursor-pointer"
              onClick={(e) => {
                this.setState({ showAdvanced: !this.state.showAdvanced })
              }}
            >
              {/* {this.state.showAdvanced ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          )} */}
              Advanced
            </div>
          )}
        {this.state.sync.source.calendarAccountType != 'ics' &&
          this.state.sync.source.calendarAccountType != 'caldav' &&
          this.state.sync.destination.calendarAccountType != 'caldav' &&
          this.state.showAdvanced && (
            <div className="p-4 border mb-4">
              <div className="text-xl mb-2 mt-2">Filtering</div>
              <div className="flex flex-col gap-2">
                <CBCheckItem
                  onChange={(e) => {
                    if (e) {
                      if (
                        this.state.sync.source.calendarAccountType == 'google'
                      ) {
                        this.changeSyncValue('syncLevel', ['busy'])
                      } else {
                        this.changeSyncValue(
                          'syncLevel',
                          this.state.sync.syncLevel.filter((it) => it != 'free')
                        )
                      }
                    } else {
                      if (
                        this.state.sync.source.calendarAccountType == 'google'
                      ) {
                        this.changeSyncValue('syncLevel', ['free', 'busy'])
                      } else {
                        this.changeSyncValue('syncLevel', [
                          'free',
                          ...this.state.sync.syncLevel,
                        ])
                      }
                    }
                  }}
                  checked={!this.state.sync.syncLevel.includes('free')}
                  label="Exclude Free Events"
                ></CBCheckItem>
                {this.state.sync.source.calendarAccountType == 'ms' && (
                  <CBCheckItem
                    onChange={(e) => {
                      if (e) {
                        if (
                          this.state.sync.source.calendarAccountType == 'google'
                        ) {
                          this.changeSyncValue('syncLevel', ['busy'])
                        } else {
                          this.changeSyncValue(
                            'syncLevel',
                            this.state.sync.syncLevel.filter(
                              (it) => it != 'tentative'
                            )
                          )
                        }
                      } else {
                        if (
                          this.state.sync.source.calendarAccountType == 'google'
                        ) {
                          this.changeSyncValue('syncLevel', ['free', 'busy'])
                        } else {
                          this.changeSyncValue('syncLevel', [
                            'tentative',
                            ...this.state.sync.syncLevel,
                          ])
                        }
                      }
                    }}
                    checked={!this.state.sync.syncLevel.includes('tentative')}
                    label="Exclude tentative Events"
                  ></CBCheckItem>
                )}
                {this.state.sync.source.calendarAccountType == 'google' && (
                  <CBCheckItem
                    onChange={(e) => {
                      this.changeSyncValue('excludeInvites', e)
                    }}
                    checked={this.state.sync.excludeInvites}
                    label="Exclude unaccepted invites"
                  ></CBCheckItem>
                )}
              </div>
              <div className="mt-4 flex flex-row items-center gap-4">
                <div className="text-base mt-2 mb-1 font-manrope font-sm pl-2">
                  Exclude source events with color
                </div>
                <Select
                  size="small"
                  style={{
                    width: '200px',
                    margin: '10px 0px 0px 0px',
                  }}
                  placeholder="Select a color to filter"
                  value={this.state.sync.filterColor}
                  onChange={(e) => {
                    //console.log(e)
                    this.changeSyncValue('filterColor', e)
                  }}
                >
                  {this.getColorOptions()}
                </Select>

                {/* <CBCheckItem
            
              onChange={(e) => {
                if(e) {
                  if(this.state.sync.source.calendarAccountType == "ms") {
                    this.changeSyncValue('filterColor', "Green category")
                  } else {
                    this.changeSyncValue('filterColor', "11")
                  }
                } else {
                  this.changeSyncValue('filterColor', "none")
                }
                
                
              }}
              checked={this.state.sync.filterColor != "none"}
              label="Filter Color Tomato FOR GOOGLE Green category FOR MS "
            ></CBCheckItem> */}
              </div>
              {/* <div className="flex flex-col gap-2 mt-4">
              <div classname="w-96">
                <CBCheckItem
                  onChange={(e) => {
                    this.setState(
                      {
                        timeRangeActive: !this.state.timeRangeActive,
                        sync: {
                          ...this.state.sync,
                          startCutOff: !this.state.timeRangeActive ? 9 : -1,
                          endCutOff: !this.state.timeRangeActive ? 17 : -1,
                        },
                      },
                      () => {
                        if (this.state.timeRangeActive) {
                          this.changeSyncValue('startCutOff', 9)
                        } else {
                          this.changeSyncValue('startCutOff', -1)
                        }
                      }
                    )
                  }}
                  checked={
                    this.state.timeRangeActive ||
                    this.state.sync.startCutOff != -1 ||
                    this.state.sync.endCutOff != -1
                  }
                  label="Filter in time range (experimental not shipping in next release)"
                ></CBCheckItem>
              </div>
              <div className="flex flex-row items-center mt-8 gap-2 justify-center">
                <TimePicker
                  style={{ 'font-family': 'sarabun' }}
                  className="focus:ring-2 focus:ring-offset-1 focus:ring-neutral_4"
                  disabled={!this.state.timeRangeActive}
                  size="large"
                  use12Hours={true}
                  minuteStep={60}
                  format="h:mm a"
                  allowClear={false}
                  inputReadOnly
                  defaultValue={moment()
                    .hour(
                      this.state.sync.startCutOff != -1
                        ? this.state.sync.startCutOff
                        : 9
                    )
                    .minute(0)}
                  onChange={(v) => {
                    console.log(v.hour())

                    this.changeSyncValue('startCutOff', v.hour())
                  }}
                />
                <div>To</div>
                <TimePicker
                  style={{ 'font-family': 'sarabun' }}
                  className="focus:ring-2 focus:ring-offset-1 focus:ring-neutral_4"
                  disabled={!this.state.timeRangeActive}
                  size="large"
                  use12Hours={true}
                  minuteStep={60}
                  format="h:mm a"
                  allowClear={false}
                  inputReadOnly
                  defaultValue={moment()
                    .hour(
                      this.state.sync.endCutOff != -1
                        ? this.state.sync.endCutOff
                        : 17
                    )
                    .minute(0)}
                  onChange={(v) => {
                    console.log(v.hour())
                    this.changeSyncValue('endCutOff', v.hour())
                  }}
                />
              </div>
            </div> */}
            </div>
          )}
        {/* {this.renderPreview()} */}
        {this.props.id != null && this.state.sync.editBidirection && (
          <div className="mt-10 mb-10">
            <div className="mb-2 text-sarabun">Directions</div>
            <CBCheckItem
              label={<>Use These Options For Both Directions</>}
              checked={this.state.sync.bidirection}
              onChange={(e) => {
                this.changeSyncValue('bidirection', e)
              }}
            ></CBCheckItem>
          </div>
        )}
        {this.state.sync.bidirection && (
          <div
            className="pl-4 pr-4 pb-4 border-2 border-neutral_1 rounded-sm"
            style={{ marginBottom: 30, marginTop: 10 }}
          >
            <CBHeader
              icon={<CalendarIcon></CalendarIcon>}
              subheader={true}
              label={`Destination: ${
                this.state.sync.source.name || this.state.sync.source.summary
              } (${this.state.sync.source.email})`}
            ></CBHeader>

            {this.state.sync.bidirection && (
              <div>
                <div>Copy Color</div>
                <CBColorPicker
                  key={`source-${this.state.sync.source.id}-${this.state.sync.source.calendarAccountType}`}
                  sync={this.state.sync}
                  type={this.state.sync.source.calendarAccountType}
                  onColorChangeCallback={(color) => {
                    this.changeSyncValue('sourceColor', color)
                  }}
                  color={this.state.sync.sourceColor}
                ></CBColorPicker>
              </div>
            )}
          </div>
        )}
        {/* <Row>
           {process.env.GATSBY_DEBUG_UI == "true" && 
          <Checkbox style={{marginLeft:24}}defaultChecked={this.state.sync.fullSync} onChange={(e) =>
              this.changeSyncValue('fullSync', e.target.checked)
            }>Live Copy - changes to copies will be reflected in origin event</Checkbox>
          }
        </Row>  */}
      </>
    )
  }

  getEventTitle = () => {
    if (!this.state.sync.includeSubject) {
      if (
        this.state.sync.hasFilters &&
        !this.state.sync.syncLevel.includes('free')
      ) {
        return 'Busy ' + this.state.sync.label
      } else if (
        this.state.sync.hasFilters &&
        !this.state.sync.syncLevel.includes('free') &&
        !this.state.sync.syncLevel.includes('tentative')
      ) {
        return 'Tentative(as Free)/Busy ' + this.state.sync.label
      } else {
        return 'Free/Busy ' + this.state.sync.label
      }
    }
    return 'Your Event Subject ' + this.state.sync.label
  }
  getColor = (color) => {
    switch (color) {
      case 'Green category':
        return '#008000'
      case 'Red category':
        return '#ff0000'
      case 'Yellow category':
        return '#ffff00'
      case 'Blue category':
        return '#0000ff'
      case 'Purple category':
        return '#800080'
      case 'Orange category':
        return '#ffa500'
      case '1':
        return '#a4bdfc'
      case '2':
        return '#7ae7bf'
      case '3':
        return '#dbadff'
      case '4':
        return '#ff887c'
      case '5':
        return '#fbd75b'
      case '6':
        return '#ffb878'
      case '7':
        return '#46d6db'
      case '8':
        return '#e1e1e1'
      case '9':
        return '#5484ed'
      case '10':
        return '#51b749'
      case '11':
        return '#D50000'
    }
  }

  renderPreview = () => {
    return (
      <div className="flex flex-col gap-y-4 border p-2 border-neutral_1 mt-4  items-center mb-4">
        <div className="text-lg font-manrope">Subject Line Preview</div>
        <div className="grid lg:grid-cols-2 lg:gap-2 gap-4 grid-rows-1">
          <div className="flex flex-col items-center">
            <div className="text-lg">Source</div>
            <div>
              <div
                className="flex flex-col gap-y-2 bg-neutral_1 p-2 w-64 rounded-md pl-4"
                style={{ background: '#D5E9F9' }}
              >
                <div>Your Event Subject</div>
                {/* <div>Attendees</div>
                <div>Location</div>
                <div>Online meeting</div>
                <div>Description</div> */}
              </div>
              {/* {this.state.sync.matchColors && (
                <div className="h-2 bg-burnt"></div>
              )} */}
            </div>
            {/* {this.state.sync.hasFilters != 'default' && this.state.sync.filterColor != 'none' && (
              <div className="flex flex-row gap-3 mt-2 mb-2 items-center">
                Filter Events with Color:
                <div
                  className={`h-4 w-4 rounded-full`}
                  style={{
                    background: this.getColor(this.state.sync.filterColor),
                  }}
                ></div>
                </div>
              )} */}
            {/* {this.state.sync.hasFilters != 'default' && !this.state.sync.syncLevel.includes("free") && (
              <div className="flex flex-row gap-3 mt-2 mb-2 items-center">
                Filter Free Events
                </div>
              )} */}
            {/* {this.state.sync.hasFilters != 'default' && !this.state.sync.syncLevel.includes("tentative") && (
              <div className="flex flex-row gap-3 mt-2 mb-2 items-center justify-start">
                Filter Tentative Events
                </div>
              )} */}
            <div></div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-lg">Destination</div>
            <div>
              <div
                className="flex flex-col gap-y-2 bg-neutral_2 p-2 w-64 rounded-md pl-4"
                style={{ background: '#B1D7F3' }}
              >
                <div>{this.getEventTitle()}</div>
                {/* {this.state.sync.includeAttendees && <div>Attendees</div>}
                {this.state.sync.includeLocation && <div>Location</div>}
                {this.state.sync.includeMeeting && <div>Online meeting</div>}
                {this.state.sync.includeDescription && <div>Description</div>} */}
                {/* <div className="grid grid-cols-2 text-sm"> */}
                {/* {this.state.sync.allPrivate && (
                    <div className="flex flex-col items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                      Private
                    </div>
                  )} */}
                {/* {this.state.sync.includeReminders && (
                    <div className="flex flex-col items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                        />
                      </svg>
                      Reminders
                    </div>
                  )} */}
                {/* </div> */}
              </div>
              {/* {this.state.sync.matchColors && (
                <div className="h-2 bg-burnt"></div>
              )} */}
              {/* {this.state.sync.destColor != 'default' && (
                <div
                  className={`h-2`}
                  style={{
                    background: this.getColor(this.state.sync.destColor),
                  }}
                ></div>
              )} */}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    )
  }

  EventDetailForm = () => {
    return (
      <>
        <CBHeader
          step={2}
          label="Select Information to Sync"
          buttonLabel="Edit Previous Step"
          buttonIcon={<Up></Up>}
          buttonAction={(e) => {
            this.setState({ page: this.state.page - 1 })
          }}
        ></CBHeader>

        <div className="p-4">
          <div className="font-sarabun mt-6 mb-6">
            Select additional event info you would like to sync between
            accounts. Time and date are synced by default (busy & free slots)
            {this.props.license.policy != 'all' && (
              <div>Your administrator has limited the items you can sync</div>
            )}
          </div>
          
          {(this.props.license.policy == 'all' ||
              this.props.license.policy.includes('subject')) && (
              <div className="mb-3">
                <CBCheckItem
                  label="Subject"
                  box={true}
                  border={true}
                  checked={this.state.sync.includeSubject}
                  onChange={(e) => {
                    this.changeSyncValue('includeSubject', e)
                  }}
                ></CBCheckItem>
              </div>
            )}
          <div>
            {(this.props.license.policy == 'all' ||
              this.props.license.policy.includes('contact')) && (
              <>
                <CBCheckItem
                  label="Attendees"
                  box={true}
                  checked={this.state.sync.includeAttendees}
                  onChange={(e) => {
                    this.changeSyncValue('includeAttendees', e)
                  }}
                ></CBCheckItem>
                <div className="font-sarabun p-2 bg-neutral_1 mb-3 text-neutral_3">
                  Attendees appear in description of copy
                </div>
              </>
            )}
          </div>
          {(this.props.license.policy == 'all' ||
            this.props.license.policy.includes('description')) && (
            <div className="mb-3">
              <CBCheckItem
                label="Description"
                box={true}
                checked={this.state.sync.includeDescription}
                onChange={(e) => {
                  this.changeSyncValue('includeDescription', e)
                }}
              ></CBCheckItem>
            </div>
          )}
          {(this.props.license.policy == 'all' ||
            this.props.license.policy.includes('location')) && (
            <div className="mb-3">
              <CBCheckItem
                label="Location"
                box={true}
                checked={this.state.sync.includeLocation}
                onChange={(e) => {
                  this.changeSyncValue('includeLocation', e)
                }}
              ></CBCheckItem>
            </div>
          )}
          {(this.props.license.policy == 'all' ||
            this.props.license.policy.includes('meeting')) && (
            <div className="mb-3">
              <CBCheckItem
                label="Conference"
                box={true}
                checked={this.state.sync.includeMeeting}
                onChange={(e) => {
                  this.changeSyncValue('includeMeeting', e)
                }}
              ></CBCheckItem>
            </div>
          )}

          {(this.props.license.policy == 'all' ||
            this.props.license.policy.includes('reminder')) && (
            <div>
              <CBCheckItem
                label="Reminders"
                box={true}
                checked={this.state.sync.includeReminders}
                onChange={(e) => {
                  this.changeSyncValue('includeReminders', e)
                }}
              ></CBCheckItem>

              <div className="font-sarabun p-2 bg-neutral_1 mb-3 text-neutral_3">
                Events inherit the reminder settings of the destination
                calendar.
              </div>
            </div>
          )}
          {(this.props.license.policy == 'all' ||
            this.props.license.policy.includes('private')) && (
            <>
              <CBCheckItem
                label="All Private"
                box={true}
                checked={this.state.sync.allPrivate}
                onChange={(e) => {
                  this.changeSyncValue('allPrivate', e)
                }}
              ></CBCheckItem>

              <div className="font-sarabun p-2 bg-neutral_1 text-neutral_3">
                Copies will be marked private
              </div>
            </>
          )}
        </div>
        <div className="p-4 mb-4">

            <div className=" mb-8 p-4 flex flex-row gap-x-4 justify-between border-neutral items-start border-2">
              <div className="text-lg font-manrope">Tag</div>
              <div className="-mt-4">
                <CBInput
                  disabled={this.state.sync.label == ' '}
                  value={this.state.sync.label}
                  hint="(copy)"
                  onChange={(e) => {
                    var word = e
                    this.changeSyncValue('label', word)
                  }}
                />
                <CBCheckItem
                  label={<>No Tag</>}
                  onChange={(e) => {
                    if (e) {
                      this.changeSyncValue('label', ' ')
                    } else {
                      this.changeSyncValue('label', '(copy)')
                    }
                  }}
                  checked={this.state.sync.label == ' '}
                ></CBCheckItem>
              </div>
            </div>
            {this.state.sync && this.renderPreview()}
          </div>
      </>
    )
  }

  //  renderSteps(currentPage,onChange){
  //   return <div className="m-12">
  //     <div className="ml-12">

  //           <CBHeader label="Create Sync" icon={<NewSync/>}></CBHeader>
  //           </div>
  //     <CBSteps
  //   page={currentPage}

  //   onPageChanged={e =>
  //   {
  //     setPage(e)
  //   }}
  // >
  //   <CBStep
  //     primary="Calendars"
  //     secondary={'Source: '(sourceCalendar.name || sourceCalendar.summary) +' \n\nDest: ' + (destCalendar.name || destCalendar.summary) }
  //   />
  //   <CBStep
  //     primary="Dest Calendar"
  //     secondary={(destCalendar.name || destCalendar.summary) }

  //   />
  //   <CBStep
  //     primary="Options"
  //     secondary={
  //       currentPage >= 1
  //         ? sync.bidirection
  //           ? 'Both Directions'
  //           : 'One Direction'
  //         : 'Not Selected Yet'
  //     }

  //   />
  //   <CBStep

  //     primary="Event Fields"
  //     secondary={
  //       currentPage >= 2
  //         ? syncTypeText(
  //             sync.includeSubject,
  //             sync.includeDescription,
  //             sync.includeLocation,
  //             sync.includeReminders,
  //             sync.allPrivate
  //           )
  //         : 'Not Selected Yet'
  //     }
  //   />
  // </CBSteps>
  // </div>
  // }
  getButtonLabelByStep() {
    var label = ''
    switch (this.state.page) {
      case 0:
        // code block
        label = 'Continue to Privacy'
        break
      case 1:
        // code block
        
        if(this.state.sync.destination && this.state.sync.destination.calendarAccountType == "caldav") {
          label = 'Create Sync'
        } else {
          label = 'Continue to Options'
        }
        
        break
      case 2:
        label = 'Create Sync'
        break
      default:

      // code block
    }
    return label
  }
  render() {
    const { page, lastPage, farthestPage, sync, allCalendars } = this.state

    const sourceCalendar = sync.source || {
      name: 'Not Selected Yet',
      incomplete: true,
    }
    const destCalendar = sync.destination || {
      name: 'Not Selected Yet',
      incomplete: true,
    }

    const pages = [
      this.SourceForm,
      // this.DestinationForm,
      this.EventDetailForm,
      this.DirectionForm,
    ]

    return (
      <Layout>
        <SEO title="Create a Sync Connection" />
        <Content className="cb-content-frame" style={{ background: 'white' }}>
          <div className="cb-content-pane mobile_padding min-h-screen">
            <MediaQuery maxDeviceWidth={1224}>
              <CBSteps
                page={page}
                mobile={true}
                onPageChanged={(e) => {
                  this.changePage(e)
                }}
              >
                <CBStep
                  primary="Select Calendars"
                  secondary={
                    <div>
                      <div>
                        Source:
                        {sync.source != null
                          ? sourceCalendar.name || sourceCalendar.summary
                          : 'Not Yet Selected'}
                      </div>
                      <div>
                        Destination:
                        {sync.destination != null
                          ? destCalendar.name || destCalendar.summary
                          : 'Not Yet Selected'}
                      </div>
                    </div>
                  }
                />
                {/* <CBStep
      primary="Dest Calendar"
      secondary={(destCalendar.name || destCalendar.summary) }
      
    /> */}

                <CBStep
                  primary="Privacy Settings"
                  secondary={
                    page >= 2
                      ? syncTypeText(
                          sync.includeSubject,
                          sync.includeDescription,
                          sync.includeLocation,
                          sync.includeReminders,
                          sync.allPrivate,
                          sync.includeMeeting,
                          sync.includeAttendees,
                          sync.includeTag
                        )
                      : 'Not Selected Yet'
                  }
                />
                <CBStep
                  primary="Configure Options"
                  secondary={
                    <>
                      <div>Tag: {sync.label}</div>
                      {/* Direction: {page >= 3
                      ? sync.bidirection
                        ? 'Both Directions'
                        : 'One Direction'
                      : 'Not Selected Yet'
                  } */}
                    </>
                  }
                />
              </CBSteps>
            </MediaQuery>
            {pages[page]()}

            <SubmitButton
              type={'primary'}
              block
              badge={page === lastPage ? null : page + 2}
              size="large"
              onClick={this.nextPage}
              // loading={this.state.isLoading}
              disabled={
                (page === 0 && sourceCalendar.incomplete) ||
                (page === 0 && destCalendar.incomplete) ||
                this.state.isLoading
              }
            >
              {this.getButtonLabelByStep()}
            </SubmitButton>
            <CBModal
              title="Review Sync"
              visible={this.state.showModal}
              disabled={this.state.isLoading}
              onPositiveClick={(e) => {
                this.setState({ isLoading: true })
                this.submitSync()
              }}
              onCancelClick={(e) =>
                this.setState({ isLoading: false, showModal: false })
              }
            >
              {this.state.showModal && (
                <SyncItem
                  preview={true}
                  item={this.buildSyncItemForPreview()}
                ></SyncItem>
              )}
              {/* <div>
                Source: {sourceCalendar.name || sourceCalendar.summary} (
                {sourceCalendar.email}) {'->'}{' '}
                Destination: {destCalendar.name || destCalendar.summary} (
                {destCalendar.email})
              </div>
              <div>
                Tag:{' '}
                {this.state.sync.label == 'default'
                  ? '(copy)'
                  : this.state.sync.label == ' '
                  ? 'No Tag'
                  : this.state.sync.label}
              </div>
              <CBSampleEvent
                color={this.state.sync.destColor}
                tag={
                  this.state.sync.label == 'default'
                    ? '(copy)'
                    : this.state.sync.label == ' '
                    ? 'No Tag'
                    : this.state.sync.label
                }
                destType={destCalendar.calendarAccountType}
                policy={`${
                  this.state.sync.includeSubject ? 'subject' : 'time'
                }${this.state.sync.includeDescription ? '|description' : ''}
          ${this.state.sync.includeLocation ? '|location' : ''}
          ${this.state.sync.includeReminders ? '|reminder' : ''}
          ${this.state.sync.allPrivate ? '|private' : ''}
          ${this.state.sync.includeMeeting ? '|meeting' : ''}
          ${this.state.sync.includeAttendees ? '|contact' : ''}`}
              ></CBSampleEvent>
              <div>
                {syncTypeText(
                  this.state.sync.includeSubject,
                  this.state.sync.includeDescription,
                  this.state.sync.includeLocation,
                  this.state.sync.includeReminders,
                  this.state.sync.allPrivate,
                  this.state.sync.includeMeeting,
                  this.state.sync.includeAttendees
                )}
              </div> */}
            </CBModal>
            {this.state.error != null && (
              <span style={{ justifyContent: 'center', color: 'red' }}>
                {this.state.error}
              </span>
            )}
          </div>
        </Content>
        <MediaQuery minDeviceWidth={1224}>
          <Sider width={400} className="sider-style">
            <div className="m-12">
              <div className="ml-6">
                <CBHeader label="Create Sync" icon={<NewSync />}></CBHeader>
              </div>
              <CBSteps
                page={page}
                onPageChanged={(e) => {
                  this.changePage(e)
                }}
              >
                <CBStep
                  primary="Select Calendars"
                  secondary={
                    <div>
                      <div>
                        Source:
                        {sync.source != null
                          ? sourceCalendar.name || sourceCalendar.summary
                          : 'Not Yet Selected'}
                      </div>
                      <div>
                        Destination:
                        {sync.destination != null
                          ? destCalendar.name || destCalendar.summary
                          : 'Not Yet Selected'}
                      </div>
                    </div>
                  }
                />
                {/* <CBStep
      primary="Dest Calendar"
      secondary={(destCalendar.name || destCalendar.summary) }
      
    /> */}

                <CBStep
                  primary="Privacy Settings"
                  secondary={
                    page >= 1 ? (
                      <>
                        <div>
                          {syncTypeText(
                            sync.includeSubject,
                            sync.includeDescription,
                            sync.includeLocation,
                            sync.includeReminders,
                            sync.allPrivate,
                            sync.includeMeetings,
                            sync.includeAttendees,
                            sync.includeTag
                          )}
                        </div>
                        <div>
                          Tag: {sync.label} {sync.label == ' ' && 'No Tag'}
                        </div>
                      </>
                    ) : (
                      'Not Selected Yet'
                    )
                  }
                />
                <CBStep
                  primary="Configure Options"
                  secondary={
                    <>
                      <div>Not Yet Selected</div>
                      {/* Direction: {page >= 2
                      ? sync.bidirection
                        ? 'Both Directions'
                        : 'One Direction'
                      : 'Not Selected Yet'
                  } */}
                    </>
                  }
                />
              </CBSteps>
            </div>
            {/* <Steps
            direction="vertical"
            current={page}
            onChange={this.changePage}
            style={{  margin: 40 }}
          >
            <Step
              title="Select Source"
              description={sourceCalendar.name || sourceCalendar.summary}
            />
            <Step
              title="Select Destination"
              description={destCalendar.name || destCalendar.summary}
              disabled={sourceCalendar.incomplete}
            />
            <Step
              title="Direction &amp; Options"
              description={
                farthestPage >= 1
                  ? sync.bidirection
                    ? 'Both Directions'
                    : 'One Direction'
                  : 'Not Selected Yet'
              }
              disabled={sourceCalendar.incomplete || destCalendar.incomplete}
            />
            <Step
              style={{ minHeight: 95 }}
              title="Privacy Settings"
              description={
                farthestPage >= 2
                  ? syncTypeText(
                      sync.includeSubject,
                      sync.includeDescription,
                      sync.includeLocation,
                      sync.includeReminders,
                      sync.allPrivate
                    )
                  : 'Not Selected Yet'
              }
              disabled={sourceCalendar.incomplete || destCalendar.incomplete}
            />
          </Steps> */}
          </Sider>
        </MediaQuery>
      </Layout>
    )
  }
}

const SyncCreatePageState = ({ id }) => {
  const [accounts] = useGlobalState('accounts')
  const [license] = useGlobalState('license')
  const [syncs] = useGlobalState('syncs')
  return (
    <SyncCreatePage
      id={id}
      accounts={accounts}
      license={license}
      syncs={syncs}
    />
  )
}

export default SyncCreatePageState
