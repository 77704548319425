import React, { useEffect, useState, useRef } from 'react'
import Title from 'antd/lib/typography/Title'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import OrgSyncItem from '../components/OrgSyncItem'

import ScheduleItem from '../components/OrgScheduleItem'
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons'

import Info from '../../../images/svg/info.svg'
import JSONTree from 'react-json-tree'
import Connections from '../../../images/svg/connections.svg'
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Select,
  Table,
  Divider,
  Radio,
  Checkbox,
  Spin,
  Menu,
  Tag,
  TimePicker,
  Modal,
  InputNumber,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Text from 'antd/lib/typography/Text'
import { accountIconMap } from '../../../utils/customIcons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckboxGroup from 'antd/lib/checkbox/Group'
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  colorPositive,
  colorNeutral,
  colorNeutralDark,
  colorNegative,
} from '../../../utils/colors'
import {
  v2_plans,
  v3_plans,
  sync_plans_old_monthly,
  sync_plans_old_yearly,
  sync_plans_new_monthly,
  sync_plans_new_yearly,
} from '../components/OrgPrices'
import ColorPicker from '../../syncs/components/ColorPicker'

import momentTz from 'moment-timezone'
import moment from 'moment'
import { debounce, isBuffer } from 'lodash'
import {
  CBStep,
  CBSteps,
  CBInput,
  CBButton,
  SubmitButton,
  CBHeader,
  CBCheckItem,
  CBRadioGroupBig,
  CBRadioGroup,
  CBListItem,
  CBTextArea,
  CBModal,
  CBList,
  CBCalendar,
  CBIncrementer,
} from '../../core/components/cbd'
import OrgPrices from '../components/OrgPrices'
const theme = {
  scheme: 'google',
  author: 'seth wright (http://sethawright.com)',
  base00: '#1d1f21',
  base01: '#282a2e',
  base02: '#373b41',
  base03: '#969896',
  base04: '#b4b7b4',
  base05: '#c5c8c6',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#CC342B',
  base09: '#F96A38',
  base0A: '#FBA922',
  base0B: '#198844',
  base0C: '#3971ED',
  base0D: '#3971ED',
  base0E: '#A36AC7',
  base0F: '#3971ED',
}

const CreateOrganizationPage = ({}) => {
  function getUserSeatLabel(label) {
    return `${label}-${isYearlyPlan }`
  }
  function getSyncLabel() {
    return `one-way-sync-2022-${isYearlyPlan}`
  }
  const [license] = useGlobalState('license')
  const [currentSyncs] = useGlobalState('syncs')
  const [currentSchedulers] = useGlobalState('schedules')

  const [accounts] = useGlobalState('accounts')
  const [orgId, setOrgId] = useState(undefined)

  const [admin, setAdmin] = useState(0)
  const [basic, setBasic] = useState(0)

  const [premium, setPremium] = useState(0)

  const [pro, setPro] = useState(0)

  const [proV3, setProV3] = useState(0)

  const [syncs, setSyncs] = useState(0)
  const [isYearlyPlan, setIsYearlyPlan] = useState("monthly")
  const [error,setError] = useState(null)
  const [currentPlan, setCurrentPlan] = useState('')
  const [loading, setLoading] = useState(false)

  const [converted, setConverted] = useState(true)

  const filterDomains = [
    'gmail.com',
    'outlook.com',
    'hotmail.com',
    'fastmail.com',
    'protonmail.com',
    'google.com',
    'microsoft.com',
    'yahoo.com',
    'comcast.net',
    'att.net',
    'verizon.net'
  ]

  useEffect(() => {
    if (license.planId) {
      if (license.planId.includes('yearly')) {
        setIsYearlyPlan("yearly")
      }
      if (license.planId.includes('pro')) {
        setCurrentPlan('pro-v3')
      } else {
        setCurrentPlan(license.planId.split('-')[0])
      }

      if (!license.trial && license.subscriptionId.includes('s')) {
        // if(license.planId.includes('basic')) {
        //   setBasic(1)
        // } else if(license.planId.includes('premium')) {
        //   setPremium(1)
        // } else if(license.planId.includes('v3')) {
        //   setProV3(1)
        // } else if(license.planId.includes('pro')) {
        //   setPro(1)
        // }
      }
    }
  }, [license])


  

  async function adjustSeats(org) {
    setError(null)
    var body = {
      seats: {},
      userPlan: `${currentPlan}-${isYearlyPlan}`,
      converted: converted,
    }
    if (syncs > 0) body.seats[getSyncLabel()] = syncs

    if (admin > 0) body.seats[getUserSeatLabel('admin')] = admin

    if (basic > 0) body.seats[getUserSeatLabel('basic')] = basic

    if (premium > 0) body.seats[getUserSeatLabel('premium')] = premium

    if (pro > 0) body.seats[getUserSeatLabel('pro')] = pro

    if (proV3 > 0) body.seats[getUserSeatLabel('pro-v3')] = proV3

    if (!converted) {
      body.seats[getUserSeatLabel('group-admin')] = 1
    } else {
      body.seats[getUserSeatLabel(currentPlan)] =
        (body.seats[getUserSeatLabel(currentPlan)]
          ? body.seats[getUserSeatLabel(currentPlan)]
          : 0) + 1
    }
    body.subscriptionId = license.stripeUserId
    //console.log(body)

    try {
      setLoading(true)
      var org = await API.post(
        'organization',
        '/organization/?create_seats=true&id=' + orgId,
        {
          body,
        }
      )

      // location.reload()
      //console.log(org)
      
      if (org.message.includes('stripe.com')) window.location = org.message
    } catch (e) {
      setError(e.response.data.message)
      setLoading(false)
    } finally {
    }
  }

  function getPlanLabel(type) {
    return (
      <div>
        <div>
          ${v3_plans[type][isYearlyPlan].value}{' '}
          {isYearlyPlan }
        </div>
        <div>
          <b>
            {v3_plans[type][isYearlyPlan].syncs} Sync
            Connections
          </b>
        </div>
        {type != "admin" && <div>Unlimited connect calendar accounts</div>}
        {type != "admin" ? <div>
          10 booking
          links
        </div> : <div>0 booking links</div>}
        {type == "admin" && <div className="">Group administration only.</div>}
        {type == "admin" && currentSyncs && currentSyncs.length > 0 && <div className="text-red-500">Syncs will be deleted.</div>}
        {type == "admin" && currentSchedulers && currentSchedulers.length > 0 && <div className="text-red-500">Schedulers will be deleted.</div>}
      </div>
    )
  }

  return (
    <>
      {((license.email && license.orgId == 'single') ||
        (process.env.GATSBY_DEBUG_UI && license.email)) && (
        <div className="cb-content-frame" style={{ background: 'white' }}>
          {process.env.GATSBY_DEBUG_UI && license.orgId != 'single' && (
            <div className="text-red-600">
              
            </div>
          )}
          <div className="m-8" style={{ minHeight: 800 }}>
            <CBHeader
              icon={<Connections></Connections>}
              label="Create Organization"
              buttonLabel="Help"
              buttonIcon={<Info></Info>}
              buttonAction={e=>{
                window.open(
                  'https://help.calendarbridge.com/doc/group-account-management/creating-a-group-account/',
                  '_blank'
                )
              }}
            >
              Create Org
            </CBHeader>
            Organization:
            <div className="flex flex-row">
              <Select
                style={{ width: 300 }}
                placeholder="Select Domain"
                value={orgId}
                onChange={(v) => {
                  //console.log(v)
                  setOrgId(v.toLowerCase())
                }}
              >
                {!filterDomains.includes(license.email.split('@')[1]) && (
                  <Select.Option key="69" value={license.email.split('@')[1]}>
                    {license.email.split('@')[1]}
                  </Select.Option>
                )}
                {accounts
                  .filter(
                    (acc) => !filterDomains.includes(acc.email.split('@')[1])
                  )
                  .map((acc, i) => {
                    return (
                      <Select.Option key={i} value={acc.email.split('@')[1]}>
                        {acc.email.split('@')[1]}
                      </Select.Option>
                    )
                  })}
              </Select>
             </div>
             {filterDomains.includes(license.email.split('@')[1]) && accounts.length == 0 && <div className="mt-4 mb-4"><a href="/dashboard/accounts/new">Add a business account to continue</a></div>}
            
            {process.env.GATSBY_DEBUG_UI == 'true' && (
              <div className="p-4 m-2 border-2 border-red-700 rounded-sm">
                Debug
                <div>Manual orgId entry for testing:</div>
                <Input
                  value={orgId}
                  onChange={(e) => {
                    setOrgId(e.target.value.toLowerCase())
                  }}
                ></Input>
                <div>
                  <Checkbox
                    checked={converted}
                    onChange={(e) => {
                      setConverted(e.target.checked)
                      if (!e.target.checked) {
                        setCurrentPlan('basic')
                      }
                    }}
                  >
                    Uncheck to create old style org for testing (basic plan w
                    group-admin in stripe)
                  </Checkbox>
                </div>
              </div>
            )}
            <div className="flex items-center justify-center">

            <Radio.Group
              title="Billing Period"
              value={isYearlyPlan}
              options={[{label:'Monthly', value: 'monthly'}, {label:'Yearly',value:'yearly'}]}
              optionType="button"
              buttonStyle="solid"
              onChange={(e) => {
                setIsYearlyPlan(e.target.value)
              }}
            ></Radio.Group>
            </div>
            {currentPlan != '' && (
              <CBRadioGroupBig
                id="asdas"
                title="Your user plan"
                horizontal={true}
                collapsible={false}
                selected={
                  ['admin','basic', 'premium', 'pro-v3'].indexOf(currentPlan)
                }
                values={['admin','basic', 'premium', 'pro-v3']}
                labels={['Admin Only','Basic', 'Premium', 'Pro']}
                subLabels={[
                  getPlanLabel('admin'),
                  getPlanLabel('basic'),
                  getPlanLabel('premium'),
                  getPlanLabel('pro'),
                ]}
                onChange={(e) => {
                  setCurrentPlan(['admin','basic', 'premium', 'pro-v3'][e])
                }}
              ></CBRadioGroupBig>
            )}
            <div className="lg:w-2/4">
              <div className="text-lg">Organization Seats</div>
              
              <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral flex-wrap">
                <div className="text-lg">Basic User Seat</div>
                <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
                  <CBIncrementer
                    min={0}
                    onChange={(e) => {
                      setBasic(e)
                    }}
                    defaultValue={basic}
                  />
                  <div className="text-lg font-bold">
                    x $
                    {
                      v3_plans['basic'][isYearlyPlan ]
                        .value
                    }{' '}
                    {isYearlyPlan}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
                <div className="text-lg"> Premium User Seat</div>
                <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
                  <CBIncrementer
                    min={0}
                    onChange={(e) => {
                      setPremium(e)
                    }}
                    defaultValue={premium}
                  />
                  <div className="text-lg font-bold">
                    x $
                    {
                      v3_plans['premium'][isYearlyPlan ]
                        .value
                    }{' '}
                    {isYearlyPlan }
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
                <div className="text-lg"> Pro User Seat</div>
                <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
                  <CBIncrementer
                    min={0}
                    onChange={(e) => {
                      setProV3(e)
                    }}
                    defaultValue={proV3}
                  />
                  <div className="text-lg font-bold">
                    x $
                    {v3_plans['pro'][isYearlyPlan].value}{' '}
                    {isYearlyPlan }
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral flex-wrap">
                <div className="text-lg">Admin User Seat</div>
                <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
                  <CBIncrementer
                    min={0}
                    onChange={(e) => {
                      setAdmin(e)
                    }}
                    max={5}
                    defaultValue={admin}
                  />
                  <div className="text-lg font-bold">
                    x $
                    {
                      v3_plans['admin'][isYearlyPlan ]
                        .value
                    }{' '}
                    {isYearlyPlan}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 items-center gap-x-8 p-4 m-2 bg-neutral">
                <div className="text-lg">Managed Sync</div>
                <div className={'flex flex-col lg:flex-row gap-4 items-center'}>
                  <CBIncrementer
                    min={0}
                    onChange={(e) => {
                      setSyncs(e)
                    }}
                    defaultValue={syncs}
                  />
                  <div className="text-lg font-bold">
                    x $
                    {isYearlyPlan == 'yearly'
                      ? `${sync_plans_new_yearly} yearly`
                      : `${sync_plans_new_monthly} monthly`}
                  </div>
                </div>
              </div>
              {error && <div className="m-4 text-red-600">
                    {error}
              </div>}
              <SubmitButton
                loading={loading}
                disabled={orgId === undefined && orgId != ''}
                onClick={(e) => adjustSeats()}
              >
                Create Organization
              </SubmitButton>
              
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CreateOrganizationPage
function getOptions(checkOptions) {
  return [...checkOptions]
}
