import { NameDescription } from './Pages/NameDescription'
import { Availability } from './Pages/Availability'
import { Invitees } from './Pages/Invitees'
import { Times } from './Pages/Times'

export const GroupSchedulePages = [
  NameDescription,
  Availability,
  Invitees,
  Times,
]
