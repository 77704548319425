import React from 'react'

import { Modal, Input, Alert } from 'antd'

const DeleteAccountModal = ({
	visible,
	handleOk,
	confirmLoading,
	handleCancel,
}) => {
	return (
		<Modal
			title="Delete Account"
			visible={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
		>
			<>
				<Alert
					message="ALERT"
					description="This will permanently remove this account and all of its data."
					type="error"
					showIcon
				/>
			</>
		</Modal>
	)
}

export default DeleteAccountModal
