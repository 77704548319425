import React, {useState,useCallback, useEffect} from 'react'
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Card, Button, Spin, Input, Col } from 'antd';
import Text from 'antd/lib/typography/Text'
import { Link } from 'gatsby'
import { colorNeutralDark, colorNegative } from '../../../utils/colors'
import { useGlobalState } from '../../../store'
import { API } from 'aws-amplify'
import { debounce } from "lodash";
import { faHourglassEnd } from '@fortawesome/free-solid-svg-icons'
import { CBUrlField,CBButton,CBHeader } from '../../core/components/cbd'
const { Search } = Input;
const VanityUrl = () => {

    const [license] = useGlobalState('license')
    const [canSubmit,setCanSubmit] = useState(false)
  
    const [loading,setLoading] = useState(false)
    const [alias,setAlias] = useState(license.vanityAlias)

    const [canChange,setCanChange] = useState(license.vanityAlias == "")
    const [error,setError] = useState(null)

    useEffect(()=>{
      setAlias(license.vanityAlias)
    },[license])
   async function submitVanity(){
     try{
      
   if(alias.length >=5 && validateUserName(alias)){
     var res = await API.post('scan','/alias?action=setAlias',{
     body:{
        alias
      }
    }
     )
  
     window.location.reload()
  } else{
    setError("Invalid user name")
    setCanSubmit(false)
    setLoading(false)
  }
    } catch(ex) {
      //console.log(ex)
      setError("User name no longer available")
      setLoading(false)
      
    } 
  
  }

  async function checkName(name){
    if(name.length < 5){
      setError("User name must be at least 5 characters")
      setLoading(false)
      setCanSubmit(false)
      return
    }
    
    try{
      
    var res = await API.get('scan','/alias?action=checkAlias&alias=' + encodeURIComponent(name),{
   }
    )
      setError(null)
      setCanSubmit(true)
      //console.log(alias)
      //console.log(name)
   } catch(ex) {

    //console.log(name)
    // console.log(ex)
    //  alert("bad")
    setError("User name not available")
    setCanSubmit(false)
   }
 
   setLoading(false)
 }

  const queryUserName =  useCallback(debounce((name) => checkName(name),2000),[])
   

  function validateUserName(name) {
    var usernameRegex = /^[a-zA-Z0-9-_]+$/;
    return  name.match(usernameRegex)
  }
  // disabled={license.vanityAlias != ""}
    return <>
    <CBHeader label={license.vanityAlias != "" ? "Your Custom Url" : "Choose Your Custom Url"} subheader={true}></CBHeader>
    <div  bordered={false} title={license.vanityAlias != "" ? "Your Custom Url" : "Choose Your Custom Url"}>
    <center>
        <Col>
        <CBUrlField maxLength={25} value={license.trial ? 'your_name' : alias} disabled={license.trial || !canChange} label="https://calendarbridge.com/book/" hint="your custom name" 
      onChange={e =>{
        setLoading(true)
        var name = e.toLowerCase()
        var valid = validateUserName(name)
        if(valid || name =="") {
          setCanSubmit(false)
          setError(null)
          setAlias(name)
          queryUserName(name)
        } else {
          setCanSubmit(false)
          setLoading(false)
        }
      }
      }
      error={error}></CBUrlField>

        {/* <Input disabled={license.trial || !canChange} maxLength={25} style={{width:400}} onChange={e=>{
      setLoading(true)
      var name = e.target.value.toLowerCase()
      var valid = validateUserName(name)
      if(valid || name =="") {
        setCanSubmit(false)
        setError(null)
        setAlias(name)
        queryUserName(name)
      } else {
        setCanSubmit(false)
        setLoading(false)
      }
      }} value={license.trial ? 'your_name' : alias} addonBefore={window.location.origin + "/book/"} defaultValue={license.trial ? 'yourname' : license.vanityAlias}></Input> */}
  {/* debounce + other */}
  
 
<div><Text style={{ color: colorNeutralDark }}>(5-25 Characters, a-z,0-9,-,_)</Text>  {loading && (<Spin style={{marginLeft:8, marginTop:4}}
      indicator={
        <Loading3QuartersOutlined spin style={{  color: '--var(burnt)', fontSize: '25px' }} />
      }
    />
)}</div>
</Col>
  <div>
  
  </div>
  <div className="flex flex-row justify-center gap-x-4" style={{marginTop:8}}>
  {license.vanityAlias != "" && canChange && <div >
    <CBButton type="secondary" onClick={e => {
    setCanChange(false)
    setLoading(false)
    setCanSubmit(false)
    setAlias(license.vanityAlias)
}}>Cancel</CBButton></div>}

  {!license.trial && canChange && <CBButton disabled={!canSubmit || loading} onClick={e=> {
  
  setLoading(true)
  submitVanity()
  }}>Submit</CBButton>}
  
  
  {license.vanityAlias != "" && !canChange && <CBButton type="primary" onClick={e => setCanChange(true)}>Change</CBButton>}
  </div>
  </center>
  {license.trial && <center><Link
                key={5}
                to={`/dashboard/settings`}
                >Upgrade to any paid subscription to get a Custom Url!</Link></center>}
  </div>
  
  </>;
  }

export default VanityUrl